import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import UserPermissionsTable from '../UserPermissionsTable/UserPermissionsTable';
import RoleCell from '../UserPermissionsTable/Cells/RoleCell';
import {
  getUserPermissionsRoles,
  updateUserPermissionsRoleReset,
  addUserPermissionsUsersToGroupReset
} from '../../../../store/actions/userPermissionsActions';
import Loading from '../../../Utils/Loading/Loading';
import UserPermissionsDetailsPage from '../UserPermissionsDetailsPage/UserPermissionsDetailsPage';
import RoleDetailsPage from './RoleDetailsPage';

const groupsTableHeaders = [
  {
    Header: 'Name',
    accessor: 'roleName',
    Cell: RoleCell
  },
  {
    Header: 'Description',
    accessor: 'roleDescription'
  }
];

const Roles = props => {
  const {
    userPermissionsRoles,
    userPermissionsRolesLoading,
    userPermissionsRolesError,
    getUserPermissionsRoles,
    updateUserPermissionsRoleReset,
    addUserPermissionsUsersToGroupReset
  } = props;

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    getUserPermissionsRoles();
  }, [getUserPermissionsRoles]);

  const handleReset = () => setSelected(null);

  const handleRowClick = rowObject => setSelected(rowObject);

  const handleExit = () => {
    // reset state
    handleReset();
    updateUserPermissionsRoleReset();
    addUserPermissionsUsersToGroupReset();
    // fetch all groups again
    getUserPermissionsRoles();
  };

  const unit = 'role';

  return (
    <div className="edp-user-permissions__groups-container">
      {selected ? (
        <UserPermissionsDetailsPage exit={handleExit} subtitle={'Role Details'}>
          <RoleDetailsPage selected={selected} setSelected={setSelected} />
        </UserPermissionsDetailsPage>
      ) : userPermissionsRolesLoading ? (
        <Loading dark />
      ) : userPermissionsRolesError ? (
        <div
          style={{
            height: '13em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <span style={{ margin: '2em 5em' }}>
            Apologies, an error has occurred. Please try again later.
          </span>
        </div>
      ) : (
        <UserPermissionsTable
          generateRows={5}
          data={userPermissionsRoles}
          tableHeaders={groupsTableHeaders}
          onRowClick={handleRowClick}
          unit={unit}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userPermissionsRoles: state.userPermissions.userPermissionsRoles,
    userPermissionsRolesLoading:
      state.userPermissions.userPermissionsRolesLoading,
    userPermissionsRolesError: state.userPermissions.userPermissionsRolesError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserPermissionsRoles: () => dispatch(getUserPermissionsRoles()),
    updateUserPermissionsRoleReset: () =>
      dispatch(updateUserPermissionsRoleReset()),
    addUserPermissionsUsersToGroupReset: () =>
      dispatch(addUserPermissionsUsersToGroupReset())
  };
};

Roles.displayName = 'User Permissions Portal - Roles';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Roles);
