import React from 'react';
import { ReactComponent as Burger } from '../../../assets/img/burger.svg';
import PropTypes from 'prop-types';
import cn from 'classnames';

const NavigationBurgerIcon = props => {
  const { toggle, burgerRef } = props;

  return (
    <button
      ref={burgerRef}
      onClick={toggle}
      className={cn('edp-navbar__burger-icon')}
    >
      <Burger />
    </button>
  );
};

NavigationBurgerIcon.propTypes = {
  toggle: PropTypes.func.isRequired,
  burgerRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

export default NavigationBurgerIcon;
