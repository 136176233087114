import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import stringToTitleCase from '../../../../helpers/stringToTitleCase';
import Loading from '../../../Utils/Loading/Loading';
import {
  deleteUserPermissionsGroup,
  deleteUserPermissionsGroupReset
} from '../../../../store/actions/userPermissionsActions';

const DeleteGroupDialog = props => {
  const {
    userPermissionsGroups,
    userPermissionsDeletingGroupError,
    userPermissionsDeletingGroup,
    deleteUserPermissionsGroup,
    deleteUserPermissionsGroupReset,
    groupName,
    groupId,
    exitPage
  } = props;

  // show error message?
  const [showError, setShowError] = useState(false);
  // show success message?
  const [showSuccess, setShowSuccess] = useState(false);
  // has the user submitted a new group yet?
  const [submitted, setSubmitted] = useState(false);
  // is the modal open?
  const [open, setOpen] = React.useState(false);

  // handle success/error response in submitting group
  useEffect(() => {
    // check to see if the currently entered group information is in our groups array (i.e. it has been submitted)
    const groupHasBeenDeleted = true;
    // TODO: implement this check ^

    // if error is populated in the store, show error message
    if (userPermissionsDeletingGroupError) {
      setShowSuccess(false);
      setShowError(true);
    } // if we are not currently deleting the group, the DELETE request has been submitted
    // and the group has actually been deleted, show success
    else if (
      !userPermissionsDeletingGroup &&
      groupHasBeenDeleted &&
      submitted
    ) {
      setShowError(false);
      setShowSuccess(true);
    }
  }, [
    userPermissionsGroups,
    submitted,
    userPermissionsDeletingGroup,
    userPermissionsDeletingGroupError
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const resetAllState = () => {
    setOpen(false);
    deleteUserPermissionsGroupReset();
    setSubmitted(false);
    setShowError(false);
    setShowSuccess(false);
  };

  const handleCancel = () => resetAllState();

  const handleClose = () => {
    resetAllState();
    exitPage();
  };

  const handleDelete = () => {
    deleteUserPermissionsGroup(groupId);
    setSubmitted(true);
  };

  const titleCaseUnit = stringToTitleCase(props.unit);
  const loading = userPermissionsDeletingGroup;

  return (
    <div>
      <button
        className={cn('edp-user-permissions__button', 'red', 'right')}
        onClick={handleClickOpen}
      >
        <DeleteOutlineIcon />
        {`Delete`}
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {!loading && (
          <DialogTitle id="form-dialog-title">
            Delete {titleCaseUnit}
          </DialogTitle>
        )}
        <div
          style={{
            height: '4em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          {showError ? (
            <span style={{ margin: '1em 2em' }}>An error has occurred.</span>
          ) : showSuccess ? (
            <span style={{ margin: '1em 2em' }}>
              Successfully deleted "{groupName}" group.
            </span>
          ) : loading ? (
            <span style={{ margin: '2em 3em' }}>
              <Loading mini dark />
            </span>
          ) : (
            <span style={{ margin: '1em 2em' }}>
              Are you sure you wish to delete the "{groupName}" group?
            </span>
          )}
        </div>
        <DialogActions>
          {showError || showSuccess ? (
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          ) : loading ? null : (
            <>
              <Button onClick={handleCancel} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="secondary">
                Delete
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeleteGroupDialog.propTypes = {
  unit: PropTypes.string.isRequired,
  groupId: PropTypes.number.isRequired,
  groupName: PropTypes.string.isRequired,
  exitPage: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    userPermissionsGroups: state.userPermissions.userPermissionsGroups,
    userPermissionsCreatingGroup:
      state.userPermissions.userPermissionsCreatingGroup,
    userPermissionsCreateGroupError:
      state.userPermissions.userPermissionsCreateGroupError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteUserPermissionsGroup: (name, description, isActive) =>
      dispatch(deleteUserPermissionsGroup(name, description, isActive)),
    deleteUserPermissionsGroupReset: () =>
      dispatch(deleteUserPermissionsGroupReset())
  };
};

DeleteGroupDialog.displayName = 'User Permissions Portal - Delete Group Dialog';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteGroupDialog);
