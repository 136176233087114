import pages from '../../config/page-config';

const contains = (array, item) => {
  return array.indexOf(`${item}`) > -1;
};

export const isObjectEmpty = object => {
  return Object.entries(object).length === 0 && object.constructor === Object;
};

const accessiblePages = userPermissions => {
  if (userPermissions !== null && userPermissions !== undefined) {
    // check whether user permissions object is empty
    let userPermissionsEmpty = isObjectEmpty(userPermissions);
    // if there are no user permissions, return no pages
    if (userPermissionsEmpty) {
      return [];
    }

    // get the name of all pages user has access to
    let userAccessiblePages = Object.keys(userPermissions);

    // filter all pages to get the objects of the pages user has access to
    let accessiblePages = pages.filter(page => {
      let pageName = page.ruleName;
      return contains(userAccessiblePages, pageName);
    });

    let filteredPagesArray = [];
    // go through each accessible page
    accessiblePages.forEach(page => {
      let temp = [];
      // filter the children of these pages (i.e. drilldowns) and check that the user has access
      let res = page.navChildren.filter(child => {
        let childRuleName = child.ruleName.split(':')[1];
        return userPermissions[page.ruleName].indexOf(`${childRuleName}`) > -1;
      });
      // add each object to the array of children of this page
      res.forEach(item => {
        temp.push(item);
      });
      // retain the page object information, but overwrite it's children with the newly filtered children (i.e. drilldowns)
      page.navChildren = temp;
      // push to the array of filtered pages
      if (temp.length > 0) {
        filteredPagesArray.push(page);
      }
    });
    return filteredPagesArray;
  }
  return [];
};

export const homepageUrl = userPermissions => {
  let pages = accessiblePages(userPermissions);
  let url = pages.length > 0 ? pages[0].navChildren[0].to : '/';
  return url;
};

const checkIfCurrentPageIsPrintable = () => {
  const currentUrl = window.location.href;
  let result = false;
  pages.forEach(page => {
    page.navChildren.forEach(drilldown => {
      if (currentUrl.includes(drilldown.to) && drilldown.printable) {
        result = true;
      }
    });
  });

  return result;
};

export const currentPageIsPrintable = checkIfCurrentPageIsPrintable();

export default accessiblePages;
