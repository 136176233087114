import React, { Component } from 'react';
import moment from 'moment-timezone';
import { clockTimeFormat, clockTimeZone } from '../../../constants';

class NavigationClock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: ''
    };
  }

  componentDidMount() {
    this.getDate();
    this.interval = setInterval(() => {
      this.getDate();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getFormattedTime() {
    return moment.tz(clockTimeZone).format(clockTimeFormat);
  }

  getDate() {
    this.setState({ currentTime: this.getFormattedTime() });
  }

  render() {
    const hour12 = () => {
      var hour = d.getHours();

      if (hour >= 12) {
        hour = hour - 12;
      }

      if (hour === 0) {
        h = 12;
      }
      return hour;
    };

    var d = new Date();
    var m = d.getMinutes();
    var h = hour12();

    return (
      <>
        <div className="d-none d-sm-inline">
          <div className="edp-nav-clock__analog nav__icon">
            <div
              className="m-hand hand"
              style={{
                transform: 'translate(-50%, -100%) rotate(' + m * 6 + 'deg)'
              }}
            />
            <div
              className="h-hand hand"
              style={{
                transform:
                  'translate(-50%, -100%) rotate(' + (h * 30 + m * 0.5) + 'deg)'
              }}
            />
            <div className="center" />
          </div>
        </div>

        <span
          className="noselect edp-nav-clock__digital"
          style={clockTimeStyle}
        >
          {this.state.currentTime}
        </span>
      </>
    );
  }
}

const clockTimeStyle = {
  verticalAlign: 'middle',
  color: '#fff'
};

NavigationClock.displayName = 'NavigationClock';
export default NavigationClock;
