export default `
# Release Notes
## v6.0.0
23/03/22

### What's New

Feature
* FM Cleaning Schedules Dashboard

* FM Cleaning Reporting Dashboard

* SITA Message Type 15

* DIMA PAX table

* FM Cleaning Schedules SignalR and baseline PAX components
    * AssetPerformance SignalR channel (with all associated logic, views, and stored procedures)
    * Baseline PAX table (including the ADF pipeline & trigger to populate it)
    * The baseline PAX SignalR channel (with all associated logic, views, and stored procedures)

`;
