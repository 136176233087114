import moment from 'moment';
import { getDayOperation } from '../../components/Pages/IOC/shared/utils';
import getRandomInt from '../../helpers/randomInt';
import { clockTimeZone } from '../../constants';

const MAX_RESPONSES = 3050;
const MIN_RESPONSES = 3000;

const randomDeviceName = (terminal) => {
  const deviceNames = [
    `Terminal ${terminal} - Kiosk`,
    `Terminal ${terminal} - Male toilet`,
    `Terminal ${terminal} - Female toilet`,
    `Terminal ${terminal} - North restaurant`,
    `Terminal ${terminal} - Arrivals Gate ${getRandomInt(1, 30)}`,
    `Terminal ${terminal} - Departures Gate ${getRandomInt(1, 30)}`,
    `Terminal ${terminal} - Baggage`,
    `Terminal ${terminal} - Check-in counter ${getRandomInt(1, 30)}`
  ];

  return deviceNames[getRandomInt(0, deviceNames.length - 1)];
};

const randomResponses = (terminal) => {
  const { min } = getDayOperation(moment().subtract(3, 'days'));
  const max =
    moment()
      .tz(clockTimeZone)
      .unix() * 1000;
  const randomDate = () =>
    moment(getRandomInt(min, max)).format('YYYY-MM-DDTHH:mm:ssZ');

  // randomly choose one of:
  const reasons = [
    'Dirty Basin',
    'No paper',
    'Boiling water',
    'Wet floor',
    'Busy',
    null
  ];

  const randomReason = reasons[getRandomInt(0, reasons.length - 1)];
  const randomScore = getRandomInt(1, 5);

  let happyFaceScores = [];

  const numberOfResponses = getRandomInt(MIN_RESPONSES, MAX_RESPONSES);
  for (let y = 0; y < numberOfResponses; y++) {
    happyFaceScores.push({
      assetId: '12345',
      happyFaceDeviceId: '12345',
      happyFaceDeviceName: randomDeviceName(terminal),
      direction: 'Departure',
      dateTime: randomDate(),
      timeGrain: 'Event',
      score: randomScore,
      rawScoreSum: randomScore,
      numberOfResponses: 1,
      reasons: randomReason ? randomReason : null
    });
  }

  return {
    happyFaceScores: happyFaceScores,
    cleans: [],
    thresholdBreaches: []
  };
};

randomResponses()

export const amenities = {
  assetPerformance: {
    terminal2: {
      happyFaceScores: []
    },
    terminal3: {
      happyFaceScores: []
    },
    terminal4: {
      happyFaceScores: [
        {
          happyFaceDeviceId: 71,
          dateTime: '2022-06-21T12:44:38',
          timeGrain: 'Event',
          score: 5,
          rawScoreSum: 5,
          numberOfResponses: 1,
          reasons: 'Dirty basin',
          happyFaceDeviceName: 'T4 Departures Gate 41',
          direction: 'N/A',
          deviceLocation: 'T4'
        },
        {
          happyFaceDeviceId: 71,
          dateTime: '2022-06-21T12:44:38',
          timeGrain: 'Event',
          score: 5,
          rawScoreSum: 5,
          numberOfResponses: 1,
          reasons: 'Dirty basin',
          happyFaceDeviceName: 'T4 Departures Gate 41',
          direction: 'N/A',
          deviceLocation: 'T4'
        },
        {
          happyFaceDeviceId: 71,
          dateTime: '2022-06-21T12:44:38',
          timeGrain: 'Event',
          score: 5,
          rawScoreSum: 5,
          numberOfResponses: 1,
          reasons: 'Dirty basin',
          happyFaceDeviceName: 'T4 Departures Gate 41',
          direction: 'N/A',
          deviceLocation: 'T4'
        },

        {
          happyFaceDeviceId: 71,
          dateTime: '2022-06-21T14:44:38',
          timeGrain: 'Event',
          score: 5,
          rawScoreSum: 5,
          numberOfResponses: 1,
          reasons: 'Dirty basin',
          happyFaceDeviceName: 'T4 Departures Gate 41',
          direction: 'N/A',
          deviceLocation: 'T4'
        },
        {
          happyFaceDeviceId: 71,
          dateTime: '2022-06-21T14:44:38',
          timeGrain: 'Event',
          score: 5,
          rawScoreSum: 5,
          numberOfResponses: 1,
          reasons: 'Dirty basin',
          happyFaceDeviceName: 'T4 Departures Gate 41',
          direction: 'N/A',
          deviceLocation: 'T4'
        },
        {
          happyFaceDeviceId: 71,
          dateTime: '2022-06-21T14:44:38',
          timeGrain: 'Event',
          score: 5,
          rawScoreSum: 5,
          numberOfResponses: 1,
          reasons: 'Dirty basin',
          happyFaceDeviceName: 'T4 Departures Gate 41',
          direction: 'N/A',
          deviceLocation: 'T4'
        }
      ]
    }
  }
};
