import intArrivalsLiveImageUrl from '../../../images/intArrivalsLive.png';

export default `
# International Arrivals - Live
## Board Overview
This board provides an overview of the current average Passenger transit times through International Arrivals as well as a graphical representation of the transit times over the last 24 hours:

![International Arrivals Live board](${intArrivalsLiveImageUrl})

**Card 1** - Average transit time by zone (mins)  
**Card 2** – Average Peak Transit Times (mins)  
**Card 3** - Average transit times (mins)  

## Historical View Functionality
You can obtain a historical view of the 3 cards by using the date/time selector in the top right corner. This will show exactly what the Int Arrivals - Live board looked like at the specified date & time.

## Card 1 - Average transit times by zone (mins) 
This card reports the current travel time average between zones. You can see the passenger travel times between different zones (Gates, Immigration, Baggage Reclaim) from Gates to the Forecourt in minutes for the current day and time.
You can also view: 

- The target travel time for each zone.
- The average travel time for the same day of the week. This is calculated by using the averages recorded from historical statistics.

The data source is CMX and the card updates every 5 minutes.

## Card 2 - Average peak transit times (mins) 
This card displays a table with the top four peak travel times, in minutes, for the Immigration and Baggage Reclaim zone. The highest averages are recorded over the course of the last 24 hours to the current time.
A red, amber or green colour is assigned to each timeslot to indicate whether the time recorded is over or under the target time. 
The data source is CMX and the card updates every 5 minutes.

## Card 3 - Average transit times – Last 24 hrs. 
The graph shows a comparison between the overall average for today, the target average, and the average for this same day of the week. The data is represented over a specific time and zone selected by the following filters:

1. Data by zone i.e. gates, immigration, baggage reclaim or all zones. 
2. Data by duration i.e. 3hrs, 6hrs, 12hrs and 24hrs. 

The data source is CMX and the card updates every 5 minutes.
`.trim();
