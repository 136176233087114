import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PrimarySideBarNavigation from './PrimarySideBarNavigation';
import SecondarySideBarNavigation from './SecondarySideBarNavigation';
import cn from 'classnames';
import { toggleNavigationSideBar } from '../../../store/actions/navigationActions';
import accessiblePages from '../../../assets/js/config/pages';
import PropTypes from 'prop-types';
import OutsideClick from '../../Utils/OutsideClick/OutsideClick';
import usePrevious from '../../../helpers/usePrevious';

const NavigationSideBar = (props) => {
  const {
    showNavigationSideBar,
    toggleNavigationSideBar,
    userPermissions,
    burgerRef
  } = props;

  const prevShowNavigationSideBar = usePrevious(showNavigationSideBar);

  const [activePrimaryItem, setActivePrimaryItem] = React.useState(null);
  const [activeSecondaryItem, setActiveSecondaryItem] = React.useState(null);
  const [mouseOverSecondaryMenu, setMouseOverSecondaryMenu] = React.useState(
    false
  );

  useEffect(() => {
    // if the navigation bar has been hidden
    if (prevShowNavigationSideBar === true && showNavigationSideBar === false) {
      resetMenuState();
    }
  }, [
    prevShowNavigationSideBar,
    props.showNavigationSideBar,
    showNavigationSideBar
  ]);

  const viewportWidth = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  const breakpointXL = 1200;
  const breakpointSatisfied = viewportWidth <= breakpointXL;

  const handlePrimaryItemClick = (item) => {
    setActivePrimaryItem(item);
  };

  const handleSecondaryMenuHover = () => {
    setMouseOverSecondaryMenu(true);
  };

  const resetMenuState = () => {
    setActivePrimaryItem(null);
    setActiveSecondaryItem(null);
    setMouseOverSecondaryMenu(false);
  };

  const hideMenu = () => {
    resetMenuState();
    // on smaller devices (tablet & mobile)
    if (breakpointSatisfied) {
      // hide navigation sidebar when something is selected
      toggleNavigationSideBar();
    }
  };

  const navigationItems = accessiblePages(userPermissions);

  const handleSecondaryItemClick = (item) => {
    if (activeSecondaryItem === item) {
      setActiveSecondaryItem(null);
    } else {
      setActiveSecondaryItem(item);
    }
  };

  const handleNavigationClose = () => {
    toggleNavigationSideBar();
    setActivePrimaryItem(null);
    setActiveSecondaryItem(null);
  };

  const activePrimaryItemObject = navigationItems.filter(
    (obj) => obj.name === activePrimaryItem
  )[0];

  const activeSecondaryItemObject =
    activePrimaryItemObject !== null && activePrimaryItemObject !== undefined
      ? activePrimaryItemObject.navChildren.filter(
          (obj) => obj.name === activeSecondaryItem
        )[0]
      : null;

  const menuItemActive = activePrimaryItemObject || mouseOverSecondaryMenu;

  return (
    <OutsideClick
      callback={() =>
        breakpointSatisfied &&
        showNavigationSideBar &&
        toggleNavigationSideBar()
      }
      otherRefs={[burgerRef]}
    >
      <>
        <div
          className={cn(
            'edp-nav-side-bar__primary-container',
            'hide-when-printing',
            {
              expanded: showNavigationSideBar
            }
          )}
        >
          <PrimarySideBarNavigation
            pageConfig={navigationItems}
            activePrimaryItem={activePrimaryItemObject}
            mouseOverSecondaryMenu={mouseOverSecondaryMenu}
            handleNavigationClose={handleNavigationClose}
            handlePrimaryItemClick={handlePrimaryItemClick}
          />
        </div>
        {menuItemActive &&
        showNavigationSideBar && ( // if there is a selected primary item, show the secondary menu
            <SecondarySideBarNavigation
              navigationItems={activePrimaryItemObject.navChildren}
              handleSecondaryItemClick={handleSecondaryItemClick}
              activeSecondaryItem={activeSecondaryItemObject}
              handleSecondaryMenuHover={handleSecondaryMenuHover}
              hideMenu={hideMenu}
            />
          )}
      </>
    </OutsideClick>
  );
};

NavigationSideBar.propTypes = {
  userPermissions: PropTypes.object, // removing is required since we're having error in local
  burgerRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

const mapStateToProps = (state) => {
  return { showNavigationSideBar: state.navigation.showNavigationSideBar };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNavigationSideBar: () => dispatch(toggleNavigationSideBar())
  };
};

NavigationSideBar.displayName = 'Navigation Side Bar';
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationSideBar);
