import * as notificationsActionTypes from '../actionTypes/notificationActionTypes';
import updateObject from '../utility';
import notify from '../../components/Utils/Notifications/PopUp/createPopUpNotification';
import { toast } from 'react-toastify';
import moment from 'moment';
import { clockTimeZone } from '../../constants';

const initialState = {
  notifications: [],
  notificationCenterOpen: false,
  notificationsLoading: false,
  notificationsError: false,
  lastNotificationTime: null,
  activeTab: 'recent', // 'recent', 'history'
  filterOptions: null
};

const TWENTY_FOUR_HOURS = 86400000; /* ms */

const dismissAllNotifications = () => toast.dismiss();

const createNotifications = (state, payload, isNew) => {
  const updatedNotifications = payload
    .map(payloadNotification => {
      return {
        ...payloadNotification,
        isNew: isNew,
        customAttributes:
          payloadNotification.customAttributes &&
          JSON.parse(payloadNotification.customAttributes),
        date: moment(payloadNotification.datetime).tz(clockTimeZone)
      };
    })
    .filter(
      notification => notification.date.diff(moment()) < TWENTY_FOUR_HOURS
    );
  updatedNotifications.forEach(x => delete x.datetime);

  if (!state.notificationCenterOpen) {
    // Show the notification popup
    updatedNotifications
      .filter(notification => notification.isNew)
      .forEach(notification => {
        notify(notification);
      });
  }

  return updateObject(state, {
    notifications: [...state.notifications, ...updatedNotifications],
    lastNotificationTime: new Date(),
    notificationsLoading: false,
    ...(isNew && { lastNotificationTime: new Date() })
  });
};

const removeNotifications = (state, action) => {
  // TODO: Don't directly use the state array
  // TODO: Don't serialize all to JSON
  const notificationsToRemove = action.payload.map(x => JSON.stringify(x));
  return updateObject(state, {
    notifications: state.notifications.filter(
      notification =>
        !notificationsToRemove.includes(JSON.stringify(notification))
    )
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationsActionTypes.NOTIFICATIONS_GET_NOTIFICATIONS_COMPLETED:
      return createNotifications(state, action.payload, false);
    case notificationsActionTypes.NOTIFICATIONS_GET_NOTIFICATIONS_START:
      return updateObject(state, {
        notificationsLoading: true
      });
    case notificationsActionTypes.NOTIFICATIONS_GET_NOTIFICATIONS_FAILED:
      return updateObject(state, {
        notificationsLoading: false,
        notificationsError: true
      });
    case notificationsActionTypes.NEW_NOTIFICATION:
      return createNotifications(
        state,
        action.payload[Object.keys(action.payload)[0]], // TODO: Refactor. Should be able to handle multiple types of notifications
        !state.notificationCenterOpen
      );
    case notificationsActionTypes.NOTIFICATIONS_REMOVE:
      return removeNotifications(state, action);
    case notificationsActionTypes.NOTIFICATIONS_NOTIFICATION_CENTER_OPENED:
      dismissAllNotifications();
      return updateObject(state, {
        notificationCenterOpen: true,
        // TODO: Don't directly use the state notifications array
        notifications: state.notifications.map(notification => {
          return { ...notification, isNew: false };
        })
      });
    case notificationsActionTypes.NOTIFICATIONS_NOTIFICATION_CENTER_CLOSED:
      return updateObject(state, {
        notificationCenterOpen: false,
        activeTab: 'recent'
      });
    case notificationsActionTypes.NOTIFICATIONS_FILTERS_UPDATED:
      return updateObject(state, {
        filterOptions: action.payload
      });
    case notificationsActionTypes.NOTIFICATIONS_FILTERS_REMOVED:
      return updateObject(state, {
        filterOptions: null
      });
    case notificationsActionTypes.NOTIFICATIONS_TAB_CHANGED:
      return updateObject(state, {
        activeTab: action.payload
      });
    default:
      return state;
  }
};

export default reducer;
