export default `
# Release Notes
## v3.14.0
16/12/21

### New Features

* IOC
    * 7 Day Plan Board
        * Arrivals vs Departures card
        * Planned vs Actual Opening and Closing Times card
        * DHHS card
        * Arrivals and Departures Summary card
        * Arrivals Flight Schedules card
        * Departures Flight Schedule card
        * User Guide for 7 Day Plan Board
    * 7 Day Plan Board External
        * Limit access view for external users
    * Operations Plan
        * Moved to under IOC in main navigation

* IOC T3
    * PAX Presentation 
        * Pax presentation card for Check-In
        * Pax presentation card tab for Security
        * Forecast feature for Tomorrow and +1 Day
        * 15min and 1hr filter for data interval
        * 12hr and 24 hr timeline filter
        * User Guide updates for IOC T3 

* PBI Forward Bookings
    * Comparison functionality between primary date selection range and secondary date range.
    * Rebuild of simplified dataset in Power BI
    * Additional FB column LOS(var%,var_abs) and LTA.
    * Dynamic date filtering
    * Styling and alignment tweaks


### Bug Fixes

* Update the 7 Day Plan graph to aggregation of flights in an hour interval.
* Added handling of PAX and SEATS in terminal flight board.
* Fixed the "Unable to subscribe" notifications popping up on 7 Day Plan board. Added the auto-reconnect functionality in SignalR.
* Upgrade the deprecated version of SignalR package in React Client to latest and supported version.
* Added Flight Breakdown modal in 24-hour trendline on click of data points.
* Add date and time on the title/sub-title and Flight# column in the 24-hour trendline Flight Breakdown modal.
* Happy Face board enablement to IOC T2.
* Added handling of QF6, QF72, QF73, QF74 to be non-pax and zero out the value for pax and seats.
* Fixed breaking of trendlines and abnormality of the PAX total in the summary card.

`;
