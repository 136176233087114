import * as actionTypes from './actionTypes';
import updateObject from '../../../../store/utility';

const initialState = {
  pbiTokens: [],
  error: false
};

const getPowerBIEmbedToken = (state, action) => {
  if (action.payload === undefined || action.payload === null) {
    return state;
  }

  return updateObject(state, {
    pbiTokens: [
      ...state.pbiTokens.filter(token => token.id !== action.payload.reportId),
      {
        id: action.payload.reportId,
        token: action.payload.embedToken,
        expiration: new Date(action.payload.expiration)
      }
    ],
    error: false
  });
};

const powerBIEmbedTokenError = state => {
  return updateObject(state, {
    error: true
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EMBED_TOKEN_COMPLETED:
      return getPowerBIEmbedToken(state, action);
    case actionTypes.GET_EMBED_TOKEN_FAILED:
      return powerBIEmbedTokenError(state);
    default:
      return state;
  }
};

export default reducer;
