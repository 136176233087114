import ioc7dayplanImageUrl from '../../../images/ioc7DPboardexternal.png';

export default `
# IOC – 7 Day Plan External
## Board Overview
This board shows the IOC 7-Day Plan to support in efficiently optimizing the weekly operational plans across all domestic and international terminals. It includes a date range covering -3 days and +7 days from today.

Information and business rules applied on this board are the same across all terminals.

![IOC 7 Day Plan Board](${ioc7dayplanImageUrl})

**Card 1** – Hourly Trendline of Passenger Arrivals and Departures  
**Card 2** – Flight Summary of Operations  
**Card 3** – Terminal Flight Board  
**Card 3a** – Arrivals Flight Schedule  
**Card 3b** – Departures Flight Schedule  

## Card 1 - Hourly Trendline of Passenger Arrivals and Departures
This card shows the passenger arrivals and departures that flow through each terminal based on the operation schedule of 02:00 to 02:00 the next day. 

* There is a date filter from -3 days from Today, Today’s date, and +7 days from Today to have the flexibility to view the PAX data in a broader date range.
* Today's current hour is indicated as "Now".

The no. of passengers (PAX) is aggregated in one-hour blocks. You can either hover over or click any data point for additional information.

* You can hover over any data point to view a tooltip summary indicating the total passengers arriving or departing per one-hour block.
* You can click on any data point to view a tooltip containing the breakdown of each flight, scheduled time of departure (STD) or arrival (STA), estimated time of departure (ETD) or arrival (ETA), PAX, no. of seats, and source all within the one-hour block.

Flights that are delayed or have arrived early are adjusted accordingly. The trendline does not show canceled flights and non-passenger flights. 

The data sources and refresh frequency are AMS every 15 mins, Sabre and ACA every week. The following considerations are also applied depending on the data source:

* Where AMS data is used, the PAX measurement uses known PAX values from AMS. Data is reported in the -3 days from Today until +2 days from Today’s date.
* Where Sabre and ACA data are displayed, the total aircraft seats are used for the PAX measurement. Data is reported in the remaining 5 days or +3 days onwards from Today’s date.

## Card 2 - Flight Summary of Operations
This card shows the aggregated count of passengers/seats, load factor, and the aggregated count of scheduled and canceled flights for all arriving and departing flights.

The summary for Terminals 1 to 4 accounts for passenger-carrying flights only. The highlighted row refers to the current terminal selected in the terminal navigation.
* PAX/SEATS represents the sum or total count of passengers and/or seats, where data is available. 
* Load factor % represents the quotient of passengers divided by the total number of seats. It excludes canceled flights and flights where PAX is null or zero.

Movements for Terminals 1 to 4 account for passenger flights only, while movements for Domestic and International Freighters account for non-passenger flights only.
* MVTS represents the sum of movements or total count of passenger flights, excluding the canceled flights.
* CNX represents the sum or total count of canceled flights. These are based on the flights with flight status tagged as “C.”
* DOM and INT Freighters exclude ferry flights.
    * DOM Freighters account for the sum of all freighters passing through terminals 1, 3, and 4, while INT Freighters pass through T2 only.
    * Qantas Flights with 6-digit codes that start with QF72, QF73, and QF74 are considered freighters.
    * Flight Type Codes F, FI, FS, H, and/or A are also considered freighters.

The data sources and refresh frequency are AMS every 15 mins, Sabre and ACA every week. The following considerations are also applied depending on the data source:

* Where AMS data is used, the PAX measurement uses known PAX values from AMS. Data is reported in the -3 days from Today until +2 days from Today’s date.
* Where Sabre and ACA data are displayed, the total aircraft seats are used for the PAX measurement. Data is reported in the remaining 5 days or +3 days onwards from Today’s date.


## Card 3 - Terminal Flight Board
This card shows the arrival and departure schedules, from 02:00 to 02:00 the next day. You can view all passenger and non-passenger carrying flights that are scheduled to arrive or depart at the respective terminal and see the following information:

1.	Date
2.	Flight Number
3.	Scheduled Time of Arrival or Departure
4.	Estimated Time of Arrival or Departure
5.	Airport
6.	No. of Passengers (PAX)
7.	No. of Seats (SEATS)
8.	Status (S)
9.  Flight Type

The no. of passengers (PAX) is updated to reflect the actual no. of passengers arriving or departing. PAX applies a color-coded heatmap based on the threshold capacity limits of 1-99, 100-149, 150-199, 200-299, and 300-1000.

If PAX data is unavailable on the date selected, PAX is set to zero or N/A, and the no. of seats in the aircraft is shown. If PAX data is available, SEATS is set to zero or N/A. This applies to passenger-carrying flights only.

All non-passenger-carrying flights are automatically updated to PAX/SEATS equal to zero or N/A.

Flight Types are categorized according to passenger-carrying flights, freighters, and ferry flights. These flights are defined based on the flight type codes: 

* Passenger: C, G, J, PS, PC 

* Freighter: FS, F, H, Qantas flights with 6-digit codes that start with QF72, QF73, QF74 

* Ferry: PO, P, Qantas and Jetstar flights with 6-digit codes that start with JQ79, JQ78, and QF6 

The data sources and refresh frequency are AMS every 15 mins, Sabre and ACA every week. The following considerations are also applied depending on the data source:

* Where AMS data is used, the PAX measurement uses known PAX values from AMS. Data is reported in the -3 days from Today until +2 days from Today’s date.
* Where Sabre and ACA data are displayed, the total aircraft seats are used for the PAX measurement. Data is reported in the remaining 5 days or +3 days onwards from Today’s date.
`.trim();
