const mockData = [
  { travelTime: 3.0, timeRange: '2019-08-06T04:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T21:15:00' },
  { travelTime: 12.0, timeRange: '2019-08-06T06:05:00' },
  { travelTime: 27.0, timeRange: '2019-08-06T09:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T11:45:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T13:15:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T00:35:00' },
  { travelTime: 15.0, timeRange: '2019-08-06T08:45:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T19:15:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T01:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T14:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T17:20:00' },
  { travelTime: 5.0, timeRange: '2019-08-06T22:15:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T01:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T02:45:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T20:00:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T00:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T21:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T23:25:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T02:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T10:45:00' },
  { travelTime: 4.0, timeRange: '2019-08-06T19:25:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T14:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T01:25:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T15:45:00' },
  { travelTime: 28.0, timeRange: '2019-08-06T09:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T23:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T15:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T03:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T22:50:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T23:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T13:00:00' },
  { travelTime: 7.0, timeRange: '2019-08-06T23:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T20:50:00' },
  { travelTime: 14.0, timeRange: '2019-08-06T15:25:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T14:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T14:50:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T21:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T01:15:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T02:15:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T03:50:00' },
  { travelTime: 6.0, timeRange: '2019-08-06T17:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T18:45:00' },
  { travelTime: 9.0, timeRange: '2019-08-06T10:00:00' },
  { travelTime: 11.0, timeRange: '2019-08-06T19:45:00' },
  { travelTime: 6.0, timeRange: '2019-08-06T07:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T13:45:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T18:15:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T16:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T02:20:00' },
  { travelTime: 10.0, timeRange: '2019-08-06T10:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T10:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T15:40:00' },
  { travelTime: 10.0, timeRange: '2019-08-06T05:55:00' },
  { travelTime: 15.0, timeRange: '2019-08-06T06:40:00' },
  { travelTime: 14.0, timeRange: '2019-08-06T10:30:00' },
  { travelTime: 7.0, timeRange: '2019-08-06T19:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T03:15:00' },
  { travelTime: 17.0, timeRange: '2019-08-06T06:15:00' },
  { travelTime: 14.0, timeRange: '2019-08-06T06:10:00' },
  { travelTime: 11.0, timeRange: '2019-08-06T07:55:00' },
  { travelTime: 5.0, timeRange: '2019-08-06T05:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T15:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T16:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T18:50:00' },
  { travelTime: 5.0, timeRange: '2019-08-06T01:30:00' },
  { travelTime: 16.0, timeRange: '2019-08-06T06:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T14:00:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T02:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T13:30:00' },
  { travelTime: 5.0, timeRange: '2019-08-06T23:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T17:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T21:45:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T12:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T15:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T00:00:00' },
  { travelTime: 6.0, timeRange: '2019-08-06T21:25:00' },
  { travelTime: 33.0, timeRange: '2019-08-06T09:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T14:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T05:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T07:25:00' },
  { travelTime: 10.0, timeRange: '2019-08-06T07:50:00' },
  { travelTime: 9.0, timeRange: '2019-08-06T09:45:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T20:10:00' },
  { travelTime: 29.0, timeRange: '2019-08-06T09:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T13:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T23:30:00' },
  { travelTime: 10.0, timeRange: '2019-08-06T08:30:00' },
  { travelTime: 9.0, timeRange: '2019-08-06T22:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T03:45:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T04:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T11:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T21:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T10:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T14:10:00' },
  { travelTime: 14.0, timeRange: '2019-08-06T15:15:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T21:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T00:40:00' },
  { travelTime: 14.0, timeRange: '2019-08-06T12:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T17:25:00' },
  { travelTime: 9.0, timeRange: '2019-08-06T11:15:00' },
  { travelTime: 5.0, timeRange: '2019-08-06T23:05:00' },
  { travelTime: 9.0, timeRange: '2019-08-06T20:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T00:25:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T04:25:00' },
  { travelTime: 11.0, timeRange: '2019-08-06T12:15:00' },
  { travelTime: 8.0, timeRange: '2019-08-06T12:25:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T02:55:00' },
  { travelTime: 7.0, timeRange: '2019-08-06T06:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T05:25:00' },
  { travelTime: 12.0, timeRange: '2019-08-06T07:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T08:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T18:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T01:45:00' },
  { travelTime: 8.0, timeRange: '2019-08-06T17:45:00' },
  { travelTime: 10.0, timeRange: '2019-08-06T06:00:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T00:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T02:50:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T18:25:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T18:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T14:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T23:50:00' },
  { travelTime: 10.0, timeRange: '2019-08-06T10:10:00' },
  { travelTime: 10.0, timeRange: '2019-08-06T12:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T13:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T23:45:00' },
  { travelTime: 5.0, timeRange: '2019-08-06T17:50:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T22:00:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T04:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T05:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T13:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T00:50:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T10:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T13:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T14:45:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T16:50:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T13:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T01:40:00' },
  { travelTime: 10.0, timeRange: '2019-08-06T10:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T06:45:00' },
  { travelTime: 13.0, timeRange: '2019-08-06T15:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T08:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T00:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T03:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T01:00:00' },
  { travelTime: 29.0, timeRange: '2019-08-06T09:25:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T21:50:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T13:50:00' },
  { travelTime: 18.0, timeRange: '2019-08-06T08:55:00' },
  { travelTime: 8.0, timeRange: '2019-08-06T21:05:00' },
  { travelTime: 12.0, timeRange: '2019-08-06T08:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T02:00:00' },
  { travelTime: 11.0, timeRange: '2019-08-06T06:50:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T19:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T20:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T13:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T12:00:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T12:45:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T03:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T16:00:00' },
  { travelTime: 8.0, timeRange: '2019-08-06T07:00:00' },
  { travelTime: 9.0, timeRange: '2019-08-06T07:15:00' },
  { travelTime: 5.0, timeRange: '2019-08-06T11:10:00' },
  { travelTime: 7.0, timeRange: '2019-08-06T12:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T19:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T22:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T05:50:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T19:40:00' },
  { travelTime: 6.0, timeRange: '2019-08-06T20:35:00' },
  { travelTime: 11.0, timeRange: '2019-08-06T09:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T11:00:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T00:15:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T01:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T20:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T05:10:00' },
  { travelTime: 23.0, timeRange: '2019-08-06T06:25:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T11:50:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T01:50:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T15:50:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T04:50:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T20:05:00' },
  { travelTime: 8.0, timeRange: '2019-08-06T15:00:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T04:00:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T05:05:00' },
  { travelTime: 21.0, timeRange: '2019-08-06T09:00:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T04:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T15:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T18:55:00' },
  { travelTime: 6.0, timeRange: '2019-08-06T00:45:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T08:00:00' },
  { travelTime: 4.0, timeRange: '2019-08-06T17:35:00' },
  { travelTime: 16.0, timeRange: '2019-08-06T08:50:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T01:10:00' },
  { travelTime: 5.0, timeRange: '2019-08-06T22:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T03:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T19:00:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T00:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T16:45:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T17:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T16:30:00' },
  { travelTime: 6.0, timeRange: '2019-08-06T11:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T12:55:00' },
  { travelTime: 7.0, timeRange: '2019-08-06T21:00:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T12:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T00:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T01:55:00' },
  { travelTime: 9.0, timeRange: '2019-08-06T08:25:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T16:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T19:50:00' },
  { travelTime: 13.0, timeRange: '2019-08-06T07:05:00' },
  { travelTime: 5.0, timeRange: '2019-08-06T23:00:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T02:40:00' },
  { travelTime: 6.0, timeRange: '2019-08-06T16:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T21:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T22:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T03:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T03:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T18:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T02:25:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T17:05:00' },
  { travelTime: 8.0, timeRange: '2019-08-06T09:55:00' },
  { travelTime: 5.0, timeRange: '2019-08-06T17:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T04:45:00' },
  { travelTime: 5.0, timeRange: '2019-08-06T07:35:00' },
  { travelTime: 4.0, timeRange: '2019-08-06T20:25:00' },
  { travelTime: 11.0, timeRange: '2019-08-06T10:25:00' },
  { travelTime: 11.0, timeRange: '2019-08-06T18:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T09:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T12:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T20:15:00' },
  { travelTime: 15.0, timeRange: '2019-08-06T08:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T22:45:00' },
  { travelTime: 5.0, timeRange: '2019-08-06T05:15:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T23:15:00' },
  { travelTime: 9.0, timeRange: '2019-08-06T08:15:00' },
  { travelTime: 35.0, timeRange: '2019-08-06T09:15:00' },
  { travelTime: 4.0, timeRange: '2019-08-06T20:45:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T03:25:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T16:25:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T05:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T16:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T22:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T14:25:00' },
  { travelTime: 28.0, timeRange: '2019-08-06T06:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T03:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T21:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T11:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T23:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T14:35:00' },
  { travelTime: 7.0, timeRange: '2019-08-06T08:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T11:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T02:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T11:25:00' },
  { travelTime: 5.0, timeRange: '2019-08-06T07:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T11:35:00' },
  { travelTime: 6.0, timeRange: '2019-08-06T22:25:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T04:05:00' },
  { travelTime: 6.0, timeRange: '2019-08-06T20:40:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T04:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T12:50:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T05:45:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T04:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T05:00:00' },
  { travelTime: 23.0, timeRange: '2019-08-06T06:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T19:10:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T16:35:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T07:20:00' },
  { travelTime: 7.0, timeRange: '2019-08-06T07:45:00' },
  { travelTime: 5.0, timeRange: '2019-08-06T22:20:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T10:50:00' },
  { travelTime: 5.0, timeRange: '2019-08-06T19:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T17:00:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T13:25:00' },
  { travelTime: 8.0, timeRange: '2019-08-06T18:00:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T04:15:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T14:15:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T17:15:00' },
  { travelTime: 10.0, timeRange: '2019-08-06T18:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T18:40:00' },
  { travelTime: 12.0, timeRange: '2019-08-06T15:05:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T11:55:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T16:15:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T19:20:00' },
  { travelTime: 12.0, timeRange: '2019-08-06T10:15:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T22:30:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T03:00:00' },
  { travelTime: 3.0, timeRange: '2019-08-06T02:10:00' },
  { travelTime: 10.0, timeRange: '2019-08-06T09:50:00' }
];

export default mockData;
