import { put, takeEvery } from 'redux-saga/effects';
import * as reportActionTypes from '../store/actionTypes/reportActionTypes';
import { newNotification } from '../store/actions/notificationActions';
import NotificationDark from '../assets/img/export-dark.svg';
import moment from 'moment-timezone';
import { clockTimeZone } from '../constants';

function* createExportNotification(title, messages, status) {
  yield put(
    newNotification({
      notifications: [
        {
          type: 'Info',
          status: status,
          datetime: new Date(),
          title: title,
          message: messages,
          iconUrl: NotificationDark
        }
      ]
    })
  );
}

function* createExportNotificationWithDownload(
  title,
  status,
  filenamePrefix,
  action
) {
  const currentTime = moment()
    .tz(clockTimeZone)
    .format('YYYYMMDDHHmm');
  const fileName = `${filenamePrefix}_${currentTime}.pdf`;

  yield put(
    newNotification({
      notifications: [
        {
          type: 'Info',
          status: status,
          datetime: new Date(),
          title: title,
          message: [
            `The report was exported to a PDF file (<a download="${fileName}" href="${
              action
                ? 'data:application/pdf;base64,' + action.payload + '"'
                : ''
            } title='download'>download</a>).`
          ],
          iconUrl: NotificationDark
        }
      ]
    })
  );
}

export default function* notificationsWatcher() {
  yield takeEvery(
    reportActionTypes.REPORT_EXPORT_COMPLETED,
    createExportNotificationWithDownload,
    'Export complete',
    'Green',
    'report'
  );
  yield takeEvery(
    reportActionTypes.REPORT_EXPORT_FAILED,
    createExportNotification,
    'Export to PDF failed',
    [`The Board could not be exported at this time.`],
    'Red'
  );
  yield takeEvery(
    reportActionTypes.REPORT_EXPORT_START,
    createExportNotification,
    'Export in progress',
    [`Export in progress. This might take a few mins.`],
    'Info'
  );
}
