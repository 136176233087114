import landingPageImageUrl from '../../images/landing.png';
import loginPageImageUrl from '../../images/login.png';
import signOutPageImageUrl from '../../images/signOutPage.png';
import navigation1ImageUrl from '../../images/navigation1.png';
import navigation2ImageUrl from '../../images/navigation2.png';
import supportIconUrl from '../../images/supportIcon.png';
import supportFeedbackDropdownImageUrl from '../../images/supportFeedbackDropdown.png';
import supportFeedbackFormShortImageUrl from '../../images/supportFeedbackFormShort.png';
import supportFeedbackFormLongImageUrl from '../../images/supportFeedbackFormLong.png';
import notificationBellIconUrl from '../../images/notificationBellIcon.png';
import notificationBellIconWithNumberUrl from '../../images/notificationBellIconWithNumber.png';
import signOutButtonImageUrl from '../../images/signOutButton.png';
import notificationRecentImageUrl from '../../images/notificationRecent.png';
import notificationPopUpImageUrl from '../../images/notificationPopUp.png';
import notificationHistoryImageUrl from '../../images/notificationHistory.png';
import notificationFilteringImageUrl from '../../images/notificationFilter.png';
import { mfaLink } from '../../../../../constants';

export default `
# Getting Started

## Landing Page
From the EDP landing page you can login, raise a support ticket, give feedback and subscribe for EDP updates. 
Clicking the [Multifactor-Authentication Information (MFA) link](${mfaLink}) will redirect you to a new tab with information regarding MFA including instructions for setting it up for the first time.

![Landing Page](${landingPageImageUrl})

Access to EDP is role-based, meaning that some users will have access and others will not. If you currently have access, clicking the Login button in the top right corner and authenticating using your Melbourne Airport account will take you to EDP and display all boards that you currently have access to.

## Login Page
Once your email address and password have been entered a prompt to enter the 2-factor authentication method will appear.  
Instructions for setting up MFA for the first time can be found [here](${mfaLink}).

![Login Page](${loginPageImageUrl})
 
## Navigation

The EDP navigation system is split into multiple levels;

![Primary Navigation](${navigation1ImageUrl})  

![Secondary Navigation](${navigation2ImageUrl})

**1. Primary navigation** - grouping of boards based on business function  
**2. Secondary navigation** - the boards inside each business group

What you see will be limited to certain boards depending on your area of the business and your access level.

## Support & Feedback 
You can request support or submit feedback by selecting the help icon:

![Help Icon](${supportIconUrl})

The help button provides a dropdown where you can select the option and to give feedback or receive support;

![Feedback & Support Dropdown](${supportFeedbackDropdownImageUrl})

a free text box to add details - what support is required or any feedback. If you are not logged in, you will need to enter your name and email address so that the respective team can contact you about your query.

A support request will generate an email and send it to the ICT Service Desk on your behalf ([ictservicedesk@melair.com.au](mailto:ictservicedesk@melair.com.au)).

A feedback request will generate an email and send it to the EDP development team ([edp@melair.com.au](mailto:edp@melair.com.au)). 

![Logged Out Support & Feedback Form](${supportFeedbackFormLongImageUrl})  ![Logged In Support & Feedback Form](${supportFeedbackFormShortImageUrl})

*Feedback & Support form when logged out (left) compared to when logged in (right)*

## Notifications
While using EDP you may receive notifications when there are changes to information that you need to be aware of. These notifications are designed to alert you to upcoming changes or potential issues and appear for users with operational roles.
New notifications will pop up at the top right of the page;

![Notification Pop Up](${notificationPopUpImageUrl})

Users can click the notification bell in the navigation bar to view the notification centre. The number on the bell indicates the number of notifications received since the notification centre was last opened.

![Notification Bell Icon](${notificationBellIconUrl})  ![Notification Bell Icon with Unread](${notificationBellIconWithNumberUrl})

The 'Recent' tab shows notifications from the last 3 hours, divided into last hour and earlier. 

Selecting the 'History' tab shows all notifications from the last 24 hours - these can then be further filtered by flight number ('Flight' type only), time received, type, direction and/or status.

![Notification Centre](${notificationRecentImageUrl}) ![Recent Notifications](${notificationHistoryImageUrl}) ![Notification Filter](${notificationFilteringImageUrl})

## Sign Out Page
You can click your initials in the header to select the Sign Out option. 

![Sign Out Button](${signOutButtonImageUrl})

If you have successfully signed out, you will see this screen.

![Sign Out Page](${signOutPageImageUrl})
`.trim();
