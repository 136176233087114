import React from 'react';
import { toast } from 'react-toastify';
import Notification from '../Notification';
import AMSNotification from '../AMSNotification';
import BeontraNotification from '../BeontraNotification';
import HappyFaceNotification from '../HappyFaceNotification';
import TomTomNotification from '../TomTomNotification';
import FMHappyFaceNotification from '../FMHappyFaceNotification';

const notify = (notification) => {
  // construct the notification
  let notificationElement;
  if (notification.customAttributes == null) {
    notificationElement = () => <Notification data={notification} />;
  } else {
    switch (notification.type.toLowerCase()) {
      case 'ams':
        notificationElement = () => <AMSNotification data={notification} />;
        break;
      case 'beontra':
        notificationElement = () => <BeontraNotification data={notification} />;
        break;
      case 'happyface negative responses':
      case 'happyface negative response':
        // case 'happyface negative responses':
        notificationElement = () => (
          <FMHappyFaceNotification data={notification} />
        );
        break;
      case 'happyface':
        notificationElement = () => (
          <HappyFaceNotification data={notification} />
        );
        break;
      case 'tomtom':
        notificationElement = () => <TomTomNotification data={notification} />;
        break;
      default:
        notificationElement = () => <Notification data={notification} />;
        break;
    }
  }

  // notify users using toast
  toast(notificationElement, {
    className: 'edp-notification-pop-up__overrides'
  });
};

export default notify;
