import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const button = props => {
  const { primary, text, className, ...buttonProps } = props;
  return (
    <button {...buttonProps} className={cn('button', primary && 'button--primary', className)}>
      {text}
    </button>
  );
};

button.defaultProps = {
  text: 'Button'
};

button.propTypes = {
  primary: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default button;
