export default `
# Release Notes
## v6.4.0
02/05/22

### Enhancement:

* AssetPerformance Channel Rebuild

`;
