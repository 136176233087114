import gtCommsTwoPageViews from '../../../images/GTComms/RevenueDetails/twoPageViews.png';
import gtCommsDateFilter from '../../../images/GTComms/RevenueDetails/dateFilter.png';
import gtCommsDropdown from '../../../images/GTComms/RevenueDetails/dropdown.png';
import gtCommsExportPDF from '../../../images/GTComms/RevenueDetails/exportPDF.png';
import gtCommsTablesAndVisuals from '../../../images/GTComms/RevenueDetails/tablesAndVisuals.png';
import gtCommsAdditionalFunc from '../../../images/GTComms/RevenueDetails/additionalFunc.png';

export default `
# GT Comms – Revenue Details   
## Board Overview 
This board shows the current vs. historical occupancy, forward bookings, or revenue collected from car park transactions. It supports Ground Transport Commercial team in planning the operational resources and marketing efforts to optimize return on investment and parking provider contracts. 

The dashboard has five main components: 
- Two-Page Views 
- Date Filters 
- Drop-Down Menu 
- Tables and Graphs 
- Additional Functionality 

The data sources are SkiData and Altitude for the booking and payment data and AMS for passenger count. The refresh frequency is daily for SkiData and Altitude and every 15 mins for AMS. 

## Two Page Views 

![GT Comms Two Page Views](${gtCommsTwoPageViews})

The dashboard enables you to assess the business performance based on two date comparisons – primary date and comparison date. You can use a simple comparison vs. last year/FY19 or specify two date ranges depending on the need. 

The default page compares the primary date against a specific date range. It shows the Revenue Details performance metrics by car park and channel tables and rate of change by time band or customer segment.

Alternatively, you can compare the primary date against a simple comparison date by clicking on the A – use simple comparison button, which directs you to Page 2. It allows you to compare against same date vs. last year or financial year 2019 as well as same day of the week vs. last year or financial year 2019. Page 2 contains the Revenue Details performance metrics by car park and channel, overtime line graphs (Revenue / Transactions / ATV line), and rate of change time band or customer segment.

Click on the B – Specify date range button to return to the default page.

Basic filtering is applied on both pages. The dashboard excludes data related to Car Parks which are Pre-Booked Ride Area, Remote Taxi, Staff Car Park, Taxi Rank, or Terminal Kerbside.

## Date Filters

![GT Comms Date Filters](${gtCommsDateFilter})

Date slicers apply time-based filters across all tables and graphs. 

The primary date range is set using the 1 – Quick Date Filter and/or 2 – Primary Date Selection. 

The comparison date range is set using the 3a – Specify Date Range or 4a – Use Simple Comparison options. 

### ** 1 – Quick Date Filter  **

This date filter allows you to analyze the data based on a pre-existing time frame (e.g., last month, this calendar year, etc.). Its primary purpose is to set the minimum and maximum dates on the 2 – Primary Date Selection more efficiently. 

You can use this filter to show data depending on the different settings:  

- The first setting provides the following choices: last, next, and this.  

- The second setting lets you enter a number to define the relative date range.  

- The third setting enables you to pick the date measurement. 

When choosing ‘this’ under the first setting, the options are limited to the current day, week, month, or year. For example, if today’s date is 10 Feb 2022, choosing ‘this month’ automatically sets the date to 01 Feb 2022 (start of the month) to 28 Feb 2022 (end of the month).

You may click the eraser button beside the title to de-select any pre-selected options and reset it as blank.

###  ** 2 – Primary Date Selection  **

This date filter allows you to look at data across all visuals as a snapshot of Revenue Details based on a historical or expected booking date range.

If the 1 – Quick Date Filter is blank, you can adjust the 2 – Primary Date Selection based on the earliest and latest available date.

If the 1 – Quick Date Filter is set, you can manually adjust the 2 – Primary Date Selection within the boundaries of the quick date filter. However, the tables and graphs may result in null values if the selected primary dates fall out of range.

For example, if the 1 – Quick Date Filter is set to “Last 2 Weeks” from 10 Feb 2022 (i.e., 28 Jan 2022 to 10 Feb 2022) and you manually change the primary dates before 28 Jan 2022 (e.g., 16 Jan 2021 to 15 Jan 2022), then the tables and graphs will result in null values. Always check the date range and reset the quick date filter as necessary.

###  ** 3a – Specify Date Range / 3b – Comparison Date Selection **

The 3a – Specify Date Range option lets you to set the comparison date using a between-date range. 

When this button is clicked, it shows you the 3b – Comparison Date Selection, which acts as a second data filter. This date filter gives you the flexibility to specify the date range from the earliest to the latest available date.

###  ** 4a – Use Simple Comparison / 4b – Comparison Type to Last Year **

The 4a – Use Simple Comparison option lets you to set the comparison date using pre-fixed date selections. 

When this button is clicked, it shows you the 4b – Comparison Type to Last Year, which acts as a second data filter. This date filter gives you the option to compare against the same date as last year, same day of the week as last year, same date as financial year 2019, and same day of the week as financial year 2019.

- The Same date LY option matches the exact dates as the primary dates from the previous year. For example, if the primary dates are set to 01 Feb 2022 (Tuesday) to 28 Feb 2022 (Monday), then the Same date LY is simply 01 Feb 2021 (Monday) to 28 Feb 2021 (Sunday). 

- The Same day of week LY option matches the days within the same week as the previous year regardless of the date. For example, if the primary dates are set to 01 Feb 2022 (Tuesday) to 28 Feb 2022 (Monday), then the Same day of week LY is simply 02 Feb 2021 (Tuesday) to 01 Mar 2021 (Monday). 

- The Same Date FY19 and Same day of week FY19 options compare the primary dates against the exact dates or days within the same week as the financial year 2019. FY19 covers the period from July 1, 2018 to June 30, 2019.

## Drop-down Menu

![GT Comms Dropdown Menu](${gtCommsDropdown})

###  ** 5 – Article Group  **

This menu allows you to filter all tables and graphs based on the pre-determined options such as car park, corporate, staff, taxi, etc. 

CTRL + click to select multiple options. You may also click the eraser button beside the title to de-select any pre-selected options.  

## Tables and Visuals

![GT Comms Table and Visuals](${gtCommsTablesAndVisuals})

By default, the tables and graphs can cross-filter and cross-highlight the other visualizations on the page. For example, selecting a data point on table filters all other graphs to display only the data that applies to that data point. 

You may also export the data or show the visual as a table by clicking the three dots in the top right corner of each visual. 

###  ** 6 – Revenue Details Metrics by Car Park Name / by Channel  **

The tables show the key performance metrics by car park name and channel. 

The columns consist of Revenue, Transactions (Txn), Average Transaction Value (ATV), Income Per Passenger (IPP), Length of Stay (LOS), and Peak Occupancy. 

All metrics are broken down according to the following: 

- Absolute values represent the sum of data based on the primary date selection. 

- Variance (Absolute) values represent the difference between the sum of data based on the primary date selection minus the sum of data based on the comparison date selection. 

- Variance (%) values represent the percentage increase or decrease over a period. It is computed by dividing the variance (absolute) by the sum of data based on the comparison date selection. 

The tables are shown regardless of the page view.

###  ** 7 – Revenue / Transactions / ATV (Average Transaction Value) **

By default, this graph shows the car park forecast, revenue, and revenue versus last year or FY19 depending on the simple comparison date selection. You can change the view to Transactions or ATV by toggling the options on the right side of the graph. The latest available forecast data is manually provided by the Ground Transport team and is limited to Revenue and Transactions only. 

The x-axis (departure date) is set daily. Additional details are shown on the tooltip, which can be accessed by hovering your mouse on any data point. 

The trendlines are only shown on Page 2 – Use Simple Comparison view. 

###  ** 8 – Timebands **

This plot shows the year-on-year percent change in revenue and transactions by time band. The time bands are based on the length of stay. 

Additional details are shown on the tooltip, which can be accessed by hovering your mouse on any data points. 

This plot is shown regardless of the page view. 

###  ** 9 – Customer Segments **

This plot shows the year-on-year percentage change in revenue and transactions by customer segment. The customer segments are defined according to the length of stay (in hours), day of the week, and/or day part (am/pm). 

Additional details are shown on the tooltip, which can be accessed by hovering your mouse on any data points. 

This plot is shown regardless of the page view. 

## Additional Functionality

![GT Comms Additional Functionality](${gtCommsAdditionalFunc})

###  ** 10 – Export to PDF **

This option allows you to export the current page to a PDF format. 

![GT Comms Export PDF](${gtCommsExportPDF})

The first notification pops out on the right side of the screen, informing you that the export is in progress and that it may take a few minutes until the file is ready for download.

Once the file becomes available, the second notification pops out. You can access and download it by clicking on the notification icon in the top right corner of the page.

`.trim();
