import { Bounce } from 'react-toastify';

const config = {
  position: 'top-right', // notification positioning
  autoClose: 10000, // time (in milliseconds) to automatically dismiss the notification
  hideProgressBar: true, // show/hide the progress bar
  newestOnTop: true, // show the newest notifications on the top
  closeOnClick: true, // dismiss notification on click
  rtl: false, // right-to-left
  draggable: true, // allow dragging notification left/right to dismiss
  pauseOnHover: true, // keep the auto dismiss timer running or not on hover
  transition: Bounce, // set the transition animation
  closeButton: false // hide the default close button
};

export default config;
