import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import NotFound from './components/Pages/NotFound/NotFound';
import Layout from './hoc/Layout/Layout';
import '../src/styles/App.scss';
import { isAuth } from './assets/js/config/adalConfig';
import EDPLoading from './components/Utils/Loading/EDPLoading';
import Landing from './components/Pages/Landing/Landing';
import HelpCentre from './components/Pages/HelpCentre/HelpCentre';
import UserPermissions from './components/Pages/UserPermissions/UserPermissions';
import ComponentFactory from './components/Pages/ComponentFactory';
import {
  notFoundRedirectUrl,
  helpCentreUrl,
  userPermissionsUrl
} from './constants';
import { authContext } from './assets/js/config/adalConfig';
import { setGAUserIdentifier } from './helpers/user';
import 'core-js';
import loglevel from 'loglevel';
import startMockStream from './demo/dataGenerator';

class App extends Component {
  componentDidMount() {
    if (authContext.isCallback(window.location.hash)) {
      // This happens after the AD login screen,
      // handleWindowCallback will use the hash to
      // complete the login
      authContext.handleWindowCallback();
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      startMockStream();
    }
  }

  componentDidCatch(error, info) {
    loglevel.error(error.message, error.stack + info.componentStack);
  }

  render() {
    let unauthorizedRoutes = (
      <Switch>
        {/* This path ('/') is where the user is directed when they click things like the navbar logo, 
            the 404 'return to homepage' AND where they are directed after login. */}

        <Route path="/" exact component={Landing} />
        <Route path="/login" exact component={EDPLoading} />
        <Route path={notFoundRedirectUrl} exact component={NotFound} />
        <Route
          path={['/:primary/:secondary/:tertiary', '/:page/:drilldown']}
          render={(props) => <ComponentFactory {...props} />}
        />
        {/* <Redirect from="*" to={notFoundRedirectUrl} /> */}
        <Redirect from="*" to="/login" />
      </Switch>
    );

    let authorizedRoutes = (
      <Switch>
        {/* This path ('/') is where the user is directed when they click things like the navbar logo, 
            the 404 'return to homepage' AND where they are directed after login. */}
        <Route path="/" exact component={EDPLoading} />
        <Route path="/login" exact component={EDPLoading} />
        <Route path={userPermissionsUrl} exact component={UserPermissions} />
        <Route path={helpCentreUrl} exact component={HelpCentre} />
        <Route path={notFoundRedirectUrl} exact component={NotFound} />
        <Route
          path={['/:primary/:secondary/:tertiary', '/:page/:drilldown', '*']}
          exact
          render={(props) => <ComponentFactory {...props} />}
        />
      </Switch>
    );

    const adalError = localStorage.getItem('adal.error');
    let userAuthorised = adalError === 'Invalid_state' || isAuth();

    if (userAuthorised) {
      setGAUserIdentifier();
    }

    return (
      <div className="app">
        <Layout>
          {userAuthorised ? authorizedRoutes : unauthorizedRoutes}
        </Layout>
      </div>
    );
  }
}

export default App;
