import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import Loading from '../../Loading/Loading';
import Notification from '../Notification';
import NotificationCenterFilter, {
  MINUTES_IN_DAY
} from './NotificationCenterFilter';
import NotificationCenterNav from './NotificationCenterNav';
import AMSNotification from '../AMSNotification';
import BeontraNotification from '../BeontraNotification';
import OutsideClick from '../../OutsideClick/OutsideClick';
import {
  removeNotifications,
  removeFilterOptions,
  changeTab
} from '../../../../store/actions/notificationActions';
import { compareDates } from '../../../../helpers/date';
import { Badge } from 'reactstrap';
import { ReactComponent as CloseDark } from '../../../../assets/img/close-x_14px_000.svg';
import CloseLight from '../../../../assets/img/close-x-light.svg';
import { clockTimeFormat } from '../../../../constants';
import FilterButton from '../../Buttons/FilterButton';
import { clockTimeZone } from '../../../../constants';
import HappyFaceNotification from '../HappyFaceNotification';
import TomTomNotification from '../TomTomNotification';
import FMHappyFaceNotification from '../FMHappyFaceNotification';

class NotificationCenter extends Component {
  ONE_MINUTE = 60 * 1000; /* ms */
  TWENTY_FOUR_HOURS = 86400000; /* ms */
  interval = null;

  state = {
    isFilterOpen: false
  };

  componentDidMount() {
    this.interval = setInterval(
      () => this.removeOldNotifications(),
      this.ONE_MINUTE
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  removeOldNotifications = () => {
    const oldNotifications = this.props.notifications.filter((notification) => {
      return notification.expiryDateTime
        ? moment(notification.expiryDateTime)
            .tz(clockTimeZone)
            .isSameOrBefore(moment())
        : moment().diff(notification.date) > this.TWENTY_FOUR_HOURS;
    });
    this.props.removeNotifications(oldNotifications);
  };

  getNotifications = (notifications) => {
    const { activeTab } = this.props;
    if (activeTab === 'history') {
      return this.getHistoricalNotifications(notifications);
    } else if (activeTab === 'recent') {
      return this.getRecentNotifications(notifications);
    }
  };

  /* Filter Historical notifications */
  filterNotifications = (notifications) => {
    const { filterOptions } = this.props;

    // If no filters are set, show all notifications
    if (!filterOptions || this.getNumberFiltersApplied() === 0) {
      return notifications;
    }

    // If filters are set...
    let { flightNo, time, direction, type, ragStatus } = filterOptions;

    // If Flight number is set
    if (flightNo.length) {
      notifications = notifications.filter((notification) => {
        return (
          notification.customAttributes &&
          notification.customAttributes.flight &&
          notification.customAttributes.flight
            .toLowerCase()
            .includes(flightNo.toLowerCase())
        );
      });
    }

    // If Time is set
    if (time.min > 0 || time.max < MINUTES_IN_DAY) {
      notifications = notifications.filter((notification) => {
        // Get the notification's time in minutes for comparison
        const notificationTime = notification.date
          .format(clockTimeFormat)
          .split(':')
          .map((i) => parseInt(i))
          .reduce((hour, minute) => hour * 60 + minute, 0);
        return notificationTime >= time.min && notificationTime <= time.max;
      });
    }

    // If Direction (Arrivals / Departures) is set
    if (direction.length) {
      notifications = notifications.filter((notification) => {
        return (
          notification.customAttributes &&
          notification.customAttributes.direction &&
          direction.includes(
            notification.customAttributes.direction.toLowerCase()
          )
        );
      });
    }

    // If Type is set
    if (type.length) {
      notifications = notifications.filter((notification) => {
        return (
          (type.includes('happyface') &&
            (notification.type.toLowerCase() === 'happyface' ||
              notification.type.toLowerCase() ===
                'happyface negative responses' ||
              notification.type.toLowerCase() ===
                'happyface negative response')) ||
          // If PAX is selected, show Beontra notifications
          (type.includes('pax') &&
            notification.type.toLowerCase() === 'beontra') ||
          // If Flight is selected, show AMS notiications
          (type.includes('flight') &&
            notification.type.toLowerCase() === 'ams') ||
          (type.includes('tomtom') &&
            notification.type.toLowerCase() === 'tomtom')
        );
      });
    }

    // If Status is set
    if (ragStatus.length) {
      notifications = notifications.filter((notification) => {
        return ragStatus.includes(notification.status.toLowerCase());
      });
    }

    return notifications;
  };

  getHistoricalNotifications = (notifications) => {
    const filteredNotifications = this.filterNotifications(notifications);
    return (
      filteredNotifications
        // ensure the notification data is sorted by time (most recent first)
        .sort(compareDates)
        .map((notification, i) => {
          return this.notificationUI(notification, i);
        })
    );
  };

  getRecentNotifications = (notifications) => {
    const oneHourAgo = moment().subtract(1, 'hours');
    const threeHoursAgo = moment().subtract(3, 'hours');

    const recentNotifications = notifications.filter((notification) => {
      // only show notifications from 3 hours ago
      return notification.date >= threeHoursAgo;
    });

    // if no recent notifications, show this message
    if (!recentNotifications.length) {
      return this.noNotifications('You have no recent notifications.');
    }

    // else, show the notifications grouped into 'last hour' or 'earlier'
    return (
      recentNotifications
        // ensure the notification data is sorted by time (most recent first)
        .sort(compareDates)
        .reduce(
          (array, notification) => {
            // split the notifications into 'last hour' or 'earlier'
            if (notification.date >= oneHourAgo) {
              // last hour: within the last hour
              array[0].push(notification);
            } else {
              // earlier: in the last 1-3 hours
              array[1].push(notification);
            }
            return array;
          },
          [[], []]
        )
        .map((section, sectionId) => {
          // finally, build the UI
          if (section.length) {
            return (
              <div
                className="edp-notification-center__notification-section"
                key={sectionId}
              >
                <div className="edp-notification-center__notification-section__title">
                  {sectionId === 0 ? 'Last hour' : 'Earlier'}
                </div>
                {section.map((notification, i) => {
                  return this.notificationUI(notification, i);
                })}
              </div>
            );
          } else {
            return null;
          }
        })
    );
  };

  notificationUI = (notification, i) => {
    const { type, customAttributes, message } = notification;
    const notificationType = type && type.toLowerCase();
    return (
      <div key={i} className={cn('edp-notification-center__notification')}>
        {notificationType === 'ams' && customAttributes ? (
          <AMSNotification data={notification} />
        ) : notificationType === 'beontra' && customAttributes ? (
          <BeontraNotification data={notification} />
        ) : notificationType === 'happyface negative response' ||
          notificationType === 'happyface negative responses' ? (
          <FMHappyFaceNotification data={notification} />
        ) : notificationType === 'happyface' && customAttributes ? (
          <HappyFaceNotification data={notification} />
        ) : notificationType === 'tomtom' && message ? (
          <TomTomNotification data={notification} />
        ) : (
          <Notification data={notification} shouldAnimate={false} />
        )}
      </div>
    );
  };

  noNotifications = (message) => {
    return (
      <div style={{ textAlign: 'center' }}>
        <span className="edp-notification-center__empty-prompt noselect">
          {message}
        </span>
      </div>
    );
  };

  /* Toggle filter open/close */
  toggleFilter = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen
    });
  };

  /* Remove all currently applied filters */
  removeFilters = () => {
    this.props.removeFilterOptions();
    this.setState({
      isFilterOpen: false
    });
  };

  /* Change between 'recent' and 'history' tab */
  changeTab = (targetTab) => {
    this.setState({
      isFilterOpen: false
    });
    this.props.changeTab(targetTab);
  };

  /* Return the number of filters applied */
  getNumberFiltersApplied = () => {
    const { filterOptions } = this.props;
    let filtersApplied = 0;
    for (let option in filterOptions) {
      if (option === 'time') {
        if (
          filterOptions[option].min !== 0 ||
          filterOptions[option].max !== MINUTES_IN_DAY
        )
          filtersApplied++;
      } else {
        if (filterOptions[option].length) filtersApplied++;
      }
    }
    return filtersApplied;
  };

  render() {
    let {
      isOpen,
      closeCenter,
      notifications,
      animateOpen,
      animateClosed,
      endAnimation,
      activeTab
    } = this.props;

    const { isFilterOpen } = this.state;
    const filtersApplied = this.getNumberFiltersApplied();

    return isOpen ? (
      <OutsideClick callback={closeCenter}>
        <div
          className={cn('edp-notification-center', 'animated', {
            slideInRight: animateOpen,
            slideOutRight: animateClosed
          })}
          onAnimationEnd={endAnimation}
        >
          <div className="edp-notification-center__title-section">
            <h2 className="edp-notification-center__title">Notifications</h2>

            {activeTab === 'history' && (
              <div className="edp__filter">
                {isFilterOpen ? (
                  <NotificationCenterFilter
                    toggleFilter={this.toggleFilter}
                    isFilterOpen={isFilterOpen}
                  />
                ) : (
                  <FilterButton toggleFilter={this.toggleFilter} />
                )}
              </div>
            )}

            <button
              className="edp-button--unstyled d-md-none"
              onClick={() => closeCenter()}
            >
              <img src={CloseLight} alt="Close" />
            </button>
          </div>

          <NotificationCenterNav
            activeTab={activeTab}
            changeTab={this.changeTab}
          />

          {activeTab === 'history' && filtersApplied > 0 && (
            <div className="edp__filters-applied">
              <Badge color="warning">
                <button
                  className="edp-button--unstyled"
                  onClick={this.toggleFilter}
                >
                  {filtersApplied} {filtersApplied === 1 ? 'filter' : 'filters'}{' '}
                  applied
                </button>
                <button
                  className="edp-button--unstyled"
                  onClick={this.removeFilters}
                >
                  <CloseDark />
                </button>
              </Badge>
            </div>
          )}

          <div className={cn('edp-notification-center__content')}>
            {this.props.notificationsLoading ? (
              <Loading light />
            ) : notifications.length > 0 ? (
              this.getNotifications(notifications)
            ) : (
              this.noNotifications('You currently have no notifications.')
            )}
          </div>
        </div>
      </OutsideClick>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.notifications,
  notificationsLoading: state.notifications.notificationsLoading,
  filterOptions: state.notifications.filterOptions,
  activeTab: state.notifications.activeTab
});

const mapDispatchToProps = (dispatch) => {
  return {
    removeNotifications: (oldNotifications) =>
      dispatch(removeNotifications(oldNotifications)),
    removeFilterOptions: () => dispatch(removeFilterOptions()),
    changeTab: (targetTab) => dispatch(changeTab(targetTab))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationCenter);
