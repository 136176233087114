import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { clockTimeFormat } from '../../../constants';
import NotificationIcon from './NotificationIcon';
import NotificationActions from './NotificationActions';
import PropTypes from 'prop-types';

/* Returns the formatted time for display */
const getFormattedTime = dateTime => {
  return moment(dateTime).format(clockTimeFormat);
};

/* Returns the forecasted increase or decrease in PAX */
const getForecast = (baselinePax, forecastPax) => {
  let difference = forecastPax - baselinePax;
  const icon = difference < 0 ? 'decrease' : 'increase';
  // If the difference value is a negative number, change it to a positive for display.
  difference = difference < 0 ? -difference : difference;
  return {
    difference,
    icon
  };
};

const BeontraNotification = props => {
  let { data, isUnread, showSave, showClose } = props;
  let customAttributes = data.customAttributes;
  const direction = customAttributes.direction;
  const time = getFormattedTime(customAttributes.date);
  const forecast = getForecast(
    customAttributes.baselinePax,
    customAttributes.forecastPax
  );

  return (
    <div
      className={cn(
        'edp-notification',
        'edp-notification--beontra',
        data.status.toLowerCase(),
        isUnread && 'is-unread'
      )}
    >
      <div
        className={cn('edp-notification__highlight', data.status.toLowerCase())}
      >
        &nbsp;
      </div>

      <div className="edp-notification__icon-section">
        {data.iconUrl ? <NotificationIcon iconUrl={data.iconUrl} /> : null}
      </div>

      {customAttributes && (
        <div className="edp-notification__body">
          <div className="edp-notification__title font-weight-bold">
            <div className="forecast">
              <span className={cn('forecast-icon', forecast.icon)}>&nbsp;</span>
              <span className="forecast-pax">{forecast.difference} PAX</span>
            </div>
            <div className="location">
              <span>
                {customAttributes.terminal.toUpperCase()} {direction}{' '}
              </span>
              <span className="at">at </span>
              <span>{time}</span>
            </div>
          </div>
          <div className="edp-notification__content">
            <div className="baseline">
              Baseline: {customAttributes.baselinePax}
            </div>
            <div className="expected">
              <strong>Expected: {customAttributes.forecastPax}</strong>
            </div>
          </div>
        </div>
      )}

      <NotificationActions
        date={data.date}
        showSave={showSave}
        showClose={showClose}
      />
    </div>
  );
};

BeontraNotification.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    message: PropTypes.array,
    iconUrl: PropTypes.string,
    customAttributes: PropTypes.shape({
      terminal: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
      baselinePax: PropTypes.number.isRequired,
      forecastPax: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  isUnread: PropTypes.bool,
  showClose: PropTypes.bool,
  showSave: PropTypes.bool
};

export default BeontraNotification;
