import * as actionTypes from '../actionTypes/signalrActionTypes';

export const subscribeToGroup = (group) => {
  return {
    type: actionTypes.EDP_SIGNALR_SUBSCRIBE_TO_GROUP,
    payload: group
  };
};

export const unsubscribeFromGroup = (group) => {
  return {
    type: actionTypes.EDP_SIGNALR_UNSUBSCRIBE_FROM_GROUP,
    payload: group
  };
};
