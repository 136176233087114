import React from 'react';
import { storiesOf } from '@storybook/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { createMemoryHistory } from 'history';
import { text } from '@storybook/addon-knobs';
import v240 from './v2.4.0';
import HelpCentrePage from '../../HelpCentrePage';

const description = `
<style>
.storybook table,
.storybook td, .storybook th {
    border: 1px solid #333;
    padding: 5px 10px;
}
.storybook thead,
.storybook tfoot {
    background-color: #eee;
    color: #000;
}
ul ul {
    padding-left: 16px;
}
h3 {
    text-transform: none;
}
 </style>

 ## Component summary
 This component renders a Help Centre page generated from markdown content.

 Capabilities:
 
- Renders markdown content in a Help Centre page

 ## Component hierarchy

- HelpCentrePage

 ## Props

 <br/>
 <table class="storybook">
 <thead>
 <tr>
     <th>Property</th>
     <th>Description</th>
 </tr>
</thead>
<tbody>
  <tr>
    <td>activeHelpItem</td>
    <td>An object containing information about the item that should be rendered</td>
  </tr>
</tbody>
</table>
<br/>`;

const store = {
  getState: () => {
    return {};
  },
  subscribe: () => 0,
};

storiesOf('Help Centre', module)
  .addDecorator((story) => (
    <Router history={createMemoryHistory({ initialEntries: ['/'] })}>
      <Provider store={store}>{story()}</Provider>
    </Router>
  ))
  .add(
    'Release Notes',
    () => (
      <div>
        <HelpCentrePage activeHelpItem={{ markdown: text('Markdown', v240) }} />
      </div>
    ),
    { info: { text: description, inline: true, header: true } } // Make your component render inline with the additional info
  );
