export default `
# Release Notes
## v6.6.0
26/05/22

### What's New

Features:

* Scheduled Cleans UI Enhancements
    * Updated View
    * Baseline view
    * Tooltip (baseline vs updated)
    * Helper Text Tooltip v1.1 (baseline vs updated)
    * User Guide v1.1 (with baseline vs. updated cleans)

* Bugfixes:
    * Scheduled Cleans +4D onwards: Placeholder text "No Data Available" not yet updated
    * Recommended Cleans Tooltip on Mobile isn't fit to screen
    * Scheduled Cleans: No Data for 3D from Today

* FY19 Comparison Bookmarks
    * Revenue Details - FY19 bookmarks (Use simple comparison page)
    * Same date vs. FY19: Add simple comparison bookmark
    * Same day of week vs. FY19: Add simple comparison bookmark
    * Update Performance Metrics by Car Park / Channel tables
    * Update the Revenue / Transactions / ATV line graphs
    * Update the Time bands / Customer Segments graphs
    * Data Modelling FY 2019 Comparison Bookmarks
    * Backend 2019 Same Day of Week
    * Bookmarks for FY 2019 Same Date

`;
