import { store } from '../indexApp';
import { CALL_TOKEN_API } from '../redux/middleware/apiMiddleware';
import { loggingUrl } from '../constants';

const win = window;
const LOGLEVEL_REMOTE_SEND = 'LOGLEVEL_REMOTE_SEND';

if (!win) {
  throw new Error('Plugin for browser usage only');
}

function getStacktrace() {
  try {
    throw new Error();
  } catch (trace) {
    return trace.stack;
  }
}

const hasStacktraceSupport = !!getStacktrace();

let loglevel;
let originalFactory;
let pluginFactory;

function plain(log) {
  return `[${log.timestamp}] ${log.level.label.toUpperCase()}${
    log.logger ? ` (${log.logger})` : ''
  }: ${log.message}${log.stacktrace ? `\n${log.stacktrace}` : ''}`;
}

function json(log) {
  log.level = log.level.label;
  return log;
}

function setToken() {
  throw new Error("You can't set token for a not appled plugin");
}

const save = win.remote;

const remote = {
  noConflict() {
    if (win.remote === remote) {
      win.remote = save;
    }
    return remote;
  },
  plain,
  json,
  apply(logger) {
    if (!logger || !logger.getLogger) {
      throw new TypeError('Argument is not a root loglevel object');
    }

    if (loglevel) {
      throw new Error('You can assign a plugin only one time');
    }

    loglevel = logger;

    const send = content => {
      store.dispatch({
        [CALL_TOKEN_API]: {
          type: LOGLEVEL_REMOTE_SEND,
          payload: {
            endpoint: loggingUrl,
            body: content,
            method: 'POST'
          }
        }
      });
    };

    originalFactory = logger.methodFactory;

    pluginFactory = function remoteMethodFactory(
      methodName,
      logLevel,
      loggerName
    ) {
      const rawMethod = originalFactory(methodName, logLevel, loggerName);
      const needStack =
        hasStacktraceSupport &&
        ['trace', 'warn', 'error'].some(level => level === methodName);
      const levelVal = loglevel.levels[methodName.toUpperCase()];
      const needLog = levelVal >= loglevel.levels['DEBUG'];

      return (...args) => {
        if (needLog && args.length > 1) {
          const timestamp = new Date().toISOString();

          let stacktrace = needStack ? getStacktrace() : '';
          if (stacktrace) {
            const lines = stacktrace.split('\n');
            lines.splice(0, 3);
            const depth = 3;
            if (depth && lines.length !== depth + 1) {
              const shrink = lines.splice(0, depth);
              stacktrace = shrink.join('\n');
              if (lines.length) stacktrace += `\n    and ${lines.length} more`;
            } else {
              stacktrace = lines.join('\n');
            }
          }

          const getSeverity = level => {
            switch (level) {
              case 4: // Error
                return 'High';
              case 3: // Warning
                return 'Medium';
              case 2: // Info
                return 'None';
              case 1: // Debug
                return 'None';
              case 0: // Trace
                return 'None';
              default:
                return 'Unknown';
            }
          };

          const getType = level => {
            switch (level) {
              case 4: // Error
                return 'Error';
              case 3: // Warning
                return 'Warning';
              case 2: // Info
                return 'Information';
              case 1: // Debug
                return 'Debug';
              case 0: // Trace
                return 'Trace';
              default:
                return 'Unknown';
            }
          };

          const getSubType = level => {
            switch (level) {
              case 4: // Error
                return 'Error';
              case 3: // Warning
                return 'Warning';
              case 2: // Info
                return 'Statistics';
              case 1: // Debug
                return 'Debug';
              case 0: // Trace
                return 'Trace';
              default:
                return 'Unknown';
            }
          };

          const log = {
            source: process.env.REACT_APP_NAME,
            class: 'EDP Site',
            subclass: 'EDP Site',
            region: 'Australia',
            type: getType(levelVal),
            subtype: getSubType(levelVal),
            severity: getSeverity(levelVal),
            logDateTime: timestamp,
            title: args[0],
            body: `{"version_number": "${
              process.env.REACT_APP_VERSION
            }", "environment":"${
              process.env.REACT_APP_ENVIRONMENT
            }", "content": ${args[1]}}`
          };

          let content = '';
          try {
            content += JSON.stringify([log]);
          } catch (error) {
            rawMethod(...args);
            loglevel.getLogger('logger').error(error);
            return;
          }

          send(content);
        }

        rawMethod(...args);
      };
    };
    logger.methodFactory = pluginFactory;
    logger.setLevel(logger.getLevel());
    return logger;
  },
  disable() {
    if (!loglevel) {
      throw new Error("You can't disable a not appled plugin");
    }

    if (pluginFactory !== loglevel.methodFactory) {
      throw new Error(
        "You can't disable a plugin after appling another plugin"
      );
    }

    loglevel.methodFactory = originalFactory;
    loglevel.setLevel(loglevel.getLevel());
    loglevel = undefined;
    remote.setToken = setToken;
  },
  setToken
};

export default remote;
