import { groups } from '../Groups/mockData';
import getRandomInt from '../../../../helpers/randomInt';

const userNames = [
  'Oliver Murray',
  'Ruari Dooley',
  'David Sherman',
  'Stefan Zderic',
  'Edward Crupi',
  'Prateek Nichani',
  'Tim Gong',
  'Dylan Webb',
  'Hooman Nassery',
  'Maris Ozols',
  'Faheem Khan',
  'Priyank Gupta',
  'Dalip Legah',
  'Vladamir Funka',
  'Isabelle Everist'
];

const range = len => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

const newUser = () => {
  const name = userNames[getRandomInt(0, userNames.length - 1)];
  const splitName = name.split(' ');
  const email = splitName[0] + '.' + splitName[1] + '@example.com';
  const melAirUsername = splitName[0] + '.' + splitName[1] + '@melair.com.au';
  const groupsArray = [];
  groups.forEach(group => {
    const rand = getRandomInt(0, 1);
    if (rand > 0) {
      groupsArray.push(group);
    }
  });

  return {
    userId: getRandomInt(1, 99),
    userName: melAirUsername,
    emailAddress: email,
    groups: groupsArray,
    isActive: true
  };
};

export default function makeData(...lens) {
  const makeDataLevel = (depth = 0) => {
    const len = lens[depth];
    return range(len).map(d => {
      return {
        ...newUser(),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined
      };
    });
  };

  return makeDataLevel();
}
