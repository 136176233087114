import React from 'react';
import { Spinner } from 'reactstrap';

const TinyLoadingSpinner = () => {
  return (
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
    />
  );
};

export default TinyLoadingSpinner;
