export default `
# Release Notes
## v4.0.0
20/01/22

### New Features

* 7 Day Plan Ops Summary Card Rebuild:
    * Expanded Arrival and Departure
        * Display T1-T4 flight details in a summary card. Highlights the current selected Terminal row details.
    * Aggregated summary of Ops per Terminal
        * Total counts of MVTS
        * Total counts of CNX (Cancelled Flights
            - Does not include non-passenger carrying flights or freighters
        * Total PAX/SEATS of PAX flights
        * DOM and INT Freighters Summary

* IOC 7 Day Dashboard:
    * Updated links to free form notes in SharePoint report handover document.
    * UI update of cards to handle friendly message when empty or no data hitting for a day
    * UI update to re-arrange the sequence of columns and cards in Terminal Flight Board
    * Printable card view
    * New cross-terminal summary card to replace the COVID port ratings and current terminal summary cards

* GT Comm Revenue Details Enhancement:
    * Re-pointed Revenue Details to use consume the newly built dataset.
    * Enhanced dataset measures to handle large amount of data when doing comparison.
* Enhanced dataset measures to handle large amount of data when doing comparison.

`