import moment from 'moment';
import { clockTimeZone } from '../../../../constants';

export const navbarHeight = 41; // px

export const initialiseDeviceName = (locationState) => {
  const defaultActiveDeviceName = null; // deafult to first item in tabItems
  if (locationState) {
    // if there is location state (i.e. we have routed here and passed state), then return the tab from that state
    return locationState.activeDeviceName;
  }
  return defaultActiveDeviceName; // otherwise, return default
};

export const initialiseTab = (tabItems, locationState) => {
  const defaultTab = tabItems[0]; // deafult to first item in tabItems

  if (locationState) {
    // if there is location state (i.e. we have routed here and passed state), then return the tab from that state
    const selectedTabName = locationState.selectedTabName || tabItems[0].name;
    return tabItems.filter((x) => x.name === selectedTabName)[0];
  }
  return defaultTab; // otherwise, return default
};

// Get all dates between start and end date
export const getDates = (startDate, endDate) => {
  var dateArray = [];
  var currentDate = moment(startDate);
  var stopDate = moment(endDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
};

// Get list of date filters based on previous 3 days and next 7 days of the current day
export const getDateFilters = (currentTime, prev, next) => {
  const currentDate = moment.utc(currentTime).tz(clockTimeZone);
  const prevDays = moment(currentDate).subtract(prev, 'days');
  const nextDays = moment(currentDate).add(next, 'days');

  return getDates(prevDays, nextDays).map((val) => {
    let text = moment(val).format('DD MMM');

    // if date is current, show text as 'today'
    if (text === moment(currentDate).format('DD MMM')) {
      text = 'Today';
    }
    return { name: text, value: val };
  });
};

export const handleDirectionToUse = (direction, selectedDirection) => {
  return direction === selectedDirection;
};

// Handling of PAX and SEATS fields
export const handlePAXandSeats = (flight, date) => {
  const prev3Days = moment(date).subtract(3, 'days');
  const next2Days = moment(date).add(2, 'days');
  let pax = flight.pax;
  let seats = flight.seats;
  let counter = seats;
  let unit = 'seats';

  if (getDates(prev3Days, next2Days).indexOf(date) > -1) {
    counter = pax;
    unit = 'PAX';

    // show seats count if pax is null
    if (pax === 0 || pax === null || pax === undefined) {
      counter = seats;
      unit = 'Seats';
    }

    // if no pax OR counter uses seats, set pax to NA
    if (unit === 'Seats' || pax === 0) {
      pax = 'N/A';
    }

    // if no seats OR counter uses pax, set seats to NA
    if (unit === 'PAX' || seats === 0) {
      seats = 'N/A';
    }

    // if both pax and seats are NA, set counter to 0
    if (pax === 'N/A' && seats === 'N/A') {
      counter = 0;
    }
  }

  // if both pax and seats are null set counter to zero
  if (counter === null || counter === undefined) {
    counter = '0';
    unit = '';
  }

  // added new key (paxSeats) for handled pax and seats
  return { paxSeats: counter, seats, pax, unit };
};

export const handleTimeToUse = (flight) => {
  const format = 'YYYY-MM-DDTHH:mm:ss';
  const scheduled = flight.scheduledDateTime;
  const estimated = flight.estimatedDateTime;

  // set scheduled as default
  let timeToUse = scheduled;

  // checking if not null
  // and not defaulting to start of day (for the data issue)
  if (
    estimated !== null &&
    estimated !==
      moment(estimated)
        .startOf('day')
        .format(format)
  ) {
    if (scheduled !== estimated) {
      timeToUse = estimated;
    }
  }

  // set to zero for smooth graph presentation
  if (timeToUse === null || timeToUse === 0 || timeToUse === undefined) {
    timeToUse = 0;
  }

  return { timeToUse };
};

// Filter data using a given date
export const handleSelectedDayFlights = (time, date) => {
  if (!time) return false;

  const { min, max } = getDayOperation(date);
  const timeToCheck = moment.tz(time, clockTimeZone).unix() * 1000;

  return moment(timeToCheck).isBetween(min, max);
};

// Getting the first and last X-axis
export const getDayOperation = (selectedDay) => {
  // start of operation is 2 am of the day
  let min = moment.tz(selectedDay, clockTimeZone).startOf('day');
  min = min.add(2, 'hour').unix() * 1000;

  // end of operation if 2 am the next day
  let max = moment.tz(selectedDay, clockTimeZone).endOf('day');
  max = max.add(2, 'hour').add(1, 'minute');
  max = max.unix() * 1000;

  return { min, max };
};

export const isQantasFlight = (flightCode) => {
  const qantasCodes = ['QF6', 'QF72', 'QF73', 'QF74'];
  return qantasCodes.some((code) => {
    return (
      flightCode.length === 6 && flightCode.substring(0, code.length) === code
    );
  });
};

export const isPassengerFlight = (flightType) => {
  const paxCodes = ['C', 'G', 'J', 'PC', 'PS'];
  return paxCodes.includes(flightType);
};

export const isCancelledFlight = (status) => {
  return status === 'C';
};

export const dataAggregatedHourlyByDirection = (data, startDate, endDate) => {
  const HOUR_INTERVAL_IN_MS = 3600000; // for the x-axis

  // hourly interval initial setup
  let hourlyData = {
    Arrival: {},
    Departure: {}
  };

  // data iteration on specified time interval
  for (let i = startDate; i <= endDate; i += HOUR_INTERVAL_IN_MS) {
    hourlyData['Arrival'][i] = { total: 0, count: 0, plots: [] };
    hourlyData['Departure'][i] = { total: 0, count: 0, plots: [] };
  }

  // filter by arrival flights
  const arrivalFlights = data.filter((val) => {
    return val.arrivalDeparture === 'A';
  });

  // filter by departure flights
  const departureFlights = data.filter((val) => {
    return val.arrivalDeparture === 'D';
  });

  const populateDataSeries = (flights, direction) => {
    return flights.forEach((f) => {
      let msTime =
        moment(f.timeToUse)
          .tz(clockTimeZone, true)
          .minutes(0)
          .seconds(0)
          .unix() * 1000;

      hourlyData[direction][msTime].total += f.paxSeats;
      hourlyData[direction][msTime].count++;
      hourlyData[direction][msTime].plots.push({
        time: f.timeToUse,
        source: f.source,
        paxSeats: f.paxSeats,
        pax: f.pax,
        seats: f.seats,
        code: f.flightCode,
        unit: f.unit,
        scheduledDateTime: f.scheduledDateTime,
        estimatedDateTime: f.estimatedDateTime,
        direction: direction + 's'
      });
    });
  };

  // populate ms data with aggregation of pax
  populateDataSeries(arrivalFlights, 'Arrival');
  populateDataSeries(departureFlights, 'Departure');

  return hourlyData;
};

export const aggregatedPAXByInterval = async ({
  data,
  startDate,
  endDate,
  minuteInterval = 60 // default to 1 hour
}) => {
  const INTERVAL_IN_MILLISECONDS = minuteInterval * 60 * 1000;

  // interval initial setup
  let aggregatedData = {};

  // data iteration on specified time interval
  for (let i = startDate; i <= endDate; i += INTERVAL_IN_MILLISECONDS) {
    aggregatedData[i] = {
      updated: { total: 0, count: 0, plots: [] },
      baseline: { total: 0, count: 0, plots: [] }
    };
  }

  if (data.baseline !== null) {
    await data.baseline.forEach((f) => {
      const momentTime = moment(f.timeToUse).tz(clockTimeZone, true);
      const convertedTime = momentTime
        .minutes(0)
        .seconds(0)
        .add(
          Math.floor(
            moment(f.timeToUse)
              .tz(clockTimeZone, true)
              .minute() / minuteInterval
          ) * minuteInterval,
          'minute'
        );
      const msTime = convertedTime.unix() * 1000;

      aggregatedData[msTime]['baseline'].total += f.paxSeats;
      aggregatedData[msTime]['baseline'].count++;
      aggregatedData[msTime]['baseline'].plots.push({
        time: f.timeToUse,
        source: f.source,
        paxSeats: f.paxSeats,
        pax: f.pax,
        seats: f.seats,
        code: f.flightCode,
        unit: f.unit,
        scheduledDateTime: f.scheduledDateTime,
        estimatedDateTime: f.estimatedDateTime,
        direction: f.arrivalDeparture
      });
    });
  }

  if (data.baseline !== null) {
    await data.updated.forEach((f) => {
      const momentTime = moment(f.timeToUse).tz(clockTimeZone, true);
      const convertedTime = momentTime
        .minutes(0)
        .seconds(0)
        .add(
          Math.floor(
            moment(f.timeToUse)
              .tz(clockTimeZone, true)
              .minute() / minuteInterval
          ) * minuteInterval,
          'minute'
        );
      const msTime = convertedTime.unix() * 1000;

      aggregatedData[msTime]['updated'].total += f.paxSeats;
      aggregatedData[msTime]['updated'].count++;
      aggregatedData[msTime]['updated'].plots.push({
        time: f.timeToUse,
        source: f.source,
        paxSeats: f.paxSeats,
        pax: f.pax,
        seats: f.seats,
        code: f.flightCode,
        unit: f.unit,
        scheduledDateTime: f.scheduledDateTime,
        estimatedDateTime: f.estimatedDateTime,
        direction: f.arrivalDeparture
      });
    });
  }

  return await aggregatedData;
};

export const aggregatedHappyFaceScores = async (
  data,
  min,
  max,
  minuteInterval = 60 // default to 1 hour
) => {
  const INTERVAL_IN_MILLISECONDS = minuteInterval * 60 * 1000;

  // interval initial setup
  let aggregatedData = {};

  // data iteration on specified time interval
  for (let i = min; i <= max; i += INTERVAL_IN_MILLISECONDS) {
    aggregatedData[i] = { total: 0, count: 0, plots: [] };
  }

  await data.forEach((f) => {
    const time = f['dateTime'];
    const momentTime = moment(time).tz(clockTimeZone, true);
    const convertedTime = momentTime
      .minutes(0)
      .seconds(0)
      .add(
        Math.floor(
          moment(time)
            .tz(clockTimeZone, true)
            .minute() / minuteInterval
        ) * minuteInterval,
        'minute'
      );

    const msTime = convertedTime.unix() * 1000;

    // check if data is within time range
    if (moment(msTime).isBetween(min, max)) {
      // check if score is 4 or 5
      if (f['rawScoreSum'] === 4 || f['rawScoreSum'] === 5) {
        // check time grain
        if (f['timeGrain'] === 'Event') {
          aggregatedData[msTime].total += f['rawScoreSum'];
          aggregatedData[msTime].count += f['numberOfResponses'];
          aggregatedData[msTime].plots.push({
            ...f,
            time,
            deviceLabel: f.happyFaceDeviceName,
            reasons:
              (f['reasons'] && f['reasons'].length === 0) ||
              f['reasons'] === null ||
              f['reasons'] === 'Poor' ||
              f['reasons'] === 'Extremely Poor'
                ? 'Unspecified'
                : f['reasons']
          });
        }
      }
    }
  });

  return await aggregatedData;
};
