import React, { Component } from 'react';
import FourZeroFour from '../../../assets/img/404.png';
import Header from '../../Utils/Header/Header';

const pageTitle = '404 - Not Found';

class NotFound extends Component {
  render() {
    return (
      <>
        <Header title={pageTitle} />
        <div
          style={{
            margin: 'auto',
            textAlign: 'center',
            width: '55%',
            color: 'white',
            marginTop: '7em'
          }}
        >
          <img
            alt="404"
            src={FourZeroFour}
            style={{ width: '100%', maxWidth: '225px' }}
          />
          <br />
          <span
            style={{
              fontSize: 'calc(3vw + 30px)'
            }}
          >
            Oops!
          </span>
          <p>
            This page does not exist. Try retyping the URL or head back to the
            homepage to start again.
          </p>
          <a
            href="/"
            className="edp-button gold-button homepage-button"
            style={{
              width: '100%',
              maxWidth: '225px',
              display: 'block',
              margin: '0 auto'
            }}
          >
            Go to homepage
          </a>
        </div>
      </>
    );
  }
}

NotFound.displayName = 'NotFound';
export default NotFound;
