// API endpoints
// --------------------------------------------------------------------------------------
// Base URL
const baseUrl = process.env.REACT_APP_API_BASE_URL;
// Base API URL
const baseApiUrl = baseUrl + process.env.REACT_APP_API_PATH;
// GET Power PI Access Token
export const getPowerBITokenUrl = `${baseApiUrl}${
  process.env.REACT_APP_API_POWER_BI_TOKEN_PATH
}`;
// GET ArcGIS Access Token
export const getArcGISTokenUrl = `${baseApiUrl}${
  process.env.REACT_APP_API_ARCGIS_TOKEN_PATH
}`;
// APAM ArcGIS Server URL
export const apamArcGISServerURL = `${
  process.env.REACT_APP_API_APAM_ARCGIS_SERVER_URL
}`;
// GET and POST manning level
export const manningLevelUrl = `${baseApiUrl}${
  process.env.REACT_APP_API_MANNING_LEVEL_PATH
}`;
// GET User Role
export const getUserRoleUrl = `${baseApiUrl}${
  process.env.REACT_APP_API_USER_ROLES_PATH
}`;
// GET CMX Data for Live board
export const getCMXdataUrl = `${baseApiUrl}${
  process.env.REACT_APP_API_CMX_DATA_PATH
}`;
// GET Beontra Data
export const getBeontraDataUrl = `${baseApiUrl}${
  process.env.REACT_APP_API_BEONTRA_DATA_PATH
}`;
// GET Flight Breakdown Data
export const getFlightBreakdownUrl = `${baseApiUrl}${
  process.env.REACT_APP_API_FLIGHT_BREAKDOWN_DATA_PATH
}`;

// User Permissions Endpoints
// roles url
export const userPermissionsRolesUrl = `${baseApiUrl}${
  process.env.REACT_APP_API_USER_PERMISSIONS_ROLES_PATH
}`;
// groups url
export const userPermissionsGroupsUrl = `${baseApiUrl}${
  process.env.REACT_APP_API_USER_PERMISSIONS_GROUPS_PATH
}`;
// users url
export const userPermissionsUsersUrl = `${baseApiUrl}${
  process.env.REACT_APP_API_USER_PERMISSIONS_USERS_PATH
}`;

//Seven Day Plan Update Endpoint
export const sevenDayPlanUpdateUrl = `${baseApiUrl}${
  process.env.REACT_APP_API_SEVENDAY_PLAN_UPDATE_PATH
}`;

// GET Monthly Peak Data for Monthly Peak board
export const getMonthlyPeakDataUrl = `${baseApiUrl}${
  process.env.REACT_APP_API_MONTHLY_PEAK_DATA_PATH
}`;
// GET Daily Peak Data for Monthly Peak board
export const getDailyPeakDataUrl = `${baseApiUrl}${
  process.env.REACT_APP_API_DAILY_PEAK_DATA_PATH
}`;
export const contactUsUrl = `${baseApiUrl}feedback`;
export const loggingUrl = `${baseApiUrl}logs`;
// SignalR hub stream url for
export const getEDPSignalrUrl = `${baseUrl}${
  process.env.REACT_APP_EDP_SIGNALR_URL
}`;
// GET Notifications
export const getNotificationsUrl = `${baseApiUrl}${
  process.env.REACT_APP_API_NOTIFICATIONS_PATH
}`;
// POST Export report
export const postExportReportUrl = (reportId, workspaceId) =>
  `${baseApiUrl}export/reports/${workspaceId}/${reportId}/pdf`;
// Melbourne Airport Help email address
export const helpEmail = process.env.REACT_APP_HELP_EMAIL;
// Melbourne Airport Azure Details
export const tenantId = process.env.REACT_APP_TENANT_ID;
// Dynamic Grouping Power Apps report identifier
export const dynamicGroupingId =
  process.env.REACT_APP_DYNAMIC_GROUPING_POWER_APPS_REPORT_ID;
// AAD Registered Application Id
export const applicationId = process.env.REACT_APP_REGISTERED_AAD_APP_ID;
// Post Logout Redirect URI (the user will be directed to this page upon successful logout)
export const postLogoutRedirectUri =
  process.env.REACT_APP_REGISTERED_AAD_APP_POST_LOGOUT_REDIRECT_URI;
// Post Login Redirect URI (the user will be directed to this page upon successful authentication). Note: This must match the URI configured in the registered app in AAD
export const postLoginRedirectUri =
  process.env.REACT_APP_REGISTERED_AAD_APP_REDIRECT_URI;
// 404 Not Found url to redirect to
export const notFoundRedirectUrl = process.env.REACT_APP_404_REDIRECT_URL;
// Url for help centre
export const helpCentreUrl = process.env.REACT_APP_HELP_CENTRE_URL;
// Url for user permissions portal
export const userPermissionsUrl = process.env.REACT_APP_USER_PERMISSIONS_URL;
// Url to redirect to for login
export const loginRedirectUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL;
// MFA document link
export const mfaLink =
  'https://albert.melair.com.au/Corporate/StrategyFinance/financesystem/_layouts/15/WopiFrame2.aspx?sourcedoc=/Corporate/StrategyFinance/financesystem/Documents/Microsoft%20Two%20Factor%20Authentication%20Setup.docx&action=default';
// Support/Feedback feature
const helpEmailBody = `Please provide detail on the help you require below. EDP is only supported inside normal business hours (Mon-Fri  9am-5pm), please bear with us and someone will be in touch ASAP.`;
const helpEmailSubject = `HELPDESK`;
export const helpMailTo = `mailto:${helpEmail}?subject=${helpEmailSubject}&body=${helpEmailBody}\n`;
const updateEmailBody = `Please hit send to be subscribed for EDP updates!`;
const updateEmailSubject = `SUBSCRIBE ME`;
export const updateMailTo = `mailto:${helpEmail}?subject=${updateEmailSubject}&body=${updateEmailBody}\n`;
export const feedbackEmailTo = `mailto:${helpEmail}?subject=FEEDBACK`;
export const defaultRefreshTime = 1; // minutes
// Clock/timezone
export const clockTimeFormat = process.env.REACT_APP_CLOCK_TIME_FORMAT;
export const clockTimeZone = process.env.REACT_APP_CLOCK_TIME_ZONE;
export const momentDateFormat = 'DD.MM.YY';
// The visual (chart) name that depicts the performance duration above the defined SLA
export const intArrivalsPerformanceDurationAboveSLAVisualName =
  'c7309bdabee5db94da93';
//  The visual (chart) name that depicts the Immigration transit times
export const intArrivalsImmigrationTransitTimeVisualName =
  'b657d2f40228e660d94c';

// SignalR Groups/data sources
export const signalrMethods = {
  iocOverview: {
    signalrSubscribeMethod: 'SubscribeToIOCOverview',
    signalrUnsubscribeMethod: 'UnsubscribeFromIOCOverview',
    payloadKey: 'iocOverview',
    name: 'IOC Overview'
  },
  iocT2: {
    signalrSubscribeMethod: 'SubscribeToIOCT2',
    signalrUnsubscribeMethod: 'UnsubscribeFromIOCT2',
    payloadKey: 'iocT2',
    name: 'IOC T2'
  },
  iocT3: {
    signalrSubscribeMethod: 'SubscribeToIOCT3',
    signalrUnsubscribeMethod: 'UnsubscribeFromIOCT3',
    payloadKey: 'iocT3',
    name: 'IOC T3'
  },
  iocT4: {
    signalrSubscribeMethod: 'SubscribeToIOCT4',
    signalrUnsubscribeMethod: 'UnsubscribeFromIOCT4',
    payloadKey: 'iocT4',
    name: 'IOC T4'
  },
  notifications: {
    signalrSubscribeMethod: 'SubscribeToNotifications',
    signalrUnsubscribeMethod: 'UnsubscribeFromNotifications',
    payloadKey: 'notifications',
    name: 'Notifications'
  },
  intArrivalsLive: {
    signalrSubscribeMethod: 'SubscribeToIntArrivalsLive',
    signalrUnsubscribeMethod: 'UnsubscribeFromIntArrivalsLive',
    payloadKey: 'intArrivalsLive',
    name: 'Int Arrivals Live'
  },
  iocSevenDayT1: {
    signalrSubscribeMethod: 'SubscribeToT1SevenDayBoard',
    signalrUnsubscribeMethod: 'UnsubscribeFromT1SevenDayBoard',
    payloadKey: 'terminal1',
    name: 'IOC Seven Day T1'
  },
  iocSevenDayT3: {
    signalrSubscribeMethod: 'SubscribeToT3SevenDayBoard',
    signalrUnsubscribeMethod: 'UnsubscribeFromT3SevenDayBoard',
    payloadKey: 'terminal3',
    name: 'IOC Seven Day T3'
  },
  iocSevenDayT4: {
    signalrSubscribeMethod: 'SubscribeToT4SevenDayBoard',
    signalrUnsubscribeMethod: 'UnsubscribeFromT4SevenDayBoard',
    payloadKey: 'terminal4',
    name: 'IOC Seven Day T4'
  },
  iocSevenDayT2: {
    signalrSubscribeMethod: 'SubscribeToT2SevenDayBoard',
    signalrUnsubscribeMethod: 'UnsubscribeFromT2SevenDayBoard',
    payloadKey: 'terminal2',
    name: 'IOC Seven Day T2'
  },
  fmDailyBaseLine: {
    signalrSubscribeMethod: 'SubscribeToFMDaily',
    signalrUnsubscribeMethod: 'UnsubscribeFromFMDaily',
    payloadKey: 'fmDailyBaseLine',
    name: 'FM Baseline PAX'
  },
  fmAmenities: {
    signalrSubscribeMethod: 'SubscribeToAssetPerformance',
    signalrUnsubscribeMethod: 'UnsubscribeFromAssetPerformance',
    payloadKey: 'assetPerformance',
    name: 'FM Asset Performance '
  },
  fmCleaningSchedulesUpdated: {
    signalrSubscribeMethod: 'SubscribeToScheduledCleans',
    signalrUnsubscribeMethod: 'UnsubscribeFromScheduledCleans',
    payloadKey: 'scheduledCleans',
    name: 'FM Cleaning Updated Schedules'
  },
  fmCleaningSchedulesBaseline: {
    signalrSubscribeMethod: 'SubscribeToScheduledZoneCleans',
    signalrUnsubscribeMethod: 'UnsubscribeFromScheduledZoneCleans',
    payloadKey: 'scheduledZoneCleans',
    name: 'FM Cleaning Baseline Schedules'
  },
  gtOpsCarPark: {
    signalrSubscribeMethod: 'SubscribeToCarParkMovements',
    signalrUnsubscribeMethod: 'UnsubscribeFromCarParkMovements',
    payloadKey: 'carParkMovements',
    name: 'GT Ops Car Park Movements'
  }
};
