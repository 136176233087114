import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Loading from '../../../Utils/Loading/Loading';
import {
  deleteUserPermissionsUserFromGroup,
  deleteUserPermissionsUserFromGroupReset
} from '../../../../store/actions/userPermissionsActions';

const DeleteRoleFromGroupDialog = props => {
  const {
    userPermissionsDeleteUserFromGroupError,
    userPermissionsDeletingUserFromGroup,
    deleteUserPermissionsUserFromGroup,
    deleteUserPermissionsUserFromGroupReset,
    group,
    user,
    setSelected
  } = props;

  // show error message?
  const [showError, setShowError] = useState(false);
  // has the user submitted a new group yet?
  const [submitted, setSubmitted] = useState(false);
  // is the modal open?
  const [open, setOpen] = React.useState(false);

  // handle success/error response in submitting group
  useEffect(() => {
    // check to see if the currently entered group information is in our groups array (i.e. it has been submitted)
    const groupHasBeenDeleted = true;
    // TODO: implement this check ^

    // if error is populated in the store, show error message
    if (userPermissionsDeleteUserFromGroupError) {
      setShowError(true);
    } // if we are not currently deleting the group, the DELETE request has been submitted
    // and the group has actually been deleted, show success
    else if (
      !userPermissionsDeletingUserFromGroup &&
      groupHasBeenDeleted &&
      submitted
    ) {
      setOpen(false);
      deleteUserPermissionsUserFromGroupReset();
      setSubmitted(false);
      setShowError(false);

      // remove the group from the UI
      setSelected({
        ...user,
        groups: user.groups.filter(
          groupObject => groupObject.groupName !== group.groupName
        )
      });
    }
  }, [
    submitted,
    setSelected,
    group,
    userPermissionsDeleteUserFromGroupError,
    userPermissionsDeletingUserFromGroup,
    deleteUserPermissionsUserFromGroupReset,
    user
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const resetAllState = () => {
    setOpen(false);
    deleteUserPermissionsUserFromGroupReset();
    setSubmitted(false);
    setShowError(false);
  };

  const handleCancel = () => resetAllState();

  const handleClose = () => {
    resetAllState();
  };

  const handleDelete = () => {
    // TODO: fix parameters here
    deleteUserPermissionsUserFromGroup(user.userName, group.groupId);
    setSubmitted(true);
  };

  const loading = userPermissionsDeletingUserFromGroup;

  return (
    <>
      <button
        className={cn('edp-user-permissions__remove-button', 'grey', {
          disabled: loading
        })}
        onClick={() => handleClickOpen()}
      >
        <ClearIcon />
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {!loading && (
          <DialogTitle id="form-dialog-title">
            Delete a Role from a Group
          </DialogTitle>
        )}
        <div
          style={{
            height: '4em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          {showError ? (
            <span style={{ margin: '1em 2em' }}>An error has occurred.</span>
          ) : loading ? (
            <span style={{ margin: '2em 3em' }}>
              <Loading mini dark />
            </span>
          ) : (
            <span style={{ margin: '1em 2em' }}>
              Are you sure you wish to remove the user "{user.userName}" from
              group "{group.groupName}"?
            </span>
          )}
        </div>
        <DialogActions>
          {showError ? (
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          ) : loading ? null : (
            <>
              <Button onClick={handleCancel} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="secondary">
                Delete
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteRoleFromGroupDialog.propTypes = {
  setSelected: PropTypes.func.isRequired, // set the selected user
  group: PropTypes.object.isRequired, // the group this dialog corresponds to
  user: PropTypes.object.isRequired // the user this dialog corresponds to
};

const mapStateToProps = state => {
  return {
    userPermissionsDeletingUserFromGroup:
      state.userPermissions.userPermissionsDeletingUserFromGroup,
    userPermissionsDeleteUserFromGroupError:
      state.userPermissions.userPermissionsDeleteUserFromGroupError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteUserPermissionsUserFromGroup: (username, groupId) =>
      dispatch(deleteUserPermissionsUserFromGroup(username, groupId)),
    deleteUserPermissionsUserFromGroupReset: () =>
      dispatch(deleteUserPermissionsUserFromGroupReset())
  };
};

DeleteRoleFromGroupDialog.displayName =
  'User Permissions Portal - Delete Role from Group Dialog';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteRoleFromGroupDialog);
