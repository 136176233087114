import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { setDefaultOptions } from 'esri-loader';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';
import Header from './components/Utils/Header/Header';
import { toast } from 'react-toastify';
import toastConfig from './components/Utils/Notifications/PopUp/config/toast-config';
import { ReactRouterGlobalHistory } from 'react-router-global-history';

// configure esri-loader to lazy load the CSS
// the fisrt time any react-arcgis components are rendered
setDefaultOptions({ css: true });

const edpTitle = 'EDP';
export const store = configureStore();

// configure toast (notification handler)
toast.configure(toastConfig);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ReactRouterGlobalHistory />
      <Header title={edpTitle} />
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
