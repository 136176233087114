import laOpsCustomerServiceImageUrl from '../../../images/laOpsCustomerService.png';

export default `# GT Ops – Customer Service
## Board Overview
This board provides a drilldown into the customer service cards that are displayed on the LA Ops Overview page. The board includes two cards; 

![LA Ops Customer Service](${laOpsCustomerServiceImageUrl})

**Card 1** – Happy face by area  
**Card 2** – Query actions - hours pending  

## Card 1 - Happy Faces by Area
This card shows the Happy Face device responses for the Landside Access related devices for the last 24 hours. 
The last 24 hours of Happy Face responses are displayed, and the location of where current problems may be occurring are visible.  

The graph at the bottom can be filtered for a specific device by clicking on the device on the map, which will give an hourly representation of when each response was received. 
The colour of each device is a gradient representation of the average response for the device.  

The bar chart uses red, amber or green colouring to categorise the responses:  

- Red: Negative response  
- Amber: Neutral response  
- Green: Positive response  

The Data Source is Happy Face and the card updates every 30 minutes.

## Card 2 - Query actions – hours pending
This card shows the number of currently open queries, for Claims, Complaints and Enquiries.
You can view the current open queries and see how long they have been open. These queries are further broken down into the time that they’ve been open.
The Data Source is BPMS and the card updates every 60 minutes.
`.trim();
