import fmCleaningSchedulesv2 from '../../../images/FM/cleaningSchedulesv2.png';
import fmPeaksPrint from '../../../images/FM/peaksPrint.png';

export default `
# FM - Cleaning Schedules 
## Board Summary
This board shows the cleaning schedules, flow of passengers and satisfaction ratings to support the Facilities Management team in their resource planning allocation, cost-efficient asset utilization, and traveler journey optimization across all terminals. 

![FM Cleaning Schedules Board](${fmCleaningSchedulesv2})

**Card 1** – Recommended Cleaning Events  
**Card 2** – Half-hourly PAX Volume Trendline with Indicators  
**Card 3** – Latest Number of Negative Responses per Happy Face Device 

Scheduled Cleans are available on T2 and T4 only. 

Passengers (PAX) passing through the domestic terminals are aggregated as total departures and arrivals, while passengers passing through the international terminal are broken down into departures and arrivals. 

Happy Face is currently available on T2, T3, and T4 only. 

## Card 1 - Recommended Cleaning Events 

This card shows the suggested cleaning events to support the Facilities Management team in anticipating the optimal maintenance window during the day of operations up to +3 days ahead. Today's current hour is indicated as "Now". 

As indicated by the blue bars, the latest cleaning events are shown by default and updated accordingly. Compare against the baseline schedules by clicking on the “Compare vs. baseline” button at the bottom-right corner. As indicated by the white bars, baseline schedules are set at the start of today's date (02:00) and stored historically. It does not change once set. Return to the latest schedules by clicking on the “Return to updated schedules”. 

* Each event has a fixed duration of 30 minutes and can be scheduled every quarter-hour. 

* The no. of baseline events can vary per terminal depending on the service level agreement. 

* There can be multiple schedules per cleaning event at a direction-level (e.g., Clean #6: 1 asset at 16:00, Clean #6: 16 assets). This scenario can happen due to the varying schedules at an asset level. 

* You can hover over any data point to view a tooltip summary indicating each event's start time, end time, cleaning event no., type (updated or baseline), and which asset/direction it is pertaining to. 

The default view aggregates the schedules by direction (departures and/or arrivals). It can be expanded to enumerate all assets alphabetically by clicking on the drop-down arrow beside the direction name. The expanded view is limited to 10 rows at a time. You can use the scroll bar to move through the list.  

All cleaning events (historical and future) displayed on this card are predicted schedules only. It is derived from the Smart Scheduler model, which uses AMS and SITA data. 

## Card 2 - Half-hourly PAX Volume Trendline with Indicators  
This card serves as a guide in informing the future scheduling and planning activities to ensure that the allocated capacity meets the demand. 

There are three main components: PAX trendlines, peak indicators, and Happy Face negative response indicators. 

The PAX trendlines aggregate the no. of PAX that flow through each terminal in half-hourly blocks. You can hover over any data point to view a tooltip summary.  

* The PAX trendline on the current and historical dates is broken down into baseline PAX vs. updated PAX. 

    * The baseline trendline represents the sum of departing and/or arriving passengers at the start of Today's date. It is fixed and does not change throughout the day once set on the current day of operations. Its primary purpose is to show the PAX volume where the planned cleaning schedules for the day are based. 

    * The updated trendline represents the sum of departing and/or arriving passengers to account for any increase or decrease due to flight delays, cancellations, or early arrivals. Its primary purpose is to highlight the changes in the no. of PAX on the day of operations, resulting in adjustments in the plan as needed. 

* The PAX trendline on the future dates reflects one trendline only as flight changes are still expected until the current day of operations. It is identical to the updated PAX trendline, except its primary purpose is to guide planning rather than comparing against the baseline PAX. 

The triangle peak indicators are based on the top 5 highest absolute PAX for a given day. The gray peaks align with the baseline PAX and are automatically shown at the start of Today’s date. The blue peaks align with the updated PAX or PAX trendline to indicate any changes within the top 5 peaks. 

When printing the dashboard on any given date (click CTRL+P), the page produces an additional table showing the top 5 peaks arranged in descending order. The top 5 peaks can change depending on the time of printing. 

![FM PAX Peaks](${fmPeaksPrint})

Lastly, the Happy Face negative response indicators appear on the board when the sum of the negative responses is greater than or equal 6. It is displayed in 15-min blocks. The negative responses are updated in near real-time, appearing only on or before the “Now” indicator. 

Click on any indicators to display a modal window that shows the reasons for poor to extremely poor responses, no. of survey entries, and the proportion for each asset. 

The data sources and refresh frequency are AMS every 15 mins, Sabre and ACA every week, and Happy Face every minute. The following considerations are also applied depending on the data source: 

* Where AMS data is used, the PAX measurement uses known PAX values from AMS. Data is reported in the -3 days from Today until +2 days from Today’s date. 

* Where Sabre and ACA data are displayed, the total aircraft seats are used for the PAX measurement. Data is reported in the remaining 5 days or +3 days onwards from Today’s date. 

## Card 3 - Latest Number of Negative Responses per Happy Face Device 
This card shows the number of negative Happy Face responses in the last 15 minutes by floor level, device name, and type. You can either view the negative sentiments on the floorplan map or underneath the list. 

Each floor map, floor toggle, and device set are specific to each terminal. The statuses on the map indicate the following: 

* Green: No negative responses within the last 15 minutes 
* Amber to Red: Gradient of the severity of negative response 

When a user selects any device on the list, the map will zoom in. Any device name or row with at least one negative response is highlighted. 

The data source is Happy Face, and the card updates every minute. 

`.trim();
