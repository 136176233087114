import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ReactComponent as FilterIcon } from '../../../assets/img/filter.svg';

const FilterButton = props => {
  return (
    <button
      className={cn(
        'edp-notification-center__filter-btn',
        'edp-button--unstyled',
        'hide-when-printing'
      )}
      onClick={props.toggleFilter}
    >
      <FilterIcon className="edp__filter-icon" />
    </button>
  );
};

FilterButton.propTypes = {
  toggleFilter: PropTypes.func.isRequired
};

export default FilterButton;
