import React, { useState } from 'react';
import { ReactComponent as Chevron } from '../../../assets/img/chevron.svg';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';

const SecondarySideBarNavigationItem = (props) => {
  const {
    key,
    child,
    handleSecondaryItemClick,
    hideMenu,
    history,
    activeSecondaryItem
  } = props;

  const hasChildren = child.children !== undefined;

  const handleNavigate = (url) => {
    history.push(url);
    hideMenu();
  };

  const [animate, setAnimate] = useState(false);

  const currentUrl = window.location.pathname;

  const ruleName = child.ruleName;
  const ruleNames = ruleName.split(':');
  const primaryRuleName = ruleNames[0];
  const secondaryRuleName = ruleNames[1];

  return (
    <>
      <div key={key} className={cn('edp-nav-side-bar__secondary-item')}>
        <div
          className={cn('title', 'noselect', {
            active:
              currentUrl.includes(primaryRuleName) &&
              currentUrl.includes(secondaryRuleName)
          })}
          onClick={() => {
            if (hasChildren) {
              handleSecondaryItemClick(child.name);
              setAnimate(true);
            }
            if (child.to) {
              handleNavigate(child.to);
            }
          }}
        >
          {child.name + ' '}
          {hasChildren && (
            <Chevron
              className={cn('edp-nav-side-bar__secondary-arrow-icon', {
                up:
                  activeSecondaryItem &&
                  activeSecondaryItem.name === child.name,
                down:
                  animate &&
                  !(
                    activeSecondaryItem &&
                    activeSecondaryItem.name === child.name
                  )
              })}
              onAnimationEnd={() => setAnimate(false)}
            />
          )}
        </div>
        <div className={cn('body', 'noselect')}>
          {hasChildren &&
            activeSecondaryItem &&
            activeSecondaryItem.name === child.name &&
            activeSecondaryItem.children.map((child, i) => (
              <div
                key={i}
                className={cn('edp-nav-side-bar__secondary-sub-item', {
                  active: currentUrl.includes(child.to)
                })}
                onClick={() => {
                  if (child.to) {
                    handleNavigate(child.to);
                  }
                }}
              >
                <span>{child.name}</span>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

SecondarySideBarNavigationItem.propTypes = {
  key: PropTypes.number.isRequired,
  child: PropTypes.number.isRequired,
  handleSecondaryItemClick: PropTypes.number.isRequired,
  hideMenu: PropTypes.func.isRequired,
  activeSecondaryItem: PropTypes.number.isRequired
};

export default withRouter(SecondarySideBarNavigationItem);
