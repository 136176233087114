export default `
# Release Notes
## v5.0.1
03/03/22

### Enhancement

*  Repointing Azure Data Factory to GT Comm Dataset that contains Revenue Summary, Revenue Details and Forward bookings into single dataset. 
    * New GT Comm Report that consumes the dataset pointing to PBIWSPRD.
 
`