export default `
# Release Notes
## v6.2.0
26/04/22

### What's New

* Update on IOC 7 Day Board

`;
