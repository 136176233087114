import * as actionTypes from './actionTypes';
import * as signalrActionTypes from '../../../store/actionTypes/signalrActionTypes';
import { CALL_TOKEN_API } from '../../../redux/middleware/apiMiddleware';
import { getUserRoleUrl } from '../../../constants';

export const getUserPermissions = () => {
  if (process.env.NODE_ENV === 'development') {
    return {
      type: actionTypes.WITH_AUTH_GET_USER_ROLE_COMPLETED
    };
  }
  return {
    [CALL_TOKEN_API]: {
      type: actionTypes.WITH_AUTH_GET_USER_ROLE,
      payload: {
        endpoint: getUserRoleUrl
      }
    }
  };
};

export const connectToSignalr = () => {
  return {
    type: signalrActionTypes.EDP_SIGNALR_CONNECT
  };
};

export const clearUserPermissions = () => {
  return {
    type: actionTypes.WITH_AUTH_CLEAR_USER_ROLES
  };
};
