export default `
# Release Notes
## v2.11.0
25/03/20

### New Features

* Phase 1 of the TEC Report is available. This monthly report includes LA and Terminal Overview boards along with drilldown boards for each zone. The TEC report gives an insight on data and trends relating to traveller experience. 
* The Int Arrivals – Performance board has an export to PDF function. 
* Role Based Access Control (RBAC) – EDP Admins can easily add/delete/edit new permissions to a user’s account. 

### Bug Fixes

* International Arrivals Live transit times logic has been improved to capture more accurately live average transit times. 
* A bug preventing the correct floor from being displayed on the IOC Drilldown Happy Face cards has been fixed. 

`;
