import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';

const Board = props => {
  const { showNavigationSideBar, minimumPadding, className, children } = props;
  return (
    <div
      style={props.style}
      className={cn(
        {
          'default-board-padding': !minimumPadding,
          'minimum-board-padding': minimumPadding,
          'navbar-open': showNavigationSideBar
        },
        className
      )}
    >
      {children}
    </div>
  );
};

Board.defaultProps = {
  noPadding: false
};

Board.propTypes = {
  noPadding: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.element
};

const mapStateToProps = state => {
  return { showNavigationSideBar: state.navigation.showNavigationSideBar };
};

Board.displayName = 'Board';
export default connect(
  mapStateToProps,
  null
)(Board);
