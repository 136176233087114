// The EDP SignalR connection
export const EDP_SIGNALR_CONNECT = 'EDP_SIGNALR_CONNECT';
export const EDP_SIGNALR_DISCONNECT = 'EDP_SIGNALR_DISCONNECT';
// Responses from the EDP SignalR connection
export const EDP_SIGNALR_CONNECTED = 'EDP_SIGNALR_CONNECTED';
export const EDP_SIGNALR_DISCONNECTED = 'EDP_SIGNALR_DISCONNECTED';
export const EDP_SIGNALR_FAILED = 'EDP_SIGNALR_FAILED'; // Exception when attempting to conenct to SignalR/retrieving a payload
// Groups
export const EDP_SIGNALR_SUBSCRIBE_TO_GROUP = 'EDP_SIGNALR_SUBSCRIBE_TO_GROUP';
export const EDP_SIGNALR_UNSUBSCRIBE_FROM_GROUP =
  'EDP_SIGNALR_UNSUBSCRIBE_FROM_GROUP';
