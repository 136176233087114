import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import UserPermissionsTable from '../UserPermissionsTable/UserPermissionsTable';
import UserPermissionsDetailsPage from '../UserPermissionsDetailsPage/UserPermissionsDetailsPage';
import GroupCell from '../UserPermissionsTable/Cells/GroupCell';
import CreateGroupDialog from './CreateGroupDialog';
import GroupDetailsPage from './GroupDetailsPage';
import {
  getUserPermissionsGroups,
  getUserPermissionsRoles,
  updateUserPermissionsGroupReset,
  addUserPermissionsRolesToGroupReset
} from '../../../../store/actions/userPermissionsActions';
import Loading from '../../../Utils/Loading/Loading';
import DeleteGroupDialog from './DeleteGroupDialog';
import DeleteRoleFromGroupDialog from './DeleteRoleFromGroupDialog';
const groupsTableHeaders = [
  {
    Header: 'Name',
    accessor: 'groupName',
    Cell: GroupCell
  },
  {
    Header: 'Description',
    accessor: 'groupDescription'
  }
];

const Groups = props => {
  const {
    userPermissionsGroups,
    userPermissionsRoles,
    getUserPermissionsGroups,
    getUserPermissionsRoles,
    userPermissionsGroupsLoading,
    userPermissionsGroupsError,
    updateUserPermissionsGroupReset,
    addUserPermissionsRolesToGroupReset
  } = props;

  const [selected, setSelected] = useState(null);

  const unit = 'group';

  useEffect(() => {
    getUserPermissionsRoles();
    getUserPermissionsGroups();
  }, [getUserPermissionsGroups, getUserPermissionsRoles]);

  const handleReset = () => setSelected(null);

  const handleRowClick = rowObject => setSelected(rowObject);

  const handleExit = () => {
    // reset state
    handleReset();
    updateUserPermissionsGroupReset();
    addUserPermissionsRolesToGroupReset();
    // fetch all groups again
    getUserPermissionsGroups();
  };

  return (
    <div className="edp-user-permissions__groups-container">
      {selected ? (
        <UserPermissionsDetailsPage
          exit={handleExit}
          subtitle={'Group Details'}
          delete={
            <DeleteGroupDialog
              exitPage={handleExit}
              groupId={selected.groupId}
              groupName={selected.groupName}
              unit={unit}
            />
          }
        >
          <GroupDetailsPage
            DeleteRole={DeleteRoleFromGroupDialog}
            selected={selected}
            setSelected={setSelected}
            roles={userPermissionsRoles}
          />
        </UserPermissionsDetailsPage>
      ) : userPermissionsGroupsLoading ? (
        <Loading dark />
      ) : userPermissionsGroupsError ? (
        <div
          style={{
            height: '13em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <span style={{ margin: '2em 5em' }}>
            Apologies, an error has occurred. Please try again later.
          </span>
        </div>
      ) : (
        <UserPermissionsTable
          data={userPermissionsGroups}
          tableHeaders={groupsTableHeaders}
          onRowClick={handleRowClick}
          unit={unit}
          addDialog={rowHandler => (
            <CreateGroupDialog addGroupHandler={rowHandler} unit={unit} />
          )}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userPermissionsRoles: state.userPermissions.userPermissionsRoles,
    userPermissionsGroups: state.userPermissions.userPermissionsGroups,
    userPermissionsGroupsLoading:
      state.userPermissions.userPermissionsGroupsLoading,
    userPermissionsGroupsError: state.userPermissions.userPermissionsGroupsError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserPermissionsGroupReset: () =>
      dispatch(updateUserPermissionsGroupReset()),
    addUserPermissionsRolesToGroupReset: () =>
      dispatch(addUserPermissionsRolesToGroupReset()),
    getUserPermissionsRoles: () => dispatch(getUserPermissionsRoles()),
    getUserPermissionsGroups: () => dispatch(getUserPermissionsGroups())
  };
};

Groups.displayName = 'User Permissions Portal - Groups';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Groups);
