export default `
# Glossary
## Definitions  

**Airport Drive** - The internal roads travel route from Sharps Rd to T1 Departures via Airport Drive.

**Baggage Reclaim** - In T2 Arrivals, this is the area located from the bottom of the escalators leaving immigration until the Arrivals hall (after secondary inspection).

**Baseline Forecast** - A snapshot of the forecast for the next 12 hours taken at either 5am or 5pm. Represented by the grey line on the Beontra charts.

**Claim** - A request from a Landside Access customer for a refund.  

**Complaint ** - An email or call received from a Landside Access customer who has found something unsatisfactory.  

**Drop-off** - The internal roads travel route from Mickleham Rd on ramp to T1 Departures.  

**Enquiry** - An email or call from a Landside Access customer requesting some information.  

**ETD** - Estimated time of departure.   

**Gates** - In T2 Arrivals, this is from the pier (D20) to the end of the satellite area.  

**Immigration** - In T2 Arrivals, this is the area located at the start of the satellite (close to D3 & D4) to the beginning of the escalators before baggage reclaim.  

**PAX** - Number of passengers.  

**Pick–up** - The internal roads travel route from Mickleham Rd on ramp to T1 Arrivals.  

**STD** - Scheduled time of departure.  

**Transit time** - The time it takes for a passenger to move from one area to another in International arrivals.  

**Updated Forecast** - The latest forecast data that is available based on live changes i.e. Operational flight schedule. Represented by the blue or purple line on the Beontra cards  
`.trim();
