import React from 'react';

const GroupsCell = cellInfo => {
  const groups = cellInfo.cell.value; // an array of groups
  return groups.map(group => {
    return (
      <span className={'edp-user-permissions__table-cell--group'}>
        {group.groupName ? group.groupName : group}
      </span>
    );
  });
};

export default GroupsCell;
