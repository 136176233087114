import * as notificationActionTypes from '../actionTypes/notificationActionTypes';

export const newNotification = payload => {
  return {
    type: notificationActionTypes.NEW_NOTIFICATION,
    payload
  };
};

export const removeNotifications = notifications => {
  return {
    type: notificationActionTypes.NOTIFICATIONS_REMOVE,
    payload: notifications
  };
};

export const openNotificationCenter = () => {
  return {
    type: notificationActionTypes.NOTIFICATIONS_NOTIFICATION_CENTER_OPENED
  };
};

export const closeNotificationCenter = () => {
  return {
    type: notificationActionTypes.NOTIFICATIONS_NOTIFICATION_CENTER_CLOSED
  };
};

export const updateFilterOptions = filterOptions => {
  return {
    type: notificationActionTypes.NOTIFICATIONS_FILTERS_UPDATED,
    payload: filterOptions
  };
};

export const removeFilterOptions = () => {
  return {
    type: notificationActionTypes.NOTIFICATIONS_FILTERS_REMOVED
  };
};

export const changeTab = targetTab => {
  return {
    type: notificationActionTypes.NOTIFICATIONS_TAB_CHANGED,
    payload: targetTab
  };
};
