import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createTokenApiMiddleware } from './redux/middleware/apiMiddleware';
import loglevel from 'loglevel';
import createSagaMiddleware from 'redux-saga';
// Sagas
import signalrWatchers from './sagas/signalrSagas';
import notificationsWatchers from './sagas/notificationsSagas';
import exportWatchers from './sagas/exportSagas';
// Import reducers
import notificationReducers from './store/reducers/notificationsReducers';
import signalrReducers from './store/reducers/signalrReducers';
import authReducers from './hoc/withAuth/store/reducers';
import helpAndFeedbackReducer from './components/Navigation/NavigationBar/Dropdowns/HelpAndFeedback/store/reducers';
import embededReportReducers from './components/Utils/EmbeddedReport/store/reducers';
import cmxDataReducers from './components/Pages/IntArrivals/store/reducers';
import iocReducers from './store/reducers/iocReducers';
import intArrivalsReducers from './store/reducers/intArrivalsReducers';
import happyFaceReducers from './store/reducers/happyFaceReducers';
import navigationReducers from './store/reducers/navigationReducers';
import userPermissionsReducers from './store/reducers/userPermissionsReducers';
import fmReducers from './store/reducers/fmReducers';
import gtReducers from './store/reducers/gtReducers';

export default () => {
  const checkResponseIsOk = (response) => {
    return response.ok ||
      response.status === 401 ||
      response.url.includes('/logs')
      ? // TODO: Remove this; added to avoid an infinite loop (if the response to the logging request returns 401)
        response
      : response.text().then((text) => {
          loglevel.error(
            `Bad response (status code: ${response.status.toString()} - ${
              response.statusText
            })`,
            JSON.stringify({
              url: response.url,
              status: response.status,
              statusText: response.statusText
            })
          );
          return Promise.reject({ response, error: 'error' });
        });
  };
  const config = {
    defaultHeaders: { 'Content-Type': 'application/json' },
    checkResponseIsOk
  };

  const sagaMiddleware = createSagaMiddleware();
  const apiTokenMiddleware = createTokenApiMiddleware(config);
  const middlewares = [thunk, apiTokenMiddleware, sagaMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const rootReducer = combineReducers({
    withAuth: authReducers,
    helpAndFeedback: helpAndFeedbackReducer,
    embededReport: embededReportReducers,
    cmxData: cmxDataReducers,
    notifications: notificationReducers,
    signalr: signalrReducers,
    ioc: iocReducers,
    intArrivals: intArrivalsReducers,
    happyFace: happyFaceReducers,
    navigation: navigationReducers,
    userPermissions: userPermissionsReducers,
    fm: fmReducers,
    gt: gtReducers
  });

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, composedEnhancers);

  // Add sagas to the middleware
  sagaMiddleware.run(signalrWatchers);
  sagaMiddleware.run(notificationsWatchers);
  sagaMiddleware.run(exportWatchers);
  return store;
};
