const mockData = [
  { maximumTravelTime: 25.0, monthlyDate: '2019-07-08T00:00:00' },
  { maximumTravelTime: 33.0, monthlyDate: '2019-07-09T00:00:00' },
  { maximumTravelTime: 26.0, monthlyDate: '2019-07-10T00:00:00' },
  { maximumTravelTime: 43.0, monthlyDate: '2019-07-11T00:00:00' },
  { maximumTravelTime: 39.0, monthlyDate: '2019-07-12T00:00:00' },
  { maximumTravelTime: 11.0, monthlyDate: '2019-07-13T00:00:00' },
  { maximumTravelTime: 27.0, monthlyDate: '2019-07-14T00:00:00' },
  { maximumTravelTime: 31.0, monthlyDate: '2019-07-15T00:00:00' },
  { maximumTravelTime: 25.0, monthlyDate: '2019-07-16T00:00:00' },
  { maximumTravelTime: 35.0, monthlyDate: '2019-07-17T00:00:00' },
  { maximumTravelTime: 25.0, monthlyDate: '2019-07-18T00:00:00' },
  { maximumTravelTime: 32.0, monthlyDate: '2019-07-19T00:00:00' },
  { maximumTravelTime: 26.0, monthlyDate: '2019-07-20T00:00:00' },
  { maximumTravelTime: 43.0, monthlyDate: '2019-07-21T00:00:00' },
  { maximumTravelTime: 39.0, monthlyDate: '2019-07-22T00:00:00' },
  { maximumTravelTime: 11.0, monthlyDate: '2019-07-23T00:00:00' },
  { maximumTravelTime: 27.0, monthlyDate: '2019-07-24T00:00:00' },
  { maximumTravelTime: 31.0, monthlyDate: '2019-07-25T00:00:00' },
  { maximumTravelTime: 25.0, monthlyDate: '2019-07-26T00:00:00' },
  { maximumTravelTime: 35.0, monthlyDate: '2019-07-27T00:00:00' },
  { maximumTravelTime: 25.0, monthlyDate: '2019-07-28T00:00:00' },
  { maximumTravelTime: 33.0, monthlyDate: '2019-07-29T00:00:00' },
  { maximumTravelTime: 26.0, monthlyDate: '2019-07-30T00:00:00' },
  { maximumTravelTime: 43.0, monthlyDate: '2019-08-01T00:00:00' },
  { maximumTravelTime: 39.0, monthlyDate: '2019-08-02T00:00:00' },
  { maximumTravelTime: 11.0, monthlyDate: '2019-08-03T00:00:00' },
  { maximumTravelTime: 27.0, monthlyDate: '2019-08-04T00:00:00' },
  { maximumTravelTime: 31.0, monthlyDate: '2019-08-05T00:00:00' },
  { maximumTravelTime: 25.0, monthlyDate: '2019-08-06T00:00:00' },
  { maximumTravelTime: 35.0, monthlyDate: '2019-08-07T00:00:00' }
];

export default mockData;
