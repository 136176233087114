import React from 'react';
import { ReactComponent as SuccessTick } from '../../../assets/img/success-tick.svg';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

const SuccessIcon = props => {
  const { tooltipContent, placement } = props;

  return (
    <Tooltip title={tooltipContent} placement={placement}>
      <div style={containerStyles}>
        <SuccessTick style={iconStyles} />
      </div>
    </Tooltip>
  );
};

SuccessIcon.defaultProps = {
  tooltipContent: 'Success',
  placement: 'bottom'
};

SuccessIcon.propTypes = {
  tooltipContent: PropTypes.string,
  placement: PropTypes.string
};

const containerStyles = { display: 'inline-block' };

const iconStyles = {
  width: '15px',
  height: '15px',
  marginBottom: '3px',
  fill: '#1ab394'
};

export default SuccessIcon;
