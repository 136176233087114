export default `
# Release Notes
## v6.7.0
07/06/22

### What's New

* Updated GT Comms Configuration (Report IDs)

`;
