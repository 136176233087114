import * as actionTypes from '../actionTypes/signalrActionTypes';
import updateObject from '../utility';

const initialState = {
  isConnectedToStream: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EDP_SIGNALR_CONNECTED:
      return updateObject(state, {
        isConnectedToStream: true
      });
    case actionTypes.EDP_SIGNALR_DISCONNECTED:
      return updateObject(state, {
        isConnectedToStream: false
      });
    default:
      return state;
  }
};

export default reducer;
