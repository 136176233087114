export default `
# Release Notes
## v2.3.0
30/09/19

### What's New

*PGA*

* A new layout to the Road and Carpark Drilldown has been released. Users will notice the weekly view now has dd/mm/yy format instead of weekdays. 

* We’ve also added some extra KPI cards – (This Week, Last Week, Last Month) 


*IOC*

* EDP will now provide notifications for early/late flights and PAX shifts. Users will be able to view all past notifications in the notification centre. (Look out for the ‘Bell’ icon).

* We’ve updated the International Arrivals Live board. It now includes a map of the different transit zones.  

* The Internal Roads and Parking Capacity cards have been updated with fresh designs. 

* The weather card now contains knots for wind speed and meter values under 1 km visibility. 


### Bug Fixes

* edp timeout issue resolved! Users won’t have to constantly refresh edp anymore. 

* The Int Arrivals Peak board (Month) sometimes showed 32 days. This has been corrected so no more than 31 days are ever visible. 

* If the travel times went over 99 mins edp failed to display the 3-figure time. That’s no longer the case. 

* The ETD for AMS Departing Flights is now visible. 

* Some other minor bugs have been resolved allowing edp to be more efficient. 
 
`;
