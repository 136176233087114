import * as actionTypes from './actionTypes';
import updateObject from '../../../../../../store/utility';

const initialState = {
  didSendRequest: false,
  requestFailed: false,
  sendingRequest: false
};

const resetContactUs = (state, action) => {
  return updateObject(state, initialState);
};

const contactUsRequestCompleted = state => {
  return updateObject(state, {
    didSendRequest: true,
    requestFailed: false,
    sendingRequest: false
  });
};

const contactUsRequestStarted = (state, action) => {
  return updateObject(state, {
    didSendRequest: false,
    sendingRequest: true,
    requestFailed: false
  });
};

const contactUsRequestFailed = (state, action) => {
  return updateObject(state, {
    didSendRequest: false,
    requestFailed: true,
    sendingRequest: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONTACT_US_SEND_REQUEST_COMPLETED:
      return contactUsRequestCompleted(state, action);
    case actionTypes.CONTACT_US_SEND_REQUEST_START:
      return contactUsRequestStarted(state, action);
    case actionTypes.CONTACT_US_SEND_REQUEST_FAILED:
      return contactUsRequestFailed(state, action);
    case actionTypes.CONTACT_US_RESET:
      return resetContactUs(state);
    default:
      return state;
  }
};

export default reducer;
