import { put, takeEvery, select } from 'redux-saga/effects';
import * as signalrActionTypes from '../store/actionTypes/signalrActionTypes';
import { getLastNotificationTime } from './selectors';
import { signalrMethods } from '../constants';
import { dateDiffInMinutes } from '../helpers/date';
import { subscribeToGroup as subscribeToGroupAction } from '../store/actions/signalrActions';
import * as notificationActionTypes from '../store/actionTypes/notificationActionTypes';
import { CALL_TOKEN_API } from '../redux/middleware/apiMiddleware';
import { getNotificationsUrl } from '../constants';

const TWENTY_FOUR_HOURS = 1440; /* mins */

function* connectToNotifications() {
  // Connect to the Notifications Groups
  yield put(subscribeToGroupAction(signalrMethods.notifications));
  // Fetch historical notifications
  const lastNotificationTime = yield select(getLastNotificationTime);
  let timeAgo = TWENTY_FOUR_HOURS;
  if (lastNotificationTime !== null) {
    const minutesSinceLastNotification = dateDiffInMinutes(
      lastNotificationTime,
      new Date()
    );
    timeAgo =
      minutesSinceLastNotification > TWENTY_FOUR_HOURS
        ? TWENTY_FOUR_HOURS
        : minutesSinceLastNotification;
  }
  yield put({
    [CALL_TOKEN_API]: {
      type: notificationActionTypes.NOTIFICATIONS_GET_NOTIFICATIONS,
      payload: {
        endpoint: `${getNotificationsUrl}`,
        headers: {
          LookBackMinutes: timeAgo
        }
      }
    }
  });
}

export default function* notificationsWatcher() {
  yield takeEvery(
    signalrActionTypes.EDP_SIGNALR_CONNECTED,
    connectToNotifications
  );
}
