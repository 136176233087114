// IOC - Overview
export const IOC_OVERVIEW_PAYLOAD_COMPLETED = 'IOC_OVERVIEW_PAYLOAD_COMPLETED';

// IOC - T2
// manning level
export const IOC_T2_ARRIVALS_GET_MANNING_LEVEL =
  'IOC_T2_ARRIVALS_GET_MANNING_LEVEL';
export const IOC_T2_ARRIVALS_GET_MANNING_LEVEL_START =
  'IOC_T2_ARRIVALS_GET_MANNING_LEVEL_START';
export const IOC_T2_ARRIVALS_GET_MANNING_LEVEL_COMPLETED =
  'IOC_T2_ARRIVALS_GET_MANNING_LEVEL_COMPLETED';
export const IOC_T2_ARRIVALS_GET_MANNING_LEVEL_FAILED =
  'IOC_T2_ARRIVALS_GET_MANNING_LEVEL_FAILED';
export const IOC_T2_ARRIVALS_SET_MANNING_LEVEL =
  'IOC_T2_ARRIVALS_SET_MANNING_LEVEL';
export const IOC_T2_ARRIVALS_SET_MANNING_LEVEL_START =
  'IOC_T2_ARRIVALS_SET_MANNING_LEVEL_START';
export const IOC_T2_ARRIVALS_SET_MANNING_LEVEL_COMPLETED =
  'IOC_T2_ARRIVALS_SET_MANNING_LEVEL_COMPLETED';
export const IOC_T2_ARRIVALS_SET_MANNING_LEVEL_FAILED =
  'IOC_T2_ARRIVALS_SET_MANNING_LEVEL_FAILED';
// beontra data
export const IOC_T2_PAYLOAD_COMPLETED = 'IOC_T2_PAYLOAD_COMPLETED';
export const IOC_T2_GET_BEONTRA_DATA = 'IOC_T2_GET_BEONTRA_DATA';
export const IOC_T2_GET_BEONTRA_DATA_START = 'IOC_T2_GET_BEONTRA_DATA_START';
export const IOC_T2_GET_BEONTRA_DATA_COMPLETED =
  'IOC_T2_GET_BEONTRA_DATA_COMPLETED';
export const IOC_T2_GET_BEONTRA_DATA_FAILED = 'IOC_T2_GET_BEONTRA_DATA_FAILED';

// IOC - T3
export const IOC_T3_PAYLOAD_COMPLETED = 'IOC_T3_PAYLOAD_COMPLETED';
export const IOC_T3_GET_BEONTRA_DATA_START = 'IOC_T3_GET_BEONTRA_DATA_START';
export const IOC_T3_GET_BEONTRA_DATA = 'IOC_T3_GET_BEONTRA_DATA';
export const IOC_T3_GET_BEONTRA_DATA_COMPLETED =
  'IOC_T3_GET_BEONTRA_DATA_COMPLETED';
export const IOC_T3_GET_BEONTRA_DATA_FAILED = 'IOC_T3_GET_BEONTRA_DATA_FAILED';
export const IOC_T3_PAX_PRESENTATION_DATA_COMPLETED =
  'IOC_T3_PAX_PRESENTATION_DATA_COMPLETED';

// IOC - T4
export const IOC_T4_PAYLOAD_COMPLETED = 'IOC_T4_PAYLOAD_COMPLETED';
export const IOC_T4_GET_BEONTRA_DATA_START = 'IOC_T4_GET_BEONTRA_DATA_START';
export const IOC_T4_GET_BEONTRA_DATA = 'IOC_T4_GET_BEONTRA_DATA';
export const IOC_T4_GET_BEONTRA_DATA_COMPLETED =
  'IOC_T4_GET_BEONTRA_DATA_COMPLETED';
export const IOC_T4_GET_BEONTRA_DATA_FAILED = 'IOC_T4_GET_BEONTRA_DATA_FAILED';

// Flight Breakdown
export const IOC_GET_FLIGHT_BREAKDOWN_DATA = 'IOC_GET_FLIGHT_BREAKDOWN_DATA';
export const IOC_GET_FLIGHT_BREAKDOWN_DATA_START =
  'IOC_GET_FLIGHT_BREAKDOWN_DATA_START';
export const IOC_GET_FLIGHT_BREAKDOWN_DATA_COMPLETED =
  'IOC_GET_FLIGHT_BREAKDOWN_DATA_COMPLETED';
export const IOC_GET_FLIGHT_BREAKDOWN_DATA_FAILED =
  'IOC_GET_FLIGHT_BREAKDOWN_DATA_FAILED';

// Seven Day Dashboard
export const IOC_SEVEN_DAY_T1_PAYLOAD_COMPLETED =
  'IOC_SEVEN_DAY_T1_PAYLOAD_COMPLETED';
export const IOC_GET_SEVEN_DAY_T1_DATA_START =
  'IOC_GET_SEVEN_DAY_T1_DATA_START';
export const IOC_GET_SEVEN_DAY_T1_DATA = 'IOC_GET_SEVEN_DAY_T1_DATA';
export const IOC_GET_SEVEN_DAY_T1_DATA_COMPLETED =
  'IOC_GET_SEVEN_DAY_T1_DATA_COMPLETED';
export const IOC_GET_SEVEN_DAY_T1_DATA_FAILED =
  'IOC_GET_SEVEN_DAY_T1_DATA_FAILED';

export const IOC_SEVEN_DAY_T3_PAYLOAD_COMPLETED =
  'IOC_SEVEN_DAY_T3_PAYLOAD_COMPLETED';
export const IOC_GET_SEVEN_DAY_T3_DATA_START =
  'IOC_GET_SEVEN_DAY_T3_DATA_START';
export const IOC_GET_SEVEN_DAY_T3_DATA = 'IOC_GET_SEVEN_DAY_T3_DATA';
export const IOC_GET_SEVEN_DAY_T3_DATA_COMPLETED =
  'IOC_GET_SEVEN_DAY_T3_DATA_COMPLETED';
export const IOC_GET_SEVEN_DAY_T3_DATA_FAILED =
  'IOC_GET_SEVEN_DAY_T3_DATA_FAILED';

export const IOC_SEVEN_DAY_T4_PAYLOAD_COMPLETED =
  'IOC_SEVEN_DAY_T4_PAYLOAD_COMPLETED';
export const IOC_GET_SEVEN_DAY_T4_DATA_START =
  'IOC_GET_SEVEN_DAY_T4_DATA_START';
export const IOC_GET_SEVEN_DAY_T4_DATA = 'IOC_GET_SEVEN_DAY_T4_DATA';
export const IOC_GET_SEVEN_DAY_T4_DATA_COMPLETED =
  'IOC_GET_SEVEN_DAY_T4_DATA_COMPLETED';
export const IOC_GET_SEVEN_DAY_T4_DATA_FAILED =
  'IOC_GET_SEVEN_DAY_T4_DATA_FAILED';

export const IOC_SEVEN_DAY_T2_PAYLOAD_COMPLETED =
  'IOC_SEVEN_DAY_T2_PAYLOAD_COMPLETED';
export const IOC_GET_SEVEN_DAY_T2_DATA_START =
  'IOC_GET_SEVEN_DAY_T2_DATA_START';
export const IOC_GET_SEVEN_DAY_T2_DATA = 'IOC_GET_SEVEN_DAY_T2_DATA';
export const IOC_GET_SEVEN_DAY_T2_DATA_COMPLETED =
  'IOC_GET_SEVEN_DAY_T2_DATA_COMPLETED';
export const IOC_GET_SEVEN_DAY_T2_DATA_FAILED =
  'IOC_GET_SEVEN_DAY_T2_DATA_FAILED';

// POST SEVEN ASSET ACTUAL OPENING PLAN
export const IOC_SEVENDAY_ASSET_PLAN_UPDATE = 'IOC_SEVENDAY_ASSET_PLAN_UPDATE';
export const IOC_SEVENDAY_ASSET_PLAN_UPDATE_START =
  'IOC_SEVENDAY_ASSET_PLAN_UPDATE_START';
export const IOC_SEVENDAY_ASSET_PLAN_UPDATE_COMPLETED =
  'IOC_SEVENDAY_ASSET_PLAN_UPDATE_COMPLETED';
export const IOC_SEVENDAY_ASSET_PLAN_UPDATE_FAILED =
  'IOC_SEVENDAY_ASSET_PLAN_UPDATE_FAILED';
export const IOC_SEVENDAY_ASSET_PLAN_UPDATE_RESET =
  'IOC_SEVENDAY_ASSET_PLAN_UPDATE_RESET';
