// New notification created
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
// remove notifications
export const NOTIFICATIONS_REMOVE = 'NOTIFICATIONS_REMOVE';
// Notification center
export const NOTIFICATIONS_NOTIFICATION_CENTER_OPENED =
  'NOTIFICATIONS_NOTIFICATION_CENTER_OPENED';
export const NOTIFICATIONS_NOTIFICATION_CENTER_CLOSED =
  'NOTIFICATIONS_NOTIFICATION_CENTER_CLOSED';
// GET notifications
export const NOTIFICATIONS_GET_NOTIFICATIONS =
  'NOTIFICATIONS_GET_NOTIFICATIONS';
export const NOTIFICATIONS_GET_NOTIFICATIONS_START =
  'NOTIFICATIONS_GET_NOTIFICATIONS_START';
export const NOTIFICATIONS_GET_NOTIFICATIONS_COMPLETED =
  'NOTIFICATIONS_GET_NOTIFICATIONS_COMPLETED';
export const NOTIFICATIONS_GET_NOTIFICATIONS_FAILED =
  'NOTIFICATIONS_GET_NOTIFICATIONS_FAILED';
// Update filter options
export const NOTIFICATIONS_FILTERS_UPDATED =
  'NOTIFICATIONS_FILTERS_UPDATED';
export const NOTIFICATIONS_FILTERS_REMOVED =
  'NOTIFICATIONS_FILTERS_REMOVED';
// change tab
export const NOTIFICATIONS_TAB_CHANGED =
  'NOTIFICATIONS_TAB_CHANGED';