export default `
# Release Notes
## v2.7.0
18/12/19

### New Features
*IOC*

* A map view of the Happy Face devices has been added to the Terminal drilldown boards. You can view all devices across the airport and see the number of negative responses in the last 15 minutes on each one. If you click the Happy Face notification it will the display the Terminal Map.
* The Traveller Experience (TE) Board allows you to view the average Happy Face device scores for different categories and areas.
* IOC Drilldown Printing – You can now print the top 2 cards on IOC terminal drilldown boards. Use Ctrl + P as a shortcut.
* The “Gate” field has been added to the Arrivals Flight Card.

*Aviation Commercial (Release 2 of 2)*

* International Market – A view of PAX movements and airline performance between any origin and destination in the world. 
* Dynamic Grouping – The ability to create custom airport groups. 

*Landside Access Commercial (Release 2 of 2)*

* Salesforce Integration – New attributes from customer data available in Salesforce for marketing purposes.
* Customer Boards – Understand existing customer base, size and breakdown by channel and distance. New customers. 

### Bug Fixes

* A small change to reduce the amount of amber notifications being displayed. 
* Following the CMX analysis work, an amendment to the Int Arrivals Board will result in a reduction of false peaks.
* A slight adjustment to the Queue Time cards visible on the IOC Overview board was deployed. You will no longer see the “Now” time as there were some issues with data quality.
* An issue with the Previous 24hr PAX view has been fixed. The estimated PAX numbers will be reflective of the forecasted PAX numbers.

`;
