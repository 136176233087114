import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import cn from 'classnames';

const DetailsTable = props => {
  const {
    selected,
    headers,
    groups,
    roles,
    setSelected,
    AddUserToGroupDropdown,
    DeleteRole,
    AddRolesToGroupDropdown,
    DeleteUserFromGroupDialog,
    onInputChange
  } = props;

  const tableHeaders = headers.filter(header => header.key !== 'subRows');

  return (
    <table className="edp-user-permissions__details-page--table">
      {tableHeaders.map(header => {
        const { key, title, editable } = header;

        return (
          <tr className="edp-user-permissions__details-page--table-row">
            <th className="edp-user-permissions__details-page--table-header">
              {title}
            </th>
            <td className="edp-user-permissions__details-page--table-cell">
              {key === 'groups' && editable ? (
                <>
                  {selected[key].map(group => {
                    return (
                      <span
                        className={'edp-user-permissions__table-cell--role'}
                      >
                        {group.groupName}
                        <DeleteUserFromGroupDialog
                          user={selected}
                          group={group}
                          setSelected={setSelected}
                        />
                      </span>
                    );
                  })}
                  {groups && (
                    <AddUserToGroupDropdown
                      setUser={setSelected}
                      groups={groups}
                      user={selected}
                    />
                  )}
                </>
              ) : key === 'roles' && editable ? (
                <>
                  {selected[key].map(role => {
                    return (
                      <span
                        className={'edp-user-permissions__table-cell--role'}
                      >
                        {role.roleName}
                        <DeleteRole
                          group={selected}
                          role={role}
                          setSelected={setSelected}
                        />
                      </span>
                    );
                  })}

                  {roles && (
                    <AddRolesToGroupDropdown
                      setGroup={setSelected}
                      group={selected}
                      roles={roles}
                    />
                  )}
                </>
              ) : key === 'isActive' && editable ? (
                JSON.stringify(selected[key])
              ) : editable ? (
                <Input
                  type={
                    key.toLowerCase().includes('description')
                      ? 'textarea'
                      : 'text'
                  }
                  className={cn('edp-user-permissions__input-field', {
                    description: key.toLowerCase().includes('description')
                  })}
                  value={selected[key]}
                  onChange={e => {
                    onInputChange(key, e.target.value);
                  }}
                />
              ) : (
                selected[key]
              )}
            </td>
          </tr>
        );
      })}
    </table>
  );
};

DetailsTable.propTypes = {
  headers: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
  groups: PropTypes.array,
  roles: PropTypes.array,
  AddUserToGroupDropdown: PropTypes.element,
  DeleteUserFromGroupDialog: PropTypes.element,
  onInputChange: PropTypes.func
};

export default DetailsTable;
