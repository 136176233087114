import React, { useState } from 'react';
import { connect } from 'react-redux';
import DetailsTable from '../UserPermissionsDetailsPage/DetailsTable';
import AddUserToGroupDropdown from './AddUserToGroupDropdown';
import DeleteUserFromGroupDialog from './DeleteUserFromGroupDialog';
import {
  updateUserPermissionsUser,
  updateUserPermissionsUserReset
} from '../../../../store/actions/userPermissionsActions';
import SuccessIcon from '../../../Utils/SuccessMessages/SuccessIcon';
import TinyLoadingSpinner from '../../../Utils/Loading/TinyLoadingSpinner';
import ErrorIcon from '../../../Utils/ErrorMessages/ErrorIcon';

const detailsTableHeaders = [
  { key: 'userName', title: 'Username', editable: false },
  { key: 'emailAddress', title: 'Email Address', editable: false },
  { key: 'groups', title: 'Groups', editable: true }
];

let initialSelectedState = null;

const UserDetailsPage = props => {
  const {
    selected,
    setSelected,
    groups,
    updateUserPermissionsUser,
    updateUserPermissionsUserReset,
    userPermissionsUpdatingUser,
    userPermissionsUpdateUserError,
    userPermissionsUpdateUserSuccess
  } = props;

  const [fieldsUpdated, setFieldsUpdated] = useState(false);

  const onInputChange = (key, value) => {
    // if the fields are being updated for the first time
    if (!fieldsUpdated) {
      updateUserPermissionsUserReset();
      // store the initial state (in case of reset) and set updated state
      initialSelectedState = JSON.parse(JSON.stringify(selected));
      setFieldsUpdated(true);
    }

    // always overwrite the field value
    setSelected({ ...selected, [key]: value });
  };

  const handleSubmit = () => {
    if (fieldsUpdated) {
      // grab only the fields that should be submitted
      const updatedFields = {
        userName: selected.userName,
        emailAddress: selected.emailAddress,
        isActive: selected.isActive
      };
      updateUserPermissionsUser(selected.userId, updatedFields);
      setFieldsUpdated(false);
    }
  };

  const handleReset = () => {
    // if the fields have been updated and initial state is stored
    if (fieldsUpdated && initialSelectedState) {
      // reset the input fields to original values
      setSelected(initialSelectedState);
      setFieldsUpdated(false);
    }
  };

  return (
    <div style={{ marginTop: '15px' }}>
      <DetailsTable
        AddUserToGroupDropdown={AddUserToGroupDropdown}
        DeleteUserFromGroupDialog={DeleteUserFromGroupDialog}
        headers={detailsTableHeaders}
        groups={groups}
        selected={selected}
        setSelected={setSelected}
        onInputChange={onInputChange}
      />

      <div style={{ float: 'right', margin: '1em 0.25em' }}>
        {userPermissionsUpdatingUser ? (
          <TinyLoadingSpinner />
        ) : userPermissionsUpdateUserSuccess ? (
          <SuccessIcon
            tooltipContent={'Successfully updated'}
            placement={'left'}
          />
        ) : userPermissionsUpdateUserError ? (
          <ErrorIcon
            tooltipContent={'Error updating group. Please try again later'}
            placement={'left'}
          />
        ) : (
          fieldsUpdated && (
            <>
              <button
                className={'edp-user-permissions__button'}
                style={{ float: 'right', margin: '0 0.25em' }}
                onClick={() => handleReset()}
              >
                Reset
              </button>
              <button
                className={'edp-user-permissions__button'}
                style={{ float: 'right', margin: '0 0.25em' }}
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            </>
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userPermissionsUpdatingGroup:
      state.userPermissions.userPermissionsUpdatingGroup,
    userPermissionsUpdateGroupError:
      state.userPermissions.userPermissionsUpdateGroupError,
    userPermissionsUpdateGroupSuccess:
      state.userPermissions.userPermissionsUpdateGroupSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserPermissionsUser: (userId, newUserFields) =>
      dispatch(updateUserPermissionsUser(userId, newUserFields)),
    updateUserPermissionsUserReset: () =>
      dispatch(updateUserPermissionsUserReset())
  };
};

UserDetailsPage.displayName = 'User Permissions Portal - Group Details Page';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailsPage);
