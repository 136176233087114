import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import {
  addUserPermissionsRolesToGroup,
  addUserPermissionsRolesToGroupReset
} from '../../../../store/actions/userPermissionsActions';
import cn from 'classnames';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import ErrorIcon from '../../../Utils/ErrorMessages/ErrorIcon';
import TinyLoadingSpinner from '../../../Utils/Loading/TinyLoadingSpinner';

function stringComparison(a, b) {
  if (a.groupName < b.groupName) {
    return -1;
  }
  if (a.groupName > b.groupName) {
    return 1;
  }
  return 0;
}

const AddRolesToGroupDropdown = props => {
  const {
    userPermissionsAddingRolesToGroup,
    userPermissionsAddRolesToGroupError,
    userPermissionsAddRolesToGroupSuccess,
    addUserPermissionsRolesToGroup,
    addUserPermissionsRolesToGroupReset,
    setGroup,
    group,
    roles
  } = props;

  const groupRoleIds = group.roles.map(role => role.roleId);
  const availableRoles = roles.filter(
    role => !groupRoleIds.includes(role.roleId)
  );

  // sort available roles alphabetically by name
  availableRoles.sort(stringComparison);

  // show error message?
  const [showError, setShowError] = useState(false);
  // is the dropdown open?
  const [open, setOpen] = useState(false);
  // newly added group
  const [addedRole, setAddedRole] = useState(null);

  // handle success/error response in submitting group
  useEffect(() => {
    // if error is populated in the store, show error message
    if (userPermissionsAddRolesToGroupError) {
      setShowError(true);
    } // if we are not currently creating the group and the group has been submitted (is in groups array) show success
    if (userPermissionsAddRolesToGroupSuccess) {
      // add group to array and reset state
      const updatedGroup = JSON.parse(JSON.stringify(group));
      updatedGroup.roles.push(addedRole);
      setGroup(updatedGroup);
      setAddedRole(null);
      addUserPermissionsRolesToGroupReset();
    }
  }, [
    addUserPermissionsRolesToGroupReset,
    addedRole,
    group,
    setGroup,
    userPermissionsAddRolesToGroupError,
    userPermissionsAddRolesToGroupSuccess
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const toggleDropdown = () => {
    setOpen(!open);
  };

  const handleDropdownItemClick = role => {
    // TODO: input field validation (e.g. no submitting empty groups)
    // TODO: actually add user to group
    addUserPermissionsRolesToGroup([role.roleId], group.groupId);
    setAddedRole(role);
  };

  const loading = userPermissionsAddingRolesToGroup;

  return (
    availableRoles.length > 0 &&
    (loading ? (
      <TinyLoadingSpinner />
    ) : showError ? (
      <ErrorIcon
        tooltipContent={'Error adding a Role. Please try again later'}
        placement={'bottom'}
      />
    ) : (
      <ButtonDropdown toggle={toggleDropdown} isOpen={open} direction="down">
        <DropdownToggle tag="span" data-toggle="dropdown">
          <Tooltip title="Add" placement="right">
            <button
              className={cn('edp-user-permissions__add-button', 'green', {
                disabled: loading
              })}
              onClick={() => handleClickOpen()}
            >
              <AddCircleIcon />
            </button>
          </Tooltip>
        </DropdownToggle>
        <DropdownMenu
          left
          className={cn(
            'edp-navbar__dropdown-menu',
            'edp-user-permissions__dropdown-menu',
            'nav__dropdown-menu--form'
          )}
        >
          {availableRoles.map(role => (
            <DropdownItem
              onClick={() => handleDropdownItemClick(role)}
              className={cn('edp-navbar__dropdown-item')}
              toggle={false}
            >
              {role.roleName}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
    ))
  );
};

AddRolesToGroupDropdown.propTypes = {
  group: PropTypes.object,
  roles: PropTypes.array,
  setGroup: PropTypes.func
};

const mapStateToProps = state => {
  return {
    userPermissionsAddingRolesToGroup:
      state.userPermissions.userPermissionsAddingRolesToGroup,
    userPermissionsAddRolesToGroupError:
      state.userPermissions.userPermissionsAddRolesToGroupError,
    userPermissionsAddRolesToGroupSuccess:
      state.userPermissions.userPermissionsAddRolesToGroupSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addUserPermissionsRolesToGroup: (roleIds, groupId) =>
      dispatch(addUserPermissionsRolesToGroup(roleIds, groupId)),
    addUserPermissionsRolesToGroupReset: () =>
      dispatch(addUserPermissionsRolesToGroupReset())
  };
};

AddRolesToGroupDropdown.displayName =
  'User Permissions Portal - Add Roles to Group Dropdown';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddRolesToGroupDropdown);
