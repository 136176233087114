import React, { useState } from 'react';
import { connect } from 'react-redux';
import DetailsTable from '../UserPermissionsDetailsPage/DetailsTable';
import {
  updateUserPermissionsRole,
  updateUserPermissionsRoleReset
} from '../../../../store/actions/userPermissionsActions';
import ErrorIcon from '../../../Utils/ErrorMessages/ErrorIcon';
import SuccessIcon from '../../../Utils/SuccessMessages/SuccessIcon';
import TinyLoadingSpinner from '../../../Utils/Loading/TinyLoadingSpinner';

const detailsTableHeaders = [
  { key: 'roleName', title: 'Role Name', editable: false },
  { key: 'roleDescription', title: 'Description', editable: true }
];

let initialSelectedState = null;

const RoleDetailsPage = props => {
  const {
    selected,
    setSelected,
    updateUserPermissionsRole,
    updateUserPermissionsRoleReset,
    userPermissionsUpdatingRole,
    userPermissionsUpdateRoleError,
    userPermissionsUpdateRoleSuccess
  } = props;

  const [fieldsUpdated, setFieldsUpdated] = useState(false);

  const onInputChange = (key, value) => {
    // if the fields are being updated for the first time
    if (!fieldsUpdated) {
      updateUserPermissionsRoleReset();
      // store the initial state (in case of reset) and set updated state
      initialSelectedState = JSON.parse(JSON.stringify(selected));
      setFieldsUpdated(true);
    }

    // always overwrite the field value
    setSelected({ ...selected, [key]: value });
  };

  const handleSubmit = () => {
    if (fieldsUpdated) {
      // grab only the fields that should be submitted
      const updatedFields = {
        roleName: selected.roleName,
        roleDescription: selected.roleDescription,
        isActive: selected.isActive
      };
      updateUserPermissionsRole(selected.roleId, updatedFields);
      setFieldsUpdated(false);
    }
  };

  const handleReset = () => {
    // if the fields have been updated and initial state is stored
    if (fieldsUpdated && initialSelectedState) {
      // reset the input fields to original values
      setSelected(initialSelectedState);
      setFieldsUpdated(false);
    }
  };

  return (
    <div style={{ marginTop: '15px' }}>
      <DetailsTable
        headers={detailsTableHeaders}
        selected={selected}
        setSelected={setSelected}
        onInputChange={onInputChange}
      />

      <div style={{ float: 'right', margin: '1em 0.25em' }}>
        {userPermissionsUpdatingRole ? (
          <TinyLoadingSpinner />
        ) : userPermissionsUpdateRoleSuccess ? (
          <SuccessIcon
            tooltipContent={'Successfully updated'}
            placement={'left'}
          />
        ) : userPermissionsUpdateRoleError ? (
          <ErrorIcon
            tooltipContent={'Error updating Role. Please try again later'}
            placement={'left'}
          />
        ) : (
          fieldsUpdated && (
            <>
              <button
                className={'edp-user-permissions__button'}
                style={{ float: 'right', margin: '0 0.25em' }}
                onClick={() => handleReset()}
              >
                Reset
              </button>
              <button
                className={'edp-user-permissions__button'}
                style={{ float: 'right', margin: '0 0.25em' }}
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            </>
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userPermissionsUpdatingRole:
      state.userPermissions.userPermissionsUpdatingRole,
    userPermissionsUpdateRoleError:
      state.userPermissions.userPermissionsUpdateRoleError,
    userPermissionsUpdateRoleSuccess:
      state.userPermissions.userPermissionsUpdateRoleSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserPermissionsRole: (roleId, newRoleFields) =>
      dispatch(updateUserPermissionsRole(roleId, newRoleFields)),
    updateUserPermissionsRoleReset: () =>
      dispatch(updateUserPermissionsRoleReset())
  };
};

RoleDetailsPage.displayName = 'User Permissions Portal - Role Details Page';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleDetailsPage);
