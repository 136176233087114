import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { helpMailTo, helpEmail, updateMailTo } from '../../../constants';
import landingImage from '../../../assets/img/large-purple-board.svg';
import sadFaceError from '../../../assets/img/error-icon.png';
import exclamationTriangle from '../../../assets/img/exclamation-triangle.svg';
import { authContext } from '../../../assets/js/config/adalConfig.js';
import Footer from './Footer';
import CustomModal from '../../Utils/Modal/CustomModal';
import { isMobileOnly } from 'react-device-detect';

const unauthorisedModal = {
  title: 'There seems to be a problem.',
  icon: sadFaceError,
  iconAlt: 'sad-face-icon',
  buttonContent: 'OK',
  content: (
    <>
      <p>
        You have either provided unrecognised credentials, or we have
        encountered some technical issues on our end. Please try again.
      </p>
      <p>
        If you believe you have received this message in error, please contact{' '}
        <a href={helpMailTo}>{helpEmail}</a>
      </p>
    </>
  )
};

const mobileDeviceModal = {
  title: 'Hold up!',
  icon: exclamationTriangle,
  iconAlt: 'error-icon',
  buttonContent: 'OK',
  content: (
    <>
      <p>
        Looks like you are using a phone. EDP is currently only optimised for
        tablet and desktop, for the best experience please use either of these
        device types.
      </p>
    </>
  )
};

class Landing extends Component {
  state = {
    hideAlert: true,
    modal: unauthorisedModal
  };

  componentDidMount() {
    const adalError = localStorage.getItem('adal.error');
    if (
      (this.props.location.state !== undefined &&
        this.props.location.state.error !== undefined) ||
      adalError === 'access_denied'
    ) {
      this.setState({
        hideAlert: false
      });
      if (adalError === 'access_denied') {
        authContext.clearCache();
      }
    }
    // check whether user is viewing on mobile device
    if (isMobileOnly) {
      this.setState({
        hideAlert: false,
        modal: mobileDeviceModal
      });
    }
  }

  toggleAlert = () => {
    this.setState({
      hideAlert: !this.state.hideAlert
    });
  };

  render() {
    let { modal } = this.state;
    return (
      <>
        <Row className="edp-landing">
          <Col className="edp-landing__column my-auto">
            <div className="edp-landing__description">
              <div className="box h-100 d-flex justify-content-center flex-column">
                <p
                  className="edp-landing__title"
                  style={{ marginBottom: '24px' }}
                >
                  Start using data in new and exciting ways that are only
                  limited by your imagination
                </p>
                <p
                  className="edp-landing__subtitle"
                  style={{ marginBottom: '33px' }}
                >
                  Here’s your chance to jump on board
                </p>
                <p
                  className="edp-landing__text"
                  style={{ marginBottom: '15px' }}
                >
                  The Enterprise Data Platform (EDP) will enable us to gain
                  insights, solve business problems and identify new
                  opportunities.
                </p>
                <p
                  className="edp-landing__text"
                  style={{ marginBottom: '15px' }}
                >
                  Our strategy to drive aviation growth, unlock additional value
                  with commercial partners, improve efficiency and how we work
                  depends on our ability to leverage data for insights - This is
                  where EDP can help.
                </p>
                <p
                  className="edp-landing__text"
                  style={{ marginBottom: '15px' }}
                >
                  Want to stay up to date with the EDP Journey?
                </p>
                <a
                  id="update-me-btn"
                  style={{ margin: '15px 0', borderRadius: '1px' }}
                  className="edp-button"
                  href={updateMailTo}
                >
                  Subscribe
                </a>
              </div>
            </div>
          </Col>
          <Col
            md="6"
            className="d-none d-md-block edp-landing__image-container"
          >
            <div className="edp-landing__image-background">
              <img
                alt="EDP Board"
                src={landingImage}
                className="edp-landing__image"
              />
            </div>
          </Col>
        </Row>
        <Footer />
        <CustomModal
          toggleAlert={this.toggleAlert}
          hideAlert={this.state.hideAlert}
          buttonContent={modal.buttonContent}
          content={modal.content}
          title={modal.title}
          iconAlt={modal.iconAlt}
          icon={modal.icon}
        />
      </>
    );
  }
}

Landing.displayName = 'Landing';
export default withRouter(Landing);
