import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const loading = props => {
  return (
    <div className="center" style={{ zIndex: '1' }}>
      <div
        className={cn({
          'loading-spinner': true,
          'loading-spinner__light': props.light,
          'loading-spinner__dark': props.dark,
          'loading-spinner__normal-size': !props.mini,
          'loading-spinner__mini-size': props.mini
        })}
      />
    </div>
  );
};

loading.propTypes = {
  light: PropTypes.bool,
  dark: PropTypes.bool,
  mini: PropTypes.bool
};

export default loading;
