import { CALL_TOKEN_API } from '../../../../../../redux/middleware/apiMiddleware';
import * as actionTypes from './actionTypes';
import { contactUsUrl } from '../../../../../../constants';

export const sendContactUsRequest = (
  type,
  senderName,
  senderEmail,
  content
) => {
  return {
    [CALL_TOKEN_API]: {
      type: actionTypes.CONTACT_US_SEND_REQUEST,
      payload: {
        endpoint: contactUsUrl,
        body: JSON.stringify({
          type: type,
          senderName: senderName,
          senderEmail: senderEmail,
          content: content
        }),
        method: 'POST'
      }
    }
  };
};

export const resetContactUs = () => {
  return {
    type: actionTypes.CONTACT_US_RESET
  };
};
