export default `
# Release Notes
## v6.12.0
07/07/22

### What's New

- Updated User Guides (GT Comms Rev Details, GT Ops 7 Day Forecast Summary, Car Park Movements)
- FM Cleaning Schedules bell notification threshold enhancement
- GT Ops Car Park Movements – Value Car Park dashboard(Car and PAX Movements)
- Hotfix – remove duplicates and repoint tables in stored procedure for the 7DayFactSchedule view

`;
