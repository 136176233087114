export default `
# Release Notes
## v2.4.0
21/10/19

### New Features
*IOC*

* IOC overview board is receiving a new look. The cards at the bottom of the page have been redesigned and will now show queue times for departures & arrivals.
* Redesigns have been deployed to the Terminal drilldown (T2, T3, T4) boards also. Pax forecast and Security lines cards have a new look and additional functionality. 
* Volume of notifications has been reduced;
    - Pax shifts threshold has increased to 150 – 250 for Amber alerts. 
    - Green Notifications have been removed.

### Bug Fixes

* Performance – latest changes improve performance resulting in faster load times for all IOC boards.
* iPad loading issue –long wait times for EDP to load on iPad have been reduced (more improvements coming in upcoming releases).
 
`;
