import React, { Component } from 'react';
import {
  DropdownMenu,
  ButtonDropdown,
  DropdownToggle,
  NavItem,
  NavLink,
  DropdownItem
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sendContactUsRequest, resetContactUs } from './store/actions';
import ContactUsForm from './ContactUsForm';
import helpWhite from '../../../../../assets/img/help-white.svg';
import cn from 'classnames';
import { helpCentreUrl, userPermissionsUrl } from '../../../../../constants';

class HelpAndFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      showContactUs: false
    };
  }

  toggleDropdown = () => {
    if (this.state.showDropdown) {
      this.setState({
        showDropdown: !this.state.showDropdown,
        showContactUs: false
      });
    } else {
      this.setState({
        showDropdown: !this.state.showDropdown
      });
    }
  };

  toggleContactUs = () => {
    this.setState({
      showContactUs: !this.state.showContactUs
    });
  };

  render() {
    const {
      isAuth,
      sendingRequest,
      success,
      failure,
      resetContactUs,
      sendContactUsRequest,
      userPermissions
    } = this.props;

    const { showContactUs } = this.state;

    return (
      <ButtonDropdown
        toggle={this.toggleDropdown}
        isOpen={this.state.showDropdown}
        direction="down"
      >
        <DropdownToggle tag="span" data-toggle="dropdown">
          <NavItem>
            <NavLink className="nav__click">
              <img className="nav__icon" alt="Support" src={helpWhite} />
            </NavLink>
          </NavItem>
        </DropdownToggle>
        <DropdownMenu
          right
          className={cn(
            'edp-navbar__dropdown-menu',
            'nav__dropdown-menu--form'
          )}
        >
          {showContactUs ? (
            <ContactUsForm
              isAuth={isAuth}
              sendingRequest={sendingRequest}
              success={success}
              failure={failure}
              toggle={this.toggleDropdown}
              resetContactUs={resetContactUs}
              sendContactUsRequest={sendContactUsRequest}
            />
          ) : (
            <>
              {/* only show help centre if the user is authenticated and not currently on the help centre page */}
              {!this.props.location.pathname.includes(helpCentreUrl) && isAuth && (
                <DropdownItem
                  onClick={() =>
                    window.open(window.location.origin + helpCentreUrl)
                  } // open a new tab with the help centre url
                  className={cn('edp-navbar__dropdown-item')}
                >
                  Help Centre
                </DropdownItem>
              )}
              {/* only show user permissions if the user is authenticated, is not currently on the help centre page AND they have the RBAC Admin user role */}
              {!this.props.location.pathname.includes(userPermissionsUrl) &&
                isAuth &&
                userPermissions.rbac && (
                  <DropdownItem
                    onClick={() =>
                      window.open(window.location.origin + userPermissionsUrl)
                    } // open a new tab with the help centre url
                    className={cn('edp-navbar__dropdown-item')}
                  >
                    User Permissions
                  </DropdownItem>
                )}
              <DropdownItem
                onClick={() => this.setState({ showContactUs: true })}
                className={cn('edp-navbar__dropdown-item')}
                toggle={false}
              >
                Support & Feedback
              </DropdownItem>
            </>
          )}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}

const mapStateToProps = state => {
  return {
    success: state.helpAndFeedback.didSendRequest,
    failure: state.helpAndFeedback.requestFailed,
    sendingRequest: state.helpAndFeedback.sendingRequest
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendContactUsRequest: (type, senderName, senderEmail, content) =>
      dispatch(sendContactUsRequest(type, senderName, senderEmail, content)),
    resetContactUs: () => dispatch(resetContactUs())
  };
};

HelpAndFeedback.displayName = 'Help and Feedback';
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HelpAndFeedback));
