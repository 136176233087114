import React, { Component } from 'react';
import { connect } from 'react-redux';
import withAuth from '../../hoc/withAuth/withAuth';
import Loading from '../Utils/Loading/Loading';
import accessiblePages, { isObjectEmpty } from '../../assets/js/config/pages';
import { notFoundRedirectUrl } from '../../constants';
import Header from '../Utils/Header/Header';

class ComponentFactory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      RouteComponent: null
    };
  }

  update() {
    const { match, userPermissions } = this.props;
    let { primary, secondary, tertiary, page, drilldown } = match.params;

    // only fetch the file for this route if userPermissions are defined
    if (!isObjectEmpty(userPermissions)) {
      // fetch the data array of pages that the user has access to
      let pagesAvailable = accessiblePages(userPermissions);

      // if we have `/:page/:drilldown`
      if (page && drilldown) {
        let pageObject = null,
          drilldownObject = null;

        if (
          page === null ||
          page === undefined ||
          drilldown === null ||
          drilldown === undefined
        ) {
          this.navigateToNotFound();
          return;
        }

        // find the object corresponding to the URL's page name
        pageObject = pagesAvailable.find(obj => obj.ruleName === page);

        // find the object corresponding to the URL's drilldown name for this page
        drilldownObject = pageObject
          ? pageObject.navChildren.find(
              obj => obj.ruleName.split(':')[1] === drilldown
            )
          : null;

        // if the desired drilldown does exist, import and render it
        if (drilldownObject !== null && drilldownObject !== undefined) {
          import(`${drilldownObject.filePath}`)
            .then(component => {
              const title = `${pageObject.name} - ${drilldownObject.name}`;
              let renderComponent = (
                <>
                  <Header title={title} />
                  {<component.default />}
                </>
              );
              this.setState({
                RouteComponent: renderComponent
              });
            }) //redirect
            .catch(err => {
              this.navigateToNotFound();
            });
        } else {
          // otherwise, redirect to 404
          this.navigateToNotFound();
        }
      } else if (primary && secondary && tertiary) {
        // otherwise, if we have `/:primary/:secondary/:tertiary`

        let primaryObject = null,
          secondaryObject = null,
          teritaryObject = null;

        if (
          primary === null ||
          primary === undefined ||
          secondary === null ||
          secondary === undefined ||
          tertiary === null ||
          tertiary === undefined
        ) {
          this.navigateToNotFound();
          return;
        }

        // find the object corresponding to the URL's primary name
        primaryObject = pagesAvailable.find(obj => obj.ruleName === primary);

        // find the object corresponding to the URL's secondary name
        secondaryObject = primaryObject
          ? primaryObject.navChildren.find(
              obj => obj.ruleName.split(':')[1] === secondary
            )
          : null;

        // finally, find the object corresponding to the URL's tertiary name
        teritaryObject = secondaryObject
          ? secondaryObject.children.find(
              obj => obj.to.split('/')[3] === tertiary
            )
          : null;

        // if the desired drilldown does exist, import and render it
        if (teritaryObject !== null && teritaryObject !== undefined) {
          import(`${teritaryObject.filePath}`)
            .then(component => {
              const title = `${primaryObject.name} - ${secondaryObject.name} ${
                teritaryObject.name
              }`;
              let renderComponent = (
                <>
                  <Header title={title} />
                  {<component.default />}
                </>
              );
              this.setState({
                RouteComponent: renderComponent
              });
            }) //redirect
            .catch(err => {
              this.navigateToNotFound();
            });
        } else {
          // otherwise, redirect to 404
          this.navigateToNotFound();
        }
      }
    }
  }

  navigateToNotFound = () => {
    this.props.history.push(notFoundRedirectUrl);
  };

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    // if the url changes update the component being rendered
    if (prevProps.match.url !== this.props.match.url) {
      this.setState({ RouteComponent: null }, () => {
        this.update();
      });
    }
  }

  render() {
    let { RouteComponent } = this.state;
    let { userPermissions } = this.props;
    // if userPermissions or RouteComponent are not yet populated then show loading
    if (isObjectEmpty(userPermissions) || !RouteComponent) {
      return <Loading light />;
    } else {
      return RouteComponent;
    }
  }
}

const mapStateToProps = state => {
  return {
    userPermissions: state.withAuth.userPermissions
  };
};

export default connect(mapStateToProps)(withAuth(ComponentFactory));
