import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import cn from 'classnames';
import Header from '../../Utils/Header/Header';
import HelpCentreNavigation from '../HelpCentre/HelpCentreNavigation';
import withAuth from '../../../hoc/withAuth/withAuth';
import Users from './Users/Users';
import Groups from './Groups/Groups';
import Roles from './Roles/Roles';

const helpItems = [
  {
    categoryName: 'User Permissions',
    items: [
      {
        name: 'Groups'
      },
      {
        name: 'Roles'
      },
      {
        name: 'Users'
      }
    ]
  }
];

class UserPermissions extends Component {
  constructor() {
    super();
    this.state = {
      helpItems: helpItems,
      dropdownOpen: false,
      activeDropdownId: 0,
      activePageId: 0
    };
  }

  setPage = id => {
    this.setState({ activePageId: id });
  };

  toggleDropdownShowing = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  setDropdown = id => {
    this.setState({ activeDropdownId: id, activePageId: 0 });
  };

  render() {
    let {
      dropdownOpen,
      activeDropdownId,
      activePageId,
      helpItems
    } = this.state;

    let { showNavigationSideBar } = this.props;

    const dropdownTitle = helpItems[activeDropdownId].categoryName;
    const dropdownItems = helpItems[activeDropdownId].items;

    const activeDropdownName =
      helpItems[activeDropdownId].items[activePageId].name;

    return (
      <Header title={'EDP - User Permissions'}>
        <div className="edp-user-permissions remove-gutter">
          <Row style={{ margin: '0' }}>
            <Col
              className={cn(
                'edp-help-centre__nav-column',
                'edp-user-permissions__nav-column',
                { 'navbar-open': showNavigationSideBar }
              )}
              xs="3"
            >
              <HelpCentreNavigation
                dropdownOpen={dropdownOpen}
                dropdownTitle={dropdownTitle}
                helpItems={helpItems}
                toggleDropdownShowing={this.toggleDropdownShowing}
                setDropdown={this.setDropdown}
                setPage={this.setPage}
                dropdownItems={dropdownItems}
                activeDropdownId={activeDropdownId}
                activePageId={activePageId}
              />
            </Col>

            <Col
              className={cn('edp-user-permissions__body-column', {
                'navbar-open': showNavigationSideBar
              })}
              xs="9"
            >
              {activeDropdownName === 'Users' && <Users />}
              {activeDropdownName === 'Groups' && <Groups />}
              {activeDropdownName === 'Roles' && <Roles />}
            </Col>
          </Row>
        </div>
      </Header>
    );
  }
}

const mapStateToProps = state => {
  return { showNavigationSideBar: state.navigation.showNavigationSideBar };
};

UserPermissions.displayName = 'User Permissions Portal';
export default connect(
  mapStateToProps,
  null
)(withRouter(withAuth(UserPermissions)));
