import * as actionTypes from './actionTypes';
import updateObject from '../../../store/utility';

const allPermissions = {
  intarrivals: ['live', 'performance'],
  ioc: [
    'overview',
    't2',
    't3',
    't4',
    'manninglevel',
    'sevendayplan',
    'extra_sevendayplan',
    'aop',
    'schedules'
  ],
  fm: ['cleaningschedules', 'cleaningreporting'],
  pga: [
    'overview',
    'roadandcarpark',
    'customerservice',
    'sevendayforecastsummary',
    'carparkmovements'
  ], // deprecated user role
  laops: ['overview', 'roadandcarpark', 'customerservice'], // to be removed
  gtops: [
    'overview',
    'roadandcarpark',
    'customerservice',
    'sevendayforecastsummary',
    'carparkmovements'
  ],
  avcomm: ['ausmarket', 'intmarket', 'airlineperformance', 'airportgroupings'],
  lacomm: [
    'revenuesummary',
    'revenuedetails',
    'forwardbookings',
    'customerbase',
    'newcustomers'
  ],
  gtcomm: [
    'revenuesummary',
    'revenuedetails',
    'forwardbookings',
    'customerbase',
    'newcustomers'
  ],
  te: ['happyface', 'la', 't2', 't3', 't4'],
  notifications: ['flights'],
  rbac: ['full']
};

const initialState = {
  userPermissions: null
};

/* 
API returns an array like:
['ioc:t2'. 'intarrivals:live']

React relies on a structure like:
{
  ioc: ['overview', 'terminal2', 'terminal4'],
  intarrivals: ['live', 'monthly'],
  pga: ['overview']
}

this mapResponse function maps the array of roles to the object */
const mapResponse = (response) => {
  // TODO: implement mapping
  let rolesObject = {};
  response.forEach((role) => {
    let stringArray = role.split(':');
    let page = stringArray[0];
    let drilldown = stringArray[1];
    // if the page doesn't already exist in the array, create the field and empty array
    if (!rolesObject[page]) {
      rolesObject[page] = [];
    }
    // push this drilldown to the array
    rolesObject[page].push(drilldown);
  });

  return rolesObject;
};

const getUserRole = (state, action) => {
  if (process.env.NODE_ENV === 'development') {
    return updateObject(state, {
      userPermissions: allPermissions
    });
  }

  if (
    action.payload === undefined ||
    action.payload === null ||
    action.payload.length === 0
  ) {
    return updateObject(state, {
      userPermissions: {
        intarrivals: ['live']
      }
    });
  }

  let permissions = mapResponse(action.payload);
  return updateObject(state, {
    userPermissions: permissions
  });
};

const clearUserRoles = (state) => {
  return updateObject(state, {
    userPermissions: null
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.WITH_AUTH_GET_USER_ROLE_COMPLETED:
      return getUserRole(state, action);
    case actionTypes.WITH_AUTH_CLEAR_USER_ROLES:
      return clearUserRoles(state);
    default:
      return state;
  }
};

export default reducer;
