import React from 'react';

const RoleCell = cellInfo => {
  const roleName = cellInfo.cell.value; // a single role name
  return (
    <span className={'edp-user-permissions__table-cell--role'}>{roleName}</span>
  );
};

export default RoleCell;
