import moment from 'moment';
import getRandomInt from '../../../../helpers/randomInt';

const MINS_IN_AN_HOUR = 60; // sec
const DATA_INTERVAL = 15; // min
const HOURS_OF_DATA = 72; // hours
const PAX_LOWER_BOUND = 0; // min
const PAX_UPPER_BOUND = 300; // min

const generateMockData = () => {
  let time = moment()
    .startOf('day')
    .startOf('hour')
    .subtract(1, 'hour');
  let data = [];
  let nDataPoints = (MINS_IN_AN_HOUR / DATA_INTERVAL) * HOURS_OF_DATA; // 4 points per hour, 72 hours

  for (let i = 0; i < nDataPoints; i++) {
    // Departures data
    data.push({
      dateTime: time.format('YYYY-MM-DD[T]HH:mm:ss'),
      securityStartPax: getRandomInt(PAX_LOWER_BOUND, PAX_UPPER_BOUND),
      checkInAreaPax: getRandomInt(PAX_LOWER_BOUND, PAX_UPPER_BOUND),
      direction: 'Departure'
    });

    // increment time by
    time.add(DATA_INTERVAL, 'minutes');
  }

  return data;
};

const mockData = generateMockData();

export default mockData;
