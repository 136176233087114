import React from 'react';
import cn from 'classnames';
import NotificationIcon from './NotificationIcon';
import NotificationActions from './NotificationActions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
// import { store } from '../../../indexApp';
// import { startNavigationToHappyFaceDevice } from '../../../store/actions/happyFaceActions';

const happyFaceNotification = (props) => {
  let { data, isUnread, showSave, showClose } = props;
  let customAttributes = data.customAttributes;
  // const terminals = ['t1', 't2', 't3', 't4'];
  // const parkingLocations = ['parking'];

  // const getDirectionTabName = direction => {
  //   if (direction && direction !== 'null') {
  //     const formattedDirection = direction.toLowerCase();
  //     if (formattedDirection.includes('arrival')) {
  //       return 'Arrivals';
  //     } else if (formattedDirection.includes('departure')) {
  //       return 'Departures';
  //     } else {
  //       return null;
  //     }
  //   } else {
  //     return null;
  //   }
  // };

  // const area = customAttributes.area.toLowerCase();
  // const locationTerminals = terminals.includes(area);
  // const locationParking = parkingLocations.includes(area);

  return (
    <div
      className={cn(
        'edp-notification', // only make the notification clickable if the area exists,
        /* { 'edp-notification--clickable': locationTerminals || locationParking }, */ 'edp-notification--happy-face',
        data.status.toLowerCase(),
        isUnread && 'is-unread'
      )}
      //onClick function has been commented out/disabled temporarily as per requirement in Facility Management Feature
      /* onClick={() => {
        store.dispatch(
          startNavigationToHappyFaceDevice({
            location: locationTerminals
              ? 'terminal'
              : locationParking
              ? 'parking'
              : null,
            area: area,
            selectedTabName: getDirectionTabName(customAttributes.direction),
            activeDeviceName: customAttributes.deviceName,
            scrollTo: locationTerminals ? 'HappyFaceCard' : null
          })
        );
        if (locationTerminals) {
          props.history.push({
            pathname: `/ioc/${area}`
          });
        } else if (locationParking) {
          props.history.push({
            pathname: `/laops/customerservice`
          });
        }
      }} */
    >
      <div
        className={cn('edp-notification__highlight', data.status.toLowerCase())}
      >
        &nbsp;
      </div>

      <div className="edp-notification__icon-section">
        {data.iconUrl ? <NotificationIcon iconUrl={data.iconUrl} /> : null}
      </div>

      {customAttributes && (
        <div className="edp-notification__body">
          <div className="edp-notification__title">
            {customAttributes.deviceName}
            <br />
            Type: {customAttributes.surveyKey}
          </div>
          <div className="edp-notification__content font-weight-bold text-dark">{`${
            customAttributes.negativeResponses
          } Negative responses`}</div>
        </div>
      )}

      <NotificationActions
        date={data.date}
        showSave={showSave}
        showClose={showClose}
      />
    </div>
  );
};

happyFaceNotification.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired,
    message: PropTypes.array,
    iconUrl: PropTypes.string,
    customAttributes: PropTypes.shape({
      area: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
      deviceName: PropTypes.string.isRequired,
      negativeResponses: PropTypes.number.isRequired,
      layerName: PropTypes.string.isRequired,
      surveyKey: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  isUnread: PropTypes.bool,
  showClose: PropTypes.bool,
  showSave: PropTypes.bool
};

export default withRouter(happyFaceNotification);
