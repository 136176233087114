import React, { Component } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import { ReactComponent as X } from '../../../../../assets/img/close-x-dark.svg';
import { fetchUserInfo } from '../../../../../helpers/user';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { validateEmail } from '../../../../../helpers/regex';
import Loading from '../../../../Utils/Loading/Loading';

const successMessage = {
  title: 'Thanks!',
  body1:
    "Your message has been sent. We appreciate you contacting us. We'll be in touch soon.",
  body2: 'Have a great day!'
};

const failureMessage = {
  title: 'Error',
  body1: 'Apologies, we encountered an error with submitting your request.',
  body2: 'Please try again soon.'
};

const contactUsBodyPrompt = 'Please enter some text before sending...';
const contactUsBodyPlaceholder =
  'Please provide detailed information to help us assist you...';

const subjectOptions = ['Support', 'Feedback'];

class ContactUsForm extends Component {
  initialState = {
    subject: subjectOptions[0],
    body: '',
    userEnteredEmail: '',
    userEnteredName: '',
    showFeedback: false
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  resetState() {
    this.setState(this.initialState);
  }

  renderDropdownContent(isAuth) {
    const { showFeedback, userEnteredName, userEnteredEmail } = this.state;

    return (
      <>
        <FormGroup>
          <div className="edp-support-form__subheading noselect">
            What can we help you with today?
          </div>
        </FormGroup>
        <FormGroup className="edp-support-form__subject">
          <Input
            className="edp-support-form__input-select"
            type="select"
            onChange={event => {
              this.setState({ subject: event.target.value });
            }}
          >
            {subjectOptions.map((name, index) => (
              <option key={index}>{name}</option>
            ))}
          </Input>
        </FormGroup>
        {/* if user is not authenticated, then show fields for them to enter name and email address */}
        {isAuth ? null : (
          <>
            <FormGroup className="edp-support-form__user-name">
              <Input
                placeholder={'Name'}
                className="edp-support-form__input-text"
                type="text"
                valid={showFeedback && this.validateName(userEnteredName)}
                invalid={showFeedback && !this.validateName(userEnteredName)}
                onChange={event => {
                  this.setState({ userEnteredName: event.target.value });
                }}
              />
            </FormGroup>
            <FormGroup className="edp-support-form__user-email">
              <Input
                placeholder={'Email Address'}
                className="edp-support-form__input-text"
                type="text"
                valid={showFeedback && validateEmail(userEnteredEmail)}
                invalid={showFeedback && !validateEmail(userEnteredEmail)}
                onChange={event => {
                  this.setState({ userEnteredEmail: event.target.value });
                }}
              />
            </FormGroup>
          </>
        )}
        <FormGroup className="edp-support-form__body">
          <Input
            placeholder={
              showFeedback ? contactUsBodyPrompt : contactUsBodyPlaceholder
            }
            className="edp-support-form__textarea"
            type="textarea"
            valid={showFeedback && this.validateBody(this.state.body)}
            invalid={showFeedback && !this.validateBody(this.state.body)}
            onChange={event => {
              this.setState({ body: event.target.value });
            }}
          />
        </FormGroup>
      </>
    );
  }

  renderResponse(title, section, section2 = null) {
    return (
      <div
        style={{
          display: 'table-cell',
          verticalAlign: 'middle'
        }}
      >
        <FormGroup>
          <div className="edp-support-form__heading noselect">{title}</div>
        </FormGroup>
        <FormGroup>
          <div className="edp-support-form__subheading edp-support-form__prompt noselect">
            {section}
            <br />
            <br />
            {section2}
          </div>
        </FormGroup>
      </div>
    );
  }

  handleSubmit = (sendContactUsRequest, isAuth) => {
    const { body, userEnteredName, userEnteredEmail, subject } = this.state;

    // if the body is invalid OR if we are not authenticated AND the name and email entered are invalid
    if (
      !this.validateBody(body) ||
      (!isAuth &&
        (!this.validateName(userEnteredName) ||
          !validateEmail(userEnteredEmail)))
    ) {
      // show user appropriate feedback and return
      this.setState({ showFeedback: true });
      return;
    }

    let userName, userEmail;
    // if user is authenticated, use their name & email
    if (isAuth) {
      const userInfo = fetchUserInfo();
      // if isAuth is true, fetchUserInfo should NOT fail (i.e. an adal token should be present)
      // however, if it does for some reason (e.g. when running in development environment) we should gracefully fail and show an error
      userName = userInfo ? userInfo.name : null;
      userEmail = userInfo ? userInfo.email : null;
    } else {
      // otherwise use the one they enter
      userName = userEnteredName;
      userEmail = userEnteredEmail;
    }

    sendContactUsRequest(subject, userName, userEmail, body);
  };

  handleToggle(sendingRequest, success, failure, resetContactUs, toggle) {
    const { showDropdown } = this.state;

    // do not allow toggling of dropdown when sending a request
    if (!sendingRequest) {
      // if the dropdown is showing
      if (showDropdown) {
        // reset all state variables
        this.resetState();
        // if we had a success or failure response, then we should reset the contact us variables
        if (success || failure) {
          resetContactUs();
        }
      }

      // toggle dropdown regardless
      toggle();
    }
  }

  validateName(name) {
    return name.length >= 1 && name.length <= 500;
  }

  validateBody(body) {
    return body.length >= 1 && body.length <= 4000;
  }

  render() {
    const {
      sendingRequest,
      success,
      failure,
      resetContactUs,
      toggle,
      sendContactUsRequest,
      isAuth
    } = this.props;

    let dropdownContent = sendingRequest ? (
      <Loading dark />
    ) : success ? (
      this.renderResponse(
        successMessage.title,
        successMessage.body1,
        successMessage.body2
      )
    ) : failure ? (
      this.renderResponse(
        failureMessage.title,
        failureMessage.body1,
        failureMessage.body2
      )
    ) : (
      this.renderDropdownContent(isAuth)
    );

    return (
      <Form
        className={cn('edp-support-form', {
          'set-min-height': isAuth,
          'set-min-height-landing': !isAuth
        })}
      >
        <FormGroup>
          <div className="edp-support-form__heading noselect">Contact Us</div>
          {sendingRequest ? null : (
            <X
              className="edp-support-form__icon-button"
              onClick={() =>
                this.handleToggle(
                  sendingRequest,
                  success,
                  failure,
                  resetContactUs,
                  toggle
                )
              }
            />
          )}
        </FormGroup>
        <div
          className={cn({
            'edp-support-form__content': !success && !failure,
            'edp-support-form__content-centered': success || failure,
            'edp-support-form__content-landing-min-height': !isAuth,
            'edp-support-form__content-min-height': isAuth
          })}
        >
          {dropdownContent}
        </div>
        {sendingRequest ? null : (
          <div className="edp-support-form__button">
            {success || failure ? (
              <button
                onClick={() =>
                  this.handleToggle(
                    sendingRequest,
                    success,
                    failure,
                    resetContactUs,
                    toggle
                  )
                }
                type="button"
                className="edp-button gold-button noselect"
              >
                OK
              </button>
            ) : (
              <button
                onClick={() => this.handleSubmit(sendContactUsRequest, isAuth)}
                type="button"
                className="edp-button gold-button noselect"
              >
                Send
              </button>
            )}
          </div>
        )}
      </Form>
    );
  }
}

ContactUsForm.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  sendingRequest: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  resetContactUs: PropTypes.func.isRequired,
  sendContactUsRequest: PropTypes.func.isRequired,
  dropdownContent: PropTypes.element
};

ContactUsForm.displayName = 'Contact Us Form';
export default ContactUsForm;
