// Int Arrivals Live
export const INT_ARRIVALS_GET_CMX_DATA = 'INT_ARRIVALS_GET_CMX_DATA';
export const INT_ARRIVALS_GET_CMX_DATA_START =
  'INT_ARRIVALS_GET_CMX_DATA_START';
export const INT_ARRIVALS_GET_CMX_DATA_COMPLETED =
  'INT_ARRIVALS_GET_CMX_DATA_COMPLETED';
export const INT_ARRIVALS_GET_CMX_DATA_FAILED =
  'INT_ARRIVALS_GET_CMX_DATA_FAILED';
export const INT_ARRIVALS_GET_CMX_DATA_ERROR_RESET =
  'INT_ARRIVALS_GET_CMX_DATA_ERROR_RESET';
// Int Arrivals Peak
export const INT_ARRIVALS_GET_MONTHLY_PEAK_DATA =
  'INT_ARRIVALS_GET_MONTHLY_PEAK_DATA';
export const INT_ARRIVALS_GET_DAILY_PEAK_DATA =
  'INT_ARRIVALS_GET_DAILY_PEAK_DATA';
export const INT_ARRIVALS_GET_MONTHLY_PEAK_DATA_COMPLETED =
  'INT_ARRIVALS_GET_MONTHLY_PEAK_DATA_COMPLETED';
export const INT_ARRIVALS_GET_DAILY_PEAK_DATA_COMPLETED =
  'INT_ARRIVALS_GET_DAILY_PEAK_DATA_COMPLETED';
