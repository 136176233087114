export default `
# Release Notes
## v2.5.0
19/11/19

### New Features
*IOC*

* The Notification Centre has been updated with some new features. Users will see “Recent” and “History” sections and will have the ability to filter notifications.
* The Navigation Bar at the top of the page has been refreshed with a new design.
* The IOC overview board has been updated. The cards at the bottom of the page have been redesigned and will now show queue times for departures & T2 arrivals. This will also improve page load time performance.

### Bug Fixes

* There was an issue stopping some iPad devices connecting to the notification centre. A fix for this bug has been deployed.
* The tooltips which provide information cards have been updated to be more informative.

`;
