import gtCommsTwoPageViews from '../../../images/GTComms/ForwardBookings/twoPageViews.png';
import gtCommsDateFilter from '../../../images/GTComms/ForwardBookings/dateFilter.png';
import gtCommsDropdown from '../../../images/GTComms/ForwardBookings/dropdown.png';
import gtCommsExportPDF from '../../../images/GTComms/ForwardBookings/exportPDF.png';
import gtCommsTablesAndVisuals from '../../../images/GTComms/ForwardBookings/tablesAndVisuals.png';
import gtCommsAdditionalFunc from '../../../images/GTComms/ForwardBookings/additionalFunc.png';

export default `
# GT Comms – Forward Bookings  
## Board Overview 
This board shows the current vs. historical occupancy and forward bookings to support Ground Transport Commercial team in planning the operational resources and capacity management, maintenance, and marketing efforts. 

The dashboard has five main components:
- Two-Page Views 
- Date Filters 
- Drop-Down Menu 
- Tables and Graphs 
- Additional Functionality 

The data sources are SkiData and Altitude for the booking and payment data, which are refreshed daily. 

## Two Page Views 

![GT Comms Two Page Views](${gtCommsTwoPageViews})

The dashboard enables you to assess the business performance based on two date comparisons – primary date and comparison date. You can use a simple comparison vs. last year or specify two date ranges depending on the need. 

The default page compares the primary date against a specific date range. It shows the Forward Bookings Metrics by Car Park and Channel tables. 

Alternatively, you can compare the primary date against a simple comparison date such as the same date vs. last year or same day of the week vs. last year by clicking on the A – Use simple comparison button. Page 2 contains additional graphs such as overtime line graphs (Transactions / SOLDS / ATV / ADR), Booking Pace transactions, and rate of change by segment (Customer Segments). 

Click on the B – Specify date range button to return to the default page. 

Basic filtering is applied on all pages. The dashboard currently excludes data related to the following Car Parks: Pre-Booked Ride Area, Remote Taxi, Staff Car Park, Taxi Rank, or Terminal Kerbside. 

## Date Filters

![GT Comms Date Filters](${gtCommsDateFilter})

Date slicers apply time-based filters across all tables and graphs. 

The primary date range is set using the 1 – Quick Date Filter and/or 2 – Primary Date Selection. 

The comparison date range is set using the 3a – Use Simple Comparison or 4a – Specify Date Range options. 


### ** 1 – Quick Date Filter  **

This date filter allows you to analyze the data based on a pre-existing time frame (e.g., last month, this calendar year, etc.). Its primary purpose is to set the minimum and maximum dates on the 2 – Primary Date Selection more efficiently. 

You can use this filter to show data depending on the different settings:  

- The first setting provides the following choices: last, next, and this.  

- The second setting lets you enter a number to define the relative date range.  

- The third setting enables you to pick the date measurement. 

When choosing ‘this’ under the first setting, the options are limited to the current day, week, month, or year. For example, if today’s date is 10 Feb 2022, choosing ‘this month’ automatically sets the date to 01 Feb 2022 (start of the month) to 28 Feb 2022 (end of the month). 

You may click the eraser button beside the title to de-select any pre-selected options and reset it as blank.  

###  ** 2 – Primary Date Selection  **

This date filter allows you to look at data across all visuals as a snapshot of Forward Bookings based on a historical or expected booking date range. 

If the 1 – Quick Date Filter is blank, you can adjust the 2 – Primary Date Selection based on the earliest and latest available date. 

If the 1 – Quick Date Filter is set, you can manually adjust the 2 – Primary Date Selection within the boundaries of the quick date filter. However, the tables and graphs may result in null values if the selected primary dates fall out of range. 

For example, if the 1 – Quick Date Filter is set to “Last 2 Weeks” from 10 Feb 2022 (i.e., 28 Jan 2022 to 10 Feb 2022) and you manually change the primary dates before 28 Jan 2022 (e.g., 16 Jan 2021 to 15 Jan 2022), then the tables and graphs will result in null values. Always check the date range and reset the quick date filter as necessary. 

###  ** 3a – Use Simple Comparison / 3b – Comparison Type to Last Year   **

The 3a – Use Simple Comparison option lets you to set the comparison date using pre-fixed date selections. 

When this button is clicked, it shows you the 3b – Comparison Type to Last Year, which acts as a second data filter. This date filter gives you the option to compare against the same date as last year or the same day of the week as last year. 

- The Same date LY option matches the exact dates as the primary dates from the previous year. For example, if the primary dates are set to 01 Feb 2022 (Tuesday) to 28 Feb 2022 (Monday), then the Same date LY is simply 01 Feb 2021 (Monday) to 28 Feb 2021 (Sunday). 

- The Same day of week LY option matches the days within the same week as the previous year regardless of the date. For example, if the primary dates are set to 01 Feb 2022 (Tuesday) to 28 Feb 2022 (Monday), then the Same day of week LY is simply 02 Feb 2021 (Tuesday) to 01 Mar 2021 (Monday). 

###  ** 4a – Specify Date Range / 4b – Comparison Date Selection   **

The 4a – Specify Date Range option lets you to set the comparison date using a between-date range. 

When this button is clicked, it shows you the 4b – Comparison Date Selection, which acts as a second data filter. This date filter gives you the flexibility to specify the date range from the earliest to the latest available date. 

## Dropdown Menu

![GT Comms Dropdown Menu](${gtCommsDropdown})

###  ** 5 – Article Group  **

This menu allows you to filter all tables and graphs based on the pre-determined options such as car park, corporate, staff, taxi, etc.  

CTRL + click to select multiple options. You may also click the eraser button beside the title to de-select any pre-selected options.  

## Tables and Visuals

![GT Comms Table and Visuals](${gtCommsTablesAndVisuals})

By default, the tables and graphs can cross-filter and cross-highlight the other visualizations on the page. For example, selecting a data point on table filters all other graphs to display only the data that applies to that data point. 

You may also export the data or show the visual as a table by clicking the three dots in the top right corner of each visual. 

###  ** 6 – Forward Bookings Metrics by Car Park Name / by Channel **

The tables show the key performance metrics by car park name and channel. 

The columns consist of Revenue, Transactions (Txn), Average Transaction Value (ATV), SOLDS, Average Daily Rate (ADR), Length of Stay (LOS), and Lead Time Arrival (LTA). 

All metrics, except for LTA, are broken down according to the following: 

Absolute values represent the sum of data based on the primary date selection. 

Variance (Absolute) values represent the difference between the sum of data based on the primary date selection minus the sum of data based on the comparison date selection. 

Variance (%) values represent the percentage increase or decrease over a period. It is computed by dividing the variance (absolute) by the sum of data based on the comparison date selection. 

LTA is computed by dividing the sum of Days from Booking Minutes by the sum of the LTA Record Count. The quotient is then divided by the total minutes in a day (1,440). 

The tables are shown regardless of the page view. 

###  ** 7 – Revenue / Transactions / SOLDS / ATV / ADR **

These graphs show the car park revenue and transaction metrics this year versus the same period as last year. 

By default, the x-axis (arrival date) is set annually, but it can be drilled down to the daily level or drilled up to the yearly level using the drill mode in the top right corner of the graph. 

Additional details are shown on the tooltip, which can be accessed by hovering your mouse on any data points. 

These graphs are only shown on Page 2 or when the comparison date is set to the 4b – Comparison Type to Last Year as the data always refer to the previous year's comparison. 

###  ** 8 – Booking Pace **

This graph shows the transactions this year versus the same period as last year based on the number of days in advance when the booking/s are made. Unlike the other line graphs, it does not have a drill mode option as the x-axis is only set to the number of days. 

The average number of days from booking is shown in the top right corner. 

Additional details are shown on the tooltip, which can be accessed by hovering your mouse on any data points.  

This graph is only shown on Page 2 or when the comparison date is set to the 4b – Comparison Type to Last Year as the data always refer to the previous year's comparison. 

###  ** 9 – Customer Segments **

This plot shows the year-on-year percentage change in revenue and transactions by customer segment. The customer segments are defined according to the length of stay (in hours), day of the week, and/or day part (am/pm). 

Additional details are shown on the tooltip, which can be accessed by hovering your mouse on any data points. 

This graph is only shown on Page 2 or when the comparison date is set to the 4b – Comparison Type to Last Year as the data always refer to the previous year's comparison. 

## Additional Functionality

![GT Comms Additional Functionality](${gtCommsAdditionalFunc})

###  ** 10 – Export to PDF **

This option allows you to export the current page to a PDF format. 

![GT Comms Export PDF](${gtCommsExportPDF})

The first notification pops out on the right side of the screen, informing you that the export is in progress and that it may take a few minutes until the file is ready for download. 

Once the file becomes available, the second notification pops out. You can access and download it by clicking on the notification icon in the top right corner of the page. 

`.trim();
