import React, { useState } from 'react';
import { connect } from 'react-redux';
import AddRolesToGroupDropdown from './AddRolesToGroupDropdown';
import DetailsTable from '../UserPermissionsDetailsPage/DetailsTable';
import {
  updateUserPermissionsGroup,
  updateUserPermissionsGroupReset
} from '../../../../store/actions/userPermissionsActions';
import ErrorIcon from '../../../Utils/ErrorMessages/ErrorIcon';
import SuccessIcon from '../../../Utils/SuccessMessages/SuccessIcon';
import TinyLoadingSpinner from '../../../Utils/Loading/TinyLoadingSpinner';

const detailsTableHeaders = [
  { key: 'groupName', title: 'Group Name', editable: true },
  { key: 'groupDescription', title: 'Description', editable: true },
  { key: 'roles', title: 'Roles', editable: true }
];

let initialSelectedState = null;

const GroupDetailsPage = props => {
  const {
    selected,
    setSelected,
    DeleteRole,
    roles,
    updateUserPermissionsGroup,
    updateUserPermissionsGroupReset,
    userPermissionsUpdatingGroup,
    userPermissionsUpdateGroupError,
    userPermissionsUpdateGroupSuccess
  } = props;

  const [fieldsUpdated, setFieldsUpdated] = useState(false);

  const onInputChange = (key, value) => {
    // if the fields are being updated for the first time
    if (!fieldsUpdated) {
      updateUserPermissionsGroupReset();
      // store the initial state (in case of reset) and set updated state
      initialSelectedState = JSON.parse(JSON.stringify(selected));
      setFieldsUpdated(true);
    }

    // always overwrite the field value
    setSelected({ ...selected, [key]: value });
  };

  const handleSubmit = () => {
    if (fieldsUpdated) {
      // grab only the fields that should be submitted
      const updatedFields = {
        groupName: selected.groupName,
        groupDescription: selected.groupDescription,
        isActive: selected.isActive
      };
      updateUserPermissionsGroup(selected.groupId, updatedFields);
      setFieldsUpdated(false);
    }
  };

  const handleReset = () => {
    // if the fields have been updated and initial state is stored
    if (fieldsUpdated && initialSelectedState) {
      // reset the input fields to original values
      setSelected(initialSelectedState);
      setFieldsUpdated(false);
    }
  };

  return (
    <div style={{ marginTop: '15px' }}>
      <DetailsTable
        AddRolesToGroupDropdown={AddRolesToGroupDropdown}
        DeleteRole={DeleteRole}
        roles={roles}
        headers={detailsTableHeaders}
        selected={selected}
        setSelected={setSelected}
        onInputChange={onInputChange}
      />

      <div style={{ float: 'right', margin: '1em 0.25em' }}>
        {userPermissionsUpdatingGroup ? (
          <TinyLoadingSpinner />
        ) : userPermissionsUpdateGroupSuccess ? (
          <SuccessIcon
            tooltipContent={'Successfully updated'}
            placement={'left'}
          />
        ) : userPermissionsUpdateGroupError ? (
          <ErrorIcon
            tooltipContent={'Error updating a role. Please try again later'}
            placement={'left'}
          />
        ) : (
          fieldsUpdated && (
            <>
              <button
                className={'edp-user-permissions__button'}
                style={{ float: 'right', margin: '0 0.25em' }}
                onClick={() => handleReset()}
              >
                Reset
              </button>
              <button
                className={'edp-user-permissions__button'}
                style={{ float: 'right', margin: '0 0.25em' }}
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            </>
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userPermissionsUpdatingGroup:
      state.userPermissions.userPermissionsUpdatingGroup,
    userPermissionsUpdateGroupError:
      state.userPermissions.userPermissionsUpdateGroupError,
    userPermissionsUpdateGroupSuccess:
      state.userPermissions.userPermissionsUpdateGroupSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserPermissionsGroup: (groupId, newGroupFields) =>
      dispatch(updateUserPermissionsGroup(groupId, newGroupFields)),
    updateUserPermissionsGroupReset: () =>
      dispatch(updateUserPermissionsGroupReset())
  };
};

GroupDetailsPage.displayName = 'User Permissions Portal - Group Details Page';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupDetailsPage);
