import React, { Component } from 'react';
import { mfaLink } from '../../../constants';

class Footer extends Component {
  render() {
    return (
      <div className="edp-landing__footer">
        <a
          style={{ margin: '0', maxWidth: '100%' }}
          href={mfaLink}
          rel="noopener noreferrer"
          target="_blank"
          className="edp-landing__footer-content"
        >
          Multi-factor Authentication Information
        </a>
        <span
          style={{ textAlign: 'right' }}
          className="edp-landing__footer-content noselect"
        >
          Supported Browsers: Safari and Chrome
        </span>
      </div>
    );
  }
}

Footer.displayName = 'Footer';
export default Footer;
