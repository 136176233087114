import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const NotificationIcon = props => {
  const { iconUrl } = props;
  const image = iconUrl.includes('http')
    ? `${iconUrl}?v=${process.env.REACT_APP_VERSION}`
    : iconUrl;
  return (
    <img src={image} alt="Icon" className={cn('edp-notification__icon')} />
  );
};

NotificationIcon.propTypes = {
  iconUrl: PropTypes.string.isRequired
};

NotificationIcon.defaultProps = {
  iconUrl: ''
};

export default NotificationIcon;
