import React, { Component } from 'react';
import {
  getUserPermissions,
  clearUserPermissions,
  connectToSignalr as connectToSignalrAction
} from './store/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { adalGetToken } from 'react-adal';
import Loading from '../../components/Utils/Loading/Loading';
import { authDetails, authContext } from '../../assets/js/config/adalConfig';
import { setGAUserIdentifier } from '../../helpers/user';
import loglevel from 'loglevel';

function withAuth(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null
      };

      if (process.env.NODE_ENV === 'development') {
        this.props.getUserPermissions();
      } else {
        adalGetToken(authDetails.authContext, authDetails.resourceId)
          .then(() => {
            setGAUserIdentifier();
            this.props.connectToSignalr();
            this.props.getUserPermissions();
          })
          .catch(error => {
            loglevel.error('Authentication failed', error);
            this.props.clearUserPermissions();
            const loginError = authDetails.authContext.getLoginError();
            const loginWasTriedButFailed =
              loginError !== null && loginError !== '';
            if (loginWasTriedButFailed) {
              this.setState({ error: loginError });
              authContext.clearCache();
              this.props.history.push({
                pathname: '/',
                state: { error: this.state.error }
              });
            } else {
              // Typically error === 'login required'
              authDetails.authContext.login();
            }
          });
      }
    }

    render() {
      let componentToReturn;

      if (this.props.userPermissions) {
        componentToReturn = <WrappedComponent {...this.props} />;
      } else {
        componentToReturn = <Loading light />;
      }

      return componentToReturn;
    }
  };
}

const mapStateToProps = state => {
  return {
    userPermissions: state.withAuth.userPermissions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserPermissions: () => dispatch(getUserPermissions()),
    clearUserPermissions: () => dispatch(clearUserPermissions()),
    connectToSignalr: () => dispatch(connectToSignalrAction())
  };
};

const composedWithAuth = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withAuth
);

export default composedWithAuth;
