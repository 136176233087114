import iocOverviewImageUrl from '../../../images/iocOverview.png';

export default `
# IOC - Overview 
## Board Summary
This board shows the overview of the terminal and landside operations that impact passengers. Included is road travel times to the airport, weather, parking occupancy and forecast queue times in each terminal. 

![IOC Overview board](${iocOverviewImageUrl})

**Card 1** – Travel Time to Airport  
**Card 2** – Internal Roads  
**Card 3** - Parking  
**Card 4** – Weather  
**Card 5** – Queue Time  

## Card 1 - Travel time to airport
The time it is taking to get to the airport on the main inbound roads is displayed.

1. M2 (Bolte Bridge): This shows the time to drive between the M2 Bolte Bridge intersection and the Airport. 
2. M80 (Princes Freeway): This shows the time to drive between the M80 Princes Freeway intersection and the Airport.
3. M80 (Greensborough Rd): This shows the time to drive between the M80 Greensborough intersection and the Airport. 

The red, amber or green status is defined by different % thresholds. The percent is calculated by taking the current time with delays and comparing it to the time it would take to travel the same route without delays.  

* Green: up to 25%
* Amber: 26%-50%
* Red: more than 50%

The data source is TOM-TOM and the data updates every 15 minutes.

## Card 2 - Internal roads 
This card indicates the travel time from Mickleham Road through Departure drive and Arrival drive terminating at T1, as well as Airport Drive to Arrival Drive terminating at T1.
The arrows show a red, amber or green status depending on the time it takes. The thresholds are as follows:

* Green:  0-10 mins
* Amber:  11-15 mins
* Red:  above 15mins

The data source is TOM-TOM and the data updates every 15 minutes.

## Card 3 - Parking
You can view the current occupancy of the 3 carparks (T1-3, T4 and the LTCP) both as a value and as a percentage of the capacity.
The red, amber or green indicators also provide a quick visualization of the current capacity:

* Green: up to 69%
* Amber: 70%-89%
* Red: more than 90%

The data source is SkiData and the data updates every 5 minutes.

## Card 4 - Weather 
The card shows the current weather and forecast for Melbourne airport with the option to toggle to other major airports. 

You can toggle between Melbourne, Sydney, Brisbane, Adelaide and Perth to view the weather for the selected airport.
The card illustrates the most recent weather data that was acquired from the Accuweather service as well as the forecast of the next 3 hours. The following weather information is displayed:

1. Time
2. Weather Indicator (Clear, Cloudy, Rainy, etc)
3. Temperature
4. Wind Direction
5. Wind Speed (km/h and knots)
6. Wind Gust Speed (km/h and knots)
7. Visibility

The data source is Accuweather and the data updates every 30 minutes.

## Card 5 - Terminal Departure and Arrival Queue Times 
This card shows the queue times broken down by departures and arrivals for each terminal and zone.

1. Baseline Forecast: This is a forecast that is created at 5AM and 5PM based on Beontra historic data.
2. Updated Forecast: This forecast is based on Beontra data and is updated every 15 minutes.

A red indicator will appear above any part of the graph that has a queue time difference that is greater than 10 minutes. 
You can view any forecasted increases in departures security queue times over the next 6 hours for Terminals 2, 3 and 4 as well as the arrival primary line for Terminal 2.  

The data source is Beontra and the data updates every 15 minutes.
`.trim();
