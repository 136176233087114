import React from 'react';
import NavigationBar from './NavigationBar/NavigationBar';
import NavigationSideBar from './NavigationSideBar/NavigationSideBar';

const Navigation = props => {
  const burgerRef = React.useRef(null);

  return (
    <>
      <NavigationBar
        burgerRef={burgerRef}
        userPermissions={props.userPermissions}
      />
      <NavigationSideBar
        burgerRef={burgerRef}
        userPermissions={props.userPermissions}
      />
    </>
  );
};

Navigation.displayName = 'Navigation';
export default Navigation;
