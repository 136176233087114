import * as actionTypes from './actionTypes';
import updateObject from '../../../../store/utility';
import CMXdata from '../Live/data/CMXdata';
import monthlyPeakData from '../Peak/data/MonthlyPeakData';
import dailyPeakData from '../Peak/data/DailyPeakData';

const initialState = {
  // live board
  liveBoardData: null,
  liveBoardError: false,
  liveBoardDataEmpty: false,
  isLoading: false,
  // peak board
  monthlyPeakData: null,
  dailyPeakData: null
};

const removeToday = data => {
  // TODO: remove this once API is implemented correctly
  // get today's date
  const currentDate = new Date();
  // set time to 00:00
  currentDate.setHours(0, 0, 0, 0);
  const filteredData = data.filter(obj => {
    const date = new Date(obj.monthlyDate);
    // return true for anything before today at 00:00
    return date < currentDate;
  });
  return filteredData;
};

const processCMXdata = (state, action) => {
  // else, in development use CMX mock data
  if (process.env.NODE_ENV === 'development') {
    return updateObject(state, {
      liveBoardData: CMXdata,
      isLoading: false
    });
  }

  // if the payload is null or undefined, return error
  if (action.payload === undefined || action.payload === null) {
    return updateObject(state, {
      liveBoardError: true,
      liveBoardDataEmpty: true,
      isLoading: false
    });
  }

  // if the response is an empty array (i.e. no data for the specified date), return error and data empty
  if (Array.isArray(action.payload) && action.payload.length === 0) {
    return updateObject(state, {
      liveBoardError: true,
      liveBoardDataEmpty: true,
      isLoading: false
    });
  }

  // otherwise, return the data
  return updateObject(state, {
    liveBoardData: action.payload,
    isLoading: false
  });
};

const processMonthlyPeakData = (state, action) => {
  // in development, use CMX mock data
  if (process.env.NODE_ENV === 'development') {
    return updateObject(state, {
      monthlyPeakData: removeToday(monthlyPeakData)
    });
  }

  // use response accordingly
  if (action.payload === undefined || action.payload === null) {
    return state;
  }

  return updateObject(state, {
    monthlyPeakData: removeToday(action.payload)
  });
};

const processDailyPeakData = (state, action) => {
  // in development, use CMX mock data
  if (process.env.NODE_ENV === 'development') {
    return updateObject(state, {
      dailyPeakData: dailyPeakData
    });
  }

  // use response accordingly
  if (action.payload === undefined || action.payload === null) {
    return state;
  }

  return updateObject(state, {
    dailyPeakData: action.payload
  });
};

const handleCMXDataError = (state, action) => {
  return updateObject(state, {
    liveBoardError: true,
    isLoading: false
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INT_ARRIVALS_GET_DAILY_PEAK_DATA_COMPLETED:
      return processDailyPeakData(state, action);
    case actionTypes.INT_ARRIVALS_GET_MONTHLY_PEAK_DATA_COMPLETED:
      return processMonthlyPeakData(state, action);
    case actionTypes.INT_ARRIVALS_GET_CMX_DATA_ERROR_RESET:
      return updateObject(state, {
        liveBoardError: false,
        liveBoardDataEmpty: false,
        isLoading: false
      });
    case actionTypes.INT_ARRIVALS_GET_CMX_DATA_COMPLETED:
      return processCMXdata(state, action);
    case actionTypes.INT_ARRIVALS_GET_CMX_DATA_FAILED:
      return handleCMXDataError(state, action);
    case actionTypes.INT_ARRIVALS_GET_CMX_DATA_START:
      return updateObject(state, {
        liveBoardError: false,
        liveBoardDataEmpty: false,
        isLoading: true
      });
    default:
      return state;
  }
};

export default reducer;
