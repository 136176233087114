import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';

const NotificationCenterNav = props => {
  const { activeTab, changeTab } = props;
  return (
    <Nav className="edp-notification-center__nav">
      <NavItem>
        <NavLink
          active={activeTab === 'recent'}
          onClick={() => changeTab('recent')}
        >
          Recent
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          active={activeTab === 'history'}
          onClick={() => changeTab('history')}
        >
          History
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default NotificationCenterNav;

NotificationCenterNav.propTypes = {
  activeTab: PropTypes.string,
  changeTab: PropTypes.func
};
