import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import stringToTitleCase from '../../../../helpers/stringToTitleCase';
import {
  createUserPermissionsGroup,
  createUserPermissionsGroupReset
} from '../../../../store/actions/userPermissionsActions';
import Loading from '../../../Utils/Loading/Loading';

const ALLOW_USERS_TO_SET_ACTIVE = false;

const blankGroupForm = {
  name: '',
  description: '',
  subRows: undefined
};

const CreateGroupDialog = props => {
  const {
    userPermissionsGroups,
    userPermissionsCreateGroupError,
    userPermissionsCreatingGroup,
    createUserPermissionsGroup,
    createUserPermissionsGroupReset
  } = props;

  // show error message?
  const [showError, setShowError] = useState(false);
  // show success message?
  const [showSuccess, setShowSuccess] = useState(false);
  // has the user submitted a new group yet?
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  // the currently entered group information
  const [group, setGroup] = useState(blankGroupForm);
  // state of the `isActive` switch
  const [switchState, setSwitchState] = React.useState({
    isActive: true
  });
  // is the modal open?
  const [open, setOpen] = React.useState(false);

  // handle success/error response in submitting group
  useEffect(() => {
    // check to see if the currently entered group information is in our groups array (i.e. it has been submitted)
    const groupHasBeenSubmitted = userPermissionsGroups.some(
      userEnteredGroup =>
        userEnteredGroup.groupName === group.name &&
        userEnteredGroup.groupDescription === group.description &&
        userEnteredGroup.isActive === switchState.isActive
    );

    // if error is populated in the store, show error message
    if (userPermissionsCreateGroupError) {
      setShowSuccess(false);
      setShowError(true);
    } // if we are not currently creating the group and the group has been submitted (is in groups array) show success
    else if (
      !userPermissionsCreatingGroup &&
      groupHasBeenSubmitted &&
      formSubmitted
    ) {
      setShowError(false);
      setShowSuccess(true);
    }
  }, [
    userPermissionsCreatingGroup,
    userPermissionsCreateGroupError,
    userPermissionsGroups,
    group.groupName,
    group.groupDescription,
    group.isActive,
    group.name,
    group.description,
    switchState.isActive,
    formSubmitted
  ]);

  const handleSwitchChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const resetSwitch = () => {
    setSwitchState({ isActive: true });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    createUserPermissionsGroupReset();
    resetSwitch();
    setFormSubmitted(false);
    setGroup(blankGroupForm);
    setShowError(false);
    setShowSuccess(false);
  };

  const handleAdd = () => {
    setFormSubmitted(true);
    if (
      !/^[a-zA-Z]+$/.test(group.name) ||
      group.name === '' ||
      group.name === null ||
      group.name === undefined ||
      group.name.length === 0
    ) {
      setFormError(true);
      return;
    }
    // TODO: input field validation (e.g. no submitting empty groups)
    createUserPermissionsGroup(
      group.name,
      group.description,
      ALLOW_USERS_TO_SET_ACTIVE ? switchState.isActive : true
    );
  };

  const handleChange = name => ({ target: { value } }) => {
    setFormSubmitted(false);
    setFormError(false);
    setGroup({ ...group, [name]: value });
  };

  const titleCaseUnit = stringToTitleCase(props.unit);
  const loading = userPermissionsCreatingGroup;

  return (
    <div>
      <Tooltip title="Add">
        <IconButton aria-label="add" onClick={handleClickOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {!loading && (
          <DialogTitle id="form-dialog-title">
            Create New {titleCaseUnit}
          </DialogTitle>
        )}
        <div
          style={{
            height: '11em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          {showError ? (
            <span style={{ margin: '2em 5em' }}>An error has occurred.</span>
          ) : showSuccess ? (
            <span style={{ margin: '2em 5em' }}>
              Successfully submitted new group
            </span>
          ) : loading ? (
            <span style={{ margin: '3em 6em' }}>
              <Loading mini dark />
            </span>
          ) : (
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Group Name"
                type="text"
                fullWidth
                value={group.name}
                error={formError}
                helperText={formError && 'Group name is required.'}
                onChange={handleChange('name')}
              />
              <TextField
                margin="dense"
                label="Group Description"
                type="text"
                fullWidth
                value={group.description}
                onChange={handleChange('description')}
              />
              {ALLOW_USERS_TO_SET_ACTIVE && (
                <DialogContentText
                  style={{ marginTop: '15px', float: 'right' }}
                >
                  {titleCaseUnit} Active{' '}
                  <Switch
                    checked={switchState.isActive}
                    onChange={handleSwitchChange('isActive')}
                    value="isActive"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </DialogContentText>
              )}
            </DialogContent>
          )}
        </div>
        <DialogActions>
          {showError || showSuccess ? (
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          ) : loading ? null : (
            <>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAdd} color="primary">
                Create
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

CreateGroupDialog.propTypes = {
  unit: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    userPermissionsGroups: state.userPermissions.userPermissionsGroups,
    userPermissionsCreatingGroup:
      state.userPermissions.userPermissionsCreatingGroup,
    userPermissionsCreateGroupError:
      state.userPermissions.userPermissionsCreateGroupError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createUserPermissionsGroup: (name, description, isActive) =>
      dispatch(createUserPermissionsGroup(name, description, isActive)),
    createUserPermissionsGroupReset: () =>
      dispatch(createUserPermissionsGroupReset())
  };
};

CreateGroupDialog.displayName = 'User Permissions Portal - Create Group Dialog';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateGroupDialog);
