import * as gtActionTypes from '../actionTypes/gtActionTypes';
import updateObject from '../utility';

const initialState = {
  // GTOPS Car Park Movements Page
  gtCarParkLoading: false,
  gtCarParkError: false,
  gtCarPark: null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // For FM PAX Presentation Baseline Trendline
    case gtActionTypes.GT_CAR_PARK_MVTS_PAYLOAD_COMPLETED:
      return updateObject(state, {
        gtCarPark: action.payload
      });
    case gtActionTypes.GT_CAR_PARK_MVTS_GET_DATA_START:
      return updateObject(state, {
        gtCarParkLoading: true
      });
    case gtActionTypes.GT_CAR_PARK_MVTS_GET_DATA_FAILED:
      return updateObject(state, {
        gtCarParkLoading: false,
        gtCarParkError: true
      });

    default:
      return state;
  }
};

export default reducer;
