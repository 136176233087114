import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const UserPermissionsDetailsPage = props => {
  return (
    <div className="edp-user-permissions__details-page">
      <button className="edp-user-permissions__button" onClick={props.exit}>
        <ArrowBackIcon />
        {` Back`}
      </button>
      {props.delete}
      <div className="edp-user-permissions__details-page--title">
        {props.title}
      </div>
      <div className="edp-user-permissions__details-page--subtitle">
        {props.subtitle}
      </div>
      <div>{props.children}</div>
    </div>
  );
};

UserPermissionsDetailsPage.propTypes = {
  exit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.element.isRequired,
  delete: PropTypes.element
};

export default UserPermissionsDetailsPage;
