export default `
# Release Notes
## v6.1.0
31/03/22

### What's New

Feature

* Improvement to simplify flight type column in Terminal Flight Board - (BED- SignalR and Schema Change, Front-End). 

* Update Helper Text Tooltip for Operation Summary Card. 

* Enhancement on Load factor % computation which exclude flights with no PAX (zero or null). 

* GT Comms User Guides: 

    * Forward Bookings (v1.1) 
    * Revenue Details (v1.1) 
    * Revenue Details (v1.0) 
`;
