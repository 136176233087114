import CarParkMovementsImage from '../../../images/carParkMovements.png';

export default `# GT Ops – Car Park Movements
## Board Summary

This board provides a detailed breakdown of the actual and predicted movements inside and outside the value, staff, and valet car parks. Its primary purpose is to support the GT Ops team in anticipating the flow of travelers and ensuring the right resources are in place, thereby improving the resource allocation and capacity planning for the days ahead.

There are two navigational filters accessible at the top of the page. The date filter allows you to view each car park in a broader date range from -3 days in the past to +7 days in advance. 

Information varies according to the car park type. Value Car Park’s Bus Travel Times, Staff Car Park, and Valet Car Park are currently "in development". 

![GT Ops Car Park Movements](${CarParkMovementsImage})

**Card 1** – Vehicle Entries / Exits (Value, Staff, and Valet Car Parks)  
**Card 2** – Passenger Entries / Exits (Value Car Park only)  

## Card 1 – Vehicle Entries / Exits (Value, Staff, and Valet Car Parks)
This card displays the no. of vehicles by booking method based on the operation schedule from 00:00:00 to 23:59:59. Today's current hour is indicated as "Now".

Information varies depending on the car park selected:
- The no. of cars entering and exiting the value and valet car park is aggregated in half-hour blocks and can be filtered according to online bookings, drive-ups, or both.
- The no. of cars entering and exiting the staff car park is aggregated in 15-min blocks and specific to drive-ups only.

Online data use actual bookings to date while drive-up uses the expected number of drive-ups based on the actual bookings to-date multiplied by a drive-up-to-booking ratio. This ratio is derived from historical data (previous 28 days) of drive-up and booking data.

Bookings are broken down into arrivals (entries), departures (exits), and net movements (arrivals minus departures). You can also access this information in a specific time block by hovering over any data point on the card.

The data sources are Advam and Skidata, and the card updates every 2 hours.


## Card 2 – Passenger Entries / Exits (Value Car Park only)
This card displays the no. of passengers arriving at or departing from the value car park by booking method based on the operation schedule from 00:00:00 to 23:59:59. Today's current hour is indicated as "Now".

The no. of passengers is derived by multiplying the no. of vehicles/bookings by 2.5 according to the assumed ratio of 2.5 pax per car. It is aggregated in half-hour blocks and can be filtered according to online bookings, drive-ups, or both.

Online data use actual bookings to date while drive-up uses the expected number of drive-ups based on the actual bookings to-date multiplied by a drive-up-to-booking ratio. This ratio is derived from historical data (previous 28 days) of drive-up and booking data.

Bookings are only broken down into arrivals (entries) and departures (exits). You can also access this information in a specific time block by hovering over any data point on the card.

Click on any data point to view a modal window containing the terminal breakdown of where the passengers are going or coming from.

The data sources are Advam and Skidata, and the card updates every 2 hours.

## Card 3 – Bus Travel Times (Value and Staff Car Parks)
“In development”

## Card 4 – Processing Times (Valet Car Park only)
“In development”

`.trim();
