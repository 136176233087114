import { roles } from '../Roles/mockData';
import getRandomInt from '../../../../helpers/randomInt';

export const groups = [
  {
    groupId: 1,
    groupName: 'Admin',
    groupDescription: null,
    roles: null,
    isActive: true
  },
  {
    groupId: 2,
    groupName: 'Global',
    groupDescription: null,
    roles: null,
    isActive: true
  },
  {
    groupId: 3,
    groupName: 'Airport Manager',
    groupDescription: null,
    roles: null,
    isActive: true
  },
  {
    groupId: 4,
    groupName: 'Aviation Commercial',
    groupDescription: null,
    roles: null,
    isActive: true
  },
  {
    groupId: 5,
    groupName: 'Landside Operations',
    groupDescription: null,
    roles: null,
    isActive: true
  }
];

const range = len => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

const newGroup = () => {
  return {
    groupId: getRandomInt(1, 99),
    groupName: groups[0].groupName,
    groupDescription: 'A group description goes here',
    roles: roles,
    isActive: true
  };
};

export default function makeData(...lens) {
  const makeDataLevel = (depth = 0) => {
    const len = lens[depth];
    return range(len).map(d => {
      return {
        ...newGroup(),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined
      };
    });
  };

  return makeDataLevel();
}
