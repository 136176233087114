import * as fmActionTypes from '../actionTypes/fmActionTypes';
import updateObject from '../utility';

const initialState = {
  // IOC 7DP Baseline Channel (FmDaily)
  fmBaselineLoading: false,
  fmBaselineError: false,
  fmBaselineT1: null,
  fmBaselineT2: null,
  fmBaselineT3: null,
  fmBaselineT4: null,
  // FM AssetPerformance Channel (AssetPerformance)
  fmAmenitiesLoading: false,
  fmAmenitiesError: false,
  fmAmenitiesT2: null,
  fmAmenitiesT3: null,
  fmAmenitiesT4: null,
  // FM Scheduled Cleans Updated Channel (ScheduledCleans)
  fmCleansUpdatedLoading: false,
  fmCleansUpdatedError: false,
  fmCleansUpdatedT2: null,
  fmCleansUpdatedT4: null,
  // FM Scheduled Cleans Baseline Channel (TBD)
  fmCleansBaselineLoading: false,
  fmCleansBaselineError: false,
  fmCleansBaselineT2: null,
  fmCleansBaselineT4: null
};

const fmBaselinePayload = (state, payload) => {
  const initialState = {
    terminal1: [],
    terminal2: [],
    terminal3: [],
    terminal4: []
  };

  // grouping of data based on terminal
  const dataByTerminal = payload.fmDailyBaseLine.reduce((acc, val) => {
    switch (val.terminal) {
      case 'T1':
        acc.terminal1.push(val);
        break;
      case 'T2':
        acc.terminal2.push(val);
        break;
      case 'T3':
        acc.terminal3.push(val);
        break;
      case 'T4':
        acc.terminal4.push(val);
        break;
      default:
        break;
    }
    return acc;
  }, initialState);

  // update state object of each terminal
  return updateObject(state, {
    fmBaselineT1: dataByTerminal.terminal1,
    fmBaselineT2: dataByTerminal.terminal2,
    fmBaselineT3: dataByTerminal.terminal3,
    fmBaselineT4: dataByTerminal.terminal4
  });
};

const fmAssetPerformancePayload = (state, payload) => {
  if (payload) {
    const { assetPerformance } = payload;
    return updateObject(state, {
      fmAmenitiesT2: assetPerformance.terminal2,
      fmAmenitiesT3: assetPerformance.terminal3,
      fmAmenitiesT4: assetPerformance.terminal4
    });
  }

  return null;
};

const fmCleaningSchedulesPayload = (state, payload, type) => {
  if (payload) {
    const cleans =
      payload[type === 'Baseline' ? 'scheduledZoneCleans' : 'scheduledCleans'];
    const keyName = `fmCleans${type}`;
    return updateObject(state, {
      [keyName + 'T2']:
        cleans && cleans.terminal2 && cleans.terminal2.recommendedCleans,
      [keyName + 'T4']:
        cleans && cleans.terminal4 && cleans.terminal4.recommendedCleans
    });
  }

  return null;
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // For FM PAX Presentation Baseline Trendline
    case fmActionTypes.FM_BASELINE_PAYLOAD_COMPLETED:
      return fmBaselinePayload(state, action.payload);
    case fmActionTypes.FM_BASELINE_GET_DATA_START:
      return updateObject(state, {
        fmBaselineLoading: true
      });
    case fmActionTypes.FM_BASELINE_GET_DATA_FAILED:
      return updateObject(state, {
        fmBaselineLoading: false,
        fmBaselineError: true
      });

    // For FM PAX Presentation Sad Face Indicators
    case fmActionTypes.FM_AMENITIES_PAYLOAD_COMPLETED:
      return fmAssetPerformancePayload(state, action.payload);
    case fmActionTypes.FM_AMENITIES_GET_DATA_START:
      return updateObject(state, {
        fmAmenitiesLoading: true
      });
    case fmActionTypes.FM_AMENITIES_GET_DATA_FAILED:
      return updateObject(state, {
        fmAmenitiesLoading: false,
        fmAmenitiesError: true
      });

    // For FM Cleaning Schedules Updated Gantt Chart
    case fmActionTypes.FM_CLEANING_SCHEDULES_UPDATED_PAYLOAD_COMPLETED:
      return fmCleaningSchedulesPayload(state, action.payload, 'Updated');
    case fmActionTypes.FM_CLEANING_SCHEDULES_UPDATED_GET_DATA_START:
      return updateObject(state, {
        fmCleansUpdatedLoading: true
      });
    case fmActionTypes.FM_CLEANING_SCHEDULES_UPDATED_GET_DATA_FAILED:
      return updateObject(state, {
        fmCleansUpdatedLoading: false,
        fmCleansUpdatedError: true
      });

    // For FM Cleaning Schedules Baseline Gantt Chart
    case fmActionTypes.FM_CLEANING_SCHEDULES_BASELINE_PAYLOAD_COMPLETED:
      return fmCleaningSchedulesPayload(state, action.payload, 'Baseline');
    case fmActionTypes.FM_CLEANING_SCHEDULES_BASELINE_GET_DATA_START:
      return updateObject(state, {
        fmCleansBaselineLoading: true
      });
    case fmActionTypes.FM_CLEANING_SCHEDULES_BASELINE_GET_DATA_FAILED:
      return updateObject(state, {
        fmCleansBaselineLoading: false,
        fmCleansBaselineError: true
      });
    default:
      return state;
  }
};

export default reducer;
