import moment from 'moment';

export const dateDiffInMinutes = (date2, date1) => {
  var diff = (date2.getTime() - date1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};

export const dateToStringNoTimezone = (date) => {
  return moment(date).format('YYYY-MM-DDTHH:mm:ss');
};

export const dateToISO8601String = (date) => {
  return !date ? null : moment(date).toISOString();
};

export const unixTimeToMoment = (unixTime) => {
  return moment.unix(unixTime / 1000); /* ms to seconds  */
};

export const secondsToMinutes = (seconds) => {
  return Math.round(seconds / 60);
};

export const compareDates = (a, b) => {
  var dateA = new Date(a.date);
  var dateB = new Date(b.date);
  return dateB - dateA;
};

export const dateToMilliseconds = (date) => {
  return moment(date).unix() * 1000;
};

export const iso8601StringTo24HourFormattedString = (iso8601String) => {
  return moment(iso8601String).format('HH:mm');
};

export const dateToIso8601String = (date) => {
  return moment.utc(date).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
};

export const msToTime = (duration) => {
  var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return hours + ':' + minutes + ':' + seconds + '.' + milliseconds;
};
