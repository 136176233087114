import React from 'react';
import { Row } from 'reactstrap';
import { ReactComponent as Chevron } from '../../../assets/img/chevron.svg';
import PrimarySideBarNavigationItem from './PrimarySideBarNavigationItem';
import cn from 'classnames';
import PropTypes from 'prop-types';

const showCloseArrows = true;

const PrimarySideBarNavigation = (props) => {
  const {
    pageConfig,
    activePrimaryItem,
    mouseOverSecondaryMenu,
    handleNavigationClose,
    handlePrimaryItemClick
  } = props;

  return (
    <div className="edp-nav-side-bar__primary-navigation">
      {pageConfig.map((page, i) => (
        <Row key={i}>
          <PrimarySideBarNavigationItem
            page={page}
            active={
              (activePrimaryItem || mouseOverSecondaryMenu) &&
              page.name === activePrimaryItem.name
            }
            onItemClick={handlePrimaryItemClick}
          />
        </Row>
      ))}
      {showCloseArrows && (
        <Row
          style={{
            position: 'absolute',
            bottom: '10px',
            width: '100%'
          }}
        >
          <div
            className={cn('edp-nav-side-bar__primary-item')}
            style={{
              padding: '0 15px'
            }}
          >
            <div
              className={'edp-nav-side-bar__secondary-arrow-container'}
              onClick={handleNavigationClose}
            >
              <Chevron className={'edp-nav-side-bar__primary-arrow-icon'} />
              <Chevron className={'edp-nav-side-bar__primary-arrow-icon'} />
            </div>
          </div>
        </Row>
      )}
    </div>
  );
};

PrimarySideBarNavigation.propTypes = {
  pageConfig: PropTypes.array.isRequired,
  activePrimaryItem: PropTypes.object,
  handlePrimaryItemClick: PropTypes.func.isRequired,
  handleNavigationClose: PropTypes.func.isRequired,
  mouseOverSecondaryMenu: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
    .isRequired
};

export default PrimarySideBarNavigation;
