export default `
# Release Notes
## v6.9.0
15/06/22

### What's New

Enhancements:

* FM Cleaning Schedules Bell Notifications 
    * Updated negative happy face threshold and notification

* FM Cleaning Reporting Enhancement
    * Updated Lookback table

* IOC 7 Day Plan - Comment Link Update
    * Updated Duty Team Support Log link
    * Removed other links: SACC, Comms, and TC

* GT Ops 7 Day Forecast Summary Bug Fix
    * Applied fix to Car Park Movements filter
`;
