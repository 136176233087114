export const FM_BASELINE_PAYLOAD_COMPLETED = 'FM_BASELINE_PAYLOAD_COMPLETED';
export const FM_BASELINE_GET_DATA_START = 'FM_BASELINE_GET_DATA_START';
export const FM_BASELINE_GET_DATA_FAILED = 'FM_BASELINE_GET_DATA_FAILED';

export const FM_AMENITIES_PAYLOAD_COMPLETED = 'FM_AMENITIES_PAYLOAD_COMPLETED';
export const FM_AMENITIES_GET_DATA_START = 'FM_AMENITIES_GET_DATA_START';
export const FM_AMENITIES_GET_DATA_FAILED = 'FM_AMENITIES_GET_DATA_FAILED';

export const FM_CLEANING_SCHEDULES_UPDATED_PAYLOAD_COMPLETED =
  'FM_CLEANING_SCHEDULES_UPDATED_PAYLOAD_COMPLETED';
export const FM_CLEANING_SCHEDULES_UPDATED_GET_DATA_START =
  'FM_CLEANING_SCHEDULES_UPDATED_GET_DATA_START';
export const FM_CLEANING_SCHEDULES_UPDATED_GET_DATA_FAILED =
  'FM_CLEANING_SCHEDULES_UPDATED_GET_DATA_FAILED';

export const FM_CLEANING_SCHEDULES_BASELINE_PAYLOAD_COMPLETED =
  'FM_CLEANING_SCHEDULES_BASELINE_PAYLOAD_COMPLETED';
export const FM_CLEANING_SCHEDULES_BASELINE_GET_DATA_START =
  'FM_CLEANING_SCHEDULES_BASELINE_GET_DATA_START';
export const FM_CLEANING_SCHEDULES_BASELINE_GET_DATA_FAILED =
  'FM_CLEANING_SCHEDULES_BASELINE_GET_DATA_FAILED';
