import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Loading from '../../Utils/Loading/Loading';

const LinkRenderer = props => {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

class HelpCentrePage extends Component {
  render() {
    const { activeHelpItem } = this.props;

    return (
      <div
        className={cn(
          'edp-help-centre__container',
          'edp-help-centre__markdown'
        )}
      >
        {activeHelpItem.markdown ? (
          <ReactMarkdown
            source={activeHelpItem.markdown}
            renderers={{ link: LinkRenderer }}
          />
        ) : (
          <Loading dark />
        )}
      </div>
    );
  }
}

HelpCentrePage.propTypes = {
  activeHelpItem: PropTypes.object.isRequired
};

HelpCentrePage.displayName = 'HelpCentrePage';
export default HelpCentrePage;
