import gtCommsBoard from '../../../images/GTComms/RevenueSummary/board.png';
import gtCommsBreakdownByCarPark from '../../../images/GTComms/RevenueSummary/breakdownByCarPark.png';
import gtCommsAdditionalFunc from '../../../images/GTComms/RevenueSummary/additionalFunc.png';
import gtCommsSummaryCards from '../../../images/GTComms/RevenueSummary/summaryCards.png';
import gtCommsExportPDF from '../../../images/GTComms/RevenueSummary/exportPDF.png';

export default `
# GT Comms – Revenue Summary 
## Board Overview 
This board shows an overview of the revenue and bookings for the airport car parks. 

It includes a comparison of the revenue against forecast or budget to inform the Ground Transport team if the current revenue is achieved, overachieved, or underachieved against a target. 

It also compares the revenue and bookings against a set of dates such as month-to-date vs. last year and next 28 days vs. the previous year to determine the actual or expected growth/decline in the business performance metrics. 

The dashboard has three main components: 
- Revenue and Bookings Summary Cards 
- Revenue Breakdown by Car Park and Channel 
- Additional Functionality 

These components vary depending on the date period coverage: 
- Year-to-date (YTD) refers to the fiscal year which starts on 01 July and ends the following year on 30 June. 
- Month-to-date (MTD) refers to the period between the 1st of the current month and the day before today, assuming the business day's end has not yet passed. For example, if today’s date-time is 24 Feb 2022 13:00, MTD covers 01 Feb 2022 until 23 Feb 2022. 
- Next 28 Days (next 28D) refers to the future dates from today. For example, if today’s date-time is 24 Feb 2022 13:00, next28D covers 25 Feb 2022 until 24 March 2022. 
- Versus Last year (LY) compares the current period against the same period as last year. For example, if the current MTD covers 01 Feb 2022 until 23 Feb 2022, then MTD vs. LY covers 01 Feb 2021 until 23 Feb 2021. 

The data sources are SkiData and Altitude for the booking and payment data, which are refreshed daily. 

Basic filtering is applied on this dashboard. It currently excludes data related to the following Car Parks: Pre-Booked Ride Area, Remote Taxi, Staff Car Park, Taxi Rank, or Terminal Kerbside. 

![GT Comms Revenue Summary Board](${gtCommsBoard})

## Revenue and Bookings Summary Cards 

![GT Comms Revenue Summary Cards](${gtCommsSummaryCards})

The first three summary cards display the revenue against forecast, budget, or same period as last year. It accounts for both YTD and MTD actuals. 

The subsequent two summary cards display the current or expected bookings against the same period as last year. It accounts for both MTD actuals and the next 28D bookings. 

The values on each card represent the following: absolute base, percent variance, and absolute variance. A green value indicates a positive variance where the (a) base against forecast / budget is achieved or overachieved, or (b) base is higher this period compared to the same period as last year. Alternatively, a red value indicates a negative variance where the (a) base against forecast / budget is underachieved, or (b) base is lower this period compared to the same period as last year. 

### ** 1 – Revenue vs Forecast / Budget (YTD) **

This card shows the current YTD revenue against the forecast or budget for the fiscal year. The default view is Revenue vs Forecast (YTD). You may click on the buttons at the top right corner of this card to toggle between the forecast and budget.  

###  ** 2 – Revenue vs Forecast / Budget (MTD)  **

This card shows the current MTD revenue against the forecast or budget for the month. The default view is Revenue vs Budget (MTD). You may click on the buttons at the top right corner of this card to toggle between forecast and budget.  

###  ** 3 – Revenue vs Last Year (MTD) **

This card compares the current MTD revenue against the same period as last year. 

###  ** 4 – Bookings / On the Books vs Last Year **

This card compares the current MTD online bookings against the same period as last year. 

###  ** 5 – Bookings vs Last Year (Next 28D) **

This card compares the expected online bookings for the next 28 days against the same period as last year. 

###  ** 6 – Occupancy **

This card shows a placeholder for the current occupancy of the car parks. 


## Revenue Breakdown by Car Park and Channel

![GT Comms Breakdown by Car Park and Channel](${gtCommsBreakdownByCarPark})

###  ** 7 – Revenue vs Last Year by Carpark and 8 – Revenue vs Last Year by Channel **

These graphs display a running month-to-date total of the revenue for completed bookings only. The primary purpose of these graphs is to guide you in determining the sources of revenue that contribute significantly to the total or final value. 

- The final value (the last bar on each graph) represents this month’s revenue. 

- The initial value (the first bar on each graph) represents last year's revenue during the same month as the final value. 

- The intermediate values (bars in between each graph) represent the revenue breakdown by car park or by channel through a series of positive and negative changes. Green bars indicate an increase, while red bars indicate a decrease. 

By default, these graphs can cross-filter any visuals containing revenue data such as Revenue vs Forecast / Budget (YTD / MTD), Revenue vs Last Year (MTD), and Revenue vs Last Year by Carpark / by Channel. For example, selecting T123 on the Revenue vs Last Year by Carpark (MTD) chart filters those visuals to display only the data that applies to that data point. 

Additional details are shown on the tooltip, which can be accessed by hovering your mouse on any data points. It includes the car park or channel name, revenue for this month vs the previous year, and the absolute and % revenue change. 

## Additional Functionality

![GT Comms Additional Functionality](${gtCommsAdditionalFunc})

###  ** 9 – Export to PDF **

This option allows you to export the current page to a PDF format. 

![GT Comms Export PDF](${gtCommsExportPDF})

The first notification pops out on the right side of the screen, informing you that the export is in progress and that it may take a few minutes until the file is ready for download. 

Once the file becomes available, the second notification pops out. You can access and download it by clicking on the notification icon in the top right corner of the page. 

`.trim();
