import * as userPermissionsActionTypes from '../actionTypes/userPermissionsActionTypes';
import updateObject from '../utility';

const initialState = {
  // Roles
  // ----------- GET -----------
  // Get all Roles
  userPermissionsRoles: [],
  userPermissionsRolesLoading: false,
  userPermissionsRolesError: false,
  // ----------- POST -----------
  // Update a Role
  userPermissionsUpdatingRole: false,
  userPermissionsUpdateRoleError: false,
  userPermissionsUpdateRoleSuccess: false,

  // Groups
  // ----------- GET -----------
  // Get all Groups
  userPermissionsGroups: [],
  userPermissionsGroupsLoading: false,
  userPermissionsGroupsError: false,
  // ----------- POST -----------
  // Update a Group
  userPermissionsUpdatingGroup: false,
  userPermissionsUpdateGroupError: false,
  userPermissionsUpdateGroupSuccess: false,
  // Create a Group
  userPermissionsCreatingGroup: false,
  userPermissionsCreateGroupError: null,
  // Add Role(s) to a Group
  userPermissionsAddingRolesToGroup: false,
  userPermissionsAddRolesToGroupError: false,
  userPermissionsAddRolesToGroupSuccess: false,
  // ----------- DELETE -----------
  // Delete a Group
  userPermissionsDeletingGroup: false,
  userPermissionsDeleteGroupError: null,
  // Delete a Role from a Group
  userPermissionsDeletingRoleFromGroup: false,
  userPermissionsDeleteRoleFromGroupError: null,
  // Delete a User from a Group
  userPermissionsDeletingUserFromGroup: false,
  userPermissionsDeleteUserFromGroupError: null,

  // Users
  // ----------- GET -----------
  // Get all Users
  userPermissionsUsers: [],
  userPermissionsUsersLoading: false,
  userPermissionsUsersError: false,
  // ----------- POST -----------
  // Add Users to a Group
  userPermissionsAddingUsersToGroup: false,
  userPermissionsAddUsersToGroupError: false,
  userPermissionsAddUsersToGroupSuccess: false,
  // Update a User
  userPermissionsUpdatingUser: false,
  userPermissionsUpdateUserError: false,
  userPermissionsUpdateUserSuccess: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Roles
    // ----------- GET -----------
    case userPermissionsActionTypes.USER_PERMISSIONS_GET_ALL_ROLES_START:
      return updateObject(state, {
        userPermissionsRolesLoading: true,
        userPermissionsRolesError: false
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_GET_ALL_ROLES_COMPLETED:
      return updateObject(state, {
        userPermissionsRolesLoading: false,
        userPermissionsRolesError: false,
        userPermissionsRoles: action.payload
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_GET_ALL_ROLES_FAILED:
      return updateObject(state, {
        userPermissionsRolesLoading: false,
        userPermissionsRolesError: true
      });
    // ----------- POST (update a role) -----------
    case userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_ROLE_START:
      return updateObject(state, {
        userPermissionsUpdatingRole: true,
        userPermissionsUpdateRoleError: false,
        userPermissionsUpdateRoleSuccess: false
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_ROLE_COMPLETED:
      return updateObject(state, {
        userPermissionsUpdatingRole: false,
        userPermissionsUpdateRoleError: false,
        userPermissionsUpdateRoleSuccess: true
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_ROLE_FAILED:
      return updateObject(state, {
        userPermissionsUpdatingRole: false,
        userPermissionsUpdateRoleError: true,
        userPermissionsUpdateRoleSuccess: false
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_ROLE_RESET:
      return updateObject(state, {
        userPermissionsUpdatingRole: false,
        userPermissionsUpdateRoleError: false,
        userPermissionsUpdateRoleSuccess: false
      });

    // Groups
    // ----------- GET -----------
    case userPermissionsActionTypes.USER_PERMISSIONS_GET_ALL_GROUPS_START:
      return updateObject(state, {
        userPermissionsGroupsLoading: true,
        userPermissionsGroupsError: false
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_GET_ALL_GROUPS_COMPLETED:
      return updateObject(state, {
        userPermissionsGroupsLoading: false,
        userPermissionsGroupsError: false,
        userPermissionsGroups: action.payload
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_GET_ALL_GROUPS_FAILED:
      return updateObject(state, {
        userPermissionsGroupsLoading: false,
        userPermissionsGroupsError: true
      });
    // ----------- POST (update a group) -----------
    case userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_GROUP_START:
      return updateObject(state, {
        userPermissionsUpdatingGroup: true,
        userPermissionsUpdateGroupError: false,
        userPermissionsUpdateGroupSuccess: false
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_GROUP_COMPLETED:
      return updateObject(state, {
        userPermissionsUpdatingGroup: false,
        userPermissionsUpdateGroupError: false,
        userPermissionsUpdateGroupSuccess: true
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_GROUP_FAILED:
      return updateObject(state, {
        userPermissionsUpdatingGroup: false,
        userPermissionsUpdateGroupError: true,
        userPermissionsUpdateGroupSuccess: false
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_GROUP_RESET:
      return updateObject(state, {
        userPermissionsUpdatingGroup: false,
        userPermissionsUpdateGroupError: false,
        userPermissionsUpdateGroupSuccess: false
      });
    // ----------- POST (create a group) -----------
    case userPermissionsActionTypes.USER_PERMISSIONS_CREATE_GROUP_START:
      return updateObject(state, {
        userPermissionsCreatingGroup: true,
        userPermissionsCreateGroupError: null
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_CREATE_GROUP_COMPLETED:
      const newGroup = action.payload;
      if (newGroup.roles === null) {
        newGroup.roles = [];
      }
      return updateObject(state, {
        userPermissionsCreatingGroup: false,
        userPermissionsCreateGroupError: null,
        userPermissionsGroups: [...state.userPermissionsGroups, newGroup]
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_CREATE_GROUP_FAILED:
      return updateObject(state, {
        userPermissionsCreatingGroup: false,
        userPermissionsCreateGroupError: action.payload
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_CREATE_GROUP_RESET:
      return updateObject(state, {
        userPermissionsCreatingGroup: false,
        userPermissionsCreateGroupError: null
      });
    // ----------- POST (add Users to a Group) -----------
    case userPermissionsActionTypes.USER_PERMISSIONS_ADD_USERS_TO_GROUP_START:
      return updateObject(state, {
        userPermissionsAddingUsersToGroup: true,
        userPermissionsAddUsersToGroupError: false,
        userPermissionsAddUsersToGroupSuccess: false
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_ADD_USERS_TO_GROUP_COMPLETED:
      return updateObject(state, {
        userPermissionsAddingUsersToGroup: false,
        userPermissionsAddUsersToGroupError: false,
        userPermissionsAddUsersToGroupSuccess: true
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_ADD_USERS_TO_GROUP_FAILED:
      return updateObject(state, {
        userPermissionsAddingUsersToGroup: false,
        userPermissionsAddUsersToGroupError: true,
        userPermissionsAddUsersToGroupSuccess: false
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_ADD_USERS_TO_GROUP_RESET:
      return updateObject(state, {
        userPermissionsAddingUsersToGroup: false,
        userPermissionsAddUsersToGroupError: false,
        userPermissionsAddUsersToGroupSuccess: false
      });
    // ----------- POST (add Roles to a Group) -----------
    case userPermissionsActionTypes.USER_PERMISSIONS_ADD_ROLES_TO_GROUP_START:
      return updateObject(state, {
        userPermissionsAddingRolesToGroup: true,
        userPermissionsAddRolesToGroupError: false,
        userPermissionsAddRolesToGroupSuccess: false
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_ADD_ROLES_TO_GROUP_COMPLETED:
      return updateObject(state, {
        userPermissionsAddingRolesToGroup: false,
        userPermissionsAddRolesToGroupError: false,
        userPermissionsAddRolesToGroupSuccess: true
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_ADD_ROLES_TO_GROUP_FAILED:
      return updateObject(state, {
        userPermissionsAddingRolesToGroup: false,
        userPermissionsAddRolesToGroupError: true,
        userPermissionsAddRolesToGroupSuccess: false
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_ADD_ROLES_TO_GROUP_RESET:
      return updateObject(state, {
        userPermissionsAddingRolesToGroup: false,
        userPermissionsAddRolesToGroupError: false,
        userPermissionsAddRolesToGroupSuccess: false
      });

    // ----------- DELETE -----------
    // Group
    case userPermissionsActionTypes.USER_PERMISSIONS_DELETE_GROUP_START:
      return updateObject(state, {
        userPermissionsDeletingGroup: true,
        userPermissionsDeleteGroupError: null
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_DELETE_GROUP_COMPLETED:
      return updateObject(state, {
        userPermissionsDeletingGroup: false,
        userPermissionsDeleteGroupError: null
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_DELETE_GROUP_FAILED:
      return updateObject(state, {
        userPermissionsDeletingGroup: false,
        userPermissionsDeleteGroupError: action.payload
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_DELETE_GROUP_RESET:
      return updateObject(state, {
        userPermissionsDeletingGroup: false,
        userPermissionsDeleteGroupError: null
      });
    // Role from Group
    case userPermissionsActionTypes.USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP_START:
      return updateObject(state, {
        userPermissionsDeletingRoleFromGroup: true,
        userPermissionsDeleteRoleFromGroupError: null
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP_COMPLETED:
      return updateObject(state, {
        userPermissionsDeletingRoleFromGroup: false,
        userPermissionsDeleteRoleFromGroupError: null
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP_FAILED:
      return updateObject(state, {
        userPermissionsDeletingRoleFromGroup: false,
        userPermissionsDeleteRoleFromGroupError: action.payload
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP_RESET:
      return updateObject(state, {
        userPermissionsDeletingRoleFromGroup: false,
        userPermissionsDeleteRoleFromGroupError: null
      });
    // User from Group
    case userPermissionsActionTypes.USER_PERMISSIONS_DELETE_USER_FROM_GROUP_START:
      return updateObject(state, {
        userPermissionsDeletingUserFromGroup: true,
        userPermissionsDeleteUserFromGroupError: null
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_DELETE_USER_FROM_GROUP_COMPLETED:
      return updateObject(state, {
        userPermissionsDeletingUserFromGroup: false,
        userPermissionsDeleteUserFromGroupError: null
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_DELETE_USER_FROM_GROUP_FAILED:
      return updateObject(state, {
        userPermissionsDeletingUserFromGroup: false,
        userPermissionsDeleteUserFromGroupError: action.payload
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_DELETE_USER_FROM_GROUP_RESET:
      return updateObject(state, {
        userPermissionsDeletingUserFromGroup: false,
        userPermissionsDeleteUserFromGroupError: null
      });

    // Users
    // ----------- GET -----------
    case userPermissionsActionTypes.USER_PERMISSIONS_GET_ALL_USERS_START:
      return updateObject(state, {
        userPermissionsUsersLoading: true,
        userPermissionsUsersError: false
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_GET_ALL_USERS_COMPLETED:
      return updateObject(state, {
        userPermissionsUsersLoading: false,
        userPermissionsUsersError: false,
        userPermissionsUsers: action.payload
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_GET_ALL_USERS_FAILED:
      return updateObject(state, {
        userPermissionsUsersLoading: false,
        userPermissionsUsersError: true
      });
    // ----------- POST (update a user) -----------
    case userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_USER_START:
      return updateObject(state, {
        userPermissionsUpdatingUser: true,
        userPermissionsUpdateUserError: false,
        userPermissionsUpdateUserSuccess: false
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_USER_COMPLETED:
      return updateObject(state, {
        userPermissionsUpdatingUser: false,
        userPermissionsUpdateUserError: false,
        userPermissionsUpdateUserSuccess: true
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_USER_FAILED:
      return updateObject(state, {
        userPermissionsUpdatingUser: false,
        userPermissionsUpdateUserError: true,
        userPermissionsUpdateUserSuccess: false
      });
    case userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_USER_RESET:
      return updateObject(state, {
        userPermissionsUpdatingUser: false,
        userPermissionsUpdateUserError: false,
        userPermissionsUpdateUserSuccess: false
      });

    default:
      return state;
  }
};

export default reducer;
