import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../Buttons/button';
import InputRange from 'react-input-range';
import { updateFilterOptions } from '../../../../store/actions/notificationActions';
import OutsideClick from '../../OutsideClick/OutsideClick';
import PropTypes from 'prop-types';
import FilterButton from '../../Buttons/FilterButton';

export const MINUTES_IN_DAY = 24 * 60 - 1;
export const STEP = 60;

class NotificationCenterFilter extends Component {
  state = {
    flightNo: '',
    time: {
      min: 0,
      max: MINUTES_IN_DAY
    },
    direction: [],
    type: [],
    ragStatus: []
  };

  componentDidMount() {
    // user may have closed the filter without applying
    // so only show the applied options
    const appliedOptions = { ...this.props.filterOptions };
    this.setState({
      ...appliedOptions
    });
  }

  formatTime = value => {
    // returns the time to display on the time slider
    const hours = parseInt(value / 60);
    const minutes = value % 60;
    const label = `${hours < 10 ? '0' : ''}${hours}:${
      minutes < 10 ? '0' : ''
    }${minutes}`;
    return label;
  };

  onChange = event => {
    const el = event.currentTarget;
    if (el.type === 'checkbox') {
      // if it's a checkbox, add/remove the selection in the array
      let array = [...this.state[el.name]];
      if (el.checked) {
        array.push(el.value);
      } else {
        array = array.filter(item => item !== el.value);
      }
      this.setState({ [el.name]: array });
    } else {
      // else, save the value
      this.setState({ [el.name]: el.value });
    }
  };

  onTimeChange = value => {
    // makes sure the min and max values stay within their ranges
    if (value.max > MINUTES_IN_DAY) {
      value.max = MINUTES_IN_DAY;
    }
    if (value.max < value.min) {
      value.max = value.min + STEP;
    }
    if (value.max % STEP && value.max < MINUTES_IN_DAY) {
      value.max = (Math.floor(value.max / STEP) + 1) * STEP;
    }
    if (value.min < 0) {
      value.min = 0;
    }
    if (value.min % STEP) {
      value.min = (Math.floor(value.min / STEP) + 1) * STEP;
    }
    this.setState({ time: value });
  };

  handleApply = () => {
    // save the filter options in redux
    this.props.updateFilterOptions({ ...this.state });
    // close the filter
    this.props.toggleFilter();
  };

  render() {
    return (
      <OutsideClick callback={this.props.toggleFilter}>
        <>
          <FilterButton toggleFilter={this.props.toggleFilter} />
          <div className="edp__filter-dropdown">
            <div className="edp__filter-input">
              <label htmlFor="flightNo" className="label">
                Flight number
              </label>
              <input
                name="flightNo"
                id="flightNo"
                type="text"
                placeholder="Enter flight number"
                value={this.state.flightNo}
                onChange={this.onChange}
              />
            </div>
            <div className="edp__filter-input">
              <label htmlFor="time" className="label">
                Time
              </label>
              <div className="input-range-container">
                <InputRange
                  maxValue={MINUTES_IN_DAY}
                  minValue={0}
                  step={STEP}
                  value={this.state.time}
                  formatLabel={() => null} // hide the default label
                  onChange={value => this.onTimeChange(value)}
                />
                {/* Custom label */}
                <div className="input-range__label--external">
                  <span className="input-range__label input-range__label--external--min">
                    {this.formatTime(this.state.time.min)}
                  </span>
                  <span className="input-range__label input-range__label--external--max">
                    {this.formatTime(this.state.time.max)}
                  </span>
                </div>
              </div>
            </div>
            <div className="edp__filter-input edp-notification-center__checkbox-group">
              <div className="label">Direction</div>
              <label>
                <span>Arrival</span>
                <input
                  type="checkbox"
                  name="direction"
                  value="arrival"
                  onChange={this.onChange}
                  checked={this.state.direction.includes('arrival')}
                />
              </label>
              <label>
                <span>Departure</span>
                <input
                  type="checkbox"
                  name="direction"
                  value="departure"
                  onChange={this.onChange}
                  checked={this.state.direction.includes('departure')}
                />
              </label>
            </div>
            <div className="edp__filter-input edp-notification-center__checkbox-group">
              <div className="label">Type</div>
              <label>
                <span>PAX</span>
                <input
                  type="checkbox"
                  name="type"
                  value="pax"
                  onChange={this.onChange}
                  checked={this.state.type.includes('pax')}
                />
              </label>
              <label>
                <span>Flight</span>
                <input
                  type="checkbox"
                  name="type"
                  value="flight"
                  onChange={this.onChange}
                  checked={this.state.type.includes('flight')}
                />
              </label>
              <label>
                <span>Happy Face</span>
                <input
                  type="checkbox"
                  name="type"
                  value="happyface"
                  onChange={this.onChange}
                  checked={this.state.type.includes('happyface')}
                />
              </label>
            </div>
            <div className="edp__filter-input edp-notification-center__checkbox-group">
              <div className="label">RAG status</div>
              <label>
                <span>Red</span>
                <input
                  type="checkbox"
                  name="ragStatus"
                  value="red"
                  onChange={this.onChange}
                  checked={this.state.ragStatus.includes('red')}
                />
              </label>
              <label>
                <span>Amber</span>
                <input
                  type="checkbox"
                  name="ragStatus"
                  value="amber"
                  onChange={this.onChange}
                  checked={this.state.ragStatus.includes('amber')}
                />
              </label>
            </div>
            <Button
              primary
              text="Apply"
              className="edp__filter-apply"
              onClick={this.handleApply}
            />
          </div>
        </>
      </OutsideClick>
    );
  }
}

const mapStateToProps = state => {
  return {
    filterOptions: state.notifications.filterOptions
  };
};

const mapDispatchToProps = dispatch => ({
  updateFilterOptions: filterOptions =>
    dispatch(updateFilterOptions(filterOptions))
});

NotificationCenterFilter.propTypes = {
  toggleFilter: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationCenterFilter);
