export default `
# Release Notes
## v6.5.0
12/05/22

### Enhancements:

* Recommended Scheduled Cleans Updates

* Scheduled Cleans Helper Text Tooltip

* SITA Extract for BEONTRA configuration

* SITA Extract to shared Space configuration
    * Create container in Blob Storage
    * Orchestration to copy files from Landing Container to Shared Container

* "Today" view predicted in T3 PAX Presentation model

* Conversion of T3 PAX Presentation


`;
