import React, { Component } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap';
import cn from 'classnames';
import PropTypes from 'prop-types';

class HelpCentreNavigation extends Component {
  render() {
    const {
      dropdownOpen,
      dropdownTitle,
      helpItems,
      activeDropdownId,
      dropdownItems,
      activePageId,
      setDropdown,
      setPage,
      toggleDropdownShowing
    } = this.props;

    return (
      <div
        className={cn(
          'edp-help-centre__container',
          'edp-help-centre__container--nav',
          this.props.className
        )}
      >
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggleDropdownShowing}
          className="edp-help-centre__dropdown"
        >
          <DropdownToggle
            className="edp-help-centre__dropdown-toggle"
            caret={helpItems.length > 1}
          >
            {dropdownTitle}
          </DropdownToggle>
          {helpItems.length > 1 && (
            <DropdownMenu
              className={cn(
                'edp-help-centre__dropdown-menu',
                'edp-navbar__dropdown-menu'
              )}
            >
              {helpItems.map((item, i) => {
                return activeDropdownId !== i ? ( // only render option for dropdown items that are not selected
                  <DropdownItem
                    className={cn(
                      'edp-help-centre__dropdown-item',
                      'edp-navbar__dropdown-item'
                    )}
                    onClick={() => setDropdown(i)}
                    key={i}
                  >
                    {item.categoryName}
                  </DropdownItem>
                ) : null;
              })}
            </DropdownMenu>
          )}
        </Dropdown>
        {dropdownItems.map((item, i) => {
          return (
            <p
              className={cn('edp-help-centre__page-name', {
                active: activePageId === i
              })}
              onClick={() => setPage(i)}
              key={i}
            >
              {item.name}
            </p>
          );
        })}
      </div>
    );
  }
}

HelpCentreNavigation.propTypes = {
  dropdownOpen: PropTypes.bool.isRequired,
  dropdownTitle: PropTypes.string.isRequired,
  helpItems: PropTypes.array.isRequired,
  activeDropdownId: PropTypes.number.isRequired,
  dropdownItems: PropTypes.array.isRequired,
  activePageId: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setDropdown: PropTypes.func.isRequired,
  toggleDropdownShowing: PropTypes.func.isRequired
};

HelpCentreNavigation.displayName = 'HelpCentreNavigation';
export default HelpCentreNavigation;
