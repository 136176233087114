// roles
// GET
export const USER_PERMISSIONS_GET_ALL_ROLES = 'USER_PERMISSIONS_GET_ALL_ROLES';
export const USER_PERMISSIONS_GET_ALL_ROLES_START =
  'USER_PERMISSIONS_GET_ALL_ROLES_START';
export const USER_PERMISSIONS_GET_ALL_ROLES_COMPLETED =
  'USER_PERMISSIONS_GET_ALL_ROLES_COMPLETED';
export const USER_PERMISSIONS_GET_ALL_ROLES_FAILED =
  'USER_PERMISSIONS_GET_ALL_ROLES_FAILED';
// POST
// update a Role
export const USER_PERMISSIONS_UPDATE_ROLE = 'USER_PERMISSIONS_UPDATE_ROLE';
export const USER_PERMISSIONS_UPDATE_ROLE_START =
  'USER_PERMISSIONS_UPDATE_ROLE_START';
export const USER_PERMISSIONS_UPDATE_ROLE_COMPLETED =
  'USER_PERMISSIONS_UPDATE_ROLE_COMPLETED';
export const USER_PERMISSIONS_UPDATE_ROLE_FAILED =
  'USER_PERMISSIONS_UPDATE_ROLE_FAILED';
export const USER_PERMISSIONS_UPDATE_ROLE_RESET =
  'USER_PERMISSIONS_UPDATE_ROLE_RESET';

// groups
// GET
export const USER_PERMISSIONS_GET_ALL_GROUPS =
  'USER_PERMISSIONS_GET_ALL_GROUPS';
export const USER_PERMISSIONS_GET_ALL_GROUPS_START =
  'USER_PERMISSIONS_GET_ALL_GROUPS_START';
export const USER_PERMISSIONS_GET_ALL_GROUPS_COMPLETED =
  'USER_PERMISSIONS_GET_ALL_GROUPS_COMPLETED';
export const USER_PERMISSIONS_GET_ALL_GROUPS_FAILED =
  'USER_PERMISSIONS_GET_ALL_GROUPS_FAILED';
// POST
// update a group
export const USER_PERMISSIONS_UPDATE_GROUP = 'USER_PERMISSIONS_UPDATE_GROUP';
export const USER_PERMISSIONS_UPDATE_GROUP_START =
  'USER_PERMISSIONS_UPDATE_GROUP_START';
export const USER_PERMISSIONS_UPDATE_GROUP_COMPLETED =
  'USER_PERMISSIONS_UPDATE_GROUP_COMPLETED';
export const USER_PERMISSIONS_UPDATE_GROUP_FAILED =
  'USER_PERMISSIONS_UPDATE_GROUP_FAILED';
export const USER_PERMISSIONS_UPDATE_GROUP_RESET =
  'USER_PERMISSIONS_UPDATE_GROUP_RESET';
// POST
// create group
export const USER_PERMISSIONS_CREATE_GROUP = 'USER_PERMISSIONS_CREATE_GROUP';
export const USER_PERMISSIONS_CREATE_GROUP_START =
  'USER_PERMISSIONS_CREATE_GROUP_START';
export const USER_PERMISSIONS_CREATE_GROUP_COMPLETED =
  'USER_PERMISSIONS_CREATE_GROUP_COMPLETED';
export const USER_PERMISSIONS_CREATE_GROUP_FAILED =
  'USER_PERMISSIONS_CREATE_GROUP_FAILED';
export const USER_PERMISSIONS_CREATE_GROUP_RESET =
  'USER_PERMISSIONS_CREATE_GROUP_RESET';
// add roles to a group
export const USER_PERMISSIONS_ADD_ROLES_TO_GROUP =
  'USER_PERMISSIONS_ADD_ROLES_TO_GROUP';
export const USER_PERMISSIONS_ADD_ROLES_TO_GROUP_START =
  'USER_PERMISSIONS_ADD_ROLES_TO_GROUP_START';
export const USER_PERMISSIONS_ADD_ROLES_TO_GROUP_COMPLETED =
  'USER_PERMISSIONS_ADD_ROLES_TO_GROUP_COMPLETED';
export const USER_PERMISSIONS_ADD_ROLES_TO_GROUP_FAILED =
  'USER_PERMISSIONS_ADD_ROLES_TO_GROUP_FAILED';
export const USER_PERMISSIONS_ADD_ROLES_TO_GROUP_RESET =
  'USER_PERMISSIONS_ADD_ROLES_TO_GROUP_RESET';
// DELETE
// group
export const USER_PERMISSIONS_DELETE_GROUP = 'USER_PERMISSIONS_DELETE_GROUP';
export const USER_PERMISSIONS_DELETE_GROUP_START =
  'USER_PERMISSIONS_DELETE_GROUP_START';
export const USER_PERMISSIONS_DELETE_GROUP_COMPLETED =
  'USER_PERMISSIONS_DELETE_GROUP_COMPLETED';
export const USER_PERMISSIONS_DELETE_GROUP_FAILED =
  'USER_PERMISSIONS_DELETE_GROUP_FAILED';
export const USER_PERMISSIONS_DELETE_GROUP_RESET =
  'USER_PERMISSIONS_DELETE_GROUP_RESET';
// role from group
export const USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP =
  'USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP';
export const USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP_START =
  'USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP_START';
export const USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP_COMPLETED =
  'USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP_COMPLETED';
export const USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP_FAILED =
  'USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP_FAILED';
export const USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP_RESET =
  'USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP_RESET';
// user from group
export const USER_PERMISSIONS_DELETE_USER_FROM_GROUP =
  'USER_PERMISSIONS_DELETE_USER_FROM_GROUP';
export const USER_PERMISSIONS_DELETE_USER_FROM_GROUP_START =
  'USER_PERMISSIONS_DELETE_USER_FROM_GROUP_START';
export const USER_PERMISSIONS_DELETE_USER_FROM_GROUP_COMPLETED =
  'USER_PERMISSIONS_DELETE_USER_FROM_GROUP_COMPLETED';
export const USER_PERMISSIONS_DELETE_USER_FROM_GROUP_FAILED =
  'USER_PERMISSIONS_DELETE_USER_FROM_GROUP_FAILED';
export const USER_PERMISSIONS_DELETE_USER_FROM_GROUP_RESET =
  'USER_PERMISSIONS_DELETE_USER_FROM_GROUP_RESET';

// users
// GET
export const USER_PERMISSIONS_GET_ALL_USERS = 'USER_PERMISSIONS_GET_ALL_USERS';
export const USER_PERMISSIONS_GET_ALL_USERS_START =
  'USER_PERMISSIONS_GET_ALL_USERS_START';
export const USER_PERMISSIONS_GET_ALL_USERS_COMPLETED =
  'USER_PERMISSIONS_GET_ALL_USERS_COMPLETED';
export const USER_PERMISSIONS_GET_ALL_USERS_FAILED =
  'USER_PERMISSIONS_GET_ALL_USERS_FAILED';
// POST
// add users to a group
export const USER_PERMISSIONS_ADD_USERS_TO_GROUP =
  'USER_PERMISSIONS_ADD_USERS_TO_GROUP';
export const USER_PERMISSIONS_ADD_USERS_TO_GROUP_START =
  'USER_PERMISSIONS_ADD_USERS_TO_GROUP_START';
export const USER_PERMISSIONS_ADD_USERS_TO_GROUP_COMPLETED =
  'USER_PERMISSIONS_ADD_USERS_TO_GROUP_COMPLETED';
export const USER_PERMISSIONS_ADD_USERS_TO_GROUP_FAILED =
  'USER_PERMISSIONS_ADD_USERS_TO_GROUP_FAILED';
export const USER_PERMISSIONS_ADD_USERS_TO_GROUP_RESET =
  'USER_PERMISSIONS_ADD_USERS_TO_GROUP_RESET';
// POST
// update a user
export const USER_PERMISSIONS_UPDATE_USER = 'USER_PERMISSIONS_UPDATE_USER';
export const USER_PERMISSIONS_UPDATE_USER_START =
  'USER_PERMISSIONS_UPDATE_USER_START';
export const USER_PERMISSIONS_UPDATE_USER_COMPLETED =
  'USER_PERMISSIONS_UPDATE_USER_COMPLETED';
export const USER_PERMISSIONS_UPDATE_USER_FAILED =
  'USER_PERMISSIONS_UPDATE_USER_FAILED';
export const USER_PERMISSIONS_UPDATE_USER_RESET =
  'USER_PERMISSIONS_UPDATE_USER_RESET';
