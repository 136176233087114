import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const PrimarySideBarNavigationItem = (props) => {
  const { active, page, onItemClick } = props;
  const name = page.name;

  const currentUrl = window.location.pathname;
  const ruleName = page.ruleName;
  const ruleNames = ruleName.split(':');
  const primaryRuleName = ruleNames[0];

  return (
    <div
      className={cn('edp-nav-side-bar__primary-item', {
        active: active
      })}
      onClick={() => onItemClick(name)}
    >
      <div
        style={{ height: '30px', paddingLeft: '10px' }}
        className={cn('title', {
          active: currentUrl.includes(primaryRuleName)
        })}
      >
        <div className={cn('noselect', 'edp-nav-side-bar__primary-item-name')}>
          {name}
        </div>
      </div>
    </div>
  );
};

PrimarySideBarNavigationItem.defaultProps = {
  active: false
};

PrimarySideBarNavigationItem.propTypes = {
  active: PropTypes.bool,
  page: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired
};

export default PrimarySideBarNavigationItem;
