import * as userPermissionsActionTypes from '../actionTypes/userPermissionsActionTypes';
import { CALL_TOKEN_API } from '../../redux/middleware/apiMiddleware';
import makeRolesData from '../../components/Pages/UserPermissions/Roles/mockData';
import makeGroupsData from '../../components/Pages/UserPermissions/Groups/mockData';
import makeUsersData from '../../components/Pages/UserPermissions/Users/mockData';
import {
  userPermissionsRolesUrl,
  userPermissionsGroupsUrl,
  userPermissionsUsersUrl
} from '../../constants';
import getRandomInt from '../../helpers/randomInt';

export const getUserPermissionsRoles = () => {
  // in development, immediately send action for completed API call
  if (process.env.NODE_ENV === 'development') {
    return {
      type: userPermissionsActionTypes.USER_PERMISSIONS_GET_ALL_ROLES_COMPLETED,
      payload: makeRolesData(5)
    };
  }

  return {
    [CALL_TOKEN_API]: {
      type: userPermissionsActionTypes.USER_PERMISSIONS_GET_ALL_ROLES,
      payload: {
        endpoint: `${userPermissionsRolesUrl}`
      }
    }
  };
};

export const updateUserPermissionsRole = (roleId, newRoleFields) => {
  // in development, immediately send action for completed API call
  if (process.env.NODE_ENV === 'development') {
    return {
      type: userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_ROLE_COMPLETED,
      payload: newRoleFields
    };
  }

  return {
    [CALL_TOKEN_API]: {
      type: userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_ROLE,
      payload: {
        method: 'POST',
        endpoint: `${userPermissionsRolesUrl}/${roleId}`,
        headers: {
          contentType: 'application/json'
        },
        body: JSON.stringify(newRoleFields)
      }
    }
  };
};

export const updateUserPermissionsRoleReset = () => {
  return {
    type: userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_ROLE_RESET
  };
};

export const createUserPermissionsGroup = (name, description, isActive) => {
  // in development, immediately send action for completed API call
  if (process.env.NODE_ENV === 'development') {
    return {
      type: userPermissionsActionTypes.USER_PERMISSIONS_CREATE_GROUP_COMPLETED,
      payload: {
        groupId: getRandomInt(1, 99),
        groupName: name,
        groupDescription: description,
        roles: null,
        isActive: isActive
      }
    };
  }

  return {
    [CALL_TOKEN_API]: {
      type: userPermissionsActionTypes.USER_PERMISSIONS_CREATE_GROUP,
      payload: {
        method: 'POST',
        endpoint: `${userPermissionsGroupsUrl}`,
        headers: {
          contentType: 'application/json'
        },
        body: JSON.stringify({
          groupName: name,
          groupDescription: description,
          isActive: isActive
        })
      }
    }
  };
};

export const updateUserPermissionsGroup = (groupId, newGroupFields) => {
  // in development, immediately send action for completed API call
  if (process.env.NODE_ENV === 'development') {
    return {
      type: userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_GROUP_COMPLETED,
      payload: newGroupFields
    };
  }

  return {
    [CALL_TOKEN_API]: {
      type: userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_GROUP,
      payload: {
        method: 'POST',
        endpoint: `${userPermissionsGroupsUrl}/${groupId}`,
        headers: {
          contentType: 'application/json'
        },
        body: JSON.stringify(newGroupFields)
      }
    }
  };
};

export const updateUserPermissionsGroupReset = () => {
  return {
    type: userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_GROUP_RESET
  };
};

export const addUserPermissionsUsersToGroup = (usernames, groupId) => {
  // in development, immediately send action for completed API call
  if (process.env.NODE_ENV === 'development') {
    return {
      type:
        userPermissionsActionTypes.USER_PERMISSIONS_ADD_USERS_TO_GROUP_COMPLETED
    };
  }

  return {
    [CALL_TOKEN_API]: {
      type: userPermissionsActionTypes.USER_PERMISSIONS_ADD_USERS_TO_GROUP,
      payload: {
        method: 'POST',
        endpoint: `${userPermissionsGroupsUrl}/${groupId}/users`,
        headers: {
          contentType: 'application/json'
        },
        body: JSON.stringify({
          users: usernames
        })
      }
    }
  };
};

export const addUserPermissionsUsersToGroupReset = () => {
  return {
    type: userPermissionsActionTypes.USER_PERMISSIONS_ADD_USERS_TO_GROUP_RESET
  };
};

export const addUserPermissionsRolesToGroup = (roleIds, groupId) => {
  // in development, immediately send action for completed API call
  if (process.env.NODE_ENV === 'development') {
    return {
      type:
        userPermissionsActionTypes.USER_PERMISSIONS_ADD_ROLES_TO_GROUP_COMPLETED
    };
  }

  return {
    [CALL_TOKEN_API]: {
      type: userPermissionsActionTypes.USER_PERMISSIONS_ADD_ROLES_TO_GROUP,
      payload: {
        method: 'POST',
        endpoint: `${userPermissionsGroupsUrl}/${groupId}/roles`,
        headers: {
          contentType: 'application/json'
        },
        body: JSON.stringify({
          roles: roleIds
        })
      }
    }
  };
};

export const addUserPermissionsRolesToGroupReset = () => {
  return {
    type: userPermissionsActionTypes.USER_PERMISSIONS_ADD_ROLES_TO_GROUP_RESET
  };
};

export const deleteUserPermissionsRoleFromGroup = (groupId, roleId) => {
  // in development, immediately send action for completed API call
  if (process.env.NODE_ENV === 'development') {
    return {
      type:
        userPermissionsActionTypes.USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP_COMPLETED,
      meta: {
        groupId: groupId
      }
    };
  }

  return {
    [CALL_TOKEN_API]: {
      type: userPermissionsActionTypes.USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP,
      meta: { groupId: groupId },
      payload: {
        method: 'DELETE',
        endpoint: `${userPermissionsGroupsUrl}/${groupId}/roles/${roleId}`
      }
    }
  };
};

export const deleteUserPermissionsRoleFromGroupReset = () => {
  return {
    type:
      userPermissionsActionTypes.USER_PERMISSIONS_DELETE_ROLE_FROM_GROUP_RESET
  };
};

export const deleteUserPermissionsUserFromGroup = (userName, groupId) => {
  // in development, immediately send action for completed API call
  if (process.env.NODE_ENV === 'development') {
    return {
      type:
        userPermissionsActionTypes.USER_PERMISSIONS_DELETE_USER_FROM_GROUP_COMPLETED,
      meta: {
        groupId: groupId
      }
    };
  }

  return {
    [CALL_TOKEN_API]: {
      type: userPermissionsActionTypes.USER_PERMISSIONS_DELETE_USER_FROM_GROUP,
      meta: { groupId: groupId },
      payload: {
        method: 'DELETE',
        endpoint: `${userPermissionsGroupsUrl}/${groupId}/users/${userName}`
      }
    }
  };
};

export const deleteUserPermissionsUserFromGroupReset = () => {
  return {
    type:
      userPermissionsActionTypes.USER_PERMISSIONS_DELETE_USER_FROM_GROUP_RESET
  };
};

export const deleteUserPermissionsGroup = groupId => {
  // in development, immediately send action for completed API call
  if (process.env.NODE_ENV === 'development') {
    return {
      type: userPermissionsActionTypes.USER_PERMISSIONS_DELETE_GROUP_COMPLETED,
      meta: {
        groupId: groupId
      }
    };
  }

  return {
    [CALL_TOKEN_API]: {
      type: userPermissionsActionTypes.USER_PERMISSIONS_DELETE_GROUP,
      meta: { groupId: groupId },
      payload: {
        method: 'DELETE',
        endpoint: `${userPermissionsGroupsUrl}/${groupId}`
      }
    }
  };
};

export const deleteUserPermissionsGroupReset = () => {
  return {
    type: userPermissionsActionTypes.USER_PERMISSIONS_DELETE_GROUP_RESET
  };
};

export const createUserPermissionsGroupReset = () => {
  return {
    type: userPermissionsActionTypes.USER_PERMISSIONS_CREATE_GROUP_RESET
  };
};

export const getUserPermissionsGroups = () => {
  // in development, immediately send action for completed API call
  if (process.env.NODE_ENV === 'development') {
    return {
      type:
        userPermissionsActionTypes.USER_PERMISSIONS_GET_ALL_GROUPS_COMPLETED,
      payload: makeGroupsData(15)
    };
  }

  return {
    [CALL_TOKEN_API]: {
      type: userPermissionsActionTypes.USER_PERMISSIONS_GET_ALL_GROUPS,
      payload: {
        endpoint: `${userPermissionsGroupsUrl}`
      }
    }
  };
};

export const getUserPermissionsUsers = () => {
  // in development, immediately send action for completed API call
  if (process.env.NODE_ENV === 'development') {
    return {
      type: userPermissionsActionTypes.USER_PERMISSIONS_GET_ALL_USERS_COMPLETED,
      payload: makeUsersData(100)
    };
  }

  return {
    [CALL_TOKEN_API]: {
      type: userPermissionsActionTypes.USER_PERMISSIONS_GET_ALL_USERS,
      payload: {
        endpoint: `${userPermissionsUsersUrl}`
      }
    }
  };
};

export const updateUserPermissionsUser = (userId, newUserFields) => {
  // in development, immediately send action for completed API call
  if (process.env.NODE_ENV === 'development') {
    return {
      type: userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_USER_COMPLETED,
      payload: newUserFields
    };
  }

  return {
    [CALL_TOKEN_API]: {
      type: userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_USER,
      payload: {
        method: 'POST',
        endpoint: `${userPermissionsUsersUrl}/${userId}`,
        headers: {
          contentType: 'application/json'
        },
        body: JSON.stringify(newUserFields)
      }
    }
  };
};

export const updateUserPermissionsUserReset = () => {
  return {
    type: userPermissionsActionTypes.USER_PERMISSIONS_UPDATE_USER_RESET
  };
};
