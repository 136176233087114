import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse
} from 'reactstrap';
import brandLogo from '../../../assets/img/logo-white.svg';
import NavigationClock from './NavigationClock';
import { getToken } from '../../../assets/js/config/adalConfig';
import { withRouter } from 'react-router';
import jwtDecode from 'jwt-decode';
import HelpAndFeedback from './Dropdowns/HelpAndFeedback/HelpAndFeedback';
import Profile from './Dropdowns/Profile';
import NotificationBadge from './Dropdowns/NotificationBadge';
import { currentPageIsPrintable } from '../../../assets/js/config/pages';
import printerIcon from '../../../assets/img/print.svg';
import NavigationBreadCrumb from './NavigationBreadCrumb';
import NavigationBurgerIcon from './NavigationBurgerIcon';
import { toggleNavigationSideBar } from '../../../store/actions/navigationActions';
import PropTypes from 'prop-types';

class NavigationBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInitials: ''
    };
  }

  componentDidUpdate() {
    if (this.props.userPermissions && this.state.userInitials === '') {
      this.setState({ userInitials: this.fetchUserIntials() });
    }
  }

  fetchUserIntials() {
    let userToken = getToken();
    if (userToken !== null && userToken !== undefined) {
      const tokenPayload = jwtDecode(userToken);
      var initials = '-';
      if (
        tokenPayload.given_name !== null &&
        tokenPayload.given_name !== undefined &&
        tokenPayload.family_name !== null &&
        tokenPayload.family_name !== undefined
      ) {
        if (
          tokenPayload.given_name.length > 1 &&
          tokenPayload.family_name.length > 1
        ) {
          initials =
            tokenPayload.given_name.toUpperCase().charAt(0) +
            tokenPayload.family_name.toUpperCase().charAt(0);
        }
      } else if (
        tokenPayload.given_name !== null &&
        tokenPayload.given_name !== undefined
      ) {
        if (tokenPayload.given_name.length > 1) {
          initials =
            tokenPayload.given_name.toUpperCase().charAt(0) +
            tokenPayload.given_name.toUpperCase().charAt(1);
        }
      }
      return initials;
    }
  }

  handlePrintClick = () => {
    window.print();
  };

  render() {
    const { toggleNavigationSideBar, userPermissions, burgerRef } = this.props;
    const isAuth = userPermissions !== null;

    return (
      <Navbar className="navbar edp-navbar" expand="md" sticky={'top'}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {isAuth && (
            <NavigationBurgerIcon
              burgerRef={burgerRef}
              toggle={toggleNavigationSideBar}
            />
          )}
          <NavbarBrand className="d-none d-sm-block">
            <img src={brandLogo} alt="edp" />
          </NavbarBrand>
        </div>
        <div className="order-md-last order-0">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              listStyleType: 'none'
            }}
          >
            {currentPageIsPrintable && (
              <NavLink className="nav__link nav_icon-link">
                <div
                  onClick={this.handlePrintClick}
                  style={{ position: 'relative', cursor: 'pointer' }}
                >
                  <img alt="Print" src={printerIcon} />
                </div>
              </NavLink>
            )}
            <NotificationBadge isAuth={isAuth} />
            <HelpAndFeedback
              userPermissions={userPermissions}
              isAuth={isAuth}
            />
            <Profile userInitials={this.state.userInitials} isAuth={isAuth} />
            <NavLink className="nav__link nav_icon-link edp-nav-clock">
              <NavItem>
                <NavigationClock />
              </NavItem>
            </NavLink>
          </div>
        </div>
        <Collapse isOpen={false} navbar>
          <Nav className="align-items-center" navbar>
            {isAuth && <NavigationBreadCrumb />}
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

NavigationBar.propTypes = {
  burgerRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    toggleNavigationSideBar: () => dispatch(toggleNavigationSideBar())
  };
};

NavigationBar.displayName = 'Navigation Bar';
export default connect(
  null,
  mapDispatchToProps
)(withRouter(NavigationBar));
