import * as actionTypes from '../actionTypes/happyFaceActionTypes';
import updateObject from '../../store/utility';
import moment from 'moment';

const initialState = {
  arcGISToken: null,
  error: false,
  fetchingToken: false,
  notification: null
};

const getArcGISEmbedToken = (state, action) => {
  if (action.payload === undefined || action.payload === null) {
    getArcGISEmbedTokenError(state);
  }

  const response = JSON.parse(action.payload);
  const arcGISTokenObject = {
    accessToken: response.access_token,
    expiryTime: moment().add(response.expires_in, 'seconds') // create a moment object for current time and subtract the time (mins) that the token will expire in
  };

  return updateObject(state, {
    arcGISToken: arcGISTokenObject,
    error: false,
    fetchingToken: false
  });
};

const getArcGISEmbedTokenError = (state) => {
  return updateObject(state, {
    error: true,
    fetchingToken: false
  });
};

const startNavigationToHappyFaceDevice = (state, action) => {
  return updateObject(state, {
    notification: action.payload
  });
};

const endNavigationToHappyFaceDevice = (state) => {
  return updateObject(state, {
    notification: null
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ARCGIS_TOKEN_START:
      return updateObject(state, {
        fetchingToken: true
      });
    case actionTypes.GET_ARCGIS_TOKEN_COMPLETED:
      return getArcGISEmbedToken(state, action);
    case actionTypes.GET_ARCGIS_TOKEN_FAILED:
      return getArcGISEmbedTokenError(state);
    case actionTypes.START_NAVIGATION_TO_HAPPY_FACE_DEVICE:
      return startNavigationToHappyFaceDevice(state, action);
    case actionTypes.END_NAVIGATION_TO_HAPPY_FACE_DEVICE:
      return endNavigationToHappyFaceDevice(state);
    default:
      return state;
  }
};

export default reducer;
