export default `
# Release Notes
## v2.9.0
26/02/20

### New Features
*IOC*

* T2 Departures Check in Counter was added to the Flight Information Card.

### Bug Fixes

* A minor styling adjustment on iPad devices was deployed so the navigation buttons are easier to use. 
* The Flight Information card on iPads was resized for better user experience.
* An issue with printing on the IOC drilldown has been resolved.

`;
