import intArrivalsPeakImageUrl from '../../../images/intArrivalsPeak.png';

export default `
# International Arrivals - Peak 
## Board Overview
This board provides an overview of the average passenger transit times through the International Arrivals Immigration zone for the last 31 days.

![International Arrivals Peak board](${intArrivalsPeakImageUrl})

**Card 1** – The Immigration peak transit times  
**Card 2** – The Immigration transit times  

## Card 1 - Immigration peak transit times (Last 31 Days)
This card displays the daily peak transit time through the Immigration Zone for the last 31 days. 
The Immigration zone begins at the end of the Gates zone (Start of satellite, near gates D3 and D4) and continues to the escalator before baggage reclaim.

The chart includes a dotted line indicating the target transit time for the Immigration zone. 
By selecting a data point on the graph, you will change the selected day on the 24-hour view graph at the bottom of the page.

The Data Source is CMX and the card updates every 24 hours.

## Card 2 - Immigration transit times (24 hours)
A 24-hour view of the transit times for the selected day is visible in this card. 
The default view when the page loads is of yesterday’s data.

You can see the transit times for a particular day by selecting a day in the chart at the top of the page. 
A dotted line represents the target time (20mins) for the zone.

The Data Source is CMX and the card updates every 24 hours.
`.trim();
