import getRandomInt from '../../../../helpers/randomInt';

export let roles = [
  'intarrivals:live',
  'intarrivals:monthly',
  'intarrivals:peak',
  'intarrivals:performance',
  'ioc:overview',
  'ioc:t2',
  'ioc:t3',
  'ioc:t4',
  'laops:overview',
  'laops:roadandcarpark',
  'laops:customerservice',
  'avcomm:australianmarket',
  'avcomm:internationalmarket',
  'avcomm:airlineperformance',
  'avcomm:airportgroupings',
  'lacomm:revenuesummary',
  'lacomm:revenuedetails',
  'lacomm:forwardbookings',
  'lacomm:customerbase',
  'lacomm:newcustomers',
  'te:happyface'
];

const createRole = name => {
  return {
    roleId: getRandomInt(1, 99),
    roleName: name,
    roleDescription: 'Description goes here',
    isActive: true
  };
};

roles.forEach((roleName, i) => {
  roles[i] = createRole(roleName);
});

export const groups = ['Administrator'];

const range = len => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

const newRole = () => {
  return {
    roleId: getRandomInt(1, 99),
    roleName: 'intarrivals:live',
    roleDescription: 'Description goes here',
    isActive: true
  };
};

// {

//   "roleId": 3,

//   "roleName": "ioc",

//   "roleDescription": "operations",

//   "isActive": true

// },

export default function makeData(...lens) {
  const makeDataLevel = (depth = 0) => {
    const len = lens[depth];
    return range(len).map(d => {
      return {
        ...newRole(),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined
      };
    });
  };

  return makeDataLevel();
}
