export default `
# Release Notes
## v2.6.0
21/10/19

### New Features
*IOC*

* A view of the previous 24 hours on all PAX and Security Queue times cards is available. A new toggle allows users to switch between the default outlook view and the last 24 hrs view.
* Happyface Notifications are enabled for the following user groups: 
    * Comms Office
    * Facilities Management
    * LA Ops

*Aviation Commercial (Release 1 of 2)*

* Australian Market Board –A view of all passenger movements, purpose of travel, airlines and leakage for international flights to Australia.
* Airline Performance Board – A comparison of the change in passenger movement and capacity, grouped into regions and compared to last year.

*Landside Access Commercial (Release 1 of 2)*

* Forward Booking Board – An outlook of the expected revenue and number of transactions. Default is view is 28 days.
* Revenue Summary – A comparison view of forecast, budget and actual revenue. Users can toggle between YTD and MTD and by car park, channel etc. 
* Revenue Details – A view of performance by car park, channel, timeband, customer segment etc. Default vies is of last month.

### Bug Fixes

* Cancelled Flights were not visible for T2. This issue has been fixed.
* A small bug which created extra notifications has been corrected. 

`;
