import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import UserPermissionsTable from '../UserPermissionsTable/UserPermissionsTable';
import UserPermissionsDetailsPage from '../UserPermissionsDetailsPage/UserPermissionsDetailsPage';
import GroupsCell from '../UserPermissionsTable/Cells/GroupsCell';
// import AddUserDialog from './AddUserDialog';
import UserDetailsPage from './UserDetailsPage';
import {
  getUserPermissionsUsers,
  getUserPermissionsGroups
} from '../../../../store/actions/userPermissionsActions';
import Loading from '../../../Utils/Loading/Loading';

const usersTableHeaders = [
  {
    Header: 'Username',
    accessor: 'userName'
  },
  {
    Header: 'Group(s)',
    accessor: 'groups',
    Cell: GroupsCell
  }
];

const Users = props => {
  const {
    userPermissionsUsers,
    userPermissionsGroups,
    getUserPermissionsUsers,
    getUserPermissionsGroups,
    userPermissionsUsersLoading,
    userPermissionsUsersError
  } = props;

  const [selected, setSelected] = useState(null);

  const unit = 'user';

  useEffect(() => {
    getUserPermissionsUsers();
    getUserPermissionsGroups();
  }, [getUserPermissionsGroups, getUserPermissionsUsers]);

  const handleRowClick = rowObject => {
    // find the actual User object corresponding to this table row
    const desiredUser = userPermissionsUsers.filter(
      user =>
        user.userName === rowObject.userName &&
        user.emailAddress === rowObject.emailAddress &&
        user.isActive === rowObject.isActive
    )[0];
    setSelected(desiredUser);
  };

  const handleExit = () => {
    // reset selected user
    setSelected(null);
    // fetch all users again
    getUserPermissionsUsers();
  };

  const tableData = JSON.parse(JSON.stringify(userPermissionsUsers));
  tableData.forEach(
    // pass only the groupName to the tableData
    // this allows for searching (useGlobalFilter) as it is an Array of Strings rather than an Array of Objects
    (row, i) => (tableData[i].groups = row.groups.map(group => group.groupName))
  );

  return (
    <div className="edp-user-permissions__users-container">
      {selected ? (
        <UserPermissionsDetailsPage
          exit={() => handleExit()}
          subtitle={'User Details'}
        >
          <UserDetailsPage
            groups={userPermissionsGroups}
            selected={selected}
            setSelected={setSelected}
          />
        </UserPermissionsDetailsPage>
      ) : userPermissionsUsersLoading ? (
        <Loading dark />
      ) : userPermissionsUsersError ? (
        <div
          style={{
            height: '13em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <span style={{ margin: '2em 5em' }}>
            Apologies, an error has occurred. Please try again later.
          </span>
        </div>
      ) : (
        <UserPermissionsTable
          data={tableData}
          tableHeaders={usersTableHeaders}
          onRowClick={rowObject => handleRowClick(rowObject)}
          unit={unit}
          // addDialog={rowHandler => (
          //   <AddUserDialog addUserHandler={rowHandler} unit={unit} />
          // )}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userPermissionsGroups: state.userPermissions.userPermissionsGroups,
    userPermissionsUsers: state.userPermissions.userPermissionsUsers,
    userPermissionsUsersLoading:
      state.userPermissions.userPermissionsUsersLoading,
    userPermissionsUsersError: state.userPermissions.userPermissionsUsersError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserPermissionsGroups: () => dispatch(getUserPermissionsGroups()),
    getUserPermissionsUsers: () => dispatch(getUserPermissionsUsers())
  };
};

Users.displayName = 'User Permissions Portal - Users';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
