export default `
# Release Notes
## v6.3.0
28/04/22

### Features:

* FM Cleaning Schedules Fixes and Enhancements
    * Happy Face Negative Response Map

* Reports are set to correct Configuration ID

* GT Comms Enhancements
    * Rev Details
        - Copy of Customer Segments chart and corresponding measures
        - Update Time bands and corresponding measures
        - Change value format of Rev Var (Abs) to K and Txn Var (abs) without decimals
        - Alignments to slicers and bookmarks
        - Bookmark tooltips updated
    * Forward Bookings
        - Primary date selection slider fix
        - Alignments to slicers and bookmarks
        - Bookmark tooltips updated

* FM Cleaning Reporting Enhancements
        - Lookback info textbox resize
        - PAX Heatmap info textbox resize
        - PAX by Terminal Line Graph filtering
        - Date Slicer on Negative HF per Terminal Graph
        - Default View - Total Pax and HF Negative Responses
        - Enable relationship between PAX by Terminal and PAX/Neg Response Ratio Graph
        - PAX Heatmap NOW indicator fix

`;
