import React from 'react';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import colors from '../../../../../styles/utils/_custom-variables.scss';
import Checkbox from '@material-ui/core/Checkbox';
import MaUTable from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from './TablePaginationActions';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableToolbar from './TableToolbar';
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import cn from 'classnames';

const primaryPalette = {
  main: colors.edpPurple
};

const customMaterialUITheme = createMuiTheme({
  palette: {
    secondary: primaryPalette
  }
});

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox
          className={cn('edp-user-permissions__checkbox')}
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

const EnhancedMaterialUITable = ({
  columns,
  data,
  setData,
  updateMyData,
  skipPageReset,
  onRowClick,
  addDialog,
  allowRemove,
  unit
}) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    checkboxesEnabled,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter }
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      checkboxesEnabled &&
        hooks.columns.push(columns => [
          // Let's make a column for selection

          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox.  Pagination is a problem since this will select all
            // rows even though not all rows are on the current page.  The solution should
            // be server side pagination.  For one, the clients should not download all
            // rows in most cases.  The client should only download data for the current page.
            // In that case, getToggleAllRowsSelectedProps works fine.
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            )
          },
          ...columns
        ]);
    }
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value));
  };

  const removeByIndexes = (array, indexs) =>
    array.filter((_, i) => !indexs.includes(i));

  const deleteRowHandler = event => {
    const newData = removeByIndexes(
      data,
      Object.keys(selectedRowIds).map(x => parseInt(x, 10))
    );
    setData(newData);
  };

  const addRowHandler = user => {
    const newData = data.concat([user]);
    setData(newData);
  };

  // Render the UI for your table
  return (
    <ThemeProvider theme={customMaterialUITheme}>
      <TableContainer>
        <TableToolbar
          unit={unit}
          numRows={preGlobalFilteredRows.length}
          numSelected={Object.keys(selectedRowIds).length}
          deleteUserHandler={allowRemove ? deleteRowHandler : null}
          addDialog={addDialog ? addDialog(addRowHandler) : null}
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          handleChangePage={handleChangePage}
          globalFilter={globalFilter}
        />
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell
                    {...(column.id === 'selection'
                      ? column.getHeaderProps()
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                    className={cn(
                      'edp-user-permissions__table--header-cell',
                      'noselect',
                      column.id
                    )}
                  >
                    {column.render('Header')}
                    {column.id !== 'selection' ? (
                      <TableSortLabel
                        active={column.isSorted}
                        // react-table has a unsorted state which is not treated here
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  className={cn('edp-user-permissions__table--row', {
                    clickable: onRowClick !== undefined
                  })}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <TableCell
                        onClick={() => {
                          onRowClick !== undefined && onRowClick(row.original);
                        }}
                        {...cell.getCellProps()}
                        className={cn(
                          'edp-user-permissions__table--header-cell',
                          'noselect',
                          cell.column.id
                        )}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: 'All', value: rows.length }
                ]}
                colSpan={4}
                count={rows.length} // show the length of the filtered data (rows) rather than the whole set (data)
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </MaUTable>
      </TableContainer>
    </ThemeProvider>
  );
};

EnhancedMaterialUITable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  updateMyData: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  skipPageReset: PropTypes.bool.isRequired,
  unit: PropTypes.string.isRequired,
  allowRemove: PropTypes.bool.isRequired,
  checkboxesEnabled: PropTypes.bool.isRequired,
  addDialog: PropTypes.func
};

export default EnhancedMaterialUITable;
