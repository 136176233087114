import { AuthenticationContext, adalFetch } from 'react-adal';
import {
  tenantId,
  applicationId,
  postLogoutRedirectUri,
  postLoginRedirectUri
} from '../../../constants';
import getHistory from 'react-router-global-history';

const adalConfig = {
  tenant: tenantId,
  clientId: applicationId,
  endpoints: {
    api: applicationId
  },
  postLogoutRedirectUri: postLogoutRedirectUri,
  redirectUri: postLoginRedirectUri,
  navigateToLoginRequestUrl: false,
  cacheLocation: 'localStorage'
};

adalConfig.callback = (errorDesc, token, error) => {
  // The token will be added to the browser's storage by react-adal
  if (
    authContext.isCallback(window.location.hash) &&
    window.location.hash.includes('#')
  ) {
    getHistory().push('/login');
  }
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => {
  return authContext.getCachedToken(authContext.config.clientId);
};

const shouldAcquireNewToken = message => message === 'User login is required';

export const getTokenWithRefresh = () => {
  const resourceGuiId = authContext.config.clientId;
  return new Promise((resolve, reject) => {
    authContext.acquireToken(resourceGuiId, (message, token, msg) => {
      if (!msg) {
        resolve(token);
      } else if (shouldAcquireNewToken(message)) {
        authContext.acquireTokenRedirect(resourceGuiId);
        reject('Login required. Redirecting to obtain a new token.');
      } else {
        authContext.acquireTokenRedirect(resourceGuiId);
        reject({ message, msg });
      }
    });
  });
};

export const authDetails = {
  authContext,
  resourceId: adalConfig.endpoints.api
};

export const isAuth = () => {
  return getToken() !== null;
};

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
