export default `
# Release Notes
## v2.8.0
30/01/20

### New Features
*IOC*

* Int Arrivals Performance Board – This new board provides post event analysis on the international arrivals primary line. A breakdown of PAX and flights is available for each timeslot that’s above the transit time SLA.  
* T2 Arrivals PAX Presentation – An enhancement to this card has been deployed. Forecasting can be increased to up to 72 hours ahead. Also available will be a forecasted PAX and flight breakdown for any time slot up to 72 hours ahead. 

### Bug Fixes

* Some minor issues relating to LA Ops and LA Commercial have been fixed.
* AMS card on iPads have been adjusted to display to the full-page width. 
* Happy Face notifications will now link to correct floor on terminal card.

`;
