import iocTerminalImageUrl from '../../../images/iocTerminal.png';

export default `
# IOC - Terminals (T2, T3 & T4)
## Board Overview

This board shows the projected number of passengers for Terminal 3 Departures. It also provides information on the next 20 flights that are scheduled to depart or arrive and the negative sentiments in the last 15 minutes across T2, T3, and T4.

![IOC Terminal Board](${iocTerminalImageUrl})

**Card 1** – Passenger Approximates  

**Card 2** – Flight Departures / Arrivals  

**Card 3** – Number of Negative Responses per Happy Face Device  

## Card 1 - Passenger Approximates

This card displays the number of departing passengers in the check-in and security areas for the next 48 hours. You can either view tomorrow’s forecast or the day after tomorrow. There are additional options to view the data in 15-min or 1-hour blocks and 12-hour or 24-hour periods (accessible by the filter icon).

You can hover over any data point to view a tooltip indicating the aggregated or total count of departing passengers based on the selected filters.

The primary data source is Departures PAX Presentation Engine, with AMS and SITA as the key sources, and the card updates every 15 minutes. This card is currently available on Terminal 3 Departures only.


## Card 2 - Flight Departures / Arrivals

This card shows either the flight departures or arrivals information. You can view the next 20 flights that are scheduled to depart or arrive and see the following information:

1.	Flight Number
2.	Number of Passengers (PAX)
3.	Scheduled Time of Departure (STD) or Scheduled Time of Arrival (STA)
4.	Estimated Time of Departure (ETD) or Estimated Time of Arrival (ETA)
5.	Status
6.	Gate (Departures only)
7.	Baggage Carousel (Arrivals only)
8.	Checked-in, % (T2 Departures Only)
9.	Boarded, % (T2 Departures Only)
10.	Check-in Counter (T2 Departures Only)

Delayed or canceled flights are highlighted in red.

The data source is AMS, and the card updates every 2 minutes.

## Card 3 – Number of Negative Responses per Happy Face Device

This card shows the number of negative Happy Face responses that occurred in the last 15 minutes by floor level, device name, and type. You can either view the negative sentiments on the floorplan map or in the list underneath. 

Each floor map, floor toggle, and device set are specific to each terminal. It also shows all devices on both departures and arrivals. The statuses on the map indicate the following:

* Green: No negative responses within the last 15 minutes
* Amber to Red: Gradient of the severity of negative response

When a user selects a device in the list, the map will zoom in.

The data source is Happy Face, and the card updates every minute.
`.trim();
