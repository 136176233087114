import React from 'react';
import { ReactComponent as ExclamationTriangle } from '../../../assets/img/exclamation-triangle.svg';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

const ErrorIcon = props => {
  const { tooltipContent, placement } = props;

  return (
    <Tooltip title={tooltipContent} placement={placement}>
      <div style={containerStyles}>
        <ExclamationTriangle style={iconStyles} />
      </div>
    </Tooltip>
  );
};

ErrorIcon.defaultProps = {
  tooltipContent: 'An error occurred.',
  placement: 'bottom'
};

ErrorIcon.propTypes = {
  tooltipContent: PropTypes.string,
  placement: PropTypes.string
};

const containerStyles = { display: 'inline-block' };

const iconStyles = {
  width: '15px',
  height: '15px',
  fill: '#fe4a49',
  cursor: 'pointer'
};

export default ErrorIcon;
