export default `
# Release Notes
## v2.10.0
05/03/20

### New Features

* The Travel time to Airport and Internal Roads Cards have been redesigned resulting in an improved load performance of the IOC Overview board.
* IOC T2 Arrivals PAX breakdown printing feature has been deployed.
* A new field in the Airline performance report - AMS Flight Type is available for filtering flights.

`;
