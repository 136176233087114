import React, { Component } from 'react';
import Navigation from '../../components/Navigation/Navigation';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';

class Layout extends Component {
  state = {
    expanded: false
  };

  toggleExpand = () => this.setState({ expanded: !this.state.expanded });

  render() {
    return (
      <>
        <Navigation userPermissions={this.props.userPermissions} />
        <Container fluid>{this.props.children}</Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userPermissions: state.withAuth.userPermissions,
    showNavigationSideBar: state.navigation.showNavigationSideBar
  };
};

export default connect(mapStateToProps)(Layout);
