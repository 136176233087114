import * as actionTypes from '../actionTypes/navigationActionTypes';

export const openNavigationSideBar = () => {
  return {
    type: actionTypes.NAVIGATION_OPEN_SIDE_BAR_MENU
  };
};

export const closeNavigationSideBar = () => {
  return {
    type: actionTypes.NAVIGATION_CLOSE_SIDE_BAR_MENU
  };
};

export const toggleNavigationSideBar = () => {
  return {
    type: actionTypes.NAVIGATION_TOGGLE_SIDE_BAR_MENU
  };
};
