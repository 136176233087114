import * as actionTypes from '../actionTypes/navigationActionTypes';
import updateObject from '../utility';

const initialState = {
  showNavigationSideBar: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NAVIGATION_OPEN_SIDE_BAR_MENU:
      return updateObject(state, {
        showNavigationSideBar: true
      });
    case actionTypes.NAVIGATION_CLOSE_SIDE_BAR_MENU:
      return updateObject(state, {
        showNavigationSideBar: false
      });
    case actionTypes.NAVIGATION_TOGGLE_SIDE_BAR_MENU:
      return updateObject(state, {
        showNavigationSideBar: !state.showNavigationSideBar
      });
    default:
      return state;
  }
};

export default reducer;
