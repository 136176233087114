const mockData = [
  {
    dateTime: '2019-09-01T18:55:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 6,
    totalDurationOnThisWeekday: 16,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '18:55'
  },
  {
    dateTime: '2019-09-01T16:40:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '16:40'
  },
  {
    dateTime: '2019-09-01T19:35:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 15,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '19:35'
  },
  {
    dateTime: '2019-09-02T10:55:00',
    weekday: 'Monday',
    gatesDuration: 5,
    immigrationDuration: 6,
    baggageDuration: 6,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 12,
    totalDuration: 17,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '10:55'
  },
  {
    dateTime: '2019-09-01T23:20:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 6,
    totalDurationOnThisWeekday: 19,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '23:20'
  },
  {
    dateTime: '2019-09-01T17:05:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 12,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '17:05'
  },
  {
    dateTime: '2019-09-01T20:50:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '20:50'
  },
  {
    dateTime: '2019-09-01T18:25:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 17,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 21,
    totalDurationOnThisWeekday: 19,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '18:25'
  },
  {
    dateTime: '2019-09-02T05:40:00',
    weekday: 'Monday',
    gatesDuration: 5,
    immigrationDuration: 9,
    baggageDuration: 8,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 22,
    totalDurationOnThisWeekday: 16,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '05:40'
  },
  {
    dateTime: '2019-09-01T14:45:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 15,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '14:45'
  },
  {
    dateTime: '2019-09-02T10:20:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 8,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 13,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 12,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '10:20'
  },
  {
    dateTime: '2019-09-01T17:30:00',
    weekday: 'Sunday',
    gatesDuration: 6,
    immigrationDuration: 6,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 14,
    totalDurationOnThisWeekday: 15,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '17:30'
  },
  {
    dateTime: '2019-09-02T02:50:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '02:50'
  },
  {
    dateTime: '2019-09-02T03:20:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '03:20'
  },
  {
    dateTime: '2019-09-02T06:10:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 17,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 21,
    totalDurationOnThisWeekday: 20,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '06:10'
  },
  {
    dateTime: '2019-09-02T06:15:00',
    weekday: 'Monday',
    gatesDuration: 4,
    immigrationDuration: 17,
    baggageDuration: 16,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 37,
    totalDurationOnThisWeekday: 20,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '06:15'
  },
  {
    dateTime: '2019-09-02T07:00:00',
    weekday: 'Monday',
    gatesDuration: 7,
    immigrationDuration: 7,
    baggageDuration: 12,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 13,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 26,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '07:00'
  },
  {
    dateTime: '2019-09-01T13:35:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 4,
    totalDuration: 6,
    totalDurationOnThisWeekday: 11,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '13:35'
  },
  {
    dateTime: '2019-09-02T03:30:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '03:30'
  },
  {
    dateTime: '2019-09-01T11:10:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 13,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 16,
    totalDurationOnThisWeekday: 26,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '11:10'
  },
  {
    dateTime: '2019-09-02T04:15:00',
    weekday: 'Monday',
    gatesDuration: 3,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 3,
    totalDuration: 8,
    totalDurationOnThisWeekday: 7,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '04:15'
  },
  {
    dateTime: '2019-09-01T17:45:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 10,
    baggageDuration: 10,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 21,
    totalDurationOnThisWeekday: 16,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '17:45'
  },
  {
    dateTime: '2019-09-02T04:05:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '04:05'
  },
  {
    dateTime: '2019-09-02T06:55:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 18,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 13,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 22,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '06:55'
  },
  {
    dateTime: '2019-09-01T12:45:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 7,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 11,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '12:45'
  },
  {
    dateTime: '2019-09-01T14:15:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '14:15'
  },
  {
    dateTime: '2019-09-02T06:45:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 12,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 16,
    totalDurationOnThisWeekday: 25,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '06:45'
  },
  {
    dateTime: '2019-09-01T21:35:00',
    weekday: 'Sunday',
    gatesDuration: 2,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 7,
    totalDurationOnThisWeekday: 17,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '21:35'
  },
  {
    dateTime: '2019-09-02T03:05:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '03:05'
  },
  {
    dateTime: '2019-09-01T17:40:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 7,
    baggageDuration: 10,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 18,
    totalDurationOnThisWeekday: 16,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '17:40'
  },
  {
    dateTime: '2019-09-02T05:50:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 15,
    baggageDuration: 13,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 29,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '05:50'
  },
  {
    dateTime: '2019-09-01T14:40:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '14:40'
  },
  {
    dateTime: '2019-09-01T16:20:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '16:20'
  },
  {
    dateTime: '2019-09-02T07:05:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 4,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 13,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 7,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '07:05'
  },
  {
    dateTime: '2019-09-01T15:40:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '15:40'
  },
  {
    dateTime: '2019-09-02T09:05:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 12,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 16,
    totalDurationOnThisWeekday: 26,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '09:05'
  },
  {
    dateTime: '2019-09-02T00:55:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 3,
    totalDuration: 6,
    totalDurationOnThisWeekday: 9,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '00:55'
  },
  {
    dateTime: '2019-09-02T07:25:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 8,
    baggageDuration: 16,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 5,
    immigrationDurationOnThisWeekday: 14,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 25,
    totalDurationOnThisWeekday: 30,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '07:25'
  },
  {
    dateTime: '2019-09-02T04:40:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '04:40'
  },
  {
    dateTime: '2019-09-01T15:50:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '15:50'
  },
  {
    dateTime: '2019-09-02T10:35:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 13,
    baggageDurationOnThisWeekday: 12,
    totalDuration: 6,
    totalDurationOnThisWeekday: 29,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '10:35'
  },
  {
    dateTime: '2019-09-02T10:45:00',
    weekday: 'Monday',
    gatesDuration: 6,
    immigrationDuration: 3,
    baggageDuration: 6,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 12,
    totalDuration: 15,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '10:45'
  },
  {
    dateTime: '2019-09-01T12:25:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 6,
    totalDurationOnThisWeekday: 20,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '12:25'
  },
  {
    dateTime: '2019-09-01T11:55:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 6,
    totalDurationOnThisWeekday: 24,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '11:55'
  },
  {
    dateTime: '2019-09-02T02:55:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '02:55'
  },
  {
    dateTime: '2019-09-01T23:35:00',
    weekday: 'Sunday',
    gatesDuration: 3,
    immigrationDuration: 4,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 9,
    totalDurationOnThisWeekday: 17,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '23:35'
  },
  {
    dateTime: '2019-09-02T01:45:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '01:45'
  },
  {
    dateTime: '2019-09-01T21:40:00',
    weekday: 'Sunday',
    gatesDuration: 4,
    immigrationDuration: 4,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 10,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '21:40'
  },
  {
    dateTime: '2019-09-02T08:05:00',
    weekday: 'Monday',
    gatesDuration: 2,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 14,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 7,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '08:05'
  },
  {
    dateTime: '2019-09-01T22:35:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 6,
    totalDurationOnThisWeekday: 21,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '22:35'
  },
  {
    dateTime: '2019-09-02T09:35:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 5,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 8,
    totalDurationOnThisWeekday: 26,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '09:35'
  },
  {
    dateTime: '2019-09-02T08:55:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 7,
    baggageDuration: 22,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 30,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '08:55'
  },
  {
    dateTime: '2019-09-01T15:55:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '15:55'
  },
  {
    dateTime: '2019-09-02T07:15:00',
    weekday: 'Monday',
    gatesDuration: 3,
    immigrationDuration: 6,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 13,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 11,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '07:15'
  },
  {
    dateTime: '2019-09-02T07:50:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 13,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 6,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '07:50'
  },
  {
    dateTime: '2019-09-01T19:15:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '19:15'
  },
  {
    dateTime: '2019-09-02T02:15:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '02:15'
  },
  {
    dateTime: '2019-09-01T21:45:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 5,
    baggageDuration: 8,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 14,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '21:45'
  },
  {
    dateTime: '2019-09-01T13:10:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 12,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '13:10'
  },
  {
    dateTime: '2019-09-02T02:05:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '02:05'
  },
  {
    dateTime: '2019-09-02T09:30:00',
    weekday: 'Monday',
    gatesDuration: 4,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 9,
    totalDurationOnThisWeekday: 26,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '09:30'
  },
  {
    dateTime: '2019-09-02T07:10:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 7,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 13,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 11,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '07:10'
  },
  {
    dateTime: '2019-09-01T20:10:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '20:10'
  },
  {
    dateTime: '2019-09-01T22:45:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 6,
    totalDurationOnThisWeekday: 20,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '22:45'
  },
  {
    dateTime: '2019-09-01T13:05:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 12,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '13:05'
  },
  {
    dateTime: '2019-09-01T14:05:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 12,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '14:05'
  },
  {
    dateTime: '2019-09-02T09:20:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 6,
    totalDurationOnThisWeekday: 25,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '09:20'
  },
  {
    dateTime: '2019-09-01T13:00:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '13:00'
  },
  {
    dateTime: '2019-09-02T06:00:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 13,
    baggageDuration: 10,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 24,
    totalDurationOnThisWeekday: 19,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '06:00'
  },
  {
    dateTime: '2019-09-01T17:25:00',
    weekday: 'Sunday',
    gatesDuration: 3,
    immigrationDuration: 4,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 9,
    totalDurationOnThisWeekday: 15,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '17:25'
  },
  {
    dateTime: '2019-09-01T18:40:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 6,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '18:40'
  },
  {
    dateTime: '2019-09-02T01:05:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 3,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 3,
    totalDuration: 7,
    totalDurationOnThisWeekday: 7,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '01:05'
  },
  {
    dateTime: '2019-09-01T22:40:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 6,
    totalDurationOnThisWeekday: 21,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '22:40'
  },
  {
    dateTime: '2019-09-02T08:15:00',
    weekday: 'Monday',
    gatesDuration: 4,
    immigrationDuration: 5,
    baggageDuration: 6,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 13,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 15,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '08:15'
  },
  {
    dateTime: '2019-09-02T07:30:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 9,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 13,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 12,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '07:30'
  },
  {
    dateTime: '2019-09-02T01:55:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '01:55'
  },
  {
    dateTime: '2019-09-02T04:35:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '04:35'
  },
  {
    dateTime: '2019-09-02T09:15:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 6,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '09:15'
  },
  {
    dateTime: '2019-09-02T04:30:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 7,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 3,
    totalDuration: 11,
    totalDurationOnThisWeekday: 7,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '04:30'
  },
  {
    dateTime: '2019-09-01T21:50:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 6,
    baggageDuration: 6,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 13,
    totalDurationOnThisWeekday: 16,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '21:50'
  },
  {
    dateTime: '2019-09-01T12:10:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 9,
    baggageDuration: 6,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 16,
    totalDurationOnThisWeekday: 22,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '12:10'
  },
  {
    dateTime: '2019-09-02T08:30:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 8,
    baggageDuration: 9,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 18,
    totalDurationOnThisWeekday: 26,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '08:30'
  },
  {
    dateTime: '2019-09-02T07:35:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 15,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 13,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 19,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '07:35'
  },
  {
    dateTime: '2019-09-02T01:30:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '01:30'
  },
  {
    dateTime: '2019-09-01T21:55:00',
    weekday: 'Sunday',
    gatesDuration: 6,
    immigrationDuration: 6,
    baggageDuration: 5,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 17,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '21:55'
  },
  {
    dateTime: '2019-09-01T14:35:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '14:35'
  },
  {
    dateTime: '2019-09-01T20:20:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 10,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 13,
    totalDurationOnThisWeekday: 12,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '20:20'
  },
  {
    dateTime: '2019-09-01T19:40:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 6,
    totalDurationOnThisWeekday: 17,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '19:40'
  },
  {
    dateTime: '2019-09-01T20:40:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 6,
    totalDurationOnThisWeekday: 17,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '20:40'
  },
  {
    dateTime: '2019-09-01T13:55:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 12,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '13:55'
  },
  {
    dateTime: '2019-09-01T12:20:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 7,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 11,
    totalDurationOnThisWeekday: 21,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '12:20'
  },
  {
    dateTime: '2019-09-01T19:20:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 6,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 9,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '19:20'
  },
  {
    dateTime: '2019-09-01T13:20:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 4,
    totalDuration: 6,
    totalDurationOnThisWeekday: 11,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '13:20'
  },
  {
    dateTime: '2019-09-01T11:30:00',
    weekday: 'Sunday',
    gatesDuration: 6,
    immigrationDuration: 9,
    baggageDuration: 6,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 21,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '11:30'
  },
  {
    dateTime: '2019-09-01T16:10:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '16:10'
  },
  {
    dateTime: '2019-09-02T08:20:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 5,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 8,
    totalDurationOnThisWeekday: 26,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '08:20'
  },
  {
    dateTime: '2019-09-02T09:10:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 6,
    totalDurationOnThisWeekday: 26,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '09:10'
  },
  {
    dateTime: '2019-09-01T11:45:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 8,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 10,
    baggageDurationOnThisWeekday: 12,
    totalDuration: 12,
    totalDurationOnThisWeekday: 25,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '11:45'
  },
  {
    dateTime: '2019-09-01T22:05:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 8,
    baggageDuration: 11,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 20,
    totalDurationOnThisWeekday: 19,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '22:05'
  },
  {
    dateTime: '2019-09-01T14:00:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 4,
    totalDuration: 6,
    totalDurationOnThisWeekday: 10,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '14:00'
  },
  {
    dateTime: '2019-09-02T10:10:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 8,
    baggageDuration: 15,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 5,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 24,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '10:10'
  },
  {
    dateTime: '2019-09-02T06:40:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 18,
    baggageDuration: 10,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 29,
    totalDurationOnThisWeekday: 24,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '06:40'
  },
  {
    dateTime: '2019-09-01T15:25:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '15:25'
  },
  {
    dateTime: '2019-09-01T11:35:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 7,
    baggageDuration: 7,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 15,
    totalDurationOnThisWeekday: 26,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '11:35'
  },
  {
    dateTime: '2019-09-02T08:10:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 14,
    baggageDurationOnThisWeekday: 12,
    totalDuration: 6,
    totalDurationOnThisWeekday: 29,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '08:10'
  },
  {
    dateTime: '2019-09-02T05:55:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 14,
    baggageDuration: 13,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 28,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '05:55'
  },
  {
    dateTime: '2019-09-01T12:50:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 6,
    totalDurationOnThisWeekday: 15,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '12:50'
  },
  {
    dateTime: '2019-09-02T01:15:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 3,
    totalDuration: 6,
    totalDurationOnThisWeekday: 7,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '01:15'
  },
  {
    dateTime: '2019-09-01T16:50:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 4,
    totalDuration: 6,
    totalDurationOnThisWeekday: 11,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '16:50'
  },
  {
    dateTime: '2019-09-01T21:25:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 6,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '21:25'
  },
  {
    dateTime: '2019-09-01T15:20:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 7,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 10,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '15:20'
  },
  {
    dateTime: '2019-09-01T23:10:00',
    weekday: 'Sunday',
    gatesDuration: 5,
    immigrationDuration: 4,
    baggageDuration: 10,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 19,
    totalDurationOnThisWeekday: 19,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '23:10'
  },
  {
    dateTime: '2019-09-01T21:20:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 6,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '21:20'
  },
  {
    dateTime: '2019-09-01T18:05:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 6,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '18:05'
  },
  {
    dateTime: '2019-09-01T21:10:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 6,
    totalDurationOnThisWeekday: 19,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '21:10'
  },
  {
    dateTime: '2019-09-01T19:45:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 6,
    totalDurationOnThisWeekday: 16,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '19:45'
  },
  {
    dateTime: '2019-09-01T13:50:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 12,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '13:50'
  },
  {
    dateTime: '2019-09-02T07:40:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 6,
    baggageDuration: 12,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 5,
    immigrationDurationOnThisWeekday: 14,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 19,
    totalDurationOnThisWeekday: 30,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '07:40'
  },
  {
    dateTime: '2019-09-01T17:10:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '17:10'
  },
  {
    dateTime: '2019-09-02T05:35:00',
    weekday: 'Monday',
    gatesDuration: 7,
    immigrationDuration: 7,
    baggageDuration: 6,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 20,
    totalDurationOnThisWeekday: 15,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '05:35'
  },
  {
    dateTime: '2019-09-02T00:10:00',
    weekday: 'Monday',
    gatesDuration: 3,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 8,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '00:10'
  },
  {
    dateTime: '2019-09-01T20:00:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 6,
    totalDurationOnThisWeekday: 16,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '20:00'
  },
  {
    dateTime: '2019-09-02T10:50:00',
    weekday: 'Monday',
    gatesDuration: 4,
    immigrationDuration: 5,
    baggageDuration: 7,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 16,
    totalDurationOnThisWeekday: 25,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '10:50'
  },
  {
    dateTime: '2019-09-01T19:30:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '19:30'
  },
  {
    dateTime: '2019-09-01T11:25:00',
    weekday: 'Sunday',
    gatesDuration: 5,
    immigrationDuration: 4,
    baggageDuration: 14,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 23,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '11:25'
  },
  {
    dateTime: '2019-09-02T01:10:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 3,
    totalDuration: 6,
    totalDurationOnThisWeekday: 7,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '01:10'
  },
  {
    dateTime: '2019-09-01T16:15:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 15,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '16:15'
  },
  {
    dateTime: '2019-09-01T19:05:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 6,
    totalDurationOnThisWeekday: 16,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '19:05'
  },
  {
    dateTime: '2019-09-01T12:55:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '12:55'
  },
  {
    dateTime: '2019-09-02T02:40:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '02:40'
  },
  {
    dateTime: '2019-09-02T03:00:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '03:00'
  },
  {
    dateTime: '2019-09-02T00:05:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 18,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 2,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 22,
    totalDurationOnThisWeekday: 5,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '00:05'
  },
  {
    dateTime: '2019-09-01T13:15:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 12,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '13:15'
  },
  {
    dateTime: '2019-09-01T16:55:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '16:55'
  },
  {
    dateTime: '2019-09-02T01:50:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '01:50'
  },
  {
    dateTime: '2019-09-02T01:20:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '01:20'
  },
  {
    dateTime: '2019-09-01T15:15:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 6,
    totalDurationOnThisWeekday: 16,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '15:15'
  },
  {
    dateTime: '2019-09-02T05:45:00',
    weekday: 'Monday',
    gatesDuration: 9,
    immigrationDuration: 12,
    baggageDuration: 11,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 32,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '05:45'
  },
  {
    dateTime: '2019-09-01T17:35:00',
    weekday: 'Sunday',
    gatesDuration: 6,
    immigrationDuration: 8,
    baggageDuration: 8,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 22,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '17:35'
  },
  {
    dateTime: '2019-09-02T00:30:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 4,
    baggageDurationOnThisWeekday: 3,
    totalDuration: 6,
    totalDurationOnThisWeekday: 9,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '00:30'
  },
  {
    dateTime: '2019-09-01T15:30:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '15:30'
  },
  {
    dateTime: '2019-09-01T20:05:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '20:05'
  },
  {
    dateTime: '2019-09-02T10:15:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 12,
    baggageDuration: 9,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 5,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 22,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '10:15'
  },
  {
    dateTime: '2019-09-01T20:35:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 8,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 11,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '20:35'
  },
  {
    dateTime: '2019-09-01T23:15:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 5,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 8,
    totalDurationOnThisWeekday: 19,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '23:15'
  },
  {
    dateTime: '2019-09-01T16:05:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '16:05'
  },
  {
    dateTime: '2019-09-02T04:45:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '04:45'
  },
  {
    dateTime: '2019-09-02T02:45:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '02:45'
  },
  {
    dateTime: '2019-09-02T03:40:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '03:40'
  },
  {
    dateTime: '2019-09-02T08:45:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 13,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 5,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 17,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '08:45'
  },
  {
    dateTime: '2019-09-02T06:05:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 22,
    baggageDuration: 15,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 38,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '06:05'
  },
  {
    dateTime: '2019-09-01T23:00:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 6,
    totalDurationOnThisWeekday: 17,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '23:00'
  },
  {
    dateTime: '2019-09-01T17:20:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '17:20'
  },
  {
    dateTime: '2019-09-02T00:35:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 4,
    baggageDurationOnThisWeekday: 4,
    totalDuration: 6,
    totalDurationOnThisWeekday: 9,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '00:35'
  },
  {
    dateTime: '2019-09-01T23:45:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 5,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 8,
    totalDurationOnThisWeekday: 15,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '23:45'
  },
  {
    dateTime: '2019-09-02T05:00:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 4,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 7,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '05:00'
  },
  {
    dateTime: '2019-09-01T11:05:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 10,
    baggageDuration: 6,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 17,
    totalDurationOnThisWeekday: 24,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '11:05'
  },
  {
    dateTime: '2019-09-02T02:35:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '02:35'
  },
  {
    dateTime: '2019-09-02T04:25:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 5,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 8,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '04:25'
  },
  {
    dateTime: '2019-09-01T13:45:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '13:45'
  },
  {
    dateTime: '2019-09-02T08:40:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 13,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 5,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 17,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '08:40'
  },
  {
    dateTime: '2019-09-02T10:40:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 6,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 13,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 9,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '10:40'
  },
  {
    dateTime: '2019-09-01T21:30:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 6,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '21:30'
  },
  {
    dateTime: '2019-09-02T03:55:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '03:55'
  },
  {
    dateTime: '2019-09-01T16:00:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '16:00'
  },
  {
    dateTime: '2019-09-01T13:25:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 12,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '13:25'
  },
  {
    dateTime: '2019-09-01T19:25:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 4,
    baggageDuration: 6,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 11,
    totalDurationOnThisWeekday: 15,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '19:25'
  },
  {
    dateTime: '2019-09-02T02:20:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '02:20'
  },
  {
    dateTime: '2019-09-01T21:05:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 6,
    totalDurationOnThisWeekday: 19,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '21:05'
  },
  {
    dateTime: '2019-09-01T15:10:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 6,
    totalDurationOnThisWeekday: 17,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '15:10'
  },
  {
    dateTime: '2019-09-01T22:30:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 10,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 6,
    totalDurationOnThisWeekday: 22,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '22:30'
  },
  {
    dateTime: '2019-09-02T04:55:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 4,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 7,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '04:55'
  },
  {
    dateTime: '2019-09-02T03:50:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '03:50'
  },
  {
    dateTime: '2019-09-02T09:40:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 12,
    totalDuration: 6,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '09:40'
  },
  {
    dateTime: '2019-09-01T17:50:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 11,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 15,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '17:50'
  },
  {
    dateTime: '2019-09-01T23:30:00',
    weekday: 'Sunday',
    gatesDuration: 2,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 7,
    totalDurationOnThisWeekday: 17,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '23:30'
  },
  {
    dateTime: '2019-09-02T04:20:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 3,
    totalDuration: 6,
    totalDurationOnThisWeekday: 7,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '04:20'
  },
  {
    dateTime: '2019-09-01T18:15:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 6,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '18:15'
  },
  {
    dateTime: '2019-09-02T04:00:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '04:00'
  },
  {
    dateTime: '2019-09-02T03:25:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '03:25'
  },
  {
    dateTime: '2019-09-01T18:10:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 6,
    totalDurationOnThisWeekday: 19,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '18:10'
  },
  {
    dateTime: '2019-09-01T12:30:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 10,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 13,
    totalDurationOnThisWeekday: 20,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '12:30'
  },
  {
    dateTime: '2019-09-01T21:15:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 6,
    totalDurationOnThisWeekday: 20,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '21:15'
  },
  {
    dateTime: '2019-09-01T23:05:00',
    weekday: 'Sunday',
    gatesDuration: 3,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 8,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '23:05'
  },
  {
    dateTime: '2019-09-01T22:55:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 6,
    totalDurationOnThisWeekday: 17,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '22:55'
  },
  {
    dateTime: '2019-09-02T06:50:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 16,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 20,
    totalDurationOnThisWeekday: 26,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '06:50'
  },
  {
    dateTime: '2019-09-01T17:15:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '17:15'
  },
  {
    dateTime: '2019-09-02T02:25:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '02:25'
  },
  {
    dateTime: '2019-09-02T01:35:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '01:35'
  },
  {
    dateTime: '2019-09-02T09:25:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 6,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '09:25'
  },
  {
    dateTime: '2019-09-01T14:10:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 12,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '14:10'
  },
  {
    dateTime: '2019-09-01T19:50:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 6,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 9,
    totalDurationOnThisWeekday: 15,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '19:50'
  },
  {
    dateTime: '2019-09-01T12:15:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 6,
    totalDurationOnThisWeekday: 22,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '12:15'
  },
  {
    dateTime: '2019-09-01T17:00:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '17:00'
  },
  {
    dateTime: '2019-09-01T22:50:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 6,
    totalDurationOnThisWeekday: 17,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '22:50'
  },
  {
    dateTime: '2019-09-01T15:35:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 12,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 16,
    totalDurationOnThisWeekday: 12,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '15:35'
  },
  {
    dateTime: '2019-09-02T06:25:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 19,
    baggageDuration: 12,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 10,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 32,
    totalDurationOnThisWeekday: 22,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '06:25'
  },
  {
    dateTime: '2019-09-02T06:35:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 25,
    baggageDuration: 11,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 37,
    totalDurationOnThisWeekday: 23,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '06:35'
  },
  {
    dateTime: '2019-09-01T12:00:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 6,
    totalDurationOnThisWeekday: 22,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '12:00'
  },
  {
    dateTime: '2019-09-01T22:15:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 6,
    totalDurationOnThisWeekday: 20,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '22:15'
  },
  {
    dateTime: '2019-09-01T16:25:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 16,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '16:25'
  },
  {
    dateTime: '2019-09-01T12:05:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 6,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 9,
    totalDurationOnThisWeekday: 20,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '12:05'
  },
  {
    dateTime: '2019-09-01T18:35:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 6,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '18:35'
  },
  {
    dateTime: '2019-09-02T08:25:00',
    weekday: 'Monday',
    gatesDuration: 6,
    immigrationDuration: 6,
    baggageDuration: 9,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 21,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '08:25'
  },
  {
    dateTime: '2019-09-01T18:50:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 16,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '18:50'
  },
  {
    dateTime: '2019-09-01T16:30:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 15,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '16:30'
  },
  {
    dateTime: '2019-09-01T20:25:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '20:25'
  },
  {
    dateTime: '2019-09-02T07:55:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 13,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 6,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '07:55'
  },
  {
    dateTime: '2019-09-01T18:00:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 8,
    baggageDuration: 11,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 20,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '18:00'
  },
  {
    dateTime: '2019-09-02T00:45:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 4,
    baggageDurationOnThisWeekday: 4,
    totalDuration: 6,
    totalDurationOnThisWeekday: 9,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '00:45'
  },
  {
    dateTime: '2019-09-01T19:00:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 6,
    totalDurationOnThisWeekday: 15,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '19:00'
  },
  {
    dateTime: '2019-09-01T19:10:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 6,
    totalDurationOnThisWeekday: 16,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '19:10'
  },
  {
    dateTime: '2019-09-01T18:45:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 6,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '18:45'
  },
  {
    dateTime: '2019-09-01T23:25:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 6,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '23:25'
  },
  {
    dateTime: '2019-09-01T14:50:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '14:50'
  },
  {
    dateTime: '2019-09-01T16:35:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '16:35'
  },
  {
    dateTime: '2019-09-01T18:20:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 17,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 21,
    totalDurationOnThisWeekday: 19,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '18:20'
  },
  {
    dateTime: '2019-09-01T16:45:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '16:45'
  },
  {
    dateTime: '2019-09-02T00:25:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 4,
    baggageDurationOnThisWeekday: 3,
    totalDuration: 6,
    totalDurationOnThisWeekday: 9,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '00:25'
  },
  {
    dateTime: '2019-09-02T05:25:00',
    weekday: 'Monday',
    gatesDuration: 5,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 4,
    totalDuration: 10,
    totalDurationOnThisWeekday: 12,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '05:25'
  },
  {
    dateTime: '2019-09-01T11:40:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 13,
    baggageDuration: 6,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 12,
    totalDuration: 20,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '11:40'
  },
  {
    dateTime: '2019-09-02T10:00:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 10,
    baggageDuration: 6,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 17,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '10:00'
  },
  {
    dateTime: '2019-09-02T09:00:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 4,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 12,
    totalDuration: 7,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '09:00'
  },
  {
    dateTime: '2019-09-02T00:50:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 4,
    totalDuration: 6,
    totalDurationOnThisWeekday: 10,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '00:50'
  },
  {
    dateTime: '2019-09-02T08:00:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 14,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 6,
    totalDurationOnThisWeekday: 29,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '08:00'
  },
  {
    dateTime: '2019-09-01T20:55:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 5,
    baggageDuration: 9,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 15,
    totalDurationOnThisWeekday: 17,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '20:55'
  },
  {
    dateTime: '2019-09-02T05:30:00',
    weekday: 'Monday',
    gatesDuration: 5,
    immigrationDuration: 6,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 13,
    totalDurationOnThisWeekday: 15,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '05:30'
  },
  {
    dateTime: '2019-09-01T22:10:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 13,
    baggageDuration: 11,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 25,
    totalDurationOnThisWeekday: 21,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '22:10'
  },
  {
    dateTime: '2019-09-02T00:15:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 6,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 4,
    baggageDurationOnThisWeekday: 3,
    totalDuration: 9,
    totalDurationOnThisWeekday: 8,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '00:15'
  },
  {
    dateTime: '2019-09-02T03:35:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '03:35'
  },
  {
    dateTime: '2019-09-02T05:20:00',
    weekday: 'Monday',
    gatesDuration: 3,
    immigrationDuration: 2,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 4,
    totalDuration: 7,
    totalDurationOnThisWeekday: 11,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '05:20'
  },
  {
    dateTime: '2019-09-01T20:15:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 8,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 12,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '20:15'
  },
  {
    dateTime: '2019-09-01T21:00:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 6,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '21:00'
  },
  {
    dateTime: '2019-09-01T13:30:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '13:30'
  },
  {
    dateTime: '2019-09-01T15:00:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 15,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '15:00'
  },
  {
    dateTime: '2019-09-02T09:55:00',
    weekday: 'Monday',
    gatesDuration: 8,
    immigrationDuration: 7,
    baggageDuration: 6,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 12,
    totalDuration: 21,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '09:55'
  },
  {
    dateTime: '2019-09-01T22:00:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 6,
    baggageDuration: 9,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 16,
    totalDurationOnThisWeekday: 19,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '22:00'
  },
  {
    dateTime: '2019-09-02T08:35:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 9,
    baggageDuration: 11,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 21,
    totalDurationOnThisWeekday: 26,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '08:35'
  },
  {
    dateTime: '2019-09-01T12:40:00',
    weekday: 'Sunday',
    gatesDuration: 3,
    immigrationDuration: 7,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 12,
    totalDurationOnThisWeekday: 19,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '12:40'
  },
  {
    dateTime: '2019-09-02T05:15:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 3,
    totalDuration: 6,
    totalDurationOnThisWeekday: 9,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '05:15'
  },
  {
    dateTime: '2019-09-01T19:55:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 5,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 8,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '19:55'
  },
  {
    dateTime: '2019-09-01T11:20:00',
    weekday: 'Sunday',
    gatesDuration: 4,
    immigrationDuration: 8,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 14,
    totalDurationOnThisWeekday: 25,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '11:20'
  },
  {
    dateTime: '2019-09-01T14:55:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '14:55'
  },
  {
    dateTime: '2019-09-02T01:25:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '01:25'
  },
  {
    dateTime: '2019-09-01T22:20:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 10,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 6,
    totalDurationOnThisWeekday: 21,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '22:20'
  },
  {
    dateTime: '2019-09-02T08:50:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 17,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 21,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '08:50'
  },
  {
    dateTime: '2019-09-02T05:05:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 4,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 7,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '05:05'
  },
  {
    dateTime: '2019-09-02T03:15:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '03:15'
  },
  {
    dateTime: '2019-09-02T09:45:00',
    weekday: 'Monday',
    gatesDuration: 7,
    immigrationDuration: 10,
    baggageDuration: 4,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 21,
    totalDurationOnThisWeekday: 26,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '09:45'
  },
  {
    dateTime: '2019-09-01T23:50:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 8,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 12,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '23:50'
  },
  {
    dateTime: '2019-09-02T05:10:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 3,
    totalDuration: 6,
    totalDurationOnThisWeekday: 9,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '05:10'
  },
  {
    dateTime: '2019-09-01T23:40:00',
    weekday: 'Sunday',
    gatesDuration: 2,
    immigrationDuration: 5,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 9,
    totalDurationOnThisWeekday: 15,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '23:40'
  },
  {
    dateTime: '2019-09-02T01:40:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '01:40'
  },
  {
    dateTime: '2019-09-01T15:45:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '15:45'
  },
  {
    dateTime: '2019-09-02T09:50:00',
    weekday: 'Monday',
    gatesDuration: 7,
    immigrationDuration: 4,
    baggageDuration: 10,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 12,
    totalDuration: 21,
    totalDurationOnThisWeekday: 27,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '09:50'
  },
  {
    dateTime: '2019-09-01T11:50:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 20,
    baggageDuration: 6,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 10,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 27,
    totalDurationOnThisWeekday: 24,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '11:50'
  },
  {
    dateTime: '2019-09-01T13:40:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 12,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '13:40'
  },
  {
    dateTime: '2019-09-02T10:05:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 12,
    baggageDuration: 9,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 5,
    immigrationDurationOnThisWeekday: 12,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 22,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '10:05'
  },
  {
    dateTime: '2019-09-02T00:20:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 4,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 4,
    baggageDurationOnThisWeekday: 3,
    totalDuration: 8,
    totalDurationOnThisWeekday: 8,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '00:20'
  },
  {
    dateTime: '2019-09-01T12:35:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 8,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 6,
    totalDurationOnThisWeekday: 20,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '12:35'
  },
  {
    dateTime: '2019-09-01T18:30:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 8,
    totalDuration: 6,
    totalDurationOnThisWeekday: 19,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '18:30'
  },
  {
    dateTime: '2019-09-02T02:00:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '02:00'
  },
  {
    dateTime: '2019-09-02T04:50:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '04:50'
  },
  {
    dateTime: '2019-09-02T02:10:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '02:10'
  },
  {
    dateTime: '2019-09-02T00:40:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 5,
    baggageDurationOnThisWeekday: 4,
    totalDuration: 6,
    totalDurationOnThisWeekday: 10,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '00:40'
  },
  {
    dateTime: '2019-09-01T17:55:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 12,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 9,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 15,
    totalDurationOnThisWeekday: 18,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '17:55'
  },
  {
    dateTime: '2019-09-01T22:25:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 10,
    baggageDurationOnThisWeekday: 10,
    totalDuration: 6,
    totalDurationOnThisWeekday: 23,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '22:25'
  },
  {
    dateTime: '2019-09-02T04:10:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '04:10'
  },
  {
    dateTime: '2019-09-01T11:15:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 13,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 17,
    totalDurationOnThisWeekday: 26,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '11:15'
  },
  {
    dateTime: '2019-09-01T20:30:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '20:30'
  },
  {
    dateTime: '2019-09-01T20:45:00',
    weekday: 'Sunday',
    gatesDuration: 3,
    immigrationDuration: 5,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 7,
    baggageDurationOnThisWeekday: 7,
    totalDuration: 10,
    totalDurationOnThisWeekday: 17,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '20:45'
  },
  {
    dateTime: '2019-09-01T14:20:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 13,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '14:20'
  },
  {
    dateTime: '2019-09-02T03:45:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '03:45'
  },
  {
    dateTime: '2019-09-02T10:30:00',
    weekday: 'Monday',
    gatesDuration: 5,
    immigrationDuration: 3,
    baggageDuration: 13,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 14,
    baggageDurationOnThisWeekday: 12,
    totalDuration: 21,
    totalDurationOnThisWeekday: 30,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '10:30'
  },
  {
    dateTime: '2019-09-02T01:00:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 4,
    baggageDurationOnThisWeekday: 4,
    totalDuration: 6,
    totalDurationOnThisWeekday: 9,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '01:00'
  },
  {
    dateTime: '2019-09-02T00:00:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 5,
    totalDuration: 6,
    totalDurationOnThisWeekday: 12,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '00:00'
  },
  {
    dateTime: '2019-09-01T14:30:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '14:30'
  },
  {
    dateTime: '2019-09-01T14:25:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '14:25'
  },
  {
    dateTime: '2019-09-02T10:25:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 13,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 6,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '10:25'
  },
  {
    dateTime: '2019-09-02T07:45:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 11,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 13,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 15,
    totalDurationOnThisWeekday: 28,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '07:45'
  },
  {
    dateTime: '2019-09-02T03:10:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '03:10'
  },
  {
    dateTime: '2019-09-02T06:20:00',
    weekday: 'Monday',
    gatesDuration: 9,
    immigrationDuration: 3,
    baggageDuration: 25,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 3,
    immigrationDurationOnThisWeekday: 10,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 37,
    totalDurationOnThisWeekday: 22,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '06:20'
  },
  {
    dateTime: '2019-09-01T23:55:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 11,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 15,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '23:55'
  },
  {
    dateTime: '2019-09-01T15:05:00',
    weekday: 'Sunday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 2,
    immigrationDurationOnThisWeekday: 6,
    baggageDurationOnThisWeekday: 6,
    totalDuration: 6,
    totalDurationOnThisWeekday: 14,
    totalDurationLastYear: 0,
    dateId: 20190901,
    time: '15:05'
  },
  {
    dateTime: '2019-09-02T07:20:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 8,
    baggageDuration: 7,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 14,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 16,
    totalDurationOnThisWeekday: 29,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '07:20'
  },
  {
    dateTime: '2019-09-02T11:00:00',
    weekday: 'Monday',
    gatesDuration: 5,
    immigrationDuration: 6,
    baggageDuration: 6,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 11,
    totalDuration: 17,
    totalDurationOnThisWeekday: 26,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '11:00'
  },
  {
    dateTime: '2019-09-02T02:30:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 3,
    baggageDuration: 2,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 1,
    immigrationDurationOnThisWeekday: 3,
    baggageDurationOnThisWeekday: 2,
    totalDuration: 6,
    totalDurationOnThisWeekday: 6,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '02:30'
  },
  {
    dateTime: '2019-09-02T06:30:00',
    weekday: 'Monday',
    gatesDuration: 1,
    immigrationDuration: 19,
    baggageDuration: 12,
    gatesTarget: 10,
    immigrationTarget: 20,
    baggageTarget: 15,
    gatesDurationOnThisWeekday: 4,
    immigrationDurationOnThisWeekday: 11,
    baggageDurationOnThisWeekday: 9,
    totalDuration: 32,
    totalDurationOnThisWeekday: 24,
    totalDurationLastYear: 0,
    dateId: 20190902,
    time: '06:30'
  }
];

export default mockData;
