import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import SecondarySideBarNavigationItem from './SecondarySideBarNavigationItem';

const SecondarySideBarNavigation = (props) => {
  const {
    navigationItems,
    handleSecondaryItemClick,
    activeSecondaryItem,
    handleSecondaryMenuHover,
    hideMenu
  } = props;

  return (
    <div
      onMouseEnter={() => handleSecondaryMenuHover()}
      onMouseLeave={() => hideMenu()}
      className={cn('edp-nav-side-bar__secondary-container')}
    >
      {navigationItems.map((child, i) => {
        return (
          <SecondarySideBarNavigationItem
            key={i}
            child={child}
            handleSecondaryItemClick={handleSecondaryItemClick}
            hideMenu={hideMenu}
            activeSecondaryItem={activeSecondaryItem}
          />
        );
      })}
    </div>
  );
};

SecondarySideBarNavigation.propTypes = {
  navigationItems: PropTypes.array.isRequired,
  handleSecondaryItemClick: PropTypes.func.isRequired,
  activeSecondaryItem: PropTypes.object,
  handleSecondaryMenuHover: PropTypes.func.isRequired,
  hideMenu: PropTypes.func.isRequired
};

export default SecondarySideBarNavigation;
