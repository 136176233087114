// Markdown files
// user guides
import gettingStarted from '../pages/UserGuides/GettingStarted';
import intArrivalsLive from '../pages/UserGuides/IntArrivals/Live';
import intArrivalsPeak from '../pages/UserGuides/IntArrivals/Peak';
import ioc7DayPlan from '../pages/UserGuides/IOC/7 Day Plan';
import ioc7DayPlanExternal from '../pages/UserGuides/IOC/7 Day Plan - External';
import iocDrilldowns from '../pages/UserGuides/IOC/Terminals';
import iocOverview from '../pages/UserGuides/IOC/Overview';
import gtOpsOverview from '../pages/UserGuides/LAOps/Overview';
import gtOpsCustomerService from '../pages/UserGuides/LAOps/CustomerService';
import gtOpsRoadAndCarPark from '../pages/UserGuides/LAOps/RoadAndCarPark';
import gtOps7DFS from '../pages/UserGuides/LAOps/7DayForecastSummary';
import gtOpsCarParkMovements from '../pages/UserGuides/LAOps/CarParkMovements';
import teHappyFace from '../pages/UserGuides/TE/HappyFace';
import fmCleaningSchedules from '../pages/UserGuides/FM/CleaningSchedules';
import gtCommForwardBookings from '../pages/UserGuides/GTComms/ForwardBookings';
import gtCommRevenueDetails from '../pages/UserGuides/GTComms/RevenueDetails';
import gtCommRevenueSummary from '../pages/UserGuides/GTComms/RevenueSummary';
import glossary from '../pages/UserGuides/Glossary';

const importAll = (r) => {
  return r
    .keys()
    .filter((f) => {
      const regex = /^(?!.*stories\.js).*\.js$/;
      return regex.test(f);
    })
    .map((f) => {
      return {
        name: f.replace('./', '').replace('.js', ''),
        markdown: r(f).default
      };
    })
    .sort()
    .reverse();
};

const files = importAll(
  require.context('../pages/ReleaseNotes', true, /\.js$/)
);

const helpItems = [
  {
    categoryName: 'User Guides',
    items: [
      {
        name: 'Getting started',
        markdown: gettingStarted
      },
      {
        name: 'Glossary',
        markdown: glossary
      },
      {
        name: 'Int Arrivals - Live',
        markdown: intArrivalsLive
      },
      {
        name: 'Int Arrivals - Peak',
        markdown: intArrivalsPeak
      },
      {
        name: 'IOC - Overview',
        markdown: iocOverview
      },
      {
        name: 'IOC - Terminals',
        markdown: iocDrilldowns
      },
      {
        name: 'IOC - 7 Day Plan',
        markdown: ioc7DayPlan
      },
      {
        name: 'IOC - 7 Day Plan External',
        markdown: ioc7DayPlanExternal
      },
      {
        name: 'FM - Cleaning Schedules',
        markdown: fmCleaningSchedules
      },
      {
        name: 'GT Comms - Revenue Summary',
        markdown: gtCommRevenueSummary
      },
      {
        name: 'GT Comms - Revenue Details',
        markdown: gtCommRevenueDetails
      },
      {
        name: 'GT Comms - Forward Bookings',
        markdown: gtCommForwardBookings
      },
      {
        name: 'GT Ops - Overview',
        markdown: gtOpsOverview
      },
      {
        name: 'GT Ops - Road and Car Park',
        markdown: gtOpsRoadAndCarPark
      },
      {
        name: 'GT Ops - Customer Service',
        markdown: gtOpsCustomerService
      },
      {
        name: 'GT Ops - 7 Day Forecast Summary',
        markdown: gtOps7DFS
      },
      {
        name: 'GT Ops - Car Park Movements',
        markdown: gtOpsCarParkMovements
      },      
      {
        name: 'TE - Happy Face',
        markdown: teHappyFace
      }
    ]
  },
  {
    categoryName: 'Release Notes',
    items: files
  }
];

export default helpItems;
