import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { momentDateFormat, clockTimeFormat } from '../../../constants';
import NotificationIcon from './NotificationIcon';
import NotificationActions from './NotificationActions';
import PropTypes from 'prop-types';

const EARLY = 'Early';
const DELAYED = 'Delay';
const CANCELLED = 'Cancelled';

/* Returns the formatted ST/ET date and time for display */
const getFormattedDate = dateTime => {
  let formattedDateTime = {
    date: dateTime ? moment(dateTime).format(momentDateFormat) : '-',
    time: dateTime ? moment(dateTime).format(clockTimeFormat) : '-'
  };
  return formattedDateTime;
};

/* Returns the difference between the ST and ET */
const getDuration = (st, et) => {
  let diff = moment(st).diff(et, 'minutes');
  if (diff < 0) diff = -diff;
  return `${diff} mins`;
};

const AMSNotification = props => {
  let { data, isUnread, showSave, showClose } = props;
  let customAttributes = data.customAttributes;
  let formattedSt, formattedEt, duration;
  formattedSt = getFormattedDate(customAttributes.st);
  if (customAttributes.status !== CANCELLED) {
    formattedEt = getFormattedDate(customAttributes.et);
  }
  if (
    customAttributes.status === EARLY ||
    customAttributes.status === DELAYED
  ) {
    duration = getDuration(customAttributes.st, customAttributes.et);
  }

  return (
    <div
      className={cn(
        'edp-notification',
        'edp-notification--ams',
        data.status.toLowerCase(),
        isUnread && 'is-unread'
      )}
    >
      <div
        className={cn('edp-notification__highlight', data.status.toLowerCase())}
      >
        &nbsp;
      </div>

      <div className="edp-notification__icon-section">
        {data.iconUrl ? <NotificationIcon iconUrl={data.iconUrl} /> : null}
      </div>

      {customAttributes && (
        <div className="edp-notification__body">
          <div className="edp-notification__title font-weight-bold">
            {customAttributes.flight} {customAttributes.status} {duration}
          </div>
          <div className="edp-notification__content">
            <div className="edp-notification--ams__time">
              <div className="type">ST{customAttributes.direction[0]}</div>
              <div className="date">{formattedSt.date}</div>
              <div className="time">{formattedSt.time}</div>
            </div>
            {customAttributes.status !== CANCELLED && (
              <>
                <div className="edp-notification--ams__time-divide">—</div>
                <div className="edp-notification--ams__time">
                  <div className="type">ET{customAttributes.direction[0]}</div>
                  <div className="date">{formattedEt.date}</div>
                  <div className="time">{formattedEt.time}</div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <NotificationActions
        date={data.date}
        showSave={showSave}
        showClose={showClose}
      />
    </div>
  );
};

AMSNotification.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    message: PropTypes.array,
    iconUrl: PropTypes.string,
    customAttributes: PropTypes.shape({
      terminal: PropTypes.string,
      direction: PropTypes.string.isRequired,
      flight: PropTypes.string.isRequired,
      st: PropTypes.string.isRequired,
      et: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  isUnread: PropTypes.bool,
  showClose: PropTypes.bool,
  showSave: PropTypes.bool
};

export default AMSNotification;
