import laOpsRoadAndCarParkImageUrl from '../../../images/laOpsRoadAndCarPark.png';

export default `
# GT Ops – Road and Car Park
## Board Overview
This board is a more detailed view of the road and car park information presented on the LA Ops Overview dashboard.

This information is displayed in three different cards;

![LA Ops Road and Car Park](${laOpsRoadAndCarParkImageUrl})

**Card 1** - Travel times  
**Card 2** - KPI  
**Card 3** - Car park entry/exit - hourly  

## Card 1 - Travel Times
A view of the current and historic travel time for the Pick Up, Drop Off and Airport Drive routes. 
The Today toggle shows up to the previous 24 hours from the current time. There are 3 lines plotted on this graph:

1. Today: This is the line that represents todays travel time.
2. Average: is the average time taken at that time on that day every week for the previous year.
3. Target: This line is a straight-line set at 15 minutes for each route.

The 'This Week' toggle shows the travel time for the previous 8 days. Each line on this graph will represent a date so you can compare todays performance with the previous days. 

The data source is TOM-TOM and the card updates every 15 minutes.

## Card 2 - KPI
This card presents a view of the percentage of time the internal road travel times is below 16 minutes for each route. A 24 hour and morning peak (06:00 - 10:00) view is presented. The card has four toggles which allow you to switch between different time frames: 

- This week - Starting from Monday to now.
- Last Week - Measured from Monday to Sunday.
- This month - Measured from the start of the Month until now.
- Last month - Measured from the start of last month to end of last month.  

There is a red, amber or green status on each donut to indicate performance against the KPI;   

- Red: 0 – 89%  
- Amber: 90% - 95%  
- Green: 95% – 100%

The data source is TOM-TOM and the card updates every 15 minutes.  

## Card 3 - Car park entry/exit
This card shows the number of vehicles in and out for each car park over a 24-hour period. You can toggle between the different carparks to view the transactions for each respective car park. The total entries (in), exits (out) and the net difference is displayed.
The data source is SkiData and the card updates every 5 minutes.
`.trim();
