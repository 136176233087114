import { getToken } from '../assets/js/config/adalConfig';
import jwtDecode from 'jwt-decode';

export const fetchUserInfo = () => {
  let userToken = getToken();
  if (userToken !== null) {
    const tokenPayload = jwtDecode(userToken);
    const fullName = tokenPayload.given_name + ' ' + tokenPayload.family_name;
    const email = tokenPayload.upn;
    return {
      name: fullName,
      email: email
    };
  } else {
    return null;
  }
};

export const setGAUserIdentifier = () => {
  const userInfo = fetchUserInfo();
  if (userInfo) {
    if (
      window.dataLayer === undefined ||
      window.dataLayer === null ||
      window.dataLayer.filter(item => item.user_id === userInfo.email)
        .length === 0
    ) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'set_user_id',
        user_id: userInfo.email
      });
    }
  }
};
