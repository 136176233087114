import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// a React function component that will execute a callback from props upon click outside the element
function OutsideClick(props) {
  const { callback, children, otherRefs } = props;
  const wrapperRef = useRef(null);

  // execute callback function if clicked outside of element
  function handleClickOutside(event) {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      (!otherRefs ||
        otherRefs.every(ref => {
          return ref.current ? !ref.current.contains(event.target) : false;
        })) // if there are other refs supplied, ensure that the event target is not contained within any of those refs
    ) {
      callback();
    }
  }

  useEffect(() => {
    // bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return <div ref={wrapperRef}>{children}</div>;
}

OutsideClick.defaultProps = {
  otherRefs: null
};

OutsideClick.propTypes = {
  callback: PropTypes.func.isRequired,
  otherRefs: PropTypes.array,
  children: PropTypes.element.isRequired
};

export default OutsideClick;
