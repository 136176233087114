export default `
# Release Notes
## v6.8.0
09/06/22

### What's New

Feature

* GT Ops 7 Day Forecast Summary - Car Park Movements Table

`;
