import React from 'react';
import pageConfig from '../../../assets/config/page-config';

const seperator = (
  <span style={{ margin: '0 15px' }} className="noselect">
    /
  </span>
);

const NavigationBreadCrumb = () => {
  const currentPage = window.location.pathname;
  const urlSections = currentPage.split('/');
  const primaryItem = urlSections[1];
  const secondaryItem = urlSections[2];
  const tertiaryItem = urlSections[3];

  const pageObject = pageConfig.filter(
    page => page.ruleName === primaryItem
  )[0];
  const primaryName = pageObject ? pageObject.name : null;
  const fullRuleName = primaryItem + ':' + secondaryItem;
  const secondaryPageObject = pageObject
    ? pageObject.navChildren.filter(child => child.ruleName === fullRuleName)[0]
    : null;
  const secondaryName = secondaryPageObject ? secondaryPageObject.name : null;
  const tertiaryName =
    secondaryPageObject && secondaryPageObject.children
      ? secondaryPageObject.children.filter(child =>
          child.to.includes(tertiaryItem)
        )[0].name
      : null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
      className={'edp-navbar__bread-crumb'}
    >
      <span style={{ color: 'white', marginLeft: '2.3em' }}>
        {primaryItem && <span>{primaryName}</span>}
        {secondaryItem && (
          <>
            {seperator}
            <span>{secondaryName}</span>
          </>
        )}
        {tertiaryItem && (
          <>
            {seperator}
            <span>{tertiaryName}</span>
          </>
        )}
      </span>
    </div>
  );
};

export default NavigationBreadCrumb;
