import startsWith from 'lodash.startswith';
import isUndefined from 'lodash.isundefined';
import isFunction from 'lodash.isfunction';
import omitBy from 'lodash.omitby';
import curryRight from 'lodash.curryright';
import identity from 'lodash.identity';
import fetch from 'cross-fetch';
import { adalApiFetch } from '../../assets/js/config/adalConfig';

export const CALL_TOKEN_API = '@@CALL_TOKEN_API';

const NotImplemented = function(message) {
  this.name = 'NotImplemented';
  this.message = message || 'Method not implemented';
  this.stack = new Error().stack;
};
NotImplemented.prototype = Object.create(Error.prototype);
NotImplemented.prototype.constructor = NotImplemented;

function checkResponseIsOk(response) {
  return response.ok
    ? response
    : response.text().then(text => {
        return Promise.reject(text);
      });
}

async function responseToCompletion(response) {
  const contentType = response.headers.get('Content-Type');
  if (contentType && startsWith(contentType, 'application/json')) {
    const text = await response.text();
    try {
      return JSON.parse(text);
    } catch (e) {
      return text;
    }
  }
  return response.text();
}

function createAsyncAction(type, step, payload, meta = {}) {
  const action = {
    type: `${type}_${step}`,
    payload,
    meta: Object.assign(meta, { asyncStep: step })
  };
  if (payload && payload instanceof Error) {
    Object.assign(action.meta, {
      error: true
    });
  }
  return action;
}

function createStartAction(type, payload) {
  return createAsyncAction(type, 'START', payload);
}

function createCompletionAction(type, payload, meta) {
  return createAsyncAction(type, 'COMPLETED', payload, meta);
}

function createFailureAction(type, error) {
  return createAsyncAction(type, 'FAILED', new TypeError(error));
}

export function createResponseHandlerWithMeta(meta) {
  const baseHandler = meta.responseHandler || identity;
  const handlerToCurry = (response, meta) => baseHandler(response, meta);
  return curryRight(handlerToCurry)(meta);
}

export class TokenApiService {
  constructor(apiAction, dispatch, config = {}) {
    this.apiAction = apiAction;
    this.meta = this.apiAction.meta || {};
    this.dispatch = dispatch;
    this.config = config;

    this.defaultHeaders = this.config.defaultHeaders || {
      'Content-Type': 'application/json'
    };
    this.checkResponseIsOk = this.configOrDefault('checkResponseIsOk');
    this.actionKey = this.config.actionKey || CALL_TOKEN_API;
    this.preProcessRequest = this.config.preProcessRequest;
  }

  configOrDefault(key) {
    return this.config[key] || this.defaultMethods[key];
  }

  configOrNotImplemented(key) {
    const method = this.config[key];
    if (!method) {
      throw new NotImplemented(`Please provide ${key} in config`);
    }
    return method;
  }

  get defaultMethods() {
    return {
      catchApiRequestError: this.defaultCatchApiRequestError,
      checkResponseIsOk
    };
  }

  completeApiRequest(type, finalResponse) {
    this.dispatch(createCompletionAction(type, finalResponse, this.meta));
    return finalResponse;
  }

  defaultCatchApiRequestError(type, error) {
    return error;
  }

  catchApiRequestError(type, error) {
    const fn = this.configOrDefault('catchApiRequestError');
    this.dispatch(createFailureAction(type, error));
    return fn(type, error);
  }

  preserveHeaderValues(meta, response) {
    const headersToPreserve = meta.preserveHeaders;
    if (Array.isArray(headersToPreserve)) {
      const responseHeaders = response.headers;
      const preservedHeaders = headersToPreserve.reduce((headers, header) => {
        headers[header] = responseHeaders.get(header);
        return headers;
      }, {});
      meta.preservedHeaders = preservedHeaders;
    }
    return response;
  }

  apiRequest(fetchArgs, action) {
    const meta = action.meta || {};
    const completeApiRequest = this.completeApiRequest.bind(this, action.type);
    const catchApiRequestError = this.catchApiRequestError.bind(
      this,
      action.type
    );
    const preserveHeaderValues = this.preserveHeaderValues.bind(
      this,
      this.meta
    );
    return adalApiFetch(fetch, fetchArgs[0], fetchArgs[1])
      .then(this.checkResponseIsOk)
      .then(preserveHeaderValues)
      .then(responseToCompletion)
      .then(createResponseHandlerWithMeta(meta))
      .then(completeApiRequest)
      .catch(catchApiRequestError);
  }

  // This is called when an API request is made
  apiCallFromAction(action) {
    const apiFetchArgs = this.getApiFetchArgsFromActionPayload(action.payload);
    this.dispatch(createStartAction(action.type, action.payload));
    return this.apiRequest(apiFetchArgs, action, this.store);
  }

  get apiCallMethod() {
    return this.apiCallFromAction;
  }

  get curriedApiCallMethod() {
    return this.apiCallMethod.bind(this, this.apiAction);
  }

  getApiFetchArgsFromActionPayload(payload, token = null, authenticate = true) {
    let { headers, endpoint, method, body, credentials } = payload;
    if (isUndefined(method)) {
      method = 'GET';
    }

    headers = Object.assign(this.defaultHeaders, headers);
    if (token && authenticate) {
      ({ headers, endpoint, body } = this.addTokenToRequest(
        headers,
        endpoint,
        body,
        token
      ));
    }
    if (isFunction(this.preProcessRequest)) {
      ({ headers, endpoint, body } = this.preProcessRequest(
        headers,
        endpoint,
        body
      ));
    }

    return [
      endpoint,
      omitBy({ method, body, credentials, headers }, isUndefined)
    ];
  }

  async call() {
    return this.curriedApiCallMethod();
  }
}

export function createApiAction(action) {
  return {
    [CALL_TOKEN_API]: action
  };
}

export function createTokenApiMiddleware(config = {}) {
  return store => next => async action => {
    let apiAction = action[config.actionKey];

    if (apiAction === undefined) {
      if (action[CALL_TOKEN_API] === undefined) {
        return next(action);
      }
      apiAction = action[CALL_TOKEN_API];
    }

    const tokenApiService = new TokenApiService(
      apiAction,
      store.dispatch,
      config
    );

    return await tokenApiService.call();
  };
}
