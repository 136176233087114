import React, { useMemo, useEffect, useState } from 'react';
import EnhancedMaterialUITable from './EnhancedMaterialUITable/EnhancedMaterialUITable';
import PropTypes from 'prop-types';

const UserPermissionsTable = props => {
  const {
    data,
    unit,
    addDialog,
    allowRemove,
    onRowClick,
    tableHeaders
  } = props;

  const columns = useMemo(() => tableHeaders, [tableHeaders]);

  const [tableData, setTableData] = useState(data);
  const [skipPageReset, setSkipPageReset] = useState(false);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setTableData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <div>
      <EnhancedMaterialUITable
        columns={columns}
        data={tableData}
        unit={unit}
        setData={setTableData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        addDialog={addDialog}
        allowRemove={allowRemove}
        onRowClick={onRowClick}
        checkboxesEnabled={false}
      />
    </div>
  );
};

UserPermissionsTable.defaultProps = {
  allowAdd: false,
  allowRemove: false,
  data: [],
  loading: false,
  error: false
};

UserPermissionsTable.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  tableHeaders: PropTypes.array.isRequired,
  unit: PropTypes.string.isRequired,
  onRowClick: PropTypes.func,
  allowRemove: PropTypes.bool,
  addDialog: PropTypes.func
};

export default UserPermissionsTable;
