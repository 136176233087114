import React from 'react';
import cn from 'classnames';
import NotificationIcon from './NotificationIcon';
import NotificationActions from './NotificationActions';
import PropTypes from 'prop-types';
import getHistory from 'react-router-global-history';

const TomTomNotification = (props) => {
  let { data, isUnread, showSave, showClose } = props;

  return (
    <div
      className={cn(
        'edp-notification',
        'edp-notification--tomtom',
        data.status.toLowerCase(),
        isUnread && 'is-unread'
      )}
      onClick={() => {
        getHistory().push(`/ioc/overview`);
      }}
    >
      <div
        className={cn('edp-notification__highlight', data.status.toLowerCase())}
      >
        &nbsp;
      </div>

      <div className="edp-notification__icon-section">
        {data.iconUrl ? <NotificationIcon iconUrl={data.iconUrl} /> : null}
      </div>

      <div className="edp-notification__body">
        <div className="edp-notification__title font-weight-bold">
          {data.title}
        </div>
        <div className="edp-notification__content font-weight-bold text-dark">
          {data.message !== null &&
            Array.isArray(data.message) &&
            data.message.map((text, i) => {
              return <div key={i} dangerouslySetInnerHTML={{ __html: text }} />;
            })}
        </div>
      </div>

      <NotificationActions
        date={data.date}
        showSave={showSave}
        showClose={showClose}
      />
    </div>
  );
};

TomTomNotification.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.string.isRequired,
    date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    message: PropTypes.array,
    iconUrl: PropTypes.string,
    title: PropTypes.string
  }).isRequired,
  isUnread: PropTypes.bool,
  showClose: PropTypes.bool,
  showSave: PropTypes.bool
};

export default TomTomNotification;
