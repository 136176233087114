export default `
# Release Notes
## v4.1.0
16/02/22

### What's New

Bug Fix 
* Added estimatedDateTime to the filtering of data to be used in the 7 Day Plan:
    * PAX trendline chart to fix the blank page issue on some dates.

* Fix Aviation BD dashboard report, showing delayed numbers configured using Powerbi timeout:
    * Set refresh timeout to 180 minute

* Fix GTComm data factory refresh sequence:
    * Adjust timeout for Until wait activity

`