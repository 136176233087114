export default `
# Release Notes
## v6.10.0
23/06/22

### What's New

* Revenue Details - FY19 Bookmarks
    * Add simple comparison bookmarks:
        - Same day of week vs. FY19
        - Same date vs. FY19
* Update on the Revenue / Transactions / ATV line graphs
* Update on the Time bands / Customer Segments graphs
* Update on Performance Metrics by Car Park / Channel tables
* Bugfixes for:
    * Missing peak occupancy var (abs) and var (%) columns on the tables (SD / SDOW)
    * Inaccurate tooltips and inconsistent width
    * Additional unnecessary bookmark acronyms added in the visual titles
    * Incorrect value format of Rev Var (Abs) to K and Txn Var (abs) without decimals
    * Inaccurate values being displayed on the dashboard (SD / SDOW)

* Cleaning Schedules Enhancements
    * PAX Presentation - Adjust Negative Response Indicators' Threshold to 3
    * Gate Proximity Recategorization of T2 Assets

* GT Comms Revenue Details/Forward Bookings Visual Issue
    * Specify Date Range visuals and measures are still working as expected after some changes on the reports

* GT Ops 7 Day Forecast Summary: UI Enhancements
    * Switched Arrivals and Departures columns
    * Changed Net Movements formula to Arrivals minus Departures
    * Updated Car Park Type labels:
        - Terminal T123 to T123
        - Terminal T4 to T4
        - Premium T123 to P123
        - Premium T4 to P4
`;
