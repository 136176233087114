import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import cn from 'classnames';
import { ReactComponent as X } from '../../../assets/img/close-x-light.svg';

let CustomModal = props => {
  return (
    <Modal
      className={cn(
        'edp-custom-modal',
        { default: !props.className },
        props.className
      )}
      isOpen={!props.hideAlert}
      toggle={props.toggleAlert}
      centered={true}
    >
      {props.title && props.icon && props.iconAlt && (
        <ModalHeader style={modalHeaderStyling}>
          <img style={modalIconStyling} alt={props.iconAlt} src={props.icon} />
          {props.title}
        </ModalHeader>
      )}
      <ModalBody>
        {props.showCloseIcon && (
          <X
            className={cn(
              'edp-support-form__icon-button',
              'edp-tooltip-modal__button'
            )}
            onClick={props.toggleAlert}
          />
        )}
        {props.content}
      </ModalBody>
      {props.buttonContent && (
        <ModalFooter>
          <button
            color="primary"
            className={cn('edp-button', 'gold-button')}
            onClick={props.toggleAlert}
          >
            {props.buttonContent}
          </button>
        </ModalFooter>
      )}
    </Modal>
  );
};

const modalIconStyling = {
  width: '80px',
  display: 'block',
  margin: '0 auto',
  paddingBottom: '10px'
};

const modalHeaderStyling = {
  width: '100%',
  display: 'block',
  margin: '0 auto',
  textAlign: 'center'
};

export default CustomModal;
