import { runWithAdal } from 'react-adal';
import { authContext } from './assets/js/config/adalConfig';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import remote from './plugins/loglevel-redux-remote';
import logNotification from './plugins/loglevel-notification';
import log from 'loglevel';

const DO_NOT_LOGIN = true;

runWithAdal(
  authContext,
  () => {
    require('./indexApp.js');
    remote.apply(log);
    logNotification.apply(log);
    log.enableAll();
  },
  DO_NOT_LOGIN
);
