import React from 'react';
import Helmet from 'react-helmet';

/* this component is used in any components that need to set properties in the header tag. 
Currently this only affects the page title, but can be could include meta tags etc. in future if required */

const Header = props => {
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      {props.children}
    </>
  );
};

export default Header;
