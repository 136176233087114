import * as intArrivalsActionTypes from '../actionTypes/intArrivalsActionTypes';
import updateObject from '../utility';
import { signalrMethods } from '../../constants';

const initialState = {
  intArrivalsTransitTimes: null
};

const isResponseValid = payload => {
  return payload !== undefined && Array.isArray(payload) && payload.length > 0;
};

const intArrivalsTransitTimes = (state, payload) => {
  if (isResponseValid(payload)) {
    return updateObject(state, {
      intArrivalsTransitTimes: payload
    });
  }
  return state;
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case intArrivalsActionTypes.INT_ARRIVALS_PAYLOAD_COMPLETED:
      return intArrivalsTransitTimes(
        state,
        action.payload[signalrMethods.intArrivalsLive.payloadKey]
      );
    case intArrivalsActionTypes.INT_ARRIVALS_GET_TRANSIT_TIMES_LIVE_COMPLETED:
      return intArrivalsTransitTimes(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
