import React, { Component } from 'react';
import {
  DropdownMenu,
  ButtonDropdown,
  DropdownToggle,
  NavItem,
  NavLink,
  DropdownItem
} from 'reactstrap';
import cn from 'classnames';
import { authContext } from '../../../../assets/js/config/adalConfig';
import Avatar from '../../../Utils/Avatar/Avatar';
import { loginRedirectUrl } from '../../../../constants';
import { ReactComponent as SignOutIcon } from '../../../../assets/img/sign-out.svg';

const iconStyle = {
  marginRight: '5px',
  marginTop: '-2px'
};

const children = [
  {
    name: 'Sign out',
    exact: true,
    icon: <SignOutIcon style={iconStyle} />,
    onClick: () => {
      authContext.logOut();
    }
  }
];

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ showDropdown: !this.state.showDropdown });
  }

  render() {
    let { isAuth, userInitials } = this.props;
    let { showDropdown } = this.state;
    let showChildren = isAuth;

    return (
      <ButtonDropdown
        toggle={this.toggle}
        isOpen={showDropdown}
        direction="down"
      >
        <DropdownToggle tag="span" data-toggle="dropdown">
          <NavItem className="noselect nav-item--dark">
            {!isAuth ? (
              <NavLink className="nav__link" href={loginRedirectUrl}>
                Login
              </NavLink>
            ) : (
              <NavLink>
                <Avatar avatarDisabled={false} intials={userInitials} />
              </NavLink>
            )}
          </NavItem>
        </DropdownToggle>
        {showChildren ? (
          <DropdownMenu
            className={cn('sign-out-dropdown', 'edp-navbar__dropdown-menu')}
          >
            {children.map((child, index) => {
              return (
                <DropdownItem
                  key={index}
                  to={child.to}
                  exact={child.exact.toString()}
                  onClick={child.onClick}
                  className={cn('edp-navbar__dropdown-item', 'nav__signout')}
                >
                  {child.icon}
                  {child.name}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        ) : null}
      </ButtonDropdown>
    );
  }
}

Profile.displayName = 'Profile';
export default Profile;
