import React from 'react';

const GroupCell = cellInfo => {
  const groupName = cellInfo.cell.value; // a single group name
  return (
    <span className={'edp-user-permissions__table-cell--group'}>
      {groupName}
    </span>
  );
};

export default GroupCell;
