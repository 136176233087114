export default `
# Release Notes
## v5.0.0
24/02/22

### Enhancement

* BED - Feed FactFlightPaxCapacity with new fields. 
    * Update the AMS datashare fact table (ams.FactFlightPaxCapacity) schema to handle to new columns. 
    * Update the stored procedure filling the Fact to bring through the new columns from [stg_ams].[AMSFlightPaxCapacity. 
 

* SITA MT11. 
    * Transform Raw SITA (MT11) data in Databricks and writes the raw data to Blob Storage. 
    * Stage Curated SITA (MT11) data in SQL Database, writes the raw data from blob to SQL SQLDB. 
    * Orchestration of SITA (MT11) End-to-End. Pipeline that orchestrates the execution MT11 data from source going to Blob Storage. 
    * Transform Staged SITA (MT11) data in SQL Database. Replace NVARCHAR(4000)to appropriate schema.  
 

* BED - Logic for AMS over ACA and Sabre 
    * AMS Logic to handle: 
        - T1 = +4 days 
        - T2 = + 7 days 
        - T3 = +7 days 

    * T4: 
        - JQ = +4 days 
        - ZL = + 2 days 
        - Others = +3 days 

`