import React, { Component } from 'react';
import { connect } from 'react-redux';
import withAuth from '../../../hoc/withAuth/withAuth';
import Loading from './Loading';
import { Redirect } from 'react-router-dom';
import { isObjectEmpty, homepageUrl } from '../../../assets/js/config/pages';

class EDPLoading extends Component {
  render() {
    let { userPermissions } = this.props;
    let permissionsLoaded = !isObjectEmpty(userPermissions);
    let url = permissionsLoaded ? homepageUrl(userPermissions) : '/error';
    return permissionsLoaded ? <Redirect to={url} /> : <Loading light />;
  }
}

const mapStateToProps = state => {
  return {
    userPermissions: state.withAuth.userPermissions
  };
};

EDPLoading.displayName = 'EDPLoading';
export default connect(mapStateToProps)(withAuth(EDPLoading));
