import React from 'react';
import cn from 'classnames';
import NotificationIcon from './NotificationIcon';
import NotificationActions from './NotificationActions';
import PropTypes from 'prop-types';
import moment from 'moment';
import { clockTimeFormat } from '../../../constants';
import getHistory from 'react-router-global-history';

const FMHappyFaceNotification = (props) => {
  let { data, isUnread, showSave, showClose } = props;
  let customAttributes = data.customAttributes;

  const TIME_BLOCK_INTERVAL = 15;
  const timeBlock = moment(customAttributes.timeBlock, 'YYYY-MM-DD HH:mm:ss');

  const negativeResponseTimeBlock = `${timeBlock.format(
    'DD MMM'
  )} (${timeBlock.format(clockTimeFormat)} - ${timeBlock
    .add(TIME_BLOCK_INTERVAL, 'minute')
    .subtract('1', 'minute')
    .format(clockTimeFormat)})`;

  return (
    <div
      className={cn(
        'edp-notification',
        'edp-notification--happy-face',
        data.status.toLowerCase(),
        isUnread && 'is-unread'
      )}
      onClick={() => {
        getHistory().push({
          pathname: `/fm/cleaningschedules`,
          terminal: customAttributes.terminal
        });
      }}
      style={{ height: '155px' }} // inline custom height for FM notification
    >
      <div
        className={cn('edp-notification__highlight', data.status.toLowerCase())}
      >
        &nbsp;
      </div>

      <div className="edp-notification__icon-section">
        {data.iconUrl ? <NotificationIcon iconUrl={data.iconUrl} /> : null}
      </div>

      {customAttributes && (
        <div className="edp-notification__body">
          <div
            className="edp-notification__title"
            style={{ fontSize: '0.75rem', lineHeight: '1rem', color: '#000' }}
          >
            <span
              style={{
                fontSize: '0.9rem',
                fontWeight: 'bold'
              }}
            >
              Negative Response Threshold Reached
            </span>
            <br />
            <br />
            {customAttributes.terminal}
            {/* {customAttributes.terminal} - {customAttributes.direction || null} */}
            <br />
            {customAttributes.type || 'Amenities'}
            <br />
            {negativeResponseTimeBlock}
          </div>
          <div className="edp-notification__content font-weight-bold text-dark">{`${
            customAttributes.responseCount
          } Negative responses`}</div>
        </div>
      )}

      <NotificationActions
        date={data.date}
        showSave={showSave}
        showClose={showClose}
      />
    </div>
  );
};

FMHappyFaceNotification.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.string.isRequired,
    date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    message: PropTypes.array,
    iconUrl: PropTypes.string,
    customAttributes: PropTypes.shape({
      terminal: PropTypes.string.isRequired,
      direction: PropTypes.string,
      responseCount: PropTypes.string.isRequired,
      timeBlock: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  isUnread: PropTypes.bool,
  showClose: PropTypes.bool,
  showSave: PropTypes.bool
};

export default FMHappyFaceNotification;
