export default `
# Release Notes
## v2.2.0
05/09/19

### Enhancement

* Improved Live Board:
    * The International arrivals live board has been rebuilt in Highcharts. This allows a greater level of functionality, such as being able to filter by zone, adjust the timeframe and view the state of the live board at a previous date and time using a date picker. 

    * Notification Improvements:
        - The notifications feature has been improved with the addition of Beontra notifications. A Beontra notification will be sent when the forecast PAX numbers for a timeslot increases from the baseline forecast taken at 5am. The colour of the notification is determined as
        - Red = Pax increase greater than 250 
        - Amber = Pax Increase between 100 and 250 
        - Green = Pax Difference less than 100 and a previous notification has been sent for the timeslot. 

    * AMS Notifications can now display an icon depending on the direction of travel.

* International Arrivals - Printable Peak Board 


### Minor Changes and Fixes:

* Ingestion of DIMA Data to the Platform is now running. This includes all three files (Traveller Summary, Age and Passport) 

* Sabre RPA System Set Up – Acquires O&D, Leg Statistics and Leg Flow data from the Sabre website on a monthly schedule 

* iPad Tooltip styling has been fixed. A stying change resulted in odd behaviours on certain devices. 

* AMS Amber on time notification have been fixed. Logic for the status of a flight was improved to fix this issue. 

* Signal R will now reconnect when the computer wakes up. Previously after disconnecting Live board data and notifications would stop being received after the disconnect. 

* Beontra will now use the best available baseline if the 5am Baseline is unavailable. If the Beontra dataset fails at 5am the daily forecast will default to the next best available timeslot. 

`