import { eventChannel, END } from 'redux-saga';
import {
  call,
  put,
  take,
  cancelled,
  takeLatest,
  takeEvery,
  fork,
  cancel,
  delay
} from 'redux-saga/effects';
import * as signalR from '@microsoft/signalr';
import * as actionTypes from '../store/actionTypes/signalrActionTypes';
import * as notificationsActionTypes from '../store/actionTypes/notificationActionTypes';
import * as iocActionTypes from '../store/actionTypes/iocActionTypes';
import * as fmActionTypes from '../store/actionTypes/fmActionTypes';
import * as intArrivalsActionTypes from '../store/actionTypes/intArrivalsActionTypes';
import * as gtActionTypes from '../store/actionTypes/gtActionTypes';
import { getTokenWithRefresh } from '../assets/js/config/adalConfig';
import { getEDPSignalrUrl, signalrMethods } from '../constants';
import loglevel from 'loglevel';
// Payload action tyes

const SIGNALR_CHANNEL_NAME = 'edp'; // The name of the EDP SignalR channel
const ONE_MINUTE = 60 * 1000; /* ms */
const DELAY_BEFORE_RECONNECT_IN_MILLISECONDS = 3.5 * 1000; // The milliseonds delay before attempting to reconnect to SignalR after an unexpected dropout
const SIGNALR_SERVER_TIMEOUT_IN_MILLISECONDS = 100000; // The SignalR server timeout in milliseconds
const MAXIMUM_ERRORS = 2; // Maxium number of SignalR errors that can occurr within a specified timeframe
var errors = [];
let connection;
let connectionQueue = [];

const connectToSignalr = (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      let socket = new signalR.HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: getTokenWithRefresh
        })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      socket.serverTimeoutInMilliseconds = SIGNALR_SERVER_TIMEOUT_IN_MILLISECONDS;
      const start = async (socket) => {
        try {
          await socket.start().then(() => {
            resolve(socket);
          });
        } catch (err) {
          loglevel.error(
            `Unable to start the socket connection (${url})`,
            JSON.stringify(err)
          );
          reject('Unable to start the socket connection');
        }
      };
      start(socket);
    } catch (error) {
      loglevel.error(
        'Could not initialise connection with SignalR',
        JSON.stringify(error)
      );
      reject('Could not initialise connection with SignalR');
    }
  });
};

const connectToPendingBoards = () => {
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };
  if (connectionQueue.length > 0) {
    connectionQueue.filter(onlyUnique).forEach((group) => {
      subscribeToGroup(null, group);
    });
  }
};

const createSocketChannel = (socket, channel) => {
  return eventChannel((emit) => {
    socket.on(channel, (data) => {
      emit(data);
    });

    socket.onclose((error) => {
      if (error !== undefined) {
        // Something bad happened causing the socket to close
        emit(new Error(error.message));
      } else {
        // Close initiated on purpose
        errors.length = 0;
        emit(END);
      }
    });

    const unsubscribe = () => {
      socket.stop();
    };

    return unsubscribe;
  });
};

const subscribeToGroup = (action, group) => {
  if (connection === undefined) {
    connectionQueue.push(action.payload);
  } else {
    const subscriptionGroup = group === undefined ? action.payload : group;
    connection
      .invoke(`${subscriptionGroup.signalrSubscribeMethod}`)
      .catch((err) => {
        loglevel.error(
          `Failed to subscribe to a resource ${subscriptionGroup.name}`,
          JSON.stringify(err)
        );
        connectionQueue.push(subscriptionGroup);
      });
  }
};

const unsubscribeFromGroup = (action) => {
  connectionQueue = connectionQueue.filter(
    (connection) => connection !== action.payload
  );
  if (connection) {
    connection
      .invoke(`${action.payload.signalrUnsubscribeMethod}`)
      .catch((err) => {
        loglevel.error(
          'Failed to unsubscribe from a Board',
          JSON.stringify(err)
        );
      });
  }
};

/**
 * TODO: Refactoring
 * - Made some refactoring but can still be refactor
 * @param {*} payload
 */
function* handleNewSignalrPayload(payload) {
  const {
    notifications,
    iocOverview,
    iocT2,
    iocT3,
    iocT4,
    iocSevenDayT1,
    iocSevenDayT2,
    iocSevenDayT3,
    iocSevenDayT4,
    fmDailyBaseLine,
    fmAmenities,
    fmCleaningSchedulesBaseline,
    fmCleaningSchedulesUpdated,
    intArrivalsLive,
    gtOpsCarPark
  } = signalrMethods;

  for (var key in payload) {
    console.log(key, payload);
    // notifications payload handler
    if (key.toLowerCase().includes(notifications.payloadKey)) {
      yield put({
        type: notificationsActionTypes.NEW_NOTIFICATION,
        payload
      });
    }

    // other payload handler
    switch (key) {
      case iocOverview.payloadKey:
        yield put({
          type: iocActionTypes.IOC_OVERVIEW_PAYLOAD_COMPLETED,
          payload
        });
        break;
      case iocT2.payloadKey:
        yield put({
          type: iocActionTypes.IOC_T2_PAYLOAD_COMPLETED,
          payload
        });
        break;
      case iocT3.payloadKey:
        yield put({
          type: iocActionTypes.IOC_T3_PAYLOAD_COMPLETED,
          payload
        });
        break;
      case iocT4.payloadKey:
        yield put({
          type: iocActionTypes.IOC_T4_PAYLOAD_COMPLETED,
          payload
        });
        break;
      case intArrivalsLive.payloadKey:
        yield put({
          type: intArrivalsActionTypes.INT_ARRIVALS_PAYLOAD_COMPLETED,
          payload
        });
        break;
      case iocSevenDayT1.payloadKey:
        yield put({
          type: iocActionTypes.IOC_SEVEN_DAY_T1_PAYLOAD_COMPLETED,
          payload
        });
        break;
      case iocSevenDayT2.payloadKey:
        yield put({
          type: iocActionTypes.IOC_SEVEN_DAY_T2_PAYLOAD_COMPLETED,
          payload
        });
        break;
      case iocSevenDayT3.payloadKey:
        yield put({
          type: iocActionTypes.IOC_SEVEN_DAY_T3_PAYLOAD_COMPLETED,
          payload
        });
        break;
      case iocSevenDayT4.payloadKey:
        yield put({
          type: iocActionTypes.IOC_SEVEN_DAY_T4_PAYLOAD_COMPLETED,
          payload
        });
        break;
      case fmDailyBaseLine.payloadKey:
        yield put({
          type: fmActionTypes.FM_BASELINE_PAYLOAD_COMPLETED,
          payload
        });
        break;
      case fmAmenities.payloadKey:
        yield put({
          type: fmActionTypes.FM_AMENITIES_PAYLOAD_COMPLETED,
          payload
        });
        break;
      case fmCleaningSchedulesUpdated.payloadKey:
        yield put({
          type: fmActionTypes.FM_CLEANING_SCHEDULES_UPDATED_PAYLOAD_COMPLETED,
          payload
        });
        break;
      case fmCleaningSchedulesBaseline.payloadKey:
        yield put({
          type: fmActionTypes.FM_CLEANING_SCHEDULES_BASELINE_PAYLOAD_COMPLETED,
          payload
        });
        break;
      case gtOpsCarPark.payloadKey:
        yield put({
          type: gtActionTypes.GT_CAR_PARK_MVTS_PAYLOAD_COMPLETED,
          payload
        });
        break;

      default:
        break;
    }
  }
}

function* listenForSocketMessages(
  signalrUrl,
  channelName,
  onConnectActionType,
  failedActionType,
  reconnectActionType,
  disconnectActionType
) {
  try {
    var socketChannel;
    // Connect the the SignalR channel
    connection = yield call(connectToSignalr, signalrUrl);
    socketChannel = yield call(createSocketChannel, connection, channelName);
    yield put({ type: onConnectActionType });
    let payload;
    while (true) {
      // wait for a message from the channel
      payload = yield take(socketChannel);
      // New payload received; put the relevant action type
      yield call(handleNewSignalrPayload, payload);
    }
  } catch (error) {
    loglevel.error(`Error fetching message from socket`, error);
    yield put({
      type: failedActionType
    });
    if (
      errors.filter((er) => new Date() - er.date < ONE_MINUTE).length >
      MAXIMUM_ERRORS
    ) {
      loglevel.error('Terminating SignalR connection', {
        message: 'Four exceptions occurred within one minute'
      });
      loglevel.info(`Failed to connect to the data stream`, [
        'Please refresh the page to retry'
      ]);
      yield put({ type: disconnectActionType });
    } else {
      errors.push({ data: error, date: new Date() });
      yield delay(DELAY_BEFORE_RECONNECT_IN_MILLISECONDS);
      yield put({ type: reconnectActionType });
    }
  } finally {
    if (yield cancelled()) {
      // close the channel
      if (socketChannel !== undefined) {
        socketChannel.close();
      }
    }
  }
}

function* connectToEDPChannel(
  signalrUrl,
  channelName,
  onConnectActionType,
  failedActionType,
  reconnectActionType,
  disconnectActionType,
  onDisconnectActionType
) {
  const task = yield fork(
    listenForSocketMessages,
    signalrUrl,
    channelName,
    onConnectActionType,
    failedActionType,
    reconnectActionType,
    disconnectActionType
  );
  // when DISCONNECT action is dispatched, we cancel the socket task
  yield take(disconnectActionType);
  yield cancel(task);
  yield put({ type: onDisconnectActionType });
  // Disconnect successful
}

export default function* signalrWatcher() {
  yield takeEvery(actionTypes.EDP_SIGNALR_SUBSCRIBE_TO_GROUP, subscribeToGroup);
  yield takeEvery(
    actionTypes.EDP_SIGNALR_UNSUBSCRIBE_FROM_GROUP,
    unsubscribeFromGroup
  );
  yield takeEvery(actionTypes.EDP_SIGNALR_CONNECTED, connectToPendingBoards);
  yield takeLatest(
    actionTypes.EDP_SIGNALR_CONNECT, // When this actionType is dispatched...
    connectToEDPChannel, // ...execute this method
    getEDPSignalrUrl, // URL to connect to SignalR
    SIGNALR_CHANNEL_NAME, // The name of the SignalR channel
    actionTypes.EDP_SIGNALR_CONNECTED, // Action Type that will be called when we are connected to SignalR
    actionTypes.EDP_SIGNALR_FAILED, // Failed action type
    actionTypes.EDP_SIGNALR_CONNECT, // Action Type that will be called when establishing a connection SignalR
    actionTypes.EDP_SIGNALR_DISCONNECT, // Action Type that will be called to send a disconnect request to SignalR
    actionTypes.EDP_SIGNALR_DISCONNECTED // Action Type that will be called when disconnected from SignalR
  );
}
