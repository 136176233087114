export default `
# Release Notes
## v5.0.2
10/03/22

### Enhancement

* Identification of deleted flights using FactFlightCapacity
    * SQL Table View that replicates the same column as FactFlightPaxCapacity and tag as "RE".

* ADF Populating Staging Table for AMS Deleted Flight View.
    * Automated run of pipeline activity connected to the Master AMS Schedule.
    * Copies AMS Deleted to Archive.

* Backend components for the Lo-Fi Prototype (T2 and T4)
    * Smart Scheduling Lo-Fi Excel Workbook providing FM Team with a daily recommendation.
 
`;
