import laOpsOverviewImageUrl from '../../../images/laOpsOverview.png';

export default `
# GT Ops - Overview
## Board Overview
This board displays a snapshot of information for the LA Ops team to assist them in their daily operations. Data is displayed in four different cards:

![LA Ops Overview board](${laOpsOverviewImageUrl})

**Card 1** - Internal roads travel time  
**Card 2** - Occupancy  
**Card 3** - Parking (last hour)  
**Card 4** - BPMS Queries  
**Card 5** - Happy face  

## Card 1 - Internal Road Travel Time 
This card displays different metrics for Pick up, Drop off and Airport drive routes.  

1. The far-left column shows the current travel time through the 3 different zones.  
2. The +30 min Prediction shows the predicted travel time in 30 minutes.  
3. The Last Week (Same Time) column shows the time it took to travel at the same time last week.  
4. There are two columns that are updated on a rolling 24hrs basis. These are:  
    a. Peak travel time  
    b. The % of time it has been below 16 minutes 

Zone information:

-	Pick Up: Mickleham Road on-ramp to T1 via Arrivals drive
-	Drop Off: Mickleham Road on-ramp to T1 via Departures drive
-	Airport Drive: Airport Drive (Sharps Rd) to T1 via Arrivals Drive

The coloured status indicator is defined by different time thresholds:

- Green: between 0 and 15min to move through the defined zone
- Red: above 15 minutes to move through the defined zone

The data source is TOM-TOM and the card updates every 15 minutes.
 
## Card 2 - Occupancy 
You can read the current occupancy of the three main carparks both as a value and as a percentage of the capacity.
The red, amber or green indicators also provide a quick visualization of the current capacity:

- Green: up to 69%
- Amber: 70%-89%
- Red: more than 90%

The data source is SkiData and the card updates every 5 minutes.

## Card 3 - Parking (last hour) 
You can view the number of cars that have entered and exited the three main carparks within the last hour, as well as the net change of vehicles.

The red, amber or green indicators also provide a quick visualization of the current capacity:

- Green: Net up to +- 49 vehicles
- Amber: Net between +-50-99
- Red: Net above +-100 

The data source is SkiData and the card updates every 5 minutes.

## Card 4 - BPMS Queries 
This card shows the number of open queries that have been received via email and are still open in the last 3 months. Data is grouped in two ways:  

1. The colours are categorised by time into less than 24hrs, 24 to 72hrs and 72hrs to 3 Months.  
2. The columns are separated by query type into Claims, Complaints and Enquires.  

The data source is BPMS and the card updates every 60 minutes.

## Card 5 - Happy face 
This card shows the number of responses in the last hour and groups them by type. The average score is the average of all the results received in the last hour.
The map view represents the number of responses in each zone. The larger the circle the more responses have been received.
The data source is Happy Face and the card updates every 30 minutes.
`.trim();
