// note: ruleName is used in URL and in code for things relating to the URL
const pagesUrl = '.';

const boards = [
  {
    name: 'Av Comm',
    ruleName: 'avcomm',
    exact: true,
    navChildren: [
      {
        to: '/avcomm/airlineperformance',
        name: 'Airline Performance',
        ruleName: 'avcomm:airlineperformance',
        filePath: pagesUrl + '/AvComm/AirlinePerformance',
        exact: true,
        printable: false
      },
      {
        to: '/avcomm/ausmarket',
        name: 'Australian Market',
        ruleName: 'avcomm:ausmarket',
        filePath: pagesUrl + '/AvComm/AustralianMarket',
        exact: true,
        printable: false
      },
      {
        to: '/avcomm/intmarket',
        name: 'International Market',
        ruleName: 'avcomm:intmarket',
        filePath: pagesUrl + '/AvComm/InternationalMarket',
        exact: true,
        printable: false
      },

      {
        to: '/avcomm/airportgroupings',
        name: 'Airport Groupings',
        ruleName: 'avcomm:airportgroupings',
        filePath: pagesUrl + '/AvComm/AirportGroupings',
        exact: true
      }
    ]
  },
  {
    name: 'Int Arrivals',
    ruleName: 'intarrivals',
    exact: true,
    navChildren: [
      {
        to: '/intarrivals/live',
        name: 'Live',
        ruleName: 'intarrivals:live',
        filePath: pagesUrl + '/IntArrivals/Live/Live',
        exact: true,
        printable: false
      },
      {
        to: '/intarrivals/performance',
        name: 'Performance',
        ruleName: 'intarrivals:performance',
        filePath: pagesUrl + '/IntArrivals/Performance/Performance',
        exact: true,
        printable: false,
        exportable: true
      }
    ]
  },
  {
    name: 'IOC',
    ruleName: 'ioc',
    exact: true,
    navChildren: [
      {
        to: '/ioc/overview',
        name: 'Overview',
        ruleName: 'ioc:overview',
        filePath: pagesUrl + '/IOC/Overview/Overview',
        exact: true,
        printable: false
      },
      {
        to: '/ioc/t2',
        name: 'T2',
        ruleName: 'ioc:t2',
        filePath: pagesUrl + '/IOC/T2',
        exact: true,
        printable: false
      },
      {
        to: '/ioc/t3',
        name: 'T3',
        ruleName: 'ioc:t3',
        filePath: pagesUrl + '/IOC/T3',
        exact: true,
        printable: false
      },
      {
        to: '/ioc/t4',
        name: 'T4',
        ruleName: 'ioc:t4',
        filePath: pagesUrl + '/IOC/T4',
        exact: true,
        printable: false
      },
      {
        to: '/ioc/sevendayplan',
        name: '7 Day Plan',
        ruleName: 'ioc:sevendayplan',
        filePath: pagesUrl + '/IOC/SevenDayBoard/SevenDayBoard',
        exact: true,
        printable: false
      },
      {
        to: '/ioc/extra_sevendayplan',
        name: '7 Day Plan - External',
        ruleName: 'ioc:extra_sevendayplan',
        filePath: pagesUrl + '/IOC/SevenDayBoard/SevenDayBoardExtra',
        exact: true,
        printable: false
      },
      {
        to: '/ioc/aop',
        name: 'Operations Plan',
        ruleName: 'ioc:aop',
        filePath: pagesUrl + '/IOC/Schedules/OpsPlan',
        exact: true,
        printable: false
      },
      {
        name: 'Schedules',
        ruleName: 'ioc:schedules',
        children: [
          {
            to: '/ioc/schedules/weeklyview',
            name: 'Weekly View',
            filePath: pagesUrl + '/IOC/Schedules/WeeklyView',
            exact: true,
            printable: false
          },
          {
            to: '/ioc/schedules/scheduleexport',
            name: 'Schedule Export',
            filePath: pagesUrl + '/IOC/Schedules/ScheduleExport',
            exact: true,
            printable: false
          },
          {
            to: '/ioc/schedules/futureview',
            name: 'Future View',
            filePath: pagesUrl + '/IOC/Schedules/FutureView',
            exact: true,
            printable: false
          },
          {
            to: '/ioc/schedules/fourdayopssummary',
            name: '4 Day Summary',
            filePath: pagesUrl + '/IOC/Schedules/FourDaySummary',
            exact: true,
            printable: false
          }
        ]
      }
    ]
  },
  {
    name: 'FM',
    ruleName: 'fm',
    exact: true,
    navChildren: [
      {
        to: '/fm/cleaningschedules',
        name: 'Cleaning Schedules',
        ruleName: 'fm:cleaningschedules',
        filePath: pagesUrl + '/FM/CleaningSchedules',
        exact: true,
        printable: true
      },
      {
        to: '/fm/cleaningreporting',
        name: 'Cleaning Reporting',
        ruleName: 'fm:cleaningreporting',
        filePath: pagesUrl + '/FM/CleaningReporting',
        exact: true,
        printable: false
      }
    ]
  },
  /* {
    name: 'LA Comm',
    ruleName: 'lacomm',
    exact: true,
    navChildren: [
      {
        to: '/lacomm/revenuesummary',
        name: 'Revenue Summary',
        ruleName: 'lacomm:revenuesummary',
        filePath: pagesUrl + '/LaComm/RevenueSummary',
        exact: true,
        printable: false
      },
      {
        to: '/lacomm/revenuedetails',
        name: 'Revenue Details',
        ruleName: 'lacomm:revenuedetails',
        filePath: pagesUrl + '/LaComm/RevenueDetails',
        exact: true,
        printable: false
      },
      {
        to: '/lacomm/forwardbookings',
        name: 'Forward Bookings',
        ruleName: 'lacomm:forwardbookings',
        filePath: pagesUrl + '/LaComm/ForwardBookings',
        exact: true,
        printable: false
      },
      {
        to: '/lacomm/customerbase',
        name: 'Customer Base',
        ruleName: 'lacomm:customerbase',
        filePath: pagesUrl + '/LaComm/CustomerBase',
        exact: true,
        printable: false
      },
      {
        to: '/lacomm/newcustomers',
        name: 'New Customers',
        ruleName: 'lacomm:newcustomers',
        filePath: pagesUrl + '/LaComm/NewCustomers',
        exact: true,
        printable: false
      }
    ]
  }, */
  {
    name: 'GT Comm',
    ruleName: 'gtcomm',
    exact: true,
    navChildren: [
      {
        to: '/gtcomm/revenuesummary',
        name: 'Revenue Summary',
        ruleName: 'gtcomm:revenuesummary',
        filePath: pagesUrl + '/GTComm/RevenueSummary',
        exact: true,
        printable: false
      },
      {
        to: '/gtcomm/revenuedetails',
        name: 'Revenue Details',
        ruleName: 'gtcomm:revenuedetails',
        filePath: pagesUrl + '/GTComm/RevenueDetails',
        exact: true,
        printable: false
      },
      {
        to: '/gtcomm/forwardbookings',
        name: 'Forward Bookings',
        ruleName: 'gtcomm:forwardbookings',
        filePath: pagesUrl + '/GTComm/ForwardBookings',
        exact: true,
        printable: false
      },
      {
        to: '/gtcomm/customerbase',
        name: 'Customer Base',
        ruleName: 'gtcomm:customerbase',
        filePath: pagesUrl + '/GTComm/CustomerBase',
        exact: true,
        printable: false
      },
      {
        to: '/gtcomm/newcustomers',
        name: 'New Customers',
        ruleName: 'gtcomm:newcustomers',
        filePath: pagesUrl + '/GTComm/NewCustomers',
        exact: true,
        printable: false
      }
    ]
  },
  /* {
    name: 'LA Ops',
    ruleName: 'laops',
    exact: true,
    navChildren: [
      {
        to: '/laops/overview',
        name: 'Overview',
        ruleName: 'laops:overview',
        filePath: pagesUrl + '/LAOps/Overview',
        exact: true,
        printable: false
      },
      {
        to: '/laops/roadandcarpark',
        name: 'Road and Car Park',
        ruleName: 'laops:roadandcarpark',
        filePath: pagesUrl + '/LAOps/RoadAndCarPark',
        exact: true,
        printable: false
      },
      {
        to: '/laops/customerservice',
        name: 'Customer Service',
        ruleName: 'laops:customerservice',
        filePath: pagesUrl + '/LAOps/CustomerService',
        exact: true,
        printable: false
      }
    ]
  }, */
  {
    name: 'GT Ops',
    ruleName: 'gtops',
    exact: true,
    navChildren: [
      {
        to: '/gtops/overview',
        name: 'Overview',
        ruleName: 'gtops:overview',
        filePath: pagesUrl + '/GTOps/Overview',
        exact: true,
        printable: false
      },
      {
        to: '/gtops/roadandcarpark',
        name: 'Road and Car Park',
        ruleName: 'gtops:roadandcarpark',
        filePath: pagesUrl + '/GTOps/RoadAndCarPark',
        exact: true,
        printable: false
      },
      {
        to: '/gtops/customerservice',
        name: 'Customer Service',
        ruleName: 'gtops:customerservice',
        filePath: pagesUrl + '/GTOps/CustomerService',
        exact: true,
        printable: false
      },
      {
        to: '/gtops/sevendayforecastsummary',
        name: '7 Day Forecast Summary',
        ruleName: 'gtops:sevendayforecastsummary',
        filePath: pagesUrl + '/GTOps/SevenDayForecastSummary',
        exact: true,
        printable: false
      },
      {
        to: '/gtops/carparkmovements',
        name: 'Car Park Movements',
        ruleName: 'gtops:carparkmovements',
        filePath: pagesUrl + '/GTOps/CarParkMovements/CarParkMovements',
        exact: true,
        printable: false
      }
    ]
  },
  {
    name: 'TE',
    ruleName: 'te',
    exact: true,
    navChildren: [
      {
        to: '/te/happyface',
        name: 'Happy Face',
        ruleName: 'te:happyface',
        filePath: pagesUrl + '/TravellerExperience/HappyFace',
        exact: true,
        printable: false
      },
      {
        name: 'T2',
        ruleName: 'te:t2',
        children: [
          {
            to: '/te/t2/overview',
            name: 'Overview',
            filePath: pagesUrl + '/TravellerExperience/T2Overview',
            exact: true,
            printable: false
          },
          {
            to: '/te/t2/arrivals',
            name: 'Arrivals',
            filePath: pagesUrl + '/TravellerExperience/T2Arrivals',
            exact: true,
            printable: false
          },
          {
            to: '/te/t2/departures',
            name: 'Departures',
            filePath: pagesUrl + '/TravellerExperience/T2Departures',
            exact: true,
            printable: false
          }
        ]
      },
      {
        name: 'T3',
        ruleName: 'te:t3',
        children: [
          {
            to: '/te/t3/overview',
            name: 'Overview',
            filePath: pagesUrl + '/TravellerExperience/T3Overview',
            exact: true,
            printable: false
          },
          {
            to: '/te/t3/arrivals',
            name: 'Arrivals',
            filePath: pagesUrl + '/TravellerExperience/T3Arrivals',
            exact: true,
            printable: false
          },
          {
            to: '/te/t3/departures',
            name: 'Departures',
            filePath: pagesUrl + '/TravellerExperience/T3Departures',
            exact: true,
            printable: false
          }
        ]
      },
      {
        name: 'T4',
        ruleName: 'te:t4',
        children: [
          {
            to: '/te/t4/overview',
            name: 'Overview',
            filePath: pagesUrl + '/TravellerExperience/T4Overview',
            exact: true,
            printable: false
          },
          {
            to: '/te/t4/arrivals',
            name: 'Arrivals',
            filePath: pagesUrl + '/TravellerExperience/T4Arrivals',
            exact: true,
            printable: false
          },
          {
            to: '/te/t4/departures',
            name: 'Departures',
            filePath: pagesUrl + '/TravellerExperience/T4Departures',
            exact: true,
            printable: false
          }
        ]
      },
      {
        name: 'LA',
        ruleName: 'te:la',
        children: [
          {
            to: '/te/la/overview',
            name: 'Overview',
            filePath: pagesUrl + '/TravellerExperience/LAOverview',
            exact: true,
            printable: false
          },
          {
            to: '/te/la/traveltime',
            name: 'Travel Time',
            filePath: pagesUrl + '/TravellerExperience/LATravelTime',
            exact: true,
            printable: false
          },
          {
            to: '/te/la/parking',
            name: 'Parking',
            filePath: pagesUrl + '/TravellerExperience/LAParking',
            exact: true,
            printable: false
          }
        ]
      }
    ]
  }
];

export default boards;
