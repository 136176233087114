import teHappyFaceImageUrl from '../../../images/te.png';

export default `
# Happy Face Board 
## Board Overview
This board provides an overview of the Happy Face responses received for all devices in the airport. The responses are represented over time and viewed in both graphical and tabular displays.

![Traveller Experience Happy Face board](${teHappyFaceImageUrl})

**Card 1** - Happy Face Graphical View  
**Card 2** - Happy Face Tabular view  

## Card 1 – Happy Face Graphical View
This card shows a graphical representation of the average responses over a selected date range. 
By default, this graph will show for the last 7 days, all areas and the total average of all Happy Face responses. 

- Target and Stretch Target values are displayed as dashed lines on the graph
- You can view the days where Happy Face device responses were lower than targets. The total average of all categories is displayed with the target and stretch target in order to easily assess the device responses as a performance metric. 
- When you select a new area, the graph will update to reflect the area selected. 
- When you select a new timeframe, the graph will update to show the points for the date range selected. You can also increase or decrease the granularity of the data being viewed by clicking the two arrows in the top right of the graph. 
- By making a selection in card 2 the graph will update to only show the selected date points. 

## Card 2 – Happy Face Tabular view
This card shows a tabular representation of the average responses over time. 
By default, this graph will show for the last 7 days, all areas and the total average of all Happy Face Responses. 

- You can get a more detailed breakdown of device responses for each category. 
- By selecting a new area, the graph will update to reflect the chosen area. 
- When you select a new timeframe, the graph will update to show the points for the date range selected. You can also increase or decrease the granularity of the data being viewed by clicking the two arrows in the top right of the graph. 
- By making a selection in card 2, the graph will update to only show the selected date points. 

`.trim();
