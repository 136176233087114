import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import { clockTimeZone, clockTimeFormat } from '../../../constants';
import { ReactComponent as Save } from '../../../assets/img/bookmark_12px_999.svg';
import { ReactComponent as Close } from '../../../assets/img/close-x_14px_000.svg';
import PropTypes from 'prop-types';

const getFormattedTimeStamp = date => {
  let formattedTimeStamp = '-';
  if (date) {
    const timeStamp = moment(date).tz(clockTimeZone);

    // this will check whether data.dateTime is the same day, month AND year as today
    if (moment().isSame(timeStamp, 'day')) {
      // if today is the same date (day, month, year) as the datetime stamp for this notification, display ONLY time
      formattedTimeStamp = timeStamp.format(clockTimeFormat);
    } else {
      // otherwise if show "X day{s} ago"
      // get the start of each day (12am) to count days ago
      const startOfTimeStamp = moment(date).startOf('day');
      const startOfToday = moment().startOf('day');

      const daysAgo = startOfToday.diff(startOfTimeStamp, 'days');
      formattedTimeStamp = (
        <>
          <div>{timeStamp.format(clockTimeFormat)}</div>
          <div>
            {daysAgo} {daysAgo === 1 ? 'day' : 'days'} ago
          </div>
        </>
      );
    }
  }
  return formattedTimeStamp;
};

const handleSave = () => {};

const handleClose = () => {};

const NotificationActions = props => {
  let { date, showSave, showClose } = props;
  const formattedTimeStamp = getFormattedTimeStamp(date);

  return (
    <div className="edp-notification__actions">
      <div className="edp-notification__buttons">
        {showSave && (
          <button
            className="edp-button--unstyled edp-notification__save"
            onClick={handleSave}
            title="Save notification"
          >
            <Save />
          </button>
        )}
        {showClose && (
          <button
            className="edp-button--unstyled edp-notification__close"
            onClick={handleClose}
            title="Close notification"
          >
            <Close />
          </button>
        )}
      </div>
      <div className="edp-notification__timestamp">{formattedTimeStamp}</div>
    </div>
  );
};

NotificationActions.propTypes = {
  date: PropTypes.object.isRequired, // moment date object
  showSave: PropTypes.bool,
  showClose: PropTypes.bool
};

export default NotificationActions;
