import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import cn from 'classnames';
import Header from '../../Utils/Header/Header';
import HelpCentreNavigation from './HelpCentreNavigation';
import HelpCentrePage from './HelpCentrePage';
import helpItemsConfig from './config/helpItems.js';
import withAuth from '../../../hoc/withAuth/withAuth';
import Board from '../../Utils/Board/Board';

class HelpCentre extends Component {
  constructor() {
    super();
    this.state = {
      helpItems: helpItemsConfig,
      dropdownOpen: false,
      activeDropdownId: 0,
      activePageId: 0
    };
  }

  setPage = id => {
    this.setState({ activePageId: id });
  };

  toggleDropdownShowing = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  setDropdown = id => {
    this.setState({ activeDropdownId: id, activePageId: 0 });
  };

  render() {
    let {
      dropdownOpen,
      activeDropdownId,
      activePageId,
      helpItems
    } = this.state;

    const dropdownTitle = helpItems[activeDropdownId].categoryName;
    const dropdownItems = helpItems[activeDropdownId].items;
    const activeHelpItem = helpItems[activeDropdownId].items[activePageId];

    return (
      <Header title={'EDP - Help Centre'}>
        <div className="edp-help-centre remove-gutter">
          <Board minimumPadding>
            <Row style={{ margin: 0 }}>
              <Col className={cn('edp-help-centre__nav-column')} lg="3">
                <HelpCentreNavigation
                  dropdownOpen={dropdownOpen}
                  dropdownTitle={dropdownTitle}
                  helpItems={helpItems}
                  toggleDropdownShowing={this.toggleDropdownShowing}
                  setDropdown={this.setDropdown}
                  setPage={this.setPage}
                  dropdownItems={dropdownItems}
                  activeDropdownId={activeDropdownId}
                  activePageId={activePageId}
                />
              </Col>

              <Col lg="9" className={cn('edp-help-centre__nav-column')}>
                <HelpCentrePage activeHelpItem={activeHelpItem} />
              </Col>
            </Row>
          </Board>
        </div>
      </Header>
    );
  }
}

HelpCentre.displayName = 'HelpCentre';
export default withRouter(withAuth(HelpCentre));
