import * as iocActionTypes from '../actionTypes/iocActionTypes';
import updateObject from '../utility';
import { signalrMethods } from '../../constants';

//
const initialState = {
  // IOC - Overview
  iocOverviewQueueForecast: null,
  iocOverviewTravelTimesToAirport: null,
  iocOverviewInternalRoadsTravelTime: null,
  iocOverviewParking: null,
  iocOverviewWeather: null,

  // IOC - T2
  // manning level
  iocT2ArrivalsManningLevel: null,
  iocT2ArrivalsManningLevelUpdated: false,
  iocT2ArrivalsManningLevelLoading: false,
  iocT2ArrivalsManningLevelError: false,
  // live forecast
  iocT2ZonesForecast: null,
  iocT2SecurityLinesForecast: null,
  // historical
  iocT2ZonesHistory: null,
  iocT2ZonesHistoryLoading: false,
  iocT2ZonesHistoryError: false,
  // flights
  iocT2Flights: null,
  // pax presentation
  iocT2PaxPresentation: null,

  // IOC - T3
  // live forecast
  iocT3ZonesForecast: null,
  iocT3SecurityLinesForecast: null,
  // historical
  iocT3ZonesHistory: null,
  iocT3ZonesHistoryLoading: false,
  iocT3ZonesHistoryError: false,
  // flights
  iocT3Flights: null,
  // pax presentation
  iocT3PaxPresentation: null,

  // IOC - T4
  // live forecast
  iocT4ZonesForecast: null,
  iocT4SecurityLinesForecast: null,
  // historical
  iocT4ZonesHistory: null,
  iocT4ZonesHistoryLoading: false,
  iocT4ZonesHistoryError: false,
  // flights
  iocT4Flights: null,
  // pax presentation
  iocT4PaxPresentation: null,

  // Flight Breakdown data
  iocFlightBreakdownData: null,
  iocFlightBreakdownDataLoading: false,
  iocFlightBreakdownDataError: false,

  // Seven Day Dashboard data
  iocSevenDayDashboardDataT1: null,
  iocSevenDayDashboardDataT1Loading: false,
  iocSevenDayDashboardDataT1Error: false,

  // Seven Day Dashboard data
  iocSevenDayDashboardDataT3: null,
  iocSevenDayDashboardDataT3Loading: false,
  iocSevenDayDashboardDataT3Error: false,

  // Seven Day Dashboard data
  iocSevenDayDashboardDataT4: null,
  iocSevenDayDashboardDataT4Loading: false,
  iocSevenDayDashboardDataT4Error: false,

  // Seven Day Dashboard data
  iocSevenDayDashboardDataT2: null,
  iocSevenDayDashboardDataT2Loading: false,
  iocSevenDayDashboardDataT2Error: false,

  // Operations Plan Post Data
  iocSevenDayAssetPlanUpdating: false,
  iocSevenDayAssetPlanUpdatingLoading: false,
  iocSevenDayAssetPlanUpdatingError: false
};

const filterInvalidZones = (payload) => {
  return payload.filter(
    (x) => !x.zone.match('^[0][.]{1}') // Filter out zones that start with '0.'; e.g. 0. Gates
  );
};

const iocOverviewPayload = (state, payload) => {
  const data = payload.iocOverview;
  if (data) {
    let newState = { ...state };
    const terminalQueueForecast = data.terminalQueueForecast;
    const travelTimesToAirport = data.travelTimesToAirport;
    const internalRoadsTravelTime = data.internalRoads;
    const parking = data.parking;
    const weather = data.weather;

    if (terminalQueueForecast) {
      newState.iocOverviewQueueForecast = terminalQueueForecast;
    }
    if (travelTimesToAirport) {
      newState.iocOverviewTravelTimesToAirport = travelTimesToAirport;
    }
    if (internalRoadsTravelTime) {
      newState.iocOverviewInternalRoadsTravelTime = internalRoadsTravelTime;
    }
    if (parking) {
      newState.iocOverviewParking = parking;
    }
    if (weather) {
      newState.iocOverviewWeather = weather;
    }
    return updateObject(state, newState);
  }
  return state;
};

const iocPayload = (state, payload, terminal) => {
  const zonesForecast = payload[terminal].zonesForecast;
  if (zonesForecast) {
    const newState = {
      [`${terminal}ZonesForecast`]: filterInvalidZones(zonesForecast)
    };
    // if the we're on T2, set the manning level too
    if (terminal === 'iocT2') {
      newState.iocT2ArrivalsManningLevel = zonesForecast[0].manningLevel;
      newState.iocT2ArrivalsManningLevelLoading = false;
      newState.iocT2ArrivalsManningLevelError = false;
    }
    return updateObject(state, newState);
  }

  const securityLinesForecast = payload[terminal].securityLinesForecast;
  if (securityLinesForecast) {
    return updateObject(state, {
      [`${terminal}SecurityLinesForecast`]: securityLinesForecast
    });
  }

  const happyFaceResponses = payload[terminal].happyFace;
  if (happyFaceResponses) {
    return updateObject(state, {
      [`${terminal}HappyFace`]: happyFaceResponses
    });
  }

  const amsFlights = payload[terminal].ams;
  if (amsFlights) {
    return updateObject(state, {
      [`${terminal}Flights`]: amsFlights
    });
  }

  const paxPresentation = payload[terminal].paxPresentation;
  if (paxPresentation) {
    return updateObject(state, {
      [`${terminal}PaxPresentation`]: paxPresentation
    });
  }
  return state;
};

const sevenDayPayload = (state, payload, loading) => {
  let terminalName = 'terminal1';
  const parent = `iocSevenDayDashboardData${payload.terminal}`;

  switch (payload.terminal) {
    case 'T2':
      terminalName = 'terminal2';
      break;
    case 'T3':
      terminalName = 'terminal3';
      break;
    case 'T4':
      terminalName = 'terminal4';
      break;
    default:
      terminalName = 'terminal1';
      break;
  }

  return {
    ...state,
    [parent]: {
      ...state[parent],
      [terminalName]: {
        ...state[parent][terminalName],
        assetOpenClose: [
          ...state[parent][terminalName]['assetOpenClose'].map((row) => {
            return row.date === payload.date ? { ...row, ...payload } : row;
          })
        ]
      }
    },
    iocSevenDayAssetPlanUpdatingLoading: loading
  };
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // IOC - T2
    // POST manning level
    case iocActionTypes.IOC_T2_ARRIVALS_SET_MANNING_LEVEL_START:
      return updateObject(state, {
        iocT2ArrivalsManningLevelLoading: true,
        iocT2ArrivalsManningLevelUpdated: false
      });
    case iocActionTypes.IOC_T2_ARRIVALS_SET_MANNING_LEVEL_COMPLETED:
      return updateObject(state, {
        iocT2ArrivalsManningLevelLoading: false,
        iocT2ArrivalsManningLevelUpdated: true,
        iocT2ArrivalsManningLevel: action.payload.manninglevel
      });
    case iocActionTypes.IOC_T2_ARRIVALS_SET_MANNING_LEVEL_FAILED:
      return updateObject(state, {
        iocT2ArrivalsManningLevelLoading: false,
        iocT2ArrivalsManningLevelUpdated: false,
        iocT2ArrivalsManningLevelError: true
      });
    // beontra data
    case iocActionTypes.IOC_T2_GET_BEONTRA_DATA_COMPLETED:
      const data = filterInvalidZones(action.payload);
      return updateObject(state, {
        iocT2ZonesHistoryLoading: false,
        iocT2ZonesHistory: data
      });
    case iocActionTypes.IOC_T2_GET_BEONTRA_DATA_START:
      return updateObject(state, {
        iocT2ZonesHistoryLoading: true
      });
    case iocActionTypes.IOC_T2_GET_BEONTRA_DATA_FAILED:
      return updateObject(state, {
        iocT2ZonesHistoryLoading: false,
        iocT2ZonesHistoryError: true
      });
    case iocActionTypes.IOC_T2_PAYLOAD_COMPLETED:
      return iocPayload(state, action.payload, signalrMethods.iocT2.payloadKey);

    // IOC - T3
    case iocActionTypes.IOC_T3_GET_BEONTRA_DATA_COMPLETED:
      return updateObject(state, {
        iocT3ZonesHistoryLoading: false,
        iocT3ZonesHistory: action.payload
      });
    case iocActionTypes.IOC_T3_GET_BEONTRA_DATA_START:
      return updateObject(state, {
        iocT3ZonesHistoryLoading: true
      });
    case iocActionTypes.IOC_T3_GET_BEONTRA_DATA_FAILED:
      return updateObject(state, {
        iocT3ZonesHistoryLoading: false,
        iocT3ZonesHistoryError: true
      });
    case iocActionTypes.IOC_T3_PAX_PRESENTATION_DATA_COMPLETED:
      return updateObject(state, {
        iocT3PaxPresentation: action.payload
      });
    case iocActionTypes.IOC_T3_PAYLOAD_COMPLETED:
      return iocPayload(state, action.payload, signalrMethods.iocT3.payloadKey);

    // IOC - T4
    case iocActionTypes.IOC_T4_GET_BEONTRA_DATA_COMPLETED:
      return updateObject(state, {
        iocT4ZonesHistoryLoading: false,
        iocT4ZonesHistory: action.payload
      });
    case iocActionTypes.IOC_T4_GET_BEONTRA_DATA_START:
      return updateObject(state, {
        iocT4ZonesHistoryLoading: true
      });
    case iocActionTypes.IOC_T4_GET_BEONTRA_DATA_FAILED:
      return updateObject(state, {
        iocT4ZonesHistoryLoading: false,
        iocT4ZonesHistoryError: true
      });
    case iocActionTypes.IOC_T4_PAYLOAD_COMPLETED:
      return iocPayload(state, action.payload, signalrMethods.iocT4.payloadKey);

    // Flight Breakdown
    case iocActionTypes.IOC_GET_FLIGHT_BREAKDOWN_DATA_START:
      return updateObject(state, {
        iocFlightBreakdownDataLoading: true,
        iocFlightBreakdownDataError: false
      });
    case iocActionTypes.IOC_GET_FLIGHT_BREAKDOWN_DATA_COMPLETED:
      return updateObject(state, {
        iocFlightBreakdownDataLoading: false,
        iocFlightBreakdownData: action.payload
      });
    case iocActionTypes.IOC_GET_FLIGHT_BREAKDOWN_DATA_FAILED:
      return updateObject(state, {
        iocFlightBreakdownDataLoading: false,
        iocFlightBreakdownDataError: true
      });

    // IOC - Overview
    case iocActionTypes.IOC_OVERVIEW_PAYLOAD_COMPLETED:
      return iocOverviewPayload(state, action.payload);

    // IOC - Seven Day Dashboard
    case iocActionTypes.IOC_SEVEN_DAY_T1_PAYLOAD_COMPLETED:
      return updateObject(state, {
        iocSevenDayDashboardDataT1Loading: false,
        iocSevenDayDashboardDataT1: action.payload
      });
    case iocActionTypes.IOC_GET_SEVEN_DAY_T1_DATA_START:
      return updateObject(state, {
        iocSevenDayDashboardDataT1Loading: true
      });
    case iocActionTypes.IOC_GET_SEVEN_DAY_T1_DATA_FAILED:
      return updateObject(state, {
        iocSevenDayDashboardDataT1Loading: false,
        iocSevenDayDashboardDataT1Error: true
      });

    case iocActionTypes.IOC_SEVEN_DAY_T3_PAYLOAD_COMPLETED:
      return updateObject(state, {
        iocSevenDayDashboardDataT3Loading: false,
        iocSevenDayDashboardDataT3: action.payload
      });
    case iocActionTypes.IOC_GET_SEVEN_DAY_T3_DATA_START:
      return updateObject(state, {
        iocSevenDayDashboardDataT3Loading: true
      });
    case iocActionTypes.IOC_GET_SEVEN_DAY_T3_DATA_FAILED:
      return updateObject(state, {
        iocSevenDayDashboardDataT4Loading: false,
        iocSevenDayDashboardDataT4Error: true
      });

    case iocActionTypes.IOC_SEVEN_DAY_T4_PAYLOAD_COMPLETED:
      return updateObject(state, {
        iocSevenDayDashboardDataT4Loading: false,
        iocSevenDayDashboardDataT4: action.payload
      });
    case iocActionTypes.IOC_GET_SEVEN_DAY_T4_DATA_START:
      return updateObject(state, {
        iocSevenDayDashboardDataT4Loading: true
      });
    case iocActionTypes.IOC_GET_SEVEN_DAY_T4_DATA_FAILED:
      return updateObject(state, {
        iocSevenDayDashboardDataT4Loading: false,
        iocSevenDayDashboardDataT4Error: true
      });

    case iocActionTypes.IOC_SEVEN_DAY_T2_PAYLOAD_COMPLETED:
      return updateObject(state, {
        iocSevenDayDashboardDataT2Loading: false,
        iocSevenDayDashboardDataT2: action.payload
      });
    case iocActionTypes.IOC_GET_SEVEN_DAY_T2_DATA_START:
      return updateObject(state, {
        iocSevenDayDashboardDataT2Loading: true
      });
    case iocActionTypes.IOC_GET_SEVEN_DAY_T2_DATA_FAILED:
      return updateObject(state, {
        iocSevenDayDashboardDataT2Loading: false,
        iocSevenDayDashboardDataT2Error: true
      });

    // POST ASSET SEVEN DAY ASSET PLAN
    case iocActionTypes.IOC_SEVENDAY_ASSET_PLAN_UPDATE_COMPLETED:
      return sevenDayPayload(state, action.payload, false);

    case iocActionTypes.IOC_SEVENDAY_ASSET_PLAN_UPDATE_START:
      return updateObject(state, {
        iocSevenDayAssetPlanUpdatingLoading: true
      });

    case iocActionTypes.IOC_SEVENDAY_ASSET_PLAN_UPDATE_FAILED:
      return updateObject(state, {
        iocSevenDayAssetPlanUpdatingLoading: false,
        iocSevenDayAssetPlanUpdatingError: true
      });

    default:
      return state;
  }
};

export default reducer;
