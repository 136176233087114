import SevenDayForecastSummaryTable from '../../../images/7dfsCarParkMovementsv2.png';

export default `# GT Ops – 7 Day Forecast Summary
## Board Overview

This board provides a high-level snapshot of the forecasted car movements entering and exiting the car parks. It's primary purpose is to support the GT Ops team in anticipating the flow of travelers and ensuring the right resources are in place, thereby improving the capacity planning for the days ahead.

![GT Ops Seven Day Forecast Summary Car Park Movements Table](${SevenDayForecastSummaryTable})

## Card 1 – Daily No. of Online and Drive-up Bookings
This card displays the daily sum of online and drive-up bookings based on the operation schedule from 00:00:00 to 23:59:59. There is a date filter at the top-right corner, giving you the flexibility to view the data in a broader date range from tomorrow until the next 7 days.

Online data use actual bookings to date, applicable to all car parks except staff. Drive-up uses the expected number of drive-ups based on the actual bookings to-date multiplied by a drive-up-to-booking ratio. This ratio is derived from historical data (previous 28 days) of drive-up and booking data. Drive-ups are applicable to all car parks. Bookings are also broken down into arrivals (entries), departures (exits), and net movements (arrivals minus departures).

You can sort the table in ascending or descending order by clicking on any column titles to define the sort order.

The data sources are Advam and Skidata, and the card updates every two hours.
`.trim();
