export default `
# Release Notes
## v2.1.0
19/08/19

### New Features

* Notification:
    * Notification Centre
        * Notification centre is one place for viewing all currently active notifications. 
    * AMS Notification
        * AMS notification are now live. They will appear as a pop-up and can also be found in the Notification Centre. 
    * Aggregated summary of Ops per Terminal
        * Total counts of MVTS
        * Total counts of CNX (Cancelled Flights
            - Does not include non-passenger carrying flights or freighters
        * Total PAX/SEATS of PAX flights
        * DOM and INT Freighters Summary

* International Arrivals ‘Peak’ Boards
    * The International Arrivals Board now has graphs to display monthly peak as well as a daily peak. By clicking on a specific date on the monthly graph, the user can see details of the day in the graph below.

* PGA Ops:  
    * Card Title Updates.
    * Minor cosmetic updates to card and tool tip text and title.


### Bug Fixes

* Authentication Timing Out 
* SSO redirecting to landing page 
* Title and Card changes PGA Overview 
* Alignment of RAG circles on Internal roads card (PGA Overview)
* Green line appearing on Internal Roads card (IOC overview) 
* L2 South Happy Face device in wrong location
* IOC Overview - Travel Time to Airport RAG Statuses
* Monthly View Fixes 
* PGA CS Drilldown - BPMS Tooltip 
* Notification Centre - Received time showing current time 
* Power BI credentials are hardcoded in the application source code. 
* DR: DIMA - Integration 
* DR: BPMS - Integration 
* DR: HappyFace - Integration 
* DR: TomTom - Integration 
* DR: LPR - Integration 
* Monthly Peak Times Board (Int Arrivals) - React 
* Updates to IOC Overview Tech Design - Documentation 
* Updates to PGA Overview Tech Design
* AMS Updates 
* CMX: Return Empty Set when there is no Data 
* PowerBI Performance -Testing Embedding Tiers 
* Notifications Framework - Notification UI - FED 
* Notifications Framework - Integrate With Notification Stream - FED 
* Notifications Framework - Notifications Table/models - Integration 
* Notifications Framework - Send Notifications - Integration 
* Notifications Framework - Signal R Channel - Integration 
* Notifications Framework - Pop-Up Logic - FED 
* Front End Graphing - Library
* Disaster Recovery Testing Plan
* Notifications AMS - Generate AMS Alerts - Integration 
* Notifications Framework - Notification Centre Live - FED 
* Notifications AMS - Previous AMS Alerts Logic - Integration 
* PGA Overview - Happy Face to show 1 hour - Integration 

`